import { EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import { answerSeveritiesLabels } from "../../bootstrap/config";
import { useViewModalContext } from "../../contexts/ViewModalProvider";
import useNavigate from "../../hooks/useNavigate";
import { OpenExternalIcon, SpreadIcon, Trashcan } from "../../icons";
import DocumentIcon from "../../icons/DocumentIcon";
import { IAction } from "../../interfaces/IAction";
import audit from "../../pages/audit";
import Can from "../can";
import UserAvatar from "../UserAvatar";

const ActionSquareHeader = ({
  action,
  onDeleteModalOpen,
  isAuditDetailsPage,
}: {
  action: IAction;
  onDeleteModalOpen: () => void;
  isAuditDetailsPage: boolean;
}) => {
  const { editAction } = useViewModalContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openInNewTab } = useNavigate();

  return (
    <Flex align="center" bg="actionSquare.header.bg" borderRadius="10px" p="12px 16px">
      <Flex align="center" w="full">
        {/* <Checkbox control={control} name="checked" /> HIDDEN FOR NOW */}
        <Flex align="center" h="52px" ml={2} position="relative" w="full">
          <Skeleton isLoaded={!!audit} rounded="full">
            <UserAvatar size="sm" user={action.assignee} withTooltip />
          </Skeleton>
          <Box color="white" m="0" ml={2} overflow="hidden">
            <Text color="actionSquare.header.textWhite" fontSize="smm" fontWeight="bold" lineHeight="20px" noOfLines={1}>
              {action.reference}
            </Text>
            <Text color={`severities.${action?.answer?.severity}`} fontSize="ssm" fontWeight="bold" lineHeight="20px" noOfLines={1}>
              {action?.answer?.severity && answerSeveritiesLabels[action.answer?.severity]}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Menu autoSelect={false} closeOnBlur closeOnSelect={false} isOpen={isOpen} onClose={onClose}>
        <MenuButton
          _active={{ bg: 'actionSquare.header.menuIconBg' }}
          _hover={{ bg: 'actionSquare.header.menuIconBg' }}
          aria-label="menu"
          as={IconButton}
          bg="actionSquare.header.menuIconBg"
          color="actionSquare.header.menuIconColor"
          flex="1"
          h="24px"
          icon={<SpreadIcon h="14px" w="14px" />}
          minW="24px"
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
          w="24px"
        />
        <MenuList border="none" boxShadow="simple" p={2} rounded="lg" w="100px" zIndex={2}>
          <MenuItem
            color="actionSquare.header.menuItemColor"
            fontSize="14px"
            onClick={(e) => {
              e.stopPropagation();
              editAction(action);
            }}
          >
            <EditIcon mr={3} stroke="currentColor" />
            Edit information
          </MenuItem>

          {!isAuditDetailsPage && (
            <>
              <MenuDivider opacity="0.1" w="calc(full - 4px)" />
              <MenuItem
                color="actionSquare.header.menuItemColor"
                fontSize="14px"
                onClick={(e) => {
                  e.stopPropagation();
                  openInNewTab(`/audits/${action.answer?.scope?._id}`);
                }}
              >
                <DocumentIcon mr={3} stroke="currentColor" />
                Related Audit&nbsp;
                <OpenExternalIcon fill="transparent" stroke="actionSquare.section.text" w="12px" />
              </MenuItem>
            </>
          )}
          <Can
            action="actions.delete"
            data={{ auditType: action.answer?.audit?.auditType }}
            yes={() => (
              <>
                <MenuDivider opacity="0.1" />
                <MenuItem
                  color="actionSquare.header.menuItemDangerColor"
                  fontSize="14px"
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteModalOpen();
                  }}
                >
                  <Trashcan mr={3} stroke="currentColor" />
                  Delete action
                </MenuItem>
              </>
            )}
          />
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ActionSquareHeader;
