import { useEffect, useMemo, useRef, useState } from 'react';

import { Box, Button, Flex, Grid, HStack, IconButton, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { capitalize, isEmpty } from 'lodash';

import { actionsSortOptions, auditDetailsTableHeaders, documentsSortOptions, findingsSortOptions, toastFailed } from '../../bootstrap/config';
import ActionProvider from '../../contexts/ActionProvider';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useFiltersContext } from '../../contexts/FiltersProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useSort from '../../hooks/useSort';
import { AddIcon } from '../../icons';
import { TViewMode } from '../../interfaces/TViewMode';
import ActionSquare from '../Actions/ActionSquare';
import ActionTrackerListItem from '../Actions/ActionTrackerListItem';
import Can from '../can';
import ChangeViewButton from '../ChangeViewButton';
import { defaultFileTypes } from '../Documents/DocumentUpload';
import FilterButton from '../FilterButton';
import FindingListItem from '../Findings/FindingListItem';
import FindingSquare from '../Findings/FindingSquare';
import Loader from '../Loader';
import SortButton from '../SortButton';
import DocumentTrackerListItem from '../TrackerItem/DocumentTrackerListItem';
import AuditDetailsTable from './AuditDetailsTable';

const AuditDataDisplay = ({ type, options }: { type: 'actions' | 'findings' | 'documents' | string; options: any }) => {
  const toast = useToast();
  const { loading, error, audit, actions, findings, updateAudit, refetchAudit, refetchFindings, refetchActions, refetchApplicableActions } =
    useAuditContext();
  const {
    filtersValues,
    setUsedFilters,
    setFilters,
    setShowFiltersPanel,
    actionFiltersValue,
    setActionFiltersValue,
    walkItemFiltersValue,
    setWalkItemFiltersValue,
    usedFilters,
    setResourceToFilter,
    cleanFilters,
  } = useFiltersContext();

  const { addAction, addFinding, setSelectedAudit } = useViewModalContext();
  const sortBy = useMemo(() => (type === 'actions' ? actionsSortOptions : type === 'findings' ? findingsSortOptions : documentsSortOptions), [type]);
  const { sortedData, sortOrder, sortType, setSortType, setSortOrder } = useSort(
    type === 'actions' ? actions : type === 'findings' ? findings : audit.attachments || [],
    type === 'documents' ? 'addedAt' : 'title',
    'desc',
  );
  const [viewMode, setViewMode] = useState<TViewMode | undefined>(options.displays?.[0] ?? 'list');

  const tableHeaderElements = useMemo(
    () => (audit.auditType?.type === 'IA' && type === 'findings' ? auditDetailsTableHeaders.iaFindings : auditDetailsTableHeaders[type]),
    [type, audit.auditType?.type],
  );

  // const tableHeaderElements = useMemo(() => auditDetailsTableHeaders[type], [type]);

  const inputFile = useRef<HTMLInputElement>(null);
  const [uploadingFiles, setUploadingFiles] = useState(false);

  useEffect(() => {
    setUsedFilters(options?.allowedFilters);
    setResourceToFilter(type);
    return () => {
      setShowFiltersPanel(false);
      setUsedFilters([]);
    };
  }, [type]);

  const setQuickFilter = (filterName: string, filterValue) => {
    cleanFilters();
    setFilters({ [filterName]: filterValue });
  };

  const isQuickFilterActive = (filterName: string) => {
    if (filterName === '') return filtersValues?.status?.value?.length === 0;

    if (filtersValues?.status?.value) {
      if (filtersValues.status.value.length > 1) return false;
      if (filtersValues.status.value.find((status) => status === filterName)) return true;
    }
    return false;
  };

  useEffect(() => {
    switch (type) {
      case 'actions':
        if (actionFiltersValue && !isEmpty(actionFiltersValue) && !isEmpty(filtersValues) && !isEmpty(usedFilters)) {
          setFilters(actionFiltersValue);
          setActionFiltersValue({});
        }
        break;
      case 'findings':
        if (walkItemFiltersValue && !isEmpty(walkItemFiltersValue) && !isEmpty(walkItemFiltersValue) && !isEmpty(usedFilters)) {
          setFilters(walkItemFiltersValue);
          setWalkItemFiltersValue({});
        }
        break;

      default:
        break;
    }
  }, [filtersValues, usedFilters, setActionFiltersValue, actionFiltersValue, walkItemFiltersValue, setWalkItemFiltersValue, setFilters]);

  useEffect(() => {
    // Parse filters to format expected by GraphQL Query
    const parsedFilters = Object.entries(filtersValues).reduce((acc, filter) => {
      if (!filter || !filter[1] || !options?.allowedFilters.includes(filter[0])) return { ...acc };

      const [key, value] = filter;

      if (
        !value.value ||
        (Array.isArray(value.value) && value.value.length === 0) ||
        (key === 'usersIds' && value.value?.assigneesIds?.length === 0)
      )
        return acc;

      return {
        ...acc,
        [key]: value?.value,
      };
    }, {});

    if (parsedFilters) {
      switch (type) {
        case 'actions':
          refetchActions({
            actionQueryInput: {
              ...parsedFilters,
              findingsIds: findings.map(({ _id }) => _id),
            },
          });
          break;

        case 'findings':
          refetchFindings({
            answerQuery: {
              ...parsedFilters,
              scope: { _id: audit?._id },
            },
          });
          break;

        default:
          break;
      }
    }
  }, [filtersValues]);

  const uploadFiles = async () => {
    if (!inputFile.current || !inputFile.current.files) return;
    const files = Array.from(inputFile.current.files);
    await Promise.all(
      files.map(async (file) => {
        try {
          const documentsData = new FormData();
          documentsData.append('elementId', audit._id);
          documentsData.append('document', file);
          const res = await axios.post(`${process.env.REACT_APP_API_URL}/files/document`, documentsData);
          const currentAttachments = (audit.attachments || []).map(({ id, name, addedAt }) => ({ id, name, addedAt }));
          await updateAudit({ variables: { audit: { _id: audit._id, attachments: [...currentAttachments, ...res.data] } } });
        } catch (error: any) {
          toast({
            ...toastFailed,
            title: 'Failed',
            description: 'Failed to upload document',
          });
        }
      }),
    );
  };

  return (
    <>
      <Box bg="auditItem.tab.bg" borderRadius="20px" p={7} w="full">
        <Flex align="center" justify="space-between">
          <Flex align="center">
            <Text color="#1E1836" fontSize="20px" fontWeight="bold" mr="18px">
              {capitalize(type)}
            </Text>
            <Can
              action="audits.edit"
              data={{ auditType: audit.auditType }}
              yes={() => (
                <IconButton
                  _hover={{ opacity: 0.7 }}
                  aria-label="Add"
                  bg="navigationTop.addButton"
                  boxShadow={['0px 0px 80px rgba(49, 50, 51, 0.25)', 'none']}
                  flexShrink={0}
                  h="1.75rem"
                  icon={<AddIcon h="13.5px" stroke="navigationTop.addIcon" w="13.5px" />}
                  isLoading={uploadingFiles}
                  minW="1.75rem"
                  onClick={() => {
                    switch (type) {
                      case 'actions':
                        addAction(async () => {
                          await refetchActions();
                          await refetchApplicableActions();
                        });
                        break;

                      case 'findings':
                        setSelectedAudit(audit);
                        addFinding(refetchFindings);
                        break;

                      case 'documents':
                        if (inputFile.current) inputFile.current.click();
                        break;

                      default:
                        break;
                    }
                  }}
                  p="0.375rem"
                  rounded="6px"
                />
              )}
            />
          </Flex>
          <Flex>
            {options.displays?.length > 0 && (
              <ChangeViewButton setViewMode={setViewMode} viewMode={viewMode as TViewMode} views={options.displays} />
            )}
            {options.sorts && (
              <SortButton setSortOrder={setSortOrder} setSortType={setSortType} sortBy={sortBy} sortOrder={sortOrder} sortType={sortType} />
            )}
            {options.filters && <FilterButton />}
          </Flex>
        </Flex>
        {error ? (
          <Text>{error.message}</Text>
        ) : loading ? (
          <Loader />
        ) : (
          <Box mt="30px">
            {options.quickFilters?.length > 0 ? (
              <>
                <HStack px={[4, 6]} spacing={2}>
                  <Button
                    _active={{
                      bg: 'auditDataDisplay.quickFilter.active.bg',
                      color: 'auditDataDisplay.quickFilter.active.color',
                    }}
                    _hover={{
                      bg: 'none',
                    }}
                    bg="auditDataDisplay.quickFilter.default.bg"
                    borderRadius="10px"
                    color="auditDataDisplay.quickFilter.default.color"
                    fontSize="smm"
                    fontWeight="bold"
                    h="32px"
                    isActive={isQuickFilterActive('')}
                    onClick={() => setQuickFilter('status', [])}
                    variant="ghost"
                  >
                    All
                  </Button>
                  {options.quickFilters.map((value: string) => (
                    <Button
                      _active={{
                        bg: 'auditDataDisplay.quickFilter.active.bg',
                        color: 'auditDataDisplay.quickFilter.active.color',
                      }}
                      _hover={{
                        bg: 'none',
                      }}
                      bg="auditDataDisplay.quickFilter.default.bg"
                      borderRadius="10px"
                      color="auditDataDisplay.quickFilter.default.color"
                      fontSize="smm"
                      fontWeight="bold"
                      h="32px"
                      isActive={isQuickFilterActive(value)}
                      key={value}
                      onClick={() => setQuickFilter('status', [value])}
                      size="sm"
                    >
                      <Flex align="center">
                        <Text>{capitalize(value)}</Text>
                      </Flex>
                    </Button>
                  ))}
                </HStack>
                <Box px="0">
                  {viewMode === 'grid' ? (
                    <ActionProvider>
                      <Grid
                        display={['grid', 'grid', 'flex']}
                        flexWrap="wrap"
                        gap={[4, 4, 6]}
                        h="fit-content"
                        pt="3"
                        px="0"
                        templateColumns={['repeat(auto-fill, minmax(250px, 1fr))', '']}
                        w="full"
                      >
                        {isEmpty(sortedData) ? (
                          <Text fontSize="18px" fontStyle="italic" h="full" w="full">
                            No {capitalize(type)} found
                          </Text>
                        ) : (
                          sortedData?.map((element) =>
                            type !== 'documents' && type === 'actions' ? (
                              <ActionSquare action={element} key={element?._id} />
                            ) : (
                              <FindingSquare finding={element} key={element?._id} />
                            ),
                          )
                        )}
                      </Grid>
                    </ActionProvider>
                  ) : (
                    <AuditDetailsTable
                      headerElements={tableHeaderElements}
                      setSortOrder={setSortOrder}
                      setSortType={setSortType}
                      sortOrder={sortOrder}
                      sortType={sortType}
                    >
                      {isEmpty(sortedData) ? (
                        <Text fontSize="18px" fontStyle="italic" h="full" py="4" w="full">
                          No {capitalize(type)} found
                        </Text>
                      ) : (
                        <Flex flexDir="column" w="full">
                          {sortedData?.map((element) =>
                            type === 'actions' ? (
                              <ActionTrackerListItem action={element} key={element?._id} />
                            ) : (
                              <FindingListItem finding={element} key={element?._id} />
                            ),
                          )}
                        </Flex>
                      )}
                    </AuditDetailsTable>
                  )}
                </Box>
              </>
            ) : (
              <Box>
                <input
                  accept={defaultFileTypes.join(',')}
                  id="file"
                  multiple
                  onChange={async () => {
                    setUploadingFiles(true);
                    await uploadFiles();
                    setUploadingFiles(false);
                    refetchAudit();
                  }}
                  ref={inputFile}
                  style={{ display: 'none' }}
                  type="file"
                />
                {isEmpty(sortedData) ? (
                  <Text fontSize="18px" fontStyle="italic" h="full" py="4" w="full">
                    No {capitalize(type)} found
                  </Text>
                ) : (
                  <AuditDetailsTable
                    headerElements={tableHeaderElements}
                    setSortOrder={setSortOrder}
                    setSortType={setSortType}
                    sortOrder={sortOrder}
                    sortType={sortType}
                  >
                    <Flex flexDir="column" h={['full', 'auto']} overflowY="auto" w="full">
                      {sortedData.map((document) => (
                        <DocumentTrackerListItem document={document} key={document.id} />
                      ))}
                    </Flex>
                  </AuditDetailsTable>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default AuditDataDisplay;

export const auditDataDisplayStyles = {
  auditDataDisplay: {
    quickFilter: {
      default: {
        bg: 'transparent',
        color: '#1E1836',
      },
      active: {
        bg: '#1E1836',
        color: '#FFFFFF',
      },
    },
  },
};
