import { createIcon } from '@chakra-ui/icons';

const Filter = createIcon({
  viewBox: '0 0 12 18',
  path: (
    <g>
      <path d="M0.5 17L0.499999 1" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M4.15381 15.2962L4.15381 2.70382" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M11.4612 11.3324L11.4612 6.66754" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M7.80737 13.5916L7.80737 4.40836" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default Filter;
