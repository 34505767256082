import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';
import { EditIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import pluralize from 'pluralize';

import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useNavigate from '../../hooks/useNavigate';
import { CheckIcon, Eye, SpreadIcon, Trashcan, WarningIcon } from '../../icons';
import { IAudit } from '../../interfaces/IAudit';
import Can from '../can';
import DeleteModal from '../Modals/DeleteModal';

const GET_AUDIT_ANSWERS_COUNT = gql`
  query ($auditId: ID!) {
    auditAnswersCount(auditId: $auditId)
  }
`;
const GET_APPLICABLE_ACTIONS = gql`
  query ($auditId: ID!) {
    auditApplicableActions(auditId: $auditId) {
      mandatory
      recommendation
      severe
      major
      minor
      ofi
      internal
      cutssharps
      entraptedpinchedcaughtbyobjectequipment
      exposurecontactwithharmfulsubstance
      fireorexplosion
      manualhandling
      miscellaneousother
      peopleobjectsfallingfromheight
      repetitivestraininjury
      roadtrafficaccident
      slipsandtrips
      struckbyagainst
      temperaturerelatedegburnsorscalds
    }
  }
`;

const AuditSquare = ({ audit }: { audit: IAudit }) => {
  const { navigateTo } = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { editAudit } = useViewModalContext();

  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();
  const { data: answersData, loading: answersLoading } = useQuery(GET_AUDIT_ANSWERS_COUNT, {
    variables: {
      auditId: audit._id,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: actionsData, loading: actionsLoading } = useQuery(GET_APPLICABLE_ACTIONS, {
    variables: {
      auditId: audit._id,
    },
    fetchPolicy: 'cache-and-network',
  });
  const actionsCount = useMemo(
    () =>
      Object.entries((actionsData?.auditApplicableActions as { [category: string]: number[] }) || {}).reduce(
        (acc, [, value]) => {
          if (typeof value[0] !== 'number') return acc;
          return {
            overdue: acc.overdue + value[0],
            total: acc.total + value[1],
          };
        },
        { total: 0, overdue: 0 },
      ),
    [JSON.stringify(actionsData)],
  );

  return (
    <>
      {isDeleteModalOpen && (
        <DeleteModal
          id={audit._id as string}
          isDeleteModalOpen={isDeleteModalOpen}
          onDeleteModalClose={onDeleteModalClose}
          reference={audit.reference as string}
          title={audit.title as string}
          type="audit"
        />
      )}
      <Stack
        _hover={{ boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.18)' }}
        bg="white"
        borderRadius="20px"
        boxShadow="sm"
        cursor="pointer"
        flexShrink={0}
        onClick={() => !audit?.metatags?.removedBy && navigateTo(`/audits/${audit._id}`)}
        p={3}
        pb={5}
        spacing={6}
        w={['full', 'full', '364px']}
      >
        <Flex align="center" bg="#1E1836" borderRadius="10px" h="64px" justify="space-between" p="12px 16px">
          <Box m="0" noOfLines={1}>
            <Text color="auditSquare.header.text" fontSize="xs" fontWeight="semi_medium" lineHeight="16px" ml={2} noOfLines={1}>
              {audit.auditType?.name}
            </Text>
            <Text color="auditSquare.header.text" fontSize="smm" fontWeight="bold" lineHeight="20px" ml={2} noOfLines={1}>
              {`${audit.reference} - ${audit.title}`}
            </Text>
          </Box>
          <Menu autoSelect={false} closeOnBlur closeOnSelect={false} isOpen={isOpen} onClose={onClose}>
            <MenuButton
              _active={{ bg: 'auditSquare.header.menuIconBg' }}
              _hover={{ bg: 'auditSquare.header.menuIconBg' }}
              aria-label="menu"
              as={IconButton}
              bg="actionSquare.header.menuIconBg"
              color="actionSquare.header.menuIconColor"
              h="24px"
              icon={<SpreadIcon h="14px" w="14px" />}
              minW="24px"
              onClick={(e) => {
                e.stopPropagation();
                onOpen();
              }}
              w="24px"
            />
            <MenuList border="none" boxShadow="simple" p={2} rounded="lg" w="100px" zIndex={2}>
              <Can
                action="audits.edit"
                data={{ auditType: audit.auditType }}
                no={() => (
                  <MenuItem
                    color="auditSquare.header.menuItemColor"
                    fontSize="14px"
                    onClick={() => !audit?.metatags?.removedBy && navigateTo(`/audits/${audit._id}`)}
                  >
                    <Eye mr={3} stroke="currentColor" />
                    View information
                  </MenuItem>
                )}
                yes={() => (
                  <MenuItem
                    color="auditSquare.header.menuItemColor"
                    fontSize="14px"
                    onClick={(e) => {
                      e.stopPropagation();
                      editAudit(audit);
                    }}
                  >
                    <EditIcon mr={3} stroke="currentColor" />
                    Edit information
                  </MenuItem>
                )}
              />
              <MenuDivider opacity="0.1" w="calc(full - 4px)" />
              <MenuItem
                color="auditSquare.header.menuItemColor"
                fontSize="14px"
                onClick={(e) => {
                  e.stopPropagation();
                  navigateTo(`/audits/${audit._id}?selectedTab=actions`);
                }}
              >
                <CheckIcon mr={3} stroke="currentColor" />
                View actions
              </MenuItem>
              <MenuItem
                color="auditSquare.header.menuItemColor"
                fontSize="14px"
                onClick={(e) => {
                  e.stopPropagation();
                  navigateTo(`/audits/${audit._id}?selectedTab=findings`);
                }}
              >
                <Eye mr={3} stroke="currentColor" />
                View findings
              </MenuItem>

              <Can
                action="audits.delete"
                data={{ auditType: audit.auditType }}
                yes={() => (
                  <>
                    <MenuDivider opacity="0.1" />
                    <MenuItem
                      color="auditSquare.header.menuItemDangerColor"
                      fontSize="14px"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteModalOpen();
                      }}
                    >
                      <Trashcan mr={3} stroke="currentColor" />
                      Delete audit
                    </MenuItem>
                  </>
                )}
              />
            </MenuList>
          </Menu>
        </Flex>
        <Flex align="center" mt="12px !important" px="12px" w="full">
          <Box lineHeight="16px" mr="12px" w="212px">
            <Text color="auditSquare.section.title" fontSize={['smm', 'ssm']} fontWeight="semi_medium">
              Business area
            </Text>
            <Text color="auditSquare.section.text" fontSize="smm" fontWeight="semi_medium" noOfLines={1}>
              {audit.location?.name}
            </Text>
          </Box>
          <Box lineHeight="16px" w="212px">
            <Text color="auditSquare.section.title" fontSize={['smm', 'ssm']} fontWeight="semi_medium">
              Business Group
            </Text>
            <Text color="auditSquare.section.text" fontSize="smm" fontWeight="semi_medium" noOfLines={1}>
              {audit.businessUnit?.name}
            </Text>
          </Box>
        </Flex>
        <Flex align="center" mt="12px !important" px="12px" w="full">
          <Box lineHeight="16px" mr="12px" w="212px">
            <Text color="auditSquare.section.title" fontSize={['smm', 'ssm']} fontWeight="semi_medium">
              Report date
            </Text>
            <Text color="auditSquare.section.text" fontSize="smm" fontWeight="semi_medium">
              {format(new Date(audit.reportDate), 'd/L/yyyy')}
            </Text>
          </Box>
          <Box lineHeight="16px" w="212px">
            <Text color="auditSquare.section.title" fontSize={['smm', 'ssm']} fontWeight="semi_medium">
              Actions
            </Text>
            <Flex align="center">
              <Tooltip
                bg="#E93C44"
                borderRadius="8px"
                color="#FFFFFF"
                fontSize="11px"
                fontWeight="400"
                hasArrow
                isDisabled={actionsCount.overdue <= 0}
                label={`${actionsCount.overdue} Overdue ${pluralize('action', actionsCount.overdue)}`}
                lineHeight="16px"
                p="6px 12px"
              >
                <Flex>
                  {actionsCount.overdue > 0 && (
                    <WarningIcon bg="#E93C44" borderRadius="3px" fill="#E93C44" mr="1.5" p="2px" stroke="white" />
                  )}
                  {actionsLoading ? (
                    <SkeletonText borderRadius="20px" mt="2" noOfLines={1} />
                  ) : (
                    <Text color="auditSquare.section.text" fontSize="smm">
                      {actionsCount.total}
                    </Text>
                  )}
                </Flex>
              </Tooltip>
            </Flex>
          </Box>
        </Flex>
        <Flex align="center" mt="12px !important" px="12px" w="full">
          <Box lineHeight="16px" mr="12px" overflow="hidden" w="212px">
            <Text color="auditSquare.section.title" fontSize={['smm', 'ssm']} fontWeight="semi_medium" lineHeight="16px">
              Key contact
            </Text>
            <Skeleton isLoaded={!!audit} rounded="full">
              {audit.auditor || audit.leadAuditorName ? (
                <Flex align="center" direction="row" pr={1}>
                  <Avatar name={audit.leadAuditor?.displayName ?? audit?.leadAuditorName} size="xs" src={audit.leadAuditor?.imgUrl ?? ''} />
                  <Box lineHeight="13px" ml="5px" overflow="hidden" textOverflow="ellipsis" w="212px" whiteSpace="nowrap">
                    <Text color="auditsList.fontColor" fontSize="ssm" isTruncated>
                      {audit.leadAuditor?.displayName ?? audit?.leadAuditorName}
                    </Text>
                    {!audit.leadAuditorName && (
                      <Text color="auditsList.secondary.fontColor" fontSize="xs" isTruncated>
                        {audit.leadAuditor?.jobTitle ? audit.leadAuditor?.jobTitle : 'No job title'}
                      </Text>
                    )}
                  </Box>
                </Flex>
              ) : (
                <Flex fontSize="smm" fontStyle="italic" pr={1}>
                  Unassigned
                </Flex>
              )}
            </Skeleton>
          </Box>
          <Box lineHeight="20px" w="212px">
            <Text color="auditSquare.section.title" fontSize={['smm', 'ssm']} fontWeight="semi_medium">
              Findings
            </Text>
            {answersLoading ? (
              <SkeletonText borderRadius="20px" mt="2" noOfLines={1} />
            ) : (
              <Flex align="center">
                <Text color="auditSquare.section.text" fontSize="smm" fontWeight="semi_medium">
                  {`${answersData?.auditAnswersCount || 'No'} open ${pluralize('finding', answersData?.auditAnswersCount || 0)}`}
                </Text>
              </Flex>
            )}
          </Box>
        </Flex>
      </Stack>
    </>
  );
};

export default AuditSquare;

export const auditSquareStyles = {
  auditSquare: {
    header: {
      text: '#ffffff',
      menuIconBg: '#4b465e',
      menuIconColor: '#FFFFFF',
      menuItemColor: '#1E1836',
      menuItemDangerColor: 'red',
    },
    section: {
      title: '#787486',
      text: '#1E1836',
    },
  },
};
