import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

import { useActionContext } from '../../contexts/ActionProvider';
import { useAuditModalContext } from '../../contexts/AuditModalProvider';
import { useAuditContext } from '../../contexts/AuditProvider';
import useNavigate from '../../hooks/useNavigate';

interface IAuditDeleteModal {
  title: string;
  reference?: string;
  id: string;
  type: 'audit' | 'action' | 'document';
  isDeleteModalOpen: boolean;
  onDeleteModalClose: () => void;
  onOuterModalClose?: () => void;
}

const DeleteModal = ({ title, reference, id, type, isDeleteModalOpen, onDeleteModalClose, onOuterModalClose }: IAuditDeleteModal) => {
  const { handleDeleteAudit } = useAuditModalContext();
  const { handleDeleteAction } = useActionContext();
  const { audit, updateAudit, refetchAudit, refetchActions } = useAuditContext();
  const { isPathActive } = useNavigate();

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isDeleteModalOpen}
      onClose={onDeleteModalClose}
      onEsc={onDeleteModalClose}
      variant="deleteModal"
    >
      <ModalOverlay />
      <ModalContent rounded="20px">
        <ModalHeader>
          <Text color="deleteModal.font.color" fontSize="lg" fontWeight="bold">
            Confirm deletion
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Stack lineHeight="20px">
            <Text color="deleteModal.font.color" fontSize="smm" fontWeight="semi_medium">
              Are you sure you want to delete the "{type === 'audit' ? `${reference} ${title}` : title}"?
            </Text>
            <Text color="deleteModal.font.color" fontSize="smm" fontWeight="semi_medium">
              This action cannot be undone.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end" spacing={4} w="full">
            <Button
              _hover={{ opacity: 0.7 }}
              bg="deleteModal.cancelButton.bg"
              color="deleteModal.cancelButton.color"
              onClick={onDeleteModalClose}
              rounded="10px"
            >
              Cancel
            </Button>
            <Button
              _hover={{ opacity: 0.7 }}
              bg="deleteModal.deleteButton.bg"
              color="deleteModal.deleteButton.color"
              onClick={async () => {
                switch (type) {
                  case 'audit':
                    handleDeleteAudit(id);
                    break;
                  case 'action':
                    await handleDeleteAction(id);
                    if (isPathActive('/audits')) refetchActions();
                    break;
                  case 'document': {
                    const currentAttachments = (audit.attachments || []).map(({ id, name, addedAt }) => ({ id, name, addedAt }));
                    await updateAudit({
                      variables: { audit: { _id: audit._id, attachments: currentAttachments.filter((att) => att.id !== id) } },
                    });
                    refetchAudit();
                    break;
                  }
                  default:
                    break;
                }
                if (onOuterModalClose) onOuterModalClose();
                onDeleteModalClose();
              }}
              rounded="10px"
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;

export const deleteModalStyles = {
  deleteModal: {
    deleteButton: {
      color: '#ffffff',
      bg: '#dc0043',
    },
    cancelButton: {
      color: '#787486',
      bg: '#F0F2F5',
    },
    font: {
      color: '#1E1836',
    },
  },
};
