import { Icon, IconProps } from '@chakra-ui/icons';

const ExportIcon = (props: IconProps) => (
  <Icon viewBox="0 0 12 12" {...props}>
    <path
      d="M6.66667 0.666748H2.53333C2.23878 0.666748 2 0.985123 2 1.37786V10.6223C2 11.015 2.23878 11.3334 2.53333 11.3334H9.46667C9.76122 11.3334 10 11.015 10 10.6223V4.00008M6.66667 0.666748V3.33341C6.66667 3.66675 7 4.00008 7.33333 4.00008H10M6.66667 0.666748L10 4.00008"
      stroke="#1E1836"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M4 8.66675H8" stroke="#1E1836" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4 6.66675H8" stroke="#1E1836" strokeLinecap="round" strokeLinejoin="round" />
  </Icon>
);

export default ExportIcon;
