import { createIcon } from '@chakra-ui/icons';

const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 15 14',
  path: (
    <g>
      <path d="M7.3158 0.518188V13.4818" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.833984 7H13.7976" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
});

export default PlusIcon;
