import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import h2p from 'html2plaintext';
import { isEmpty, omit } from 'lodash';

import { actionsSeveritiesByAuditType, toastFailed, toastSuccess } from '../../bootstrap/config';
import { useAppContext } from '../../contexts/AppProvider';
import { useAuditContext } from '../../contexts/AuditProvider';
import useDevice from '../../hooks/useDevice';
import { ChevronRight, Close, OpenExternalIcon, RefreshIcon } from '../../icons';
import { IAction } from '../../interfaces/IAction';
import { IAnswer } from '../../interfaces/IAnswer';
import { IAudit } from '../../interfaces/IAudit';
import { IUser } from '../../interfaces/IUser';
import ActionModal from '../Actions/ActionModal';
import Can, { isPermitted } from '../can';
import { Datepicker, Dropdown, TextInput } from '../Forms';
import RichTextEditor from '../Forms/RichTextEditor';
import SelectionModal from '../Selection/SelectionModal';
import UserAvatar from '../UserAvatar';

interface IFindingModal {
  finding?: IAnswer;
  onCloseModal: () => void;
  modalType: 'add' | 'edit';
  isOpenInActionModal?: boolean;
  handleCreatedFinding?: (data: Partial<IAnswer>) => void;
}

const GET_FORM_DATA = gql`
  query ($businessUnitQueryInput: BusinessUnitQueryInput) {
    businessUnits(businessUnitQueryInput: $businessUnitQueryInput) {
      _id
      name
      locationId
    }
  }
`;

const GET_USERS = gql`
  query {
    users {
      _id
      displayName
    }
  }
`;

const CREATE_FINDING = gql`
  mutation CreateFinding($answer: AnswerCreateInput!) {
    createAnswer(answer: $answer) {
      _id
      finding
      severity
    }
  }
`;

const UPDATE_FINDING = gql`
  mutation UpdateFinding($answerInput: AnswerModifyInput!) {
    updateAnswer(answerInput: $answerInput) {
      _id
      finding
      severity
    }
  }
`;

export const useFindindModalForm = () => {
  const defaultValues: Partial<IAnswer> = {
    reference: '',
    finding: '',
    details: '',
    severity: '',
    groupWide: false,
  };

  const fields = useForm({
    mode: 'all',
    defaultValues,
  });

  return fields;
};

const FindingModal = ({ finding, modalType, onCloseModal, handleCreatedFinding, isOpenInActionModal }: IFindingModal) => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const device = useDevice();
  const { user } = useAppContext();
  const { audit, refetchApplicableActions, refetchFindings, refetchActions } = useAuditContext();
  const [users, setUsers] = useState<IUser[]>([] as IUser[]);
  const [selectedAudit, setSelectedAudit] = useState<IAudit>(audit);
  const { isOpen: isSelectionModalOpen, onClose: handleSelectionModalClose, onOpen: handleSelectionModalOpen } = useDisclosure();
  const [createdActions, setCreatedActions] = useState<Partial<IAction>[]>(finding?.actions ?? ([] as Partial<IAction>[]));
  const [userName, setUserName] = useState<string>('');
  const [savingFinding, setSavingFinding] = useState(false);

  const { data, refetch: refetchBusinessGroups } = useQuery(GET_FORM_DATA, {
    variables: {
      businessUnitQueryInput: {
        locationId: selectedAudit?.locationId,
      },
    },
  });

  const severityOptions = useMemo(() => {
    refetchBusinessGroups();
    if (!selectedAudit?.auditType) return undefined;
    return actionsSeveritiesByAuditType[selectedAudit?.auditType?.type]?.map((severity) => ({
      value: severity?.toLowerCase().replace(/\/|\.| /g, ''),
      label: severity,
    }));
  }, [selectedAudit]);
  const hasEditPermission = isPermitted({ user, action: 'answers.edit', data: { auditType: selectedAudit?.auditType } });

  // gql methods
  const { data: userData } = useQuery(GET_USERS);
  const [createFinding] = useMutation(CREATE_FINDING);
  const [updateFinding] = useMutation(UPDATE_FINDING);

  // form
  const { control, formState, watch, reset } = useFindindModalForm();
  const values = watch();
  const { isValid } = formState;

  const handleSelectionModalReset = () => {
    handleSelectionModalOpen();
    setSelectedAudit({} as IAudit);
  };

  const handleCreatedAction = (action: Partial<IAction>) => {
    const actionWithoutRedundantFields = omit(action, ['noteInput']);
    setCreatedActions((prevActions) => [...prevActions, actionWithoutRedundantFields]);
  };

  useEffect(() => {
    if (modalType === 'edit') {
      reset({
        _id: finding?._id,
        reference: finding?.reference,
        finding: finding?.finding,
        severity: finding?.severity,
        date: finding?.date,
        details: finding?.details,
        ...(finding?.businessUnitId && { businessUnitId: finding?.businessUnitId }),
      });
    }
  }, [JSON.stringify(finding)]);

  useEffect(() => {
    if (!isEmpty(selectedAudit)) {
      const severity = actionsSeveritiesByAuditType[selectedAudit?.auditType?.type || ''].map((severity: string) => severity.toLowerCase().replace(/\/|\.| /g, ''));
      if (!severity.includes(finding?.severity || values.severity)) reset({ ...values, severity: '' });
    }
  }, [selectedAudit]);

  useEffect(() => {
    if (userData) setUsers([...userData.users]);
    else setUsers([]);
  }, [userData]);

  const handleFindingOperation = async (operationType: string) => {
    if (selectedAudit?.auditType?.type === 'IA' || audit?.auditType?.type === 'IA') delete values.businessUnitId;
    try {
      if (operationType === 'add') {
        const { data } = await createFinding({
          variables: {
            answer: {
              ...values,
              ...(!isEmpty(createdActions) && {
                actions: createdActions,
              }),
              scope: {
                type: 'audit',
                _id: selectedAudit?._id,
              },
            },
          },
        });
        if (handleCreatedFinding) handleCreatedFinding({ ...values, ...data.createAnswer, audit: selectedAudit });
      } else {
        await updateFinding({
          variables: {
            answerInput: {
              _id: finding?._id,
              ...values,
              scope: {
                type: 'audit',
                _id: selectedAudit?._id,
              },
            },
          },
        });
      }
      if (audit) {
        refetchApplicableActions();
        refetchFindings();
        refetchActions();
      }
      toast({ ...toastSuccess, description: 'Finding saved' });
    } catch (e: any) {
      toast({
        ...toastFailed,
        description: e.message,
      });
    } finally {
      onCloseModal();
    }
  };

  const renderCreateActionModal = (): JSX.Element => (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={device === 'desktop' || device === 'tablet' ? 'sm' : 'full'}
      variant="modalOverModal"
    >
      <ModalOverlay />
      <ActionModal
        finding={{ ...values, audit: selectedAudit }}
        handleCreatedAction={handleCreatedAction}
        isOpenInFindingModal
        modalType="add"
        onCloseModal={onClose}
      />
    </Modal>
  );

  return (
    <>
      {isOpen && renderCreateActionModal()}
      <SelectionModal
        isSelectionModalOpen={isSelectionModalOpen}
        onSelectionModalClose={handleSelectionModalClose}
        scope
        selectModalCategory="audit"
        setAddSelectedData={setSelectedAudit}
      />
      <ModalContent bg="findingModal.bg.modalContent" h={['auto', '100vh']} m="0" overflow="hidden" p={4} rounded="0">
        <ModalHeader alignItems="center" fontSize="xxl" fontWeight="bold" p="0">
          <Flex justifyContent="space-between">
            <Text alignItems="center" color="findingModal.textColor.header" fontSize={['18px', '24px']} isTruncated>
              {modalType === 'add' ? 'Add a finding' : finding?.finding}
            </Text>
            <Flex alignItems="center">
              <Close cursor="pointer" h="15px" onClick={onCloseModal} stroke="findingModal.icon.close" w="15px" />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody h="calc(100% - 2rem)" p="20px 0 0 0">
          <Flex bg="findingModal.bg.modalBody" borderRadius="11px" direction="column" h="calc(100% - 70px)">
            <Stack h="100%" overflowX="hidden" overflowY="auto" p={4} spacing={2}>
              {modalType === 'edit' && (
                <Grid columnGap={4} rowGap={4} templateColumns="repeat(2, 1fr)">
                  <GridItem>
                    <Text color="auditActionForm.labelFont.normal" fontSize="11px" fontWeight="bold">
                      Date created
                    </Text>
                    <Text fontSize="13px">
                      {format(finding?.metatags?.addedAt ? new Date(finding?.metatags?.addedAt) : new Date(), 'd MMM yyyy')}
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Text color="auditActionForm.labelFont.normal" fontSize="11px" fontWeight="bold">
                      Created by
                    </Text>
                    <Flex align="center" direction="row" mt={1}>
                      <UserAvatar
                        callback={(user) => setUserName(user?.displayName)}
                        name={finding?.metatags?.addedBy}
                        size="xs"
                        userId={finding?.metatags?.addedBy || ''}
                      />
                      {userName && (
                        <Text
                          fontSize="13px"
                          lineHeight="17px"
                          opacity="1"
                          overflow="hidden"
                          pl={2}
                          textOverflow="ellipsis"
                          w="full"
                          whiteSpace="nowrap"
                        >
                          {userName}
                        </Text>
                      )}
                    </Flex>
                  </GridItem>
                </Grid>
              )}
              <Text color="findingModal.textColor.header" fontSize="smm" fontWeight="bold">
                Applicable audit
              </Text>
              {isEmpty(selectedAudit) ? (
                <HStack>
                  <Button
                    _hover={{ bg: 'findingModal.hover.addButton' }}
                    bg="findingModal.bg.actionButton"
                    color="findingModal.textColor.actionButton"
                    fontSize="ssm"
                    fontWeight="bold"
                    h="28px"
                    onClick={handleSelectionModalOpen}
                    px={4}
                    rounded="10px"
                  >
                    Select an audit
                  </Button>
                </HStack>
              ) : (
                <HStack
                  alignItems="center"
                  bg="findingModal.bg.audit"
                  boxShadow="simple"
                  justify="space-between"
                  px={6}
                  py={4}
                  rounded="10px"
                  spacing={2}
                >
                  <Stack justifyContent="space-between" overflow="hidden" spacing={0}>
                    <Stack
                      _hover={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      alignItems="center"
                      direction="row"
                      // onClick={() => openInNewTab(`/audits/${walkItem?.audit?._id}`)}
                      spacing={2}
                    >
                      <Text color="findingModal.textColor.audit" fontSize="smm" isTruncated>
                        {`${selectedAudit?.reference} - ${selectedAudit?.title}`}
                      </Text>
                      <OpenExternalIcon fill="transparent" stroke="findingModal.icon.openExternal" w="12px" />
                    </Stack>
                    <Text color="findingModal.textColor.audit" fontSize="ssm" opacity="0.4">
                      {selectedAudit?.auditType?.name}
                    </Text>
                  </Stack>
                  {!audit && (
                    <Can
                      action="answers.edit"
                      data={{ auditType: selectedAudit?.auditType }}
                      yes={() => <RefreshIcon cursor="pointer" onClick={handleSelectionModalReset} stroke="findingModal.icon.refresh" />}
                    />
                  )}
                </HStack>
              )}
              <Text color="findingModal.textColor.header" fontSize="smm" fontWeight="bold">
                Finding details
              </Text>
              {audit?.auditType?.type !== 'IA' && selectedAudit?.auditType?.type !== 'IA' && (
                <Dropdown
                  color="auditModal.textColor.field"
                  control={control}
                  disabled={isEmpty(selectedAudit) || !hasEditPermission}
                  label="Business group"
                  name="businessUnitId"
                  options={(data?.businessUnits ?? [])
                    ?.filter((businessUnit) => businessUnit?.locationId === (audit?.locationId || selectedAudit?.locationId))
                    ?.map(({ _id, name }) => ({ value: _id, label: name }))}
                  placeholder="Select business group"
                  stroke="dropdown.icon"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
              )}
              {selectedAudit?.auditType?.type && (selectedAudit.auditType.type === 'LRQA' || selectedAudit.auditType.type === 'UKAS') && (
                <TextInput
                  color="findingModal.textColor.field"
                  control={control}
                  disabled={!hasEditPermission}
                  label="Reference"
                  name="reference"
                  placeholder="Enter finding's reference"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
              )}
              <TextInput
                color="findingModal.textColor.field"
                control={control}
                disabled={!hasEditPermission}
                label="Finding"
                name="finding"
                placeholder="Finding title"
                validations={{
                  notEmpty: true,
                }}
                variant="secondaryVariant"
              />
              <Dropdown
                color="findingModal.textColor.field"
                control={control}
                disabled={isEmpty(selectedAudit) || !hasEditPermission}
                label="Severity"
                name="severity"
                options={
                  severityOptions || [
                    { value: 'mandatory', label: 'Mandatory' },
                    { value: 'recommendation', label: 'Recommendation' },
                    { value: 'severe', label: 'Severe' },
                    { value: 'major', label: 'Major' },
                    { value: 'minor', label: 'Minor' },
                    { value: 'ofi', label: 'OFI' },
                  ]
                }
                placeholder={isEmpty(selectedAudit) ? 'Please select an audit first' : 'Select severity type'}
                stroke="dropdown.icon"
                validations={{
                  notEmpty: true,
                }}
                variant="secondaryVariant"
              />
              <Datepicker
                color="findingModal.textColor.field"
                control={control}
                disabled={!hasEditPermission}
                label="Finding date"
                name="date"
                placeholder="Finding date"
                validations={{
                  notEmpty: true,
                }}
                variant="secondaryVariant"
              />
              <Box pt="10px">
                <Text color="findingModal.textColor.field" fontSize="ssm" fontWeight="bold">
                  Finding Details
                </Text>
                <RichTextEditor control={control} name="details" readMode={!hasEditPermission} />
              </Box>

              {!isOpenInActionModal && (
                <>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text color="findingModal.textColor.header" fontSize="smm" fontWeight="bold">
                      Actions
                    </Text>
                    <Text color="findingModal.textColor.optional" fontSize="xxs" fontWeight="semi_medium">
                      Optional
                    </Text>
                  </Flex>
                  {!isEmpty(createdActions) ? (
                    <VStack>
                      {createdActions.map((action: Partial<IAction>, index) => (
                        <HStack
                          bg="findingModal.bg.audit"
                          boxShadow="simple"
                          justify="space-between"
                          key={index}
                          px={6}
                          py={4}
                          rounded="10px"
                          spacing={2}
                          w="full"
                        >
                          <Stack overflow="hidden" spacing={0}>
                            <Stack
                              // _hover={{
                              //   textDecoration: 'underline',
                              //   cursor: 'pointer',
                              // }}
                              align="center"
                              direction="row"
                              // onClick={() => openInNewTab(`/audits/${walkItem?.audit?._id}`)}
                              spacing={2}
                            >
                              <Text color="findingModal.textColor.audit" fontSize="smm" isTruncated>
                                {h2p(action?.title || '')}
                              </Text>
                              {/* <OpenExternalIcon fill="transparent" h="13px" stroke="findingModal.icon.openExternal" w="13px" /> */}
                            </Stack>
                            <Text color="findingModal.textColor.audit" fontSize="ssm" opacity="0.4">
                              {`${users.find(({ _id }) => _id === action?.assigneeId)?.displayName} - ${action?.dueDate ? `${format(new Date(action?.dueDate), 'd MMM yyyy')} -` : ''
                                } ${action?.status || 'open'}`}
                            </Text>
                          </Stack>
                        </HStack>
                      ))}
                    </VStack>
                  ) : (
                    <Text fontSize="sm" fontStyle="italic">
                      No actions
                    </Text>
                  )}
                  <HStack>
                    <Can
                      action="actions.add"
                      data={{ auditType: selectedAudit?.auditType }}
                      yes={() => (
                        <Button
                          _hover={{ bg: 'findingModal.hover.addButton' }}
                          bg="findingModal.bg.actionButton"
                          color="findingModal.textColor.actionButton"
                          fontSize="ssm"
                          fontWeight="bold"
                          h="28px"
                          onClick={onOpen}
                          px={4}
                          rounded="10px"
                        >
                          Create an action
                        </Button>
                      )}
                    />
                  </HStack>
                </>
              )}
            </Stack>
          </Flex>
          <Flex justifyContent="end" mt="25px">
            {/* {modalType === 'edit' && (
              <Button
                _hover={{ bg: 'findingModal.hover.addButton' }}
                bg="findingModal.bg.addButton"
                color="findingModal.textColor.addButton"
                fontSize="smm"
                fontWeight="bold"
                onClick={() => onAction('delete')}
              >
                Delete
              </Button>
            )} */}
            {modalType !== 'edit' && <Box />}
            <Can
              action="answers.edit"
              data={{ auditType: selectedAudit?.auditType }}
              yes={() => (
                <Button
                  _hover={{ bg: 'findingModal.hover.addButton' }}
                  bg="findingModal.bg.addButton"
                  color="findingModal.textColor.addButton"
                  disabled={(isOpenInActionModal ? !isOpenInActionModal : isEmpty(selectedAudit)) || !isValid || savingFinding}
                  fontSize="smm"
                  fontWeight="bold"
                  isLoading={savingFinding}
                  onClick={async () => {
                    setSavingFinding(true);
                    await handleFindingOperation(modalType);
                    setSavingFinding(false);
                  }}
                  p="10px 20px"
                  rounded="10px"
                >
                  {modalType === 'edit' ? 'Update' : 'Add finding'}
                  <ChevronRight ml="5px" />
                </Button>
              )}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </>
  );
};

export default FindingModal;

export const findingModalStyles = {
  findingModal: {
    bg: {
      modalBody: '#F0F2F5',
      modalContent: '#ffffff',
      documentUpload: '#ffffff',
      addButton: '#DC0043',
      actionButton: '#DC0043',
      audit: '#ffffff',
    },
    textColor: {
      header: '#1E1836',
      field: '#1E1836',
      optional: '#818197',
      addButton: '#FFFFFF',
      actionButton: '#FFFFFF',
      audit: '#1E1836',
    },
    hover: {
      addButton: '#DC0043',
      actionButton: '#DC0043',
    },
    icon: {
      close: '#1E1836',
      openExternal: '#1E1836',
      refresh: '#282F36',
    },
  },
};
