import { Icon } from '@chakra-ui/icons';

const MenuIcon = (props) => {
  const { isIndeterminate, isChecked, ...rest } = props;
  return (
    <Icon viewBox="0 0 24 24" {...rest}>
      <path d="M18.8112 9.64801C17.6024 9.64801 16.6224 10.628 16.6224 11.8368C16.6224 13.0457 17.6024 14.0256 18.8112 14.0256C20.02 14.0256 21 13.0457 21 11.8368C21 10.628 20.02 9.64801 18.8112 9.64801Z" />
      <path d="M12 9.64801C10.7912 9.64801 9.81121 10.628 9.81121 11.8368C9.81121 13.0457 10.7912 14.0256 12 14.0256C13.2089 14.0256 14.1888 13.0457 14.1888 11.8368C14.1888 10.628 13.2089 9.64801 12 9.64801Z" />
      <path d="M5.18879 9.64801C3.97995 9.64801 2.99999 10.628 2.99999 11.8368C2.99999 13.0457 3.97995 14.0256 5.18879 14.0256C6.39763 14.0256 7.37759 13.0457 7.37759 11.8368C7.37759 10.628 6.39763 9.64801 5.18879 9.64801Z" />
      <path d="M18.8112 16.2756C17.6024 16.2756 16.6224 17.2556 16.6224 18.4644C16.6224 19.6733 17.6024 20.6532 18.8112 20.6532C20.02 20.6532 21 19.6733 21 18.4644C21 17.2556 20.02 16.2756 18.8112 16.2756Z" />
      <path d="M12 16.2756C10.7912 16.2756 9.81121 17.2556 9.81121 18.4644C9.81121 19.6733 10.7912 20.6532 12 20.6532C13.2089 20.6532 14.1888 19.6733 14.1888 18.4644C14.1888 17.2556 13.2089 16.2756 12 16.2756Z" />
      <path d="M5.18879 16.2756C3.97995 16.2756 2.99999 17.2556 2.99999 18.4644C2.99999 19.6733 3.97995 20.6532 5.18879 20.6532C6.39763 20.6532 7.37759 19.6733 7.37759 18.4644C7.37759 17.2556 6.39763 16.2756 5.18879 16.2756Z" />
      <path d="M18.8112 3.0204C17.6024 3.0204 16.6224 4.00036 16.6224 5.20921C16.6224 6.41805 17.6024 7.39801 18.8112 7.39801C20.02 7.39801 21 6.41805 21 5.20921C21 4.00036 20.02 3.0204 18.8112 3.0204Z" />
      <path d="M12 3.0204C10.7912 3.0204 9.81121 4.00036 9.81121 5.20921C9.81121 6.41805 10.7912 7.39801 12 7.39801C13.2089 7.39801 14.1888 6.41805 14.1888 5.20921C14.1888 4.00036 13.2089 3.0204 12 3.0204Z" />
      <path d="M5.18879 3.0204C3.97995 3.0204 2.99999 4.00036 2.99999 5.2092C2.99999 6.41805 3.97995 7.39801 5.18879 7.39801C6.39763 7.39801 7.37759 6.41805 7.37759 5.2092C7.37759 4.00036 6.39763 3.0204 5.18879 3.0204Z" />
    </Icon>
  );
};

export default MenuIcon;
