import { useEffect } from 'react';

import { Modal, ModalOverlay } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import ActionProvider from '../../contexts/ActionProvider';
import { useAdminContext } from '../../contexts/AdminProvider';
import AuditModalProvider from '../../contexts/AuditModalProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useDevice from '../../hooks/useDevice';
import { IAction } from '../../interfaces/IAction';
import { IAnswer } from '../../interfaces/IAnswer';
import { IAudit } from '../../interfaces/IAudit';
import ActionModal from '../Actions/ActionModal';
import AuditModal from '../AuditModal/AuditModal';
import FindingDeleteModal from '../Finding/FindingDeleteModal';
import FindingModal from '../Finding/FindingModal';

const ViewModal = () => {
  const device = useDevice();
  const {
    viewModalState,
    setViewModalState,

    isViewModalOpen,
    closeModal,

    selectedAction,
    setSelectedAction,

    selectedFinding,
    setSelectedFinding,
    deleteFindingMethod,

    selectedAudit,
    setSelectedAudit,
  } = useViewModalContext();
  const { setAdminModalState } = useAdminContext();

  // set all fields to intial value
  useEffect(
    () => () => {
      setSelectedAction({} as IAction);
      setSelectedFinding({} as IAnswer);
      setSelectedAudit({} as IAudit);
      setViewModalState('');
    },
    [],
  );

  useEffect(() => {
    if (['audit', 'finding', 'deleteFinding', 'action'].includes(viewModalState)) setAdminModalState('closed');
    else if (isViewModalOpen) setAdminModalState('add');
  }, [isViewModalOpen]);

  const renderViewModal = (viewModalState: string) => {
    switch (viewModalState) {
      case 'audit':
        return (
          <AuditModalProvider>
            <AuditModal audit={selectedAudit} modalType={isEmpty(selectedAudit) ? 'add' : 'edit'} onCloseModal={closeModal} />
          </AuditModalProvider>
        );
      case 'finding':
        return (
          <ActionProvider>
            <FindingModal finding={selectedFinding} modalType={isEmpty(selectedFinding) ? 'add' : 'edit'} onCloseModal={closeModal} />
          </ActionProvider>
        );
      case 'deleteFinding':
        return (
          <FindingDeleteModal
            handleClose={closeModal}
            handleDelete={async () => {
              await deleteFindingMethod({
                variables: {
                  _id: selectedFinding?._id,
                },
              });
              closeModal();
            }}
            isOpen={!isEmpty(selectedFinding)}
            key={selectedFinding?._id}
            message={`Are you sure you want to delete ${selectedFinding?.finding}?`}
          />
        );
      case 'action':
        return (
          <ActionProvider>
            <ActionModal action={selectedAction} modalType={isEmpty(selectedAction) ? 'add' : 'edit'} onCloseModal={closeModal} />
          </ActionProvider>
        );
      default:
        return 'No view is defined';
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isViewModalOpen && ['audit', 'finding', 'deleteFinding', 'action'].includes(viewModalState)}
      key={viewModalState}
      onClose={closeModal}
      onEsc={closeModal}
      size={device === 'desktop' || device === 'tablet' ? 'lg' : 'full'}
      variant="auditModal"
    >
      <ModalOverlay />
      {renderViewModal(viewModalState)}
    </Modal>
  );
};

export default ViewModal;
