import { useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { useLocation } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import { Box, Checkbox, Stack, Text } from '@chakra-ui/react';

import { useAppContext } from '../../contexts/AppProvider';
import { useFiltersContext } from '../../contexts/FiltersProvider';
import { actionsFilterDates, auditsFilterDates, trackerFilterDates } from '../../hooks/useFiltersUtils';
import useNavigate from '../../hooks/useNavigate';
import { MinusIcon } from '../../icons';

const DateFilter = ({ filterName = 'date' }: { filterName?: string }) => {
  const { filtersValues, setFilters } = useFiltersContext();
  const { module } = useAppContext();
  const location = useLocation();
  const { getPath } = useNavigate();

  const auditsUsedFilters = useMemo(() => {
    switch (getPath()) {
      case 'actions':
        return actionsFilterDates;
      case 'audits':
      default:
        return auditsFilterDates;
    }
  }, [location.pathname]);

  const auditsFiltersValue = useMemo(() => {
    switch (getPath()) {
      case 'actions':
        return filtersValues.dueDate;
      case 'walk-items':
        return filtersValues.createdDate;
      case 'audits':
      default:
        return filtersValues[filterName];
    }
  }, [location.pathname]);

  const auditsOnChangeKey = useMemo(() => {
    switch (getPath()) {
      case 'actions':
        return 'dueDate';
      case 'walk-items':
        return 'createdDate';
      case 'audits':
      default:
        return filterName;
    }
  }, [location.pathname]);

  const value = useMemo(
    () => (module?.type === 'tracker' ? filtersValues.dueDate : auditsFiltersValue)?.value,
    [filtersValues, module?.type],
  );
  const [filterValue, startDate, endDate] = value || [];

  useEffect(() => {
    setFilters({ [module?.type === 'tracker' ? 'dueDate' : filterName]: [filterValue, new Date()] });
  }, [filterValue]);

  const onChange = (e, key) => {
    if (e.target.checked) setFilters({ [module?.type === 'tracker' ? 'dueDate' : auditsOnChangeKey]: [key] });
    else setFilters({ [module?.type === 'tracker' ? 'dueDate' : filterName]: [] });
  };

  return (
    <Box w="full">
      <Stack direction="column" mb={5}>
        {Object.entries(module?.type === 'tracker' ? trackerFilterDates : auditsUsedFilters).map(([key, label]) => (
          <Checkbox
            colorScheme="purpleHeart"
            css={{
              '.chakra-checkbox__control': {
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                background: 'white',
                borderWidth: '1px',
                borderColor: '#81819750',
                '&[data-checked]': {
                  background: '#462AC4',
                  borderColor: '#462AC4',
                  '&[data-hover]': {
                    background: '#462AC4',
                    borderColor: '#462AC4',
                  },
                },
              },
            }}
            icon={<MinusIcon />}
            isChecked={value?.includes(key)}
            key={key}
            onChange={(e) => onChange(e, key)}
          >
            <Text color="filterPanel.checkboxLabelColor" fontSize="14px">
              {label}
            </Text>
          </Checkbox>
        ))}
      </Stack>
      {filterValue === 'exactDate' && (
        <DatePicker
          inline
          onChange={(date) => setFilters({ [module?.type === 'tracker' ? 'dueDate' : filterName]: ['exactDate', date] })}
          selected={startDate}
        />
      )}

      {filterValue === 'dateRange' && (
        <DatePicker
          endDate={endDate ? new Date(endDate) : null}
          inline
          onChange={(dates) => {
            const [start, end] = dates;
            setFilters({ [module?.type === 'tracker' ? 'dueDate' : filterName]: ['dateRange', start, end] });
          }}
          selected={startDate}
          selectsRange
          startDate={startDate}
        />
      )}
    </Box>
  );
};

export default DateFilter;
