import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Flex, Text, Tooltip, useToast } from '@chakra-ui/react';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import AdminModal from '../../components/Admin/AdminModal';
import AdminTableHeader from '../../components/Admin/AdminTableHeader';
import AdminTableHeaderElement from '../../components/Admin/AdminTableHeaderElement';
import TextInput from '../../components/Forms/TextInput';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import MultipleParticipantsSelector from '../../components/Participants/MultipleParticipantsSelector';
import { useAdminContext } from '../../contexts/AdminProvider';
import { useFiltersContext } from '../../contexts/FiltersProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useNavigate from '../../hooks/useNavigate';
import { ArrowCount, EditIcon } from '../../icons';
import { IAuditType } from '../../interfaces/IAuditType';

const GET_AUDIT_TYPES = gql`
  query {
    auditTypes {
      _id
      name
      reviewers {
        _id
        displayName
        imgUrl
      }
      totalAuditsCount
    }
  }
`;
const UPDATE_AUDIT_TYPE = gql`
  mutation ($auditTypeInput: AuditTypeModifyInput!) {
    updateAuditType(auditTypeInput: $auditTypeInput) {
      _id
    }
  }
`;

const defaultValues: Partial<IAuditType> = {
  _id: undefined,
  name: 'Internal audit',
  reviewers: [],
};

const AuditTypes = () => {
  const toast = useToast();
  const { navigateTo } = useNavigate();
  const { setAuditFiltersValue } = useFiltersContext();
  const { closeModal } = useViewModalContext();
  const { adminModalState, setAdminModalState } = useAdminContext();
  const { data, loading, refetch } = useQuery(GET_AUDIT_TYPES);
  const [updateFunction] = useMutation(UPDATE_AUDIT_TYPE);
  const [sortType, setSortType] = useState('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const getAuditTypes = (auditTypesArray: IAuditType[]) => {
    if (!auditTypesArray) return [];
    return [...auditTypesArray].sort((a, b) => a.name.localeCompare(b.name));
  };
  const [auditTypes, setAuditTypes] = useState<IAuditType[]>(getAuditTypes(data?.auditTypes));

  useEffect(() => {
    setAuditTypes(getAuditTypes(data?.auditTypes));
  }, [data]);

  useEffect(() => {
    const sort = (a, b) => {
      if (sortType === 'owner') return (a.owner?.displayName || '').localeCompare(b.owner?.displayName || '');
      if (sortType === 'totalAuditsCount') return (a.totalAuditsCount || 0) > (b.totalAuditsCount || 0) ? 1 : -1;
      return (a[sortType] || 0).toString().localeCompare((b[sortType] || 0).toString());
    };
    if (sortOrder === 'asc') setAuditTypes([...auditTypes].sort((a, b) => sort(a, b)));
    else setAuditTypes([...auditTypes].sort((a, b) => sort(b, a)));
  }, [sortType, sortOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  const formValues = watch();

  // Reset the form after closing
  useEffect(() => {
    if (adminModalState === 'closed') {
      reset(defaultValues);
      closeModal();
    }
  }, [reset, adminModalState]);

  // If modal opened in edit or delete mode, reset the form and set values of edited element
  const openAuditTypeModal = (action: 'edit' | 'delete', auditType: IAuditType) => {
    setAdminModalState(action);
    reset({
      _id: auditType._id,
      name: auditType.name,
      reviewers: auditType.reviewers,
    });
  };

  const handleUpdateAuditType = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const auditType = getValues();
        await updateFunction({
          variables: {
            auditTypeInput: {
              _id: auditType._id,
              name: auditType.name,
              reviewersIds: (auditType.reviewers || []).map(({ _id }) => _id),
            },
          },
        });
        refetch();
        toast({ ...toastSuccess, description: 'Audit type updated' });
      } else {
        toast({
          ...toastFailed,
          description: 'Please complete all the required fields',
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setAdminModalState('closed');
    }
  };

  const handleAction = async (action) => {
    const isFormValid = await trigger();
    if (action === 'edit' && !isFormValid) {
      return toast({
        ...toastFailed,
        description: 'Please complete all the required fields',
      });
    }
    switch (action) {
      case 'edit':
        handleUpdateAuditType();
        break;
      default:
        setAdminModalState('closed');
    }
  };

  const renderAuditTypeRow = (auditType: IAuditType, i: number) => (
    <Flex
      alignItems="center"
      bg="#FFFFFF"
      borderBottomRadius={i === auditTypes.length - 1 ? 'lg' : ''}
      boxShadow="sm"
      flexShrink={0}
      h="73px"
      key={auditType._id}
      mb="1px"
      p={4}
      w="full"
    >
      <Flex cursor="pointer" flexDir="column" onClick={() => openAuditTypeModal('edit', auditType)} pl={1} w="45%">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {auditType.name}
        </Text>
      </Flex>
      <Flex align="center" pl={1} w="45%">
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {auditType.totalAuditsCount}
        </Text>
        <Tooltip fontSize="sm" label="Show audits">
          <ArrowCount
            cursor="pointer"
            h="10px"
            ml={1}
            onClick={() => {
              setAuditFiltersValue({ auditTypesIds: { value: [auditType._id] } });
              navigateTo('/audits');
            }}
            stroke="auditTypesAdmin.iconColor"
            w="10px"
          />
        </Tooltip>
      </Flex>
      <Flex align="center" justify="flex-end" pl={1} w="10%">
        <Tooltip fontSize="sm" label="Edit audit type">
          <EditIcon
            cursor="pointer"
            h="16px"
            ml={1}
            onClick={() => openAuditTypeModal('edit', auditType)}
            stroke="auditTypesAdmin.iconColor"
            w="16px"
          />
        </Tooltip>
      </Flex>
    </Flex>
  );

  return (
    <>
      <AdminModal collection="audit types" isOpenModal={adminModalState !== 'closed'} modalType={adminModalState} onAction={handleAction}>
        <Box mb={4}>
          <TextInput
            control={control}
            label="Audit type name"
            name="name"
            placeholder="Name"
            required
            validations={{
              notEmpty: true,
            }}
          />
        </Box>
        <MultipleParticipantsSelector
          isUserAllowedToChange
          label="Team members"
          maxParticipants={20}
          onChange={(participants) => setValue('reviewers', participants)}
          selectedParticipants={formValues.reviewers || []}
        />
      </AdminModal>
      <Header breadcrumbs={['Admin', 'Audit types']} category="audit type" mobileBreadcrumbs={['Audit types']} />
      <Flex h="calc(100vh - 160px)" overflow="auto" px={['25px', 0]}>
        <Box h={['calc(100% - 160px)', 'calc(100% - 35px)']} p={[0, '0 25px 30px 30px']} w="full">
          <AdminTableHeader>
            <AdminTableHeaderElement
              label="Title"
              onClick={() => {
                setSortType('name');
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
              }}
              showSortingIcon={sortType === 'name'}
              sortOrder={sortType === 'name' ? sortOrder : undefined}
              w="45%"
            />
            <AdminTableHeaderElement
              label="Audits number"
              onClick={() => {
                setSortType('totalAuditsCount');
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
              }}
              showSortingIcon={sortType === 'totalAuditsCount'}
              sortOrder={sortType === 'totalAuditsCount' ? sortOrder : undefined}
              w="45%"
            />
            <AdminTableHeaderElement w="10%" />
          </AdminTableHeader>
          <Flex bg="white" borderBottomRadius="20px" flexDir="column" fontSize="smm" h="full" overflow="auto" w="full">
            {loading ? (
              <Loader center />
            ) : auditTypes?.length > 0 ? (
              auditTypes?.map(renderAuditTypeRow)
            ) : (
              <Flex fontSize="18px" fontStyle="italic" h="full" justify="center" mt={4} w="full">
                No audit types found
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default AuditTypes;

export const auditTypesAdminStyles = {
  auditTypesAdmin: {
    linkColor: '#005699',
    iconColor: '#282F36',
  },
};
