import { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import { useAppContext } from '../contexts/AppProvider';
import { IUser } from '../interfaces/IUser';

const USER = gql`
  query {
    session {
      user {
        _id
        firstName
        lastName
        displayName
        email
        jobTitle
        role
        imgUrl
        defaultPage
      }
    }
  }
`;
const GET_BUSINESS_AREAS = gql`
  query BusinessAreas($locationQueryInput: LocationQueryInput) {
    locations(locationQueryInput: $locationQueryInput) {
      _id
    }
  }
`;
const GET_BUSINESS_GROUPS = gql`
  query BusinessGroups($businessUnitQueryInput: BusinessUnitQueryInput) {
    businessUnits(businessUnitQueryInput: $businessUnitQueryInput) {
      _id
    }
  }
`;

const useAuth = () => {
  const { user, setUser } = useAppContext();
  const [loaded, setLoaded] = useState<string[]>([]);

  // Create temporary user saved in hook memory until all informations are gathered from the API
  const [tempUser, setTempUser] = useState<IUser>({} as IUser);

  const { data, error } = useQuery(USER);
  useEffect(() => {
    if (data) {
      setTempUser(data.session.user);
      setLoaded(loaded => [...loaded, 'user']);
    }
  }, [JSON.stringify(data?.session?.user)]);

  const { data: businessAreasData } = useQuery(GET_BUSINESS_AREAS, {
    variables: {
      locationQueryInput: { ownerId: tempUser?._id },
    },
    skip: tempUser?._id === undefined,
  });
  useEffect(() => {
    if (businessAreasData) {
      setTempUser(user => ({ ...user, isBusinessAreaOwner: (businessAreasData.locations || []).length > 0 }));
      setLoaded(loaded => [...loaded, 'businessAreas']);
    }
  }, [JSON.stringify(businessAreasData)]);

  const { data: businessGroupsData } = useQuery(GET_BUSINESS_GROUPS, {
    variables: {
      businessUnitQueryInput: { ownerId: tempUser?._id },
    },
    skip: tempUser?._id === undefined,
  });
  useEffect(() => {
    if (businessGroupsData) {
      setTempUser(user => ({ ...user, isBusinessGroupOwner: (businessGroupsData?.businessUnits || []).length > 0 }));
      setLoaded(loaded => [...loaded, 'businessGroups']);
    }
  }, [JSON.stringify(businessGroupsData)]);

  useEffect(() => {
    if (error?.message) setUser(null);
  }, [error]);

  useEffect(() => {
    if (loaded.length === 3) setUser(tempUser);
  }, [JSON.stringify(loaded)])

  return loaded.length < 3 && user !== null;
};

export default useAuth;
