import { createIcon } from '@chakra-ui/icons';

const LicensesIcon = createIcon({
  viewBox: '0 0 22 22',
  path: (
    <g>
      <path
        d="M15.6665 3.66667C15.6665 4.37391 15.9475 5.05219 16.4476 5.55228C16.9476 6.05238 17.6259 6.33333 18.3332 6.33333C19.0404 6.33333 19.7187 6.05238 20.2188 5.55228C20.7189 5.05219 20.9998 4.37391 20.9998 3.66667C20.9998 2.95942 20.7189 2.28115 20.2188 1.78105C19.7187 1.28095 19.0404 1 18.3332 1C17.6259 1 16.9476 1.28095 16.4476 1.78105C15.9475 2.28115 15.6665 2.95942 15.6665 3.66667V3.66667Z"
        fill="None"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.61263 1.23834C4.64897 1.16678 4.70442 1.10668 4.77282 1.06469C4.84123 1.02271 4.91992 1.00049 5.00018 1.00049C5.08045 1.00049 5.15914 1.02271 5.22755 1.06469C5.29595 1.10668 5.3514 1.16678 5.38774 1.23834L6.28907 3.05345C6.32025 3.11573 6.36605 3.16952 6.42255 3.21023C6.47905 3.25095 6.54458 3.27737 6.61352 3.28723L8.63396 3.57879C8.71283 3.59011 8.787 3.62312 8.84821 3.67413C8.90942 3.72514 8.95526 3.79214 8.98063 3.86767C9.00537 3.94291 9.0084 4.0236 8.98937 4.10048C8.97035 4.17736 8.93004 4.24732 8.87307 4.30234L7.40641 5.72456C7.35715 5.77232 7.32025 5.83134 7.29888 5.89653C7.27751 5.96173 7.27232 6.03114 7.28374 6.09879L7.62952 8.10323C7.64296 8.18178 7.6339 8.26252 7.60339 8.33614C7.57287 8.40975 7.52215 8.47323 7.45707 8.51923C7.39151 8.56567 7.31439 8.59307 7.23422 8.5984C7.15406 8.60374 7.07399 8.5868 7.00285 8.54945L5.20196 7.60901C5.13978 7.57616 5.07051 7.55899 5.00018 7.55899C4.92986 7.55899 4.86059 7.57616 4.79841 7.60901L3.00018 8.54945C2.92905 8.5868 2.84897 8.60374 2.76881 8.5984C2.68865 8.59307 2.61152 8.56567 2.54596 8.51923C2.48089 8.47323 2.43017 8.40975 2.39965 8.33614C2.36913 8.26252 2.36007 8.18178 2.37352 8.10323L2.7193 6.09879C2.73072 6.03114 2.72552 5.96173 2.70415 5.89653C2.68278 5.83134 2.64588 5.77232 2.59663 5.72456L1.13352 4.30234C1.07655 4.24732 1.03624 4.17736 1.01722 4.10048C0.998194 4.0236 1.00122 3.94291 1.02596 3.86767C1.05124 3.79209 1.09705 3.72503 1.15828 3.67401C1.21951 3.62299 1.29373 3.59002 1.37263 3.57879L3.39218 3.28723C3.46017 3.27656 3.52462 3.24977 3.58014 3.2091C3.63565 3.16843 3.68063 3.11506 3.7113 3.05345L4.61263 1.23834Z"
        fill="None"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M18.3333 1H9" fill="None" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path
        d="M15.6667 3.66675V17.0001C15.6667 18.0609 15.2452 19.0784 14.4951 19.8285C13.7449 20.5787 12.7275 21.0001 11.6667 21.0001H1C2.06087 21.0001 3.07828 20.5787 3.82843 19.8285C4.57857 19.0784 5 18.0609 5 17.0001V11.6667"
        fill="None"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M7.6665 15.6667H12.9998" fill="None" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M7.6665 13H12.9998" fill="None" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M10.3335 10.3333H13.0002" fill="None" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M10.3335 7.66675H13.0002" fill="None" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default LicensesIcon;
