import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import AdminModal from '../../components/Admin/AdminModal';
import AdminTableHeader from '../../components/Admin/AdminTableHeader';
import AdminTableHeaderElement from '../../components/Admin/AdminTableHeaderElement';
import PeoplePicker from '../../components/Forms/PeoplePicker';
import TextInput from '../../components/Forms/TextInput';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import LocationListItem from '../../components/LocationListItem';
import { useAdminContext } from '../../contexts/AdminProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useDevice from '../../hooks/useDevice';
import { ILocation } from '../../interfaces/ILocation';

const GET_LOCATIONS = gql`
  query {
    locations {
      _id
      name
      ownerId
      organizationId
      owner {
        displayName
        imgUrl
      }
      trackerItemsResponsesCount
      totalAuditsCount
    }
  }
`;
const CREATE_LOCATION = gql`
  mutation ($values: LocationInput!) {
    createLocation(locationInput: $values) {
      _id
    }
  }
`;
const UPDATE_LOCATION = gql`
  mutation ($values: LocationModifyInput!) {
    updateLocation(locationModifyInput: $values) {
      _id
    }
  }
`;
const DELETE_LOCATION = gql`
  mutation ($_id: String!) {
    deleteLocation(_id: $_id)
  }
`;

const defaultValues: Partial<ILocation> = {
  _id: undefined,
  name: '',
  ownerId: '',
};

const Locations = () => {
  const toast = useToast();
  const { closeModal } = useViewModalContext();
  const { adminModalState, setAdminModalState } = useAdminContext();
  const { data, loading, refetch } = useQuery(GET_LOCATIONS);
  const [createFunction] = useMutation(CREATE_LOCATION);
  const [updateFunction] = useMutation(UPDATE_LOCATION);
  const [deleteFunction] = useMutation(DELETE_LOCATION);
  const device = useDevice();
  const [sortType, setSortType] = useState('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentLocationName, setCurrentLocationName] = useState<string>('');
  const getLocations = (locationsArray: ILocation[]) => {
    if (!locationsArray) return [];

    return [...locationsArray].sort((a, b) => a.name.localeCompare(b.name));
  };
  const [locations, setLocations] = useState<ILocation[]>(getLocations(data?.locations));

  useEffect(() => {
    setLocations(getLocations(data?.locations));
  }, [JSON.stringify(data)]);

  useEffect(() => {
    const sort = (a, b) => {
      if (sortType === 'owner') return (a.owner?.displayName || '').localeCompare(b.owner?.displayName || '');
      if (sortType === 'totalAuditsCount') return (a.totalAuditsCount || 0) > (b.totalAuditsCount || 0) ? 1 : -1;
      return (a[sortType] || 0).toString().localeCompare((b[sortType] || 0).toString());
    };

    if (sortOrder === 'asc') setLocations([...locations].sort((a, b) => sort(a, b)));
    else setLocations([...locations].sort((a, b) => sort(b, a)));
  }, [sortType, sortOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    control,
    formState: { errors },
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues,
  });

  // Reset the form after closing
  useEffect(() => {
    if (adminModalState === 'closed') {
      reset(defaultValues);
      setCurrentLocationName('');
      closeModal();
    }
  }, [reset, adminModalState]);

  // If modal opened in edit or delete mode, reset the form and set values of edited element
  const openLocationModal = (action: 'edit' | 'delete', location: ILocation) => {
    setAdminModalState(action);
    setCurrentLocationName(location.name);
    reset({
      _id: location._id,
      name: location.name,
      ownerId: location.ownerId,
    });
  };

  const handleAddLocation = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        await createFunction({ variables: { values } });
        toast({ ...toastSuccess, description: `${capitalize(t('location'))} added` });
        refetch();
      } else {
        toast({
          ...toastFailed,
          description: 'Please complete all the required fields',
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setAdminModalState('closed');
    }
  };

  const handleUpdateLocation = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        await updateFunction({ variables: { values } });
        toast({ ...toastSuccess, description: `${capitalize(t('location'))} updated` });
        refetch();
      } else {
        toast({
          ...toastFailed,
          description: 'Please complete all the required fields',
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setAdminModalState('closed');
    }
  };

  const handleDeleteLocation = async () => {
    try {
      const { _id } = getValues();
      await deleteFunction({ variables: { _id } });
      toast({ ...toastSuccess, description: `${capitalize(t('location'))} deleted` });
      refetch();
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setAdminModalState('closed');
    }
  };

  const handleAction = async (action) => {
    const isFormValid = await trigger();
    if (['add', 'edit'].includes(action) && !isFormValid) {
      return toast({
        ...toastFailed,
        description: 'Please complete all the required fields',
      });
    }
    switch (action) {
      case 'add':
        handleAddLocation();
        break;
      case 'edit':
        handleUpdateLocation();
        break;
      case 'delete':
        handleDeleteLocation();
        break;
      default:
        setAdminModalState('closed');
    }
  };

  return (
    <>
      <AdminModal collection={t('location')} isOpenModal={adminModalState !== 'closed'} modalType={adminModalState} onAction={handleAction}>
        <Flex align="flex-start" direction="column">
          <TextInput
            control={control}
            initialValue={currentLocationName.toLowerCase()}
            label={`${capitalize(t('location'))} title`}
            name="name"
            placeholder={`${capitalize(t('location'))} title`}
            validations={{
              notEmpty: true,
              uniqueValue: locations.map(({ name }) => name.toLowerCase()),
            }}
          />
          <PeoplePicker
            control={control}
            label={`${capitalize(t('location'))} director`}
            name="ownerId"
            placeholder={`${capitalize(t('location'))} director`}
            showAsDropdown={false}
            validations={{
              notEmpty: true,
            }}
          />
        </Flex>
      </AdminModal>
      <Header breadcrumbs={['Admin', 'Business Areas']} category={t('location')} />
      <Flex h="calc(100vh - 160px)" overflow="auto" px={['25px', 0]}>
        <Box h={['calc(100% - 160px)', 'calc(100% - 35px)']} p={[0, '0 25px 30px 30px']} w="full">
          <AdminTableHeader>
            <AdminTableHeaderElement
              label={`${capitalize(t('location'))} title`}
              onClick={() => {
                setSortType('name');
                setSortOrder(sortOrder === 'asc' && sortType === 'name' ? 'desc' : 'asc');
              }}
              showSortingIcon={sortType === 'name'}
              sortOrder={sortType === 'name' ? sortOrder : undefined}
              w={['80%', '40%']}
            />
            {device !== 'mobile' && (
              <AdminTableHeaderElement
                label="Director"
                onClick={() => {
                  setSortType('owner');
                  setSortOrder(sortOrder === 'asc' && sortType === 'owner' ? 'desc' : 'asc');
                }}
                showSortingIcon={sortType === 'owner'}
                sortOrder={sortType === 'owner' ? sortOrder : undefined}
                w="40%"
              />
            )}
            <AdminTableHeaderElement
              label={`${capitalize(pluralize(t('audit')))} number`}
              onClick={() => {
                setSortType('totalAuditsCount');
                setSortOrder(sortOrder === 'asc' && sortType === 'totalAuditsCount' ? 'desc' : 'asc');
              }}
              showSortingIcon={sortType === 'totalAuditsCount'}
              sortOrder={sortType === 'totalAuditsCount' ? sortOrder : undefined}
              w="10%"
            />
            <AdminTableHeaderElement w="10%" />
          </AdminTableHeader>
          <Flex bg="white" borderBottomRadius="20px" flexDir="column" fontSize="smm" h="full" overflow="auto" w="full">
            {loading ? (
              <Loader center />
            ) : locations?.length > 0 ? (
              locations?.map((location, i) => <LocationListItem key={i} location={location} openLocationModal={openLocationModal} />)
            ) : (
              <Flex fontSize="18px" fontStyle="italic" h="full" justify="center" mt={4} w="full">
                No {pluralize(t('location'))} found
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default Locations;

export const locationsStyles = {
  locations: {
    fontColor: '#818197',
    tooltipStroke: '#282F36',
  },
};
