import { createIcon } from '@chakra-ui/icons';

const Accidents = createIcon({
  displayName: 'Accidents',
  viewBox: '0 0 22 22',

  path: (
    <g>
      <path
        d="M2.00003 3.82116V10.5889C2.00005 12.728 2.64829 14.817 3.85929 16.5803C5.0703 18.3437 6.78723 19.6987 8.78375 20.4667L9.70905 20.8222C10.3252 21.0593 11.0074 21.0593 11.6236 20.8222L12.5489 20.4667C14.5455 19.6987 16.2624 18.3437 17.4734 16.5803C18.6844 14.817 19.3326 12.728 19.3327 10.5889V3.82116C19.3344 3.56649 19.2624 3.31675 19.1256 3.10198C18.9887 2.88721 18.7927 2.71655 18.5611 2.61054C16.0722 1.52418 13.3819 0.97564 10.6663 1.00083C7.95077 0.97564 5.26048 1.52418 2.77155 2.61054C2.53999 2.71655 2.34398 2.88721 2.20711 3.10198C2.07024 3.31675 1.99833 3.56649 2.00003 3.82116V3.82116Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M6 9.00057C6 10.0614 6.42141 11.0788 7.17153 11.8289C7.92164 12.579 8.93901 13.0004 9.99984 13.0004C11.0607 13.0004 12.078 12.579 12.8281 11.8289C13.5783 11.0788 13.9997 10.0614 13.9997 9.00057C13.9997 7.93975 13.5783 6.92237 12.8281 6.17226C12.078 5.42214 11.0607 5.00073 9.99984 5.00073C8.93901 5.00073 7.92164 5.42214 7.17153 6.17226C6.42141 6.92237 6 7.93975 6 9.00057V9.00057Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M12.8281 11.8289L14.9996 14.0003" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default Accidents;
