import { Controller } from 'react-hook-form';

import { Box, Button, Icon as ChakraIcon, Flex, Menu, MenuButton, MenuItemOption, MenuList, Text, Tooltip } from '@chakra-ui/react';

import useValidate from '../../hooks/useValidate';
import { Asterisk, ChevronRight } from '../../icons';
import { IField } from '../../interfaces/IField';
import { TDefinedValidations } from '../../interfaces/TValidations';

interface IDropdown extends IField {
  placeholder?: string;
  variant?: string;
  options?: {
    label?: string;
    value?: string;
  }[];
  required?: boolean;
  stroke?: string;
  help?: string;
  Icon?: any;
  color?: string;
  attributeType?: 'Category' | 'Regulatory body';
  onAction?: (type?: 'Category' | 'Regulatory body') => void;
}

const definedValidations: TDefinedValidations = {
  notEmpty: (label, validationValue, value) => {
    if (validationValue && (!value || !value.length)) return `${label} cannot be empty`;
  },
};

const MultiSelect = ({
  control,
  name,
  stroke,
  label,
  placeholder = '',
  required,
  tooltip = '',
  variant,
  validations = {},
  disabled = false,
  options = [],
  help = '',
  Icon,
  color,
  onAction,
  attributeType,
}: IDropdown) => {
  const validate = useValidate(label || name, validations, definedValidations);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const { onChange, onBlur, value: values } = field;
        const { error } = fieldState;
        const buttonText = options
          .filter(({ value }) => (values || []).includes(value))
          .map(({ label }) => label)
          .join(', ');

        return (
          <Box id={name} mt="none" w="full">
            {label && (
              <Flex align="center" justify="space-between" mb="none" pt={2}>
                <Box
                  color={error ? 'dropdown.labelFont.error' : !color ? 'dropdown.labelFont.normal' : color}
                  fontSize={variant === 'secondaryVariant' ? '11px' : '14px'}
                  fontWeight="bold"
                  left="none"
                  position="static"
                  zIndex={1}
                >
                  {label}
                  {required && (
                    <Asterisk
                      fill="questionListElement.iconAsterisk"
                      h="9px"
                      mb="8px"
                      ml="5px"
                      stroke="questionListElement.iconAsterisk"
                      w="9px"
                    />
                  )}{' '}
                  {tooltip && (
                    <Tooltip hasArrow label={tooltip} placement="top">
                      <ChakraIcon h="14px" mb={1} name="info" />
                    </Tooltip>
                  )}
                  {help && (
                    <Box fontSize="11px" mt={3} opacity={0.5}>
                      {help}
                    </Box>
                  )}
                </Box>
                {!('notEmpty' in validations) && (
                  <Text color="textInput.optional" fontSize="xxs" fontWeight="semi_medium">
                    Optional
                  </Text>
                )}
              </Flex>
            )}
            <Flex alignItems={Icon ? 'center' : ''} w="full">
              <Menu autoSelect={false} closeOnBlur={false} closeOnSelect={false} matchWidth onClose={onBlur}>
                <MenuButton
                  _active={{
                    bg: '#FFFFFF',
                    borderColor: 'textInput.border.focus.normal',
                  }}
                  _disabled={{
                    bg: 'textInput.disabled.bg',
                    color: 'textInput.disabled.font',
                    borderColor: 'textInput.disabled.border',
                    cursor: 'not-allowed',
                  }}
                  _focus={{
                    bg: '#FFFFFF',
                    borderColor: error ? 'textInput.border.focus.error' : 'textInput.border.focus.normal',
                  }}
                  _hover={{ bg: '#FFFFFF' }}
                  _placeholder={{ fontSize: 'smm', color: 'textInput.placeholder' }}
                  as={Button}
                  autoComplete="off"
                  bg="textInput.bg"
                  borderColor={error ? 'textInput.border.error' : 'textInput.border.normal'}
                  borderRadius="8px"
                  borderWidth="1px"
                  color="#282F36"
                  cursor="pointer"
                  fontSize="14px"
                  fontWeight="400"
                  h="40px"
                  isDisabled={disabled}
                  pr="8px"
                  rightIcon={<ChevronRight fontSize="20px" stroke="#282F36" transform="rotate(90deg)" />}
                  textAlign="left"
                  transition="all 0s ease"
                  w="full"
                >
                  <Text color={values?.length ? '' : 'textInput.placeholder'} isTruncated maxW="full" w="full">
                    {buttonText || placeholder}
                  </Text>
                </MenuButton>
                <MenuList
                  bg="#FFFFFF"
                  borderColor={error ? 'textInput.border.error' : 'textInput.border.normal'}
                  fontSize="14px"
                  minWidth="240px"
                  mt="0"
                  py="0"
                  transition="all 0s ease"
                  zIndex={9}
                >
                  {options &&
                    options.map(({ label, value }) => (
                      <MenuItemOption
                        _hover={{
                          bg: '#1e90ff',
                          color: '#FFFFFF',
                        }}
                        bg={values && values?.includes(value) ? '#1e90ff' : '#FFFFFF'}
                        color={values && values?.includes(value) ? '#FFFFFF' : ''}
                        cursor="default"
                        key={value}
                        onClick={() =>
                          onChange(values?.includes(value) ? values?.filter((option) => option !== value) : [...(values || []), value])
                        }
                        px="0"
                        py="0"
                        transition="all 0s ease"
                        value={value}
                      >
                        {label}
                      </MenuItemOption>
                    ))}
                </MenuList>
              </Menu>
              {Icon && onAction && <Icon cursor="pointer" ml="20px" mt="10px" onClick={() => onAction(attributeType)} stroke={stroke} />}
            </Flex>
            {error && (
              <Box color="dropdown.error" fontSize="smm" ml={1} mt={1}>
                {error.message}
              </Box>
            )}
          </Box>
        );
      }}
      rules={{ validate }}
    />
  );
};

export const dropdownStyles = {
  dropdown: {
    font: '#282F36',
    bg: '#FFFFFF',
    labelFont: {
      secondaryVariant: '#818197',
      normal: '#2B3236',
      error: '#E53E3E',
    },
    border: {
      normal: '#CBCCCD',
      error: '#E53E3E',
      focus: {
        normal: '#777777',
        error: '#E53E3E',
      },
    },
    activeBg: '#EEEEEE',
    disabled: {
      font: '#2B3236',
      border: '#EEEEEE',
      bg: '#f7f7f7',
    },
    placeholder: '#CBCCCD',
    error: '#E53E3E',
    tooltip: '#9A9EA1',
    icon: '#818197',
    chevronDownIcon: '#282F36',
  },
};

export default MultiSelect;
