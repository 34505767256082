import { createIcon } from '@chakra-ui/icons';

const LocationIcon = createIcon({
  path: (
    <>
      <path
        d="M16.9969 9.23556C16.9969 13.7837 9.00002 21 9.00002 21C9.00002 21 1.00319 13.7837 1.00319 9.23556C0.942516 7.11252 1.75056 5.05284 3.24989 3.50875C4.74922 1.96466 6.81729 1.06237 9.00002 1C11.1826 1.06276 13.2504 1.96517 14.7497 3.50917C16.2489 5.05318 17.0571 7.11263 16.9969 9.23556V9.23556Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </>
  ),
  viewBox: '0 0 18 22',
});

export default LocationIcon;
