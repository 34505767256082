import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Avatar, Box, Flex, Skeleton, SkeletonText, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { format } from 'date-fns';
import pluralize from 'pluralize';

import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useNavigate from '../../hooks/useNavigate';
import { DeleteIcon, EditIcon, Eye, WarningIcon } from '../../icons';
import { IAudit } from '../../interfaces/IAudit';
import Can from '../can';
import DeleteModal from '../Modals/DeleteModal';

const GET_APPLICABLE_ACTIONS = gql`
  query ($auditId: ID!) {
    auditApplicableActions(auditId: $auditId) {
      mandatory
      recommendation
      severe
      major
      minor
      ofi
    }
  }
`;

const AuditListItem = ({ audit }: { audit: IAudit }) => {
  const { navigateTo } = useNavigate();
  const { editAudit } = useViewModalContext();
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();
  const { data: actionsData, loading: actionsLoading } = useQuery(GET_APPLICABLE_ACTIONS, {
    variables: {
      auditId: audit._id,
    },
    fetchPolicy: 'cache-and-network',
  });
  const actionsCount = useMemo(
    () =>
      Object.entries((actionsData?.auditApplicableActions as { [category: string]: number[] }) || {}).reduce(
        (acc, [, value]) => {
          if (typeof value[0] !== 'number') return acc;
          return {
            overdue: acc.overdue + value[0],
            total: acc.total + value[1],
          };
        },
        { total: 0, overdue: 0 },
      ),
    [JSON.stringify(actionsData)],
  );

  return (
    <>
      {isDeleteModalOpen && (
        <DeleteModal
          id={audit._id as string}
          isDeleteModalOpen={isDeleteModalOpen}
          onDeleteModalClose={onDeleteModalClose}
          reference={audit.reference as string}
          title={audit.title as string}
          type="audit"
        />
      )}
      <Box
        bg="white"
        borderBottomColor="auditsList.headerBorderColor"
        borderBottomWidth="1px"
        cursor={!audit?.metatags?.removedBy ? 'pointer' : 'default'}
        onClick={() => !audit?.metatags?.removedBy && navigateTo(`/audits/${audit._id}`)}
        p="15px 25px"
        py={[1, 0]}
        w="full"
      >
        <Flex align="center" h={['full', '73px']} position="relative" w="full">
          <Flex pr={1} w="10%">
            <Tooltip hasArrow label={audit.reference}>
              <Text
                color="auditsList.fontColor"
                fontSize="smm"
                fontWeight="400"
                opacity="1"
                overflow="hidden"
                pr={1}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {audit.reference}
              </Text>
            </Tooltip>
          </Flex>
          <Stack direction="row" pr={2} spacing={1} w="15%">
            <Text
              color="auditsList.fontColor"
              fontSize="smm"
              lineHeight="17px"
              opacity="1"
              overflow="hidden"
              pr={1}
              textOverflow="ellipsis"
              w="full"
              whiteSpace="nowrap"
            >
              {audit.title}
            </Text>
          </Stack>
          <Stack direction="row" pr={2} spacing={1} w="14%">
            <Text
              color="auditsList.fontColor"
              fontSize="smm"
              lineHeight="17px"
              opacity="1"
              overflow="hidden"
              pr={1}
              textOverflow="ellipsis"
              w="full"
              whiteSpace="nowrap"
            >
              {audit.location?.name}
            </Text>
          </Stack>
          <Flex w="14%">
            <Text
              color="auditsList.fontColor"
              fontSize="smm"
              fontWeight="semi_medium"
              lineHeight="17px"
              opacity="1"
              overflow="hidden"
              pr={1}
              textOverflow="ellipsis"
              w="full"
              whiteSpace="nowrap"
            >
              {audit.businessUnit?.name ?? '-'}
            </Text>
          </Flex>
          <Flex w="10%">
            <Text color="auditsList.fontColor" fontSize="smm" fontWeight="semi_medium" pr={1}>
              {format(new Date(audit.reportDate), 'd/L/yyyy')}
            </Text>
          </Flex>
          <Box pr={2} w="20%">
            <Skeleton isLoaded={!!audit} rounded="full">
              {audit.auditor || audit.leadAuditorName ? (
                <Flex align="center" direction="row" pr={1}>
                  <Avatar name={audit.leadAuditor?.displayName ?? audit?.leadAuditorName} size="xs" src={audit.leadAuditor?.imgUrl ?? ''} />
                  <Box lineHeight="13px" ml="5px" overflow="hidden" textOverflow="ellipsis" w="212px" whiteSpace="nowrap">
                    <Text color="auditsList.fontColor" fontSize="ssm">
                      {audit.leadAuditor?.displayName ?? audit?.leadAuditorName}
                    </Text>
                    {!audit.leadAuditorName && (
                      <Text color="auditsList.secondary.fontColor" fontSize="xs">
                        {audit.leadAuditor?.jobTitle ? audit.leadAuditor?.jobTitle : 'No job title'}
                      </Text>
                    )}
                  </Box>
                </Flex>
              ) : (
                <Flex fontSize="smm" fontStyle="italic" pr={1}>
                  Unassigned
                </Flex>
              )}
            </Skeleton>
          </Box>
          <Flex align="center" w="14%">
            <Tooltip
              bg="#E93C44"
              borderRadius="8px"
              color="#FFFFFF"
              fontSize="11px"
              fontWeight="400"
              hasArrow
              isDisabled={actionsCount.overdue <= 0}
              label={`${actionsCount.overdue} Overdue ${pluralize('action', actionsCount.overdue)}`}
              lineHeight="16px"
              p="6px 12px"
            >
              <Flex>
                {actionsCount.overdue > 0 && <WarningIcon bg="#E93C44" borderRadius="3px" fill="#E93C44" mr="1.5" p="2px" stroke="white" />}
                {actionsLoading ? (
                  <SkeletonText borderRadius="20px" mt="2" noOfLines={1} />
                ) : (
                  <Text color="auditSquare.section.text" fontSize="smm" textDecor="underline">
                    {actionsCount.total}
                  </Text>
                )}
              </Flex>
            </Tooltip>
          </Flex>
          <Flex w="3%">
            <Flex color="auditsList.fontColor" opacity="1">
              <Can
                action="audits.edit"
                data={{ auditType: audit.auditType }}
                no={() => <Eye onClick={() => !audit?.metatags?.removedBy && navigateTo(`/audits/${audit._id}`)} stroke="#1E1836" />}
                yes={() => (
                  <EditIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      editAudit(audit);
                    }}
                  />
                )}
              />
              <Can
                action="audits.delete"
                data={{ auditType: audit.auditType }}
                yes={() => (
                  <DeleteIcon
                    ml="6"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteModalOpen();
                    }}
                    stroke="#1E1836"
                  />
                )}
              />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default AuditListItem;
