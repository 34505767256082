import { createIcon } from '@chakra-ui/icons';

const Policies = createIcon({
  displayName: 'Policies',
  viewBox: '0 0 22 22',

  path: (
    <g>
      <path
        d="M5.6665 12.088L7.84428 15.1787C7.92689 15.3021 8.03777 15.4039 8.1677 15.4758C8.29763 15.5477 8.44285 15.5875 8.59128 15.592C8.7397 15.5964 8.88704 15.5653 9.02103 15.5013C9.15502 15.4373 9.27179 15.3422 9.36161 15.224L16.3332 6.40356"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M1 11C1 13.6522 2.05357 16.1957 3.92893 18.0711C5.8043 19.9464 8.34784 21 11 21C13.6522 21 16.1957 19.9464 18.0711 18.0711C19.9464 16.1957 21 13.6522 21 11C21 8.34784 19.9464 5.8043 18.0711 3.92893C16.1957 2.05357 13.6522 1 11 1C8.34784 1 5.8043 2.05357 3.92893 3.92893C2.05357 5.8043 1 8.34784 1 11V11Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
});

export default Policies;
