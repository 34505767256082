import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Save } from '../../icons';

interface ISaveChangesModal {
  isSaveChangesModalOpen: boolean;
  onSaveChangesModalClose: () => void;
  onSaveChange: () => void;
  onCloseOuterModal?: () => void;
}

const SaveChangesModal = ({ isSaveChangesModalOpen, onSaveChangesModalClose, onSaveChange, onCloseOuterModal }: ISaveChangesModal) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isSaveChangesModalOpen}
      onClose={onSaveChangesModalClose}
      onEsc={onSaveChangesModalClose}
      variant="deleteModal"
    >
      <ModalOverlay />
      <ModalContent rounded="20px">
        <ModalHeader>
          <Text color="deleteModal.font.color" fontSize="lg" fontWeight="bold">
            Unsaved changes
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Stack lineHeight="20px">
            <Text color="deleteModal.font.color" fontSize="smm" fontWeight="semi_medium">
              Are you sure you want to close this action?
            </Text>
            <Text color="deleteModal.font.color" fontSize="smm" fontWeight="semi_medium">
              Unsaved changes will be lost.
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end" spacing={4} w="full">
            <Button
              _hover={{ opacity: 0.7 }}
              bg="deleteModal.cancelButton.bg"
              color="deleteModal.cancelButton.color"
              onClick={() => {
                onSaveChangesModalClose();
                if (onCloseOuterModal && typeof onCloseOuterModal === 'function') onCloseOuterModal();
              }}
              rounded="10px"
            >
              Do not save
            </Button>
            <Button
              _hover={{ opacity: 0.7 }}
              bg="deleteModal.deleteButton.bg"
              color="deleteModal.deleteButton.color"
              disabled={loading}
              isLoading={loading}
              onClick={async () => {
                setLoading(true);
                await onSaveChange();
                setLoading(false);
                onSaveChangesModalClose();
                if (onCloseOuterModal && typeof onCloseOuterModal === 'function') onCloseOuterModal();
              }}
              rounded="10px"
            >
              Save changes
              <Save ml="5px" />
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SaveChangesModal;

export const saveChangesModalStyles = {
  deleteModal: {
    deleteButton: {
      color: '#ffffff',
      bg: '#dc0043',
    },
    cancelButton: {
      color: '#787486',
      bg: '#F0F2F5',
    },
    font: {
      color: '#1E1836',
    },
  },
};
