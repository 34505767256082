import {
  Box,
  Flex,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { differenceInDays, format, formatDistanceToNow, startOfDay } from 'date-fns';
import h2p from 'html2plaintext';

import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useNavigate from '../../hooks/useNavigate';
import { OpenExternalIcon, QuestionMark } from '../../icons';
import { IAction } from '../../interfaces/IAction';
import DeleteModal from '../Modals/DeleteModal';
import ActionSquareHeader from './ActionSquareHeader';

const ActionSquare = ({ action }: { action: IAction }) => {
  const { openInNewTab, isPathActive, navigateTo } = useNavigate();
  const { editAction } = useViewModalContext();
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();
  const isAuditDetailsPage = isPathActive(`/audits/`);

  return (
    <>
      {isDeleteModalOpen && (
        <DeleteModal
          id={action._id as string}
          isDeleteModalOpen={isDeleteModalOpen}
          onDeleteModalClose={onDeleteModalClose}
          title={action.reference}
          type="action"
        />
      )}
      <Stack
        _hover={{ boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.18)' }}
        bg="white"
        border="1px solid #D2D1D7"
        borderRadius="20px"
        boxShadow="sm"
        cursor="pointer"
        flexShrink={0}
        h="290px"
        onClick={() => editAction(action)}
        p="12px"
        spacing={6}
        w={['full', 'full', '364px']}
      >
        <ActionSquareHeader action={action} isAuditDetailsPage={isAuditDetailsPage} onDeleteModalOpen={onDeleteModalOpen} />
        <Flex align="center" mt="12px !important" pl="12px" w="full">
          <Box lineHeight="16px" overflow="hidden" textOverflow="ellipsis" w="212px" whiteSpace="nowrap">
            <Text color="actionSquare.section.title" fontSize="smm" fontWeight="semi_medium" h="20px">
              Due date
            </Text>
            <Flex h="20px">
              <Text color="actionSquare.section.text" fontSize="smm" fontWeight="semi_medium">
                {action?.dueDate ? format(new Date(action.dueDate), 'dd MMM yyyy') : 'No due date'}
              </Text>
              {action?.extensions?.some((extension) => extension.isActive) && (
                <Tooltip hasArrow label="Extension has been requested">
                  <Flex align="center" borderRadius="5px" fontSize="12px" h="19px" justify="center" ml="1.5" p="0.5" w="20px">
                    <QuestionMark fill="#FF9A00" />
                  </Flex>
                </Tooltip>
              )}
            </Flex>
          </Box>
          <Box fontSize="smm" lineHeight="16px" overflow="hidden" pl={2} textOverflow="ellipsis" w="200px" whiteSpace="nowrap">
            <Text color="auditSquare.section.title" fontSize="smm" fontWeight="semi_medium" h="20px">
              Status
            </Text>
            <Flex h="20px">
              <Text
                color={
                  action?.status !== 'closed' && differenceInDays(startOfDay(new Date(action.dueDate)), startOfDay(new Date())) < 0
                    ? 'actionSquare.section.textDanger'
                    : 'actionSquare.section.text'
                }
                fontSize="smm"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {action?.status}
              </Text>
              {action?.status === 'under review' && (
                <Flex align="center" borderRadius="5px" fontSize="12px" h="19px" justify="center" ml="1.5" p="0.5" w="20px">
                  <Tooltip hasArrow label="Action submitted for review">
                    <QuestionMark fill="#3C6CE9" />
                  </Tooltip>
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
        <Flex align="center" mt="12px !important" pl="12px" pr="" w="full">
          <Box lineHeight="20px" overflow="hidden" textOverflow="ellipsis" w="212px" whiteSpace="nowrap">
            <Text color="actionSquare.section.title" fontSize="smm" fontWeight="semi_medium">
              Applicable finding
            </Text>
            <Stack
              _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
              align="center"
              direction="row"
              onClick={(e) => {
                e.stopPropagation();
                if (isAuditDetailsPage) navigateTo(`/audits/${action?.answer?.scope?._id}?findingId=${action?.answer?._id}`);
                else openInNewTab(`/audits/${action?.answer?.scope?._id}?findingId=${action?.answer?._id}`);
              }}
              pr="15px"
              spacing={2}
            >
              <Tooltip hasArrow label={action?.answer?.finding}>
                <Text color="actionSquare.section.text" fontSize="smm" fontWeight="semi_medium" isTruncated>
                  {action?.answer?.finding}
                </Text>
              </Tooltip>
              <OpenExternalIcon fill="transparent" stroke="actionSquare.section.text" w="12px" />
            </Stack>
          </Box>
          <Box fontSize="smm" lineHeight="20px" overflow="hidden" pl={2} textOverflow="ellipsis" w="200px" whiteSpace="nowrap">
            <Text color="auditSquare.section.title" fontWeight="semi_medium">
              {action.status === 'closed' ? 'Date closed' : 'Action age'}
            </Text>
            <Text color="actionSquare.section.text" fontSize="smm" fontWeight="semi_medium" textTransform="capitalize">
              {action.status === 'closed'
                ? action?.completedDate
                  ? format(new Date(action.completedDate), 'dd MMM yyyy')
                  : 'No completion date'
                : action?.metatags?.addedAt
                  ? formatDistanceToNow(new Date(action.metatags.addedAt))
                  : 'Age not provided'}
            </Text>
          </Box>
        </Flex>
        <Box lineHeight="20px" mt="12px !important" noOfLines={3} overflow="hidden" px="12px" textOverflow="ellipsis">
          <Text color="actionSquare.section.title" fontSize="smm" fontWeight="semi_medium">
            Action
          </Text>
          <Tooltip label={h2p(action?.title || '')}>
            <Text color="actionSquare.section.text" fontSize="smm" fontWeight="semi_medium" wordBreak="break-word">
              {h2p(action?.title || '')}
            </Text>
          </Tooltip>
        </Box>
      </Stack>
    </>
  );
};

export default ActionSquare;

export const actionSquareStyles = {
  actionSquare: {
    header: {
      bg: '#1E1836',
      textWhite: '#FFFFFF',
      textDanger: '#E93C44',
      menuIconBg: '#4b465e',
      menuIconColor: '#FFFFFF',
      menuItemColor: '#1E1836',
      menuItemDangerColor: 'red',
    },
    badge: {
      bg: '#41B916',
      color: '#FFF',
    },
    audit: '#1E183670',
    title: '#1E1836',
    section: {
      title: '#787486',
      text: '#1E1836',
      textDanger: '#E93C44',
    },
    button: {
      default: {
        bg: '#1315351A',
        color: '#1E1836',
      },
      missed: {
        bg: '#DC0043',
        color: '#FFFFFF',
      },
    },
    missed: {
      icon: {
        stroke: '#DC0043',
        fill: 'transparent',
      },
      color: '#DC0043',
    },
  },
};
