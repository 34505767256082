import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';
import { CheckboxGroup, Stack } from '@chakra-ui/react';

import { actionsSeveritiesByAuditType, actionStatuses, answerStatuses, walkItemStatuses } from '../../bootstrap/config';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useFiltersContext } from '../../contexts/FiltersProvider';
import useFiltersUtils, { actionPriorities } from '../../hooks/useFiltersUtils';
import useNavigate from '../../hooks/useNavigate';
import FilterCheckBox from './FilterCheckBox';

const GET_FORM_DATA = gql`
  query {
    auditTypes {
      _id
      name
      type
    }
  }
`;

const StateChoiceFilter = ({ name }: { name: string }) => {
  const { data } = useQuery(GET_FORM_DATA);
  const { audit, auditTab } = useAuditContext();
  const { filtersValues, setFilters } = useFiltersContext();
  const { trackerItemStatuses } = useFiltersUtils();
  const location = useLocation();
  const { getPath } = useNavigate();

  const usedStatuses = useMemo(() => {
    switch (getPath()) {
      case 'actions':
        return actionStatuses;
      case 'walk-items':
        return walkItemStatuses;
      case 'audits':
      default:
        if (auditTab === 'findings') return answerStatuses;
        return actionStatuses;
    }
  }, [location.pathname]);

  const usedSeverities = useMemo(() => {
    if (!audit?.auditType?.type?.toLowerCase()) return {};
    const severities = {
      ...(actionsSeveritiesByAuditType[audit?.auditType?.type] ?? []).reduce(
        (acc, severity) => ({ ...acc, [severity.toLowerCase().replace(/\/|\.| /g, '')]: severity }),
        {},
      ),
    };
    return severities;
  }, [audit]);

  const value = useMemo(() => filtersValues[name]?.value, [filtersValues, location.pathname]) as string[];

  const renderChoices = () => {
    switch (name) {
      case 'severity':
        return Object.entries(usedSeverities).map(([key, label]) => <FilterCheckBox key={key} label={label} value={key} />);
      case 'itemStatus':
        return Object.entries(trackerItemStatuses).map(([key, label]) => <FilterCheckBox key={key} label={label} value={key} />);
      case 'status':
        return Object.entries(usedStatuses).map(([key, label]) => <FilterCheckBox key={key} label={label} value={key} />);
      case 'priority':
        return Object.entries(actionPriorities).map(([key, label]) => <FilterCheckBox key={key} label={label} value={key} />);
      case 'auditTypesIds':
        return data.auditTypes?.map(({ _id, name }) => <FilterCheckBox key={_id} label={name} value={_id} />);
      default:
        break;
    }
  };

  return (
    <CheckboxGroup onChange={(newValue) => setFilters({ [name]: newValue })} value={value}>
      <Stack direction="column" overflow="auto">
        {renderChoices()}
      </Stack>
    </CheckboxGroup>
  );
};

export default StateChoiceFilter;
