import { createIcon } from '@chakra-ui/icons';

const DocumentIcon = createIcon({
  displayName: 'Accidents',
  viewBox: '0 0 12 16',

  path: (
    <g>
      <path
        d="M11.3337 12.4443V5.11095M8.33366 14.8887H1.3781C0.985368 14.8887 0.666992 14.4642 0.666992 13.9406V1.61465C0.666992 1.091 0.985368 0.666504 1.3781 0.666504H6.88921M6.88921 0.666504V4.22206C6.88921 4.6665 7.33366 5.11095 7.7781 5.11095H11.3337M6.88921 0.666504L11.3337 5.11095"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.66699 11.1108H3.33366" stroke="#1E1836" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.66699 9.11084H4.00033" stroke="#282F36" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M2.66699 7.11084H9.33366" stroke="#282F36" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.44653 11.8377C5.65328 12.3241 6.04481 12.7086 6.53499 12.9064C7.02517 13.1042 7.57384 13.0991 8.06032 12.8924C8.54679 12.6856 8.93121 12.2941 9.12901 11.8039C9.3268 11.3137 9.32178 10.7651 9.11503 10.2786C8.90828 9.79212 8.51675 9.4077 8.02657 9.2099C7.53639 9.01211 6.98772 9.01713 6.50124 9.22388C6.01477 9.43063 5.63035 9.82216 5.43255 10.3123C5.23476 10.8025 5.23978 11.3512 5.44653 11.8377V11.8377Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9 12.7778L11 14.7778" stroke="#282F36" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
});

export default DocumentIcon;
