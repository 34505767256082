import { Avatar, Box, HStack, Stack, Text, Tooltip, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';

import UserAvatar from '../UserAvatar';

const AuditMetadata = ({ audit }) => (
  <VStack flexWrap="wrap" spacing={2} w={['full', 'auto']}>
    <HStack justify="flex-start" spacing={[3, 6]} w="full">
      <HStack align="center" spacing={2}>
        <UserAvatar size="xs" user={audit.auditee} withTooltip />
        <Box>
          <Text fontSize="11px" opacity={0.5}>
            Key auditee
          </Text>
          <Text fontSize="smm">{audit?.auditee?.displayName}</Text>
        </Box>
      </HStack>
      <HStack align="center" spacing={2}>
        {audit.leadAuditor ? (
          <UserAvatar size="xs" user={audit.leadAuditor} withTooltip />
        ) : (
          <Tooltip
            bg="#1E1836"
            borderRadius="8px"
            hasArrow
            label={
              <Stack color="white" lineHeight="10px">
                <Text fontWeight="700">{audit?.leadAuditorName}</Text>
              </Stack>
            }
            p="6px 12px"
          >
            <Avatar name={audit?.leadAuditorName} size="xs" />
          </Tooltip>
        )}
        <Box>
          <Text fontSize="11px" opacity={0.5}>
            BRE Key contact
          </Text>
          <Text fontSize="smm">{audit?.leadAuditor?.displayName ?? audit?.leadAuditorName}</Text>
        </Box>
      </HStack>
    </HStack>
    <HStack spacing={0} w="full" wrap="wrap">
      <VStack align="flex-start" pr={[3, 6]} pt={2} spacing={0}>
        <Text fontSize="11px" opacity={0.5}>
          Type
        </Text>
        <Text fontSize="smm" textTransform="capitalize">
          {audit?.auditType?.name}
        </Text>
      </VStack>
      {audit?.auditType?.name === 'Internal audit' && (
        <VStack align="flex-start" pr={[3, 6]} pt={2} spacing={0}>
          <Text fontSize="11px" opacity={0.5}>
            Impartiality reviewed
          </Text>
          <Text fontSize="smm" textTransform="capitalize">
            {audit.impartialityReviewed ? 'Yes' : 'No'}
          </Text>
        </VStack>
      )}
      <VStack align="flex-start" pr={[3, 6]} pt={2} spacing={0}>
        <Text fontSize="11px" opacity={0.5}>
          Audit date
        </Text>
        <Text fontSize="smm" textTransform="capitalize">
          {format(new Date(audit.auditDate), 'd/L/yyyy')}
        </Text>
      </VStack>
      <VStack align="flex-start" pr={[3, 6]} pt={2} spacing={0}>
        <Text fontSize="11px" opacity={0.5}>
          Report date
        </Text>
        <Text fontSize="smm" textTransform="capitalize">
          {format(new Date(audit.reportDate), 'd/L/yyyy')}
        </Text>
      </VStack>
      <HStack align="center" pr={[3, 6]} pt={2} spacing={2}>
        <UserAvatar size="xs" userId={audit?.location?.ownerId} />
        <Box>
          <Text fontSize="11px" opacity={0.5}>
            Business area
          </Text>
          <Text fontSize="smm" textTransform="capitalize">
            {audit.location?.name}
          </Text>
        </Box>
      </HStack>
      {audit.businessUnit?.name && (
        <VStack align="flex-start" pr={[3, 6]} pt={2} spacing={0}>
          <Box>
            <Text fontSize="11px" opacity={0.5}>
              Business group
            </Text>
            <Text fontSize="smm" textTransform="capitalize">
              {audit.businessUnit?.name}
            </Text>
          </Box>
        </VStack>
      )}
    </HStack>
  </VStack>
);
export default AuditMetadata;
