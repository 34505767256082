import { Flex, Skeleton, Td, Tr, VStack } from '@chakra-ui/react';

import { useFiltersContext } from '../../contexts/FiltersProvider';
import useNavigate from '../../hooks/useNavigate';
import UserAvatar from '../UserAvatar';
import { ActionInsightsTableRow, ISelectedPanel } from './ActionInsightsTable';

const ActionInsightsTableList = ({
  rows = [],
  loading = false,
  selectedPanel,
  selectedAuditTypeId,
}: {
  rows: ActionInsightsTableRow[];
  loading: boolean;
  selectedPanel: ISelectedPanel;
  selectedAuditTypeId?: string;
}) => {
  const { setActionFiltersValue } = useFiltersContext();
  const { navigateTo } = useNavigate();

  const handleClick = ({ status, selectedPanelFilterId }: { status: string; selectedPanelFilterId?: string }) => {
    navigateTo('/actions');
    setActionFiltersValue({
      status: {
        value: status ? [status] : [],
      },
      ...(selectedAuditTypeId && {
        auditTypesIds: {
          value: [selectedAuditTypeId],
        },
      }),
      ...(selectedPanel === 'businessUnit' && {
        businessUnitsIds: {
          value: [selectedPanelFilterId],
        },
      }),
      ...(selectedPanel === 'location' && {
        locationsIds: {
          value: [selectedPanelFilterId],
        },
      }),
      ...(selectedPanel === 'user' && {
        usersIds: {
          value: {
            assigneesIds: [selectedPanelFilterId],
          },
        },
      }),
    });
  };

  return (
    <>
      {rows.map((row: ActionInsightsTableRow) => (
        <Tr key={row?._id}>
          <Td alignItems="center" display="flex">
            {row?.userId && <UserAvatar mr="2" size="sm" userId={row?.userId} withJobTitle />}
            {row?.supLabel && (
              <VStack align="flex-start" w="fit-content">
                <Flex color="#1E1836" fontSize="14px" opacity="0.55">
                  {row?.supLabel}
                </Flex>
                <Flex color="#1E1836" fontSize="16px">
                  {row?.label}
                </Flex>
              </VStack>
            )}
            {selectedPanel !== 'user' && !row?.supLabel && row?.label}
          </Td>
          <Td
            color="#DC0043"
            cursor="pointer"
            onClick={() => handleClick({ status: 'overdue', selectedPanelFilterId: row._id })}
            textAlign="center"
            textDecoration={loading ? 'none' : 'underline'}
          >
            <Skeleton isLoaded={!loading}>{row?.overdue}</Skeleton>
          </Td>
          <Td
            cursor="pointer"
            onClick={() => handleClick({ status: 'open', selectedPanelFilterId: row._id })}
            textAlign="center"
            textDecoration={loading ? 'none' : 'underline'}
          >
            <Skeleton isLoaded={!loading}>{row?.open}</Skeleton>
          </Td>
          <Td
            cursor="pointer"
            onClick={() => handleClick({ status: 'closed', selectedPanelFilterId: row._id })}
            textAlign="center"
            textDecoration={loading ? 'none' : 'underline'}
          >
            <Skeleton
              _after={{
                content: `'(${row?.closedAllTime})'`,
                textDecor: 'none',
                position: 'absolute',
                ml: '5px',
                color: '#787486',
              }}
              isLoaded={!loading}
              position="relative"
            >{`${row?.closedThisYear}`}</Skeleton>
          </Td>
        </Tr>
      ))}
    </>
  );
};

export default ActionInsightsTableList;
