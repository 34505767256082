import { Box, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';
import { differenceInHours, format } from 'date-fns';
import pluralize from 'pluralize';

import { answerSeveritiesLabels } from '../../bootstrap/config';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import { CheckIcon, WarningIcon } from '../../icons';

const FindingSquare = ({ finding }) => {
  const { editFinding } = useViewModalContext();
  const { audit } = useAuditContext();
  const overDueActions = finding.actions?.filter((action) => action.status === 'overdue')?.length ?? 0;
  const completedActions = finding.actions?.filter((action) => action.status === 'closed')?.length ?? 0;
  const openActions = finding.actions?.filter((action) => ['open', 'under review'].includes(action.status))?.length ?? 0;

  return (
    <Stack
      _hover={{ boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.18)' }}
      bg="white"
      border="1px solid #D2D1D7"
      borderRadius="20px"
      boxShadow="sm"
      cursor="pointer"
      flexShrink={0}
      h="auto"
      onClick={() => editFinding(finding)}
      p="12px"
      pb="20px"
      spacing={6}
      w={['full', 'full', '364px']}
    >
      <Flex align="center" bg="#1E1836" borderRadius="10px" h="64px" justify="space-between" p="12px 16px">
        <Text color="#ffffff" fontSize="14px" fontWeight="700" lineHeight="20px" noOfLines={2}>
          {finding?.reference ?? '-'}
        </Text>
      </Flex>
      <Flex align="center" h="20px" px="12px" w="full">
        <Box lineHeight="20px" overflow="hidden" textOverflow="ellipsis" w="212px" whiteSpace="nowrap">
          <Text color="actionSquare.section.title" fontSize={['smm', 'ssm']}>
            Severity rating
          </Text>
          <Tooltip label={finding?.severity && answerSeveritiesLabels[finding?.severity]}>
            <Text color="actionSquare.section.text" fontSize="ssm" isTruncated>
              {finding?.severity && answerSeveritiesLabels[finding?.severity]}
            </Text>
          </Tooltip>
        </Box>
        <Box lineHeight="20px" overflow="hidden" pl={2} textOverflow="ellipsis" w="200px" whiteSpace="nowrap">
          <Text color="actionSquare.section.title" fontSize={['smm', 'ssm']}>
            Finding date
          </Text>
          <Text fontSize="ssm" textTransform="capitalize">
            {(finding?.date && format(new Date(finding?.date), 'd/MM/yyyy')) ?? '-'}
          </Text>
        </Box>
      </Flex>
      <Flex align="center" h="20px" px="12px" w="full">
        <Box lineHeight="20px" overflow="hidden" textOverflow="ellipsis" w="212px" whiteSpace="nowrap">
          <Text color="actionSquare.section.title" fontSize={['smm', 'ssm']}>
            Action status
          </Text>
          <Flex align="center">
            {completedActions === finding.actions?.length ? (
              <Flex align="center">
                <CheckIcon stroke="#41B916" w="12px" />
                <Text fontSize="ssm" ml="1.5">
                  No open actions
                </Text>
              </Flex>
            ) : (
              <>
                {overDueActions > 0 && (
                  <Tooltip bg="#E93C44" color="#FFFFFF" label={`${overDueActions} Overdue ${pluralize('action', overDueActions)}`}>
                    <WarningIcon bg="#E93C44" borderRadius="3px" fill="#E93C44" mr="1.5" p="2px" stroke="white" />
                  </Tooltip>
                )}
                <Text color="actionSquare.section.text" fontSize="ssm">
                  {openActions + overDueActions}
                </Text>
              </>
            )}
          </Flex>
        </Box>
        <Box lineHeight="20px" overflow="hidden" pl={2} textOverflow="ellipsis" w="200px" whiteSpace="nowrap">
          <Text color="actionSquare.section.title" fontSize={['smm', 'ssm']}>
            Status
          </Text>
          <Text fontSize="ssm" textTransform="capitalize">
            {(finding.actions || []).length === 0 && differenceInHours(new Date(), new Date(finding.metatags?.addedAt)) <= 24
              ? 'new'
              : finding.status}
          </Text>
        </Box>
      </Flex>
      {audit.auditType?.type !== 'IA' && (
        <Flex align="center" h="20px" px="12px" w="full">
          <Box lineHeight="20px" overflow="hidden" textOverflow="ellipsis" w="212px" whiteSpace="nowrap">
            <Text color="actionSquare.section.title" fontSize={['smm', 'ssm']}>
              Business group
            </Text>
            <Text color="actionSquare.section.text" fontSize="ssm">
              {finding.businessUnit?.name || '-'}
            </Text>
          </Box>
        </Flex>
      )}
    </Stack>
  );
};

export default FindingSquare;
