import { gql, useQuery } from '@apollo/client';
import { Box, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { format } from 'date-fns';

import { useAuditContext } from '../../contexts/AuditProvider';
import { BlankPage, DownloadIcon, Trashcan } from '../../icons';
import { IDocument } from '../../interfaces/IResponse';
import Can from '../can';
import DeleteModal from '../Modals/DeleteModal';

const GET_DOCUMENT_DETAILS = gql`
  query FilesDetails($filesDetailsQuery: FilesDetailsQuery) {
    filesDetails(filesDetailsQuery: $filesDetailsQuery) {
      id
      thumbnail
      path
      preview
    }
  }
`;

const DocumentTrackerListItem = ({ document }: { document: IDocument }) => {
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();
  const { audit } = useAuditContext();

  const { data } = useQuery(GET_DOCUMENT_DETAILS, {
    variables: { filesDetailsQuery: { ids: [document?.id] } },
  });
  const documentDetails = (data?.filesDetails || [])[0];

  return (
    <>
      {isDeleteModalOpen && (
        <DeleteModal
          id={document.id}
          isDeleteModalOpen={isDeleteModalOpen}
          onDeleteModalClose={onDeleteModalClose}
          title={document.name}
          type="document"
        />
      )}
      <Box bg="white" borderBottomColor="actionsList.headerBorderColor" borderBottomWidth="1px" py={[1, 0]} w="full">
        <Flex align="center" h={['full', '73px']} position="relative" w="full">
          <Flex w="5%">
            <BlankPage color="#787486" />
          </Flex>
          <Flex flexDir="column" w="44%">
            <Flex
              align="flex-start"
              color="actionsList.fontColor"
              fontSize="smm"
              fontWeight="400"
              h="50%"
              lineHeight="18px"
              noOfLines={2}
              opacity="1"
              pt="3px"
              textOverflow="ellipsis"
              w="90%"
            >
              <Text>{document.name}</Text>
            </Flex>
          </Flex>
          <Flex flexDir="column" w="46%">
            <Flex
              align="flex-start"
              color="actionsList.fontColor"
              fontSize="smm"
              fontWeight="400"
              h="50%"
              lineHeight="18px"
              noOfLines={1}
              opacity="1"
              pt="3px"
              textOverflow="ellipsis"
            >
              {document.addedAt && format(new Date(document.addedAt), 'dd/MM/Y HH:mm')}
            </Flex>
          </Flex>

          <Flex mr={1} w="5%">
            <DownloadIcon
              color="#1E1836"
              cursor="pointer"
              fontSize="20px"
              onClick={() => {
                window.open(documentDetails?.path);
              }}
            />
            <Can action="audits.edit"
              data={{ auditType: audit.auditType }} yes={() => <Trashcan cursor="pointer" ml="2" onClick={onDeleteModalOpen} stroke="#1E1836" />} />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default DocumentTrackerListItem;
