import { createIcon } from '@chakra-ui/icons';

const Licenses = createIcon({
  displayName: 'Licenses',
  viewBox: '0 0 22 22',

  path: (
    <g>
      <path
        d="M15.6667 3.66667C15.6667 4.37391 15.9477 5.05219 16.4478 5.55228C16.9479 6.05238 17.6262 6.33333 18.3334 6.33333C19.0407 6.33333 19.7189 6.05238 20.219 5.55228C20.7191 5.05219 21.0001 4.37391 21.0001 3.66667C21.0001 2.95942 20.7191 2.28115 20.219 1.78105C19.7189 1.28095 19.0407 1 18.3334 1C17.6262 1 16.9479 1.28095 16.4478 1.78105C15.9477 2.28115 15.6667 2.95942 15.6667 3.66667V3.66667Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.61239 1.23822C4.64873 1.16666 4.70417 1.10655 4.77258 1.06457C4.84098 1.02259 4.91968 1.00037 4.99994 1.00037C5.0802 1.00037 5.1589 1.02259 5.2273 1.06457C5.29571 1.10655 5.35115 1.16666 5.3875 1.23822L6.28883 3.05333C6.32 3.1156 6.3658 3.1694 6.42231 3.21011C6.47881 3.25083 6.54433 3.27724 6.61327 3.28711L8.63372 3.57866C8.71259 3.58999 8.78676 3.623 8.84797 3.67401C8.90918 3.72502 8.95502 3.79202 8.98039 3.86755C9.00512 3.94279 9.00815 4.02348 8.98913 4.10036C8.97011 4.17724 8.9298 4.2472 8.87283 4.30222L7.40616 5.72444C7.35691 5.7722 7.32001 5.83122 7.29864 5.89641C7.27727 5.9616 7.27207 6.03102 7.2835 6.09866L7.62927 8.10311C7.64272 8.18165 7.63366 8.2624 7.60314 8.33602C7.57263 8.40963 7.5219 8.47311 7.45683 8.51911C7.39127 8.56555 7.31414 8.59295 7.23398 8.59828C7.15382 8.60362 7.07374 8.58667 7.00261 8.54933L5.20172 7.60889C5.13953 7.57604 5.07027 7.55887 4.99994 7.55887C4.92961 7.55887 4.86035 7.57604 4.79816 7.60889L2.99994 8.54933C2.92881 8.58667 2.84873 8.60362 2.76857 8.59828C2.6884 8.59295 2.61128 8.56555 2.54572 8.51911C2.48065 8.47311 2.42992 8.40963 2.39941 8.33602C2.36889 8.2624 2.35983 8.18165 2.37327 8.10311L2.71905 6.09866C2.73048 6.03102 2.72528 5.9616 2.70391 5.89641C2.68254 5.83122 2.64564 5.7722 2.59638 5.72444L1.13327 4.30222C1.07631 4.2472 1.036 4.17724 1.01697 4.10036C0.99795 4.02348 1.00098 3.94279 1.02572 3.86755C1.05099 3.79197 1.09681 3.72491 1.15804 3.67389C1.21926 3.62287 1.29348 3.58989 1.37238 3.57866L3.39194 3.28711C3.45993 3.27644 3.52438 3.24965 3.57989 3.20898C3.63541 3.16831 3.68038 3.11494 3.71105 3.05333L4.61239 1.23822Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M18.3333 1H9" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path
        d="M15.6667 3.66663V17C15.6667 18.0608 15.2452 19.0782 14.4951 19.8284C13.7449 20.5785 12.7275 21 11.6667 21H1C2.06087 21 3.07828 20.5785 3.82843 19.8284C4.57857 19.0782 5 18.0608 5 17V11.6666"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M7.66675 15.6666H13.0001" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M7.66675 13H13.0001" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M10.3333 10.3334H12.9999" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M10.3333 7.66663H12.9999" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default Licenses;
