import { useForm } from 'react-hook-form';

import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useDataContext } from '../../contexts/DataProvider';
import useNavigate from '../../hooks/useNavigate';
import { IAction } from '../../interfaces/IAction';
import { serializeDate } from '../../utils/helpers';
import { TextInputMultiline } from '../Forms';

interface ISubmitActionModal {
  action: IAction;
  isSubmitModalOpen: boolean;
  onSubmitModalClose: () => void;
  onOuterModalClose: () => void;
}

const SUBMIT_ACTION = gql`
  mutation SubmitAction($action: SubmitActionInput!) {
    submitAction(submitActionInput: $action)
  }
`;

const SubmitActionModal = ({ action, isSubmitModalOpen, onSubmitModalClose, onOuterModalClose }: ISubmitActionModal) => {
  const toast = useToast();
  const { isPathActive } = useNavigate();
  const { refetchAction } = useDataContext();
  const { refetchActions } = useAuditContext();
  const [submitAction] = useMutation(SUBMIT_ACTION);

  // forms
  const {
    control,
    getValues,
    formState: { isValid },
  } = useForm({
    mode: 'all',
  });

  const handleSubmitAction = async () => {
    try {
      const values = getValues();
      await submitAction({
        variables: {
          action: {
            ...values,
            _id: action._id,
            actionTimestamp: action?.metatags?.updatedAt ? serializeDate(action?.metatags?.updatedAt) : serializeDate(action?.metatags?.addedAt),
          },
        },
      });
      toast({
        ...toastSuccess,
        description: `Action submitted`,
      });
      if (isPathActive('/actions')) refetchAction();
      else if (isPathActive('/audits/')) refetchActions();
      onSubmitModalClose();
      onOuterModalClose();
    } catch (e: any) {
      toast({
        ...toastFailed,
        description: e.message,
      });
    } finally {
      onSubmitModalClose();
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isSubmitModalOpen}
      onClose={onSubmitModalClose}
      onEsc={onSubmitModalClose}
      variant="deleteModal"
    >
      <ModalOverlay />
      <ModalContent rounded="20px">
        <ModalHeader>
          <Text color="submitActionModal.font.color" fontSize="lg" fontWeight="bold">
            Submit action
          </Text>
          <ModalCloseButton />
          <Divider color="submitActionModal.divider.color" mt="10px" orientation="horizontal" />
        </ModalHeader>
        <ModalBody>
          <Stack lineHeight="15px" mb={4}>
            <Text color="submitActionModal.font.color" fontSize="smm" fontWeight="semi_medium">
              Are you sure you want to submit this action for review ?
            </Text>
            <Text color="submitActionModal.font.color" fontSize="smm" fontWeight="semi_medium">
              This action cannot be undone.
            </Text>
          </Stack>
          <TextInputMultiline
            control={control}
            label="Explanation of action submission"
            name="comment"
            placeholder="Please provide an explanation"
            validations={{
              notEmpty: true,
            }}
          />
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end" spacing={4} w="full">
            <Button
              _hover={{ opacity: 0.7 }}
              bg="submitActionModal.cancelButton.bg"
              color="submitActionModal.cancelButton.color"
              onClick={onSubmitModalClose}
              rounded="10px"
            >
              Cancel
            </Button>
            <Button
              _hover={{ opacity: 0.7 }}
              bg="submitActionModal.deleteButton.bg"
              color="submitActionModal.deleteButton.color"
              disabled={!isValid}
              onClick={handleSubmitAction}
              rounded="10px"
            >
              Submit
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SubmitActionModal;

export const submitActionModalStyles = {
  submitActionModal: {
    deleteButton: {
      color: '#ffffff',
      bg: '#dc0043',
    },
    cancelButton: {
      color: '#787486',
      bg: '#F0F2F5',
    },
    font: {
      color: '#1E1836',
    },
    divider: {
      color: '#F0F0F0',
    },
  },
};
