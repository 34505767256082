import { createContext, useContext, useMemo } from 'react';

import { gql, OperationVariables, useLazyQuery, useQuery } from '@apollo/client';

import { IAction } from '../interfaces/IAction';
import { IDataContext } from '../interfaces/IDataContext';
import { parseAction } from '../utils/helpers';

export const DataContext = createContext({} as IDataContext);

const GET_AUDITS = gql`
  query ($auditQueryInput: AuditQueryInput) {
    audits(auditQueryInput: $auditQueryInput) {
      _id
      reference
      title
      description
      auditDate
      reportDate
      locationId
      businessUnitId
      auditeeId
      auditorId
      impartialityReviewed
      typeOfAssessment
      leadAuditorId
      leadAuditorName
      attachments {
        id
        name
        addedAt
      }
      auditType {
        _id
        name
        type
        reviewersIds
      }
      location {
        _id
        name
      }
      businessUnit {
        _id
        name
      }
      auditee {
        _id
        displayName
        imgUrl
      }
      auditor {
        _id
        displayName
        imgUrl
      }
      leadAuditor {
        _id
        displayName
        imgUrl
        jobTitle
      }
      metatags {
        addedAt
        removedBy
      }
    }
  }
`;

const GET_ACTIONS = gql`
  query ($actionQueryInput: ActionQueryInput) {
    actions(actionQueryInput: $actionQueryInput) {
      _id
      reference
      findingId
      title
      dueDate
      approverId
      completedDate
      auditClosureDate
      standard
      classification
      extent
      rootCauseAnalysis
      rootCause
      completedDate
      daysOverdue
      daysOpen
      clauses
      document
      evidence {
        id
        name
        addedAt
      }
      status
      comments
      groupWide
      submittedDate
      submittedComment
      originalDueDate
      locationId
      businessUnitId
      extensions {
        dueDate
        reason
        isActive
        status
        requestedById
        extendedById
      }
      assigneeId
      assignee {
        _id
        displayName
        imgUrl
        jobTitle
      }
      approver {
        _id
        displayName
        imgUrl
        jobTitle
      }
      creator {
        _id
        displayName
        imgUrl
        jobTitle
      }
      answer {
        _id
        finding
        severity
        audit {
          _id
          title
          reference
          auditType {
            name
            type
            reviewersIds
          }
        }
        scope {
          _id
        }
      }
      notes {
        submittedById
        note
        addedAt
        submittedBy
        isEditedByAdmin
      }
      metatags {
        addedBy
        addedAt
        updatedAt
      }
    }
  }
`;

const GET_ACTIONS_COUNT = gql`
  query getActionsCounts($actionsInsightsQuery: ActionsInsightsQuery) {
    actionsInsights(actionsInsightsQuery: $actionsInsightsQuery) {
      grouped {
        total
        open
        overdue
        underReview
        closedAllTime
      }
    }
  }
`;

export const useDataContext = () => {
  const context = useContext(DataContext);
  if (!context) throw new Error('useDataContext must be used within the DataProvider');
  return context;
};

const DataProvider = ({ children }) => {
  const {
    data: auditsData,
    loading: auditsLoading,
    error: auditsError,
    refetch: auditRefetch,
  } = useQuery(GET_AUDITS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'ignore',
  });
  const {
    data: actionsData,
    loading: actionsLoading,
    error: actionsError,
    refetch: actionRefetch,
  } = useQuery(GET_ACTIONS, {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore', // used to show data even if there are errors (we want to show actions anyway)
  });
  const [fetchAction] = useLazyQuery<{ actions: IAction[] }>(GET_ACTIONS);
  const { data: actionsCountData, refetch: refetchActionsCount } = useQuery(GET_ACTIONS_COUNT);
  const actionsCount = useMemo(() => {
    const actionsCountGroup = (actionsCountData?.actionsInsights?.grouped || [])[0];
    return {
      total: actionsCountGroup?.total || 0,
      open: actionsCountGroup?.open || 0,
      overdue: actionsCountGroup?.overdue || 0,
      'under review': actionsCountGroup?.underReview || 0,
      closed: actionsCountGroup?.closedAllTime || 0,
    };
  }, [JSON.stringify(actionsCountData)]);

  const audits = auditsData?.audits;
  const refetchAudit = async (variables?: Partial<OperationVariables> | undefined) => {
    await auditRefetch(variables);
  };

  const actions: IAction[] = (actionsData?.actions || []).map(action => parseAction(action));
  const refetchAction = async (variables?: Partial<OperationVariables> | undefined) => {
    if (variables) {
      refetchActionsCount({
        actionsInsightsQuery: variables,
      });
      actionRefetch({
        actionQueryInput: variables,
      });
    } else {
      refetchActionsCount();
      actionRefetch();
    }
  };

  const value = useMemo(
    () => ({
      audits,
      auditsLoading,
      auditsError,
      actions,
      actionsLoading,
      actionsError,
      actionsCount,
      fetchAction,
      refetchAudit,
      refetchAction,
    }),
    [audits, actions, auditsLoading, auditsError, actionsLoading, actionsError],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;
