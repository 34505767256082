import { Icon, IconProps } from '@chakra-ui/icons';

const RefreshIcon = (props: IconProps) => (
  <Icon fill="none" viewBox="0 0 19 20" {...props}>
    <path
      d="M17.4467 11.9735C17.5182 11.7068 17.3599 11.4326 17.0932 11.3612C16.8264 11.2897 16.5523 11.448 16.4808 11.7147L17.4467 11.9735ZM2.58962 13.513C2.32064 13.4504 2.05192 13.6178 1.98941 13.8868L0.970703 18.27C0.908191 18.5389 1.07556 18.8077 1.34453 18.8702C1.61351 18.9327 1.88223 18.7653 1.94474 18.4963L2.85026 14.6002L6.74642 15.5057C7.01539 15.5682 7.28411 15.4008 7.34662 15.1319C7.40914 14.8629 7.24177 14.5942 6.97279 14.5317L2.58962 13.513ZM16.4808 11.7147C15.4111 15.7069 11.3076 18.0761 7.31533 17.0064L7.05651 17.9723C11.5822 19.185 16.2341 16.4992 17.4467 11.9735L16.4808 11.7147ZM7.31533 17.0064C5.40645 16.4949 3.8694 15.2907 2.90084 13.7356L2.05202 14.2643C3.14935 16.0261 4.89307 17.3926 7.05651 17.9723L7.31533 17.0064Z"
    />
    <path
      d="M1.05802 7.58226C0.986547 7.849 1.14484 8.12317 1.41157 8.19464C1.6783 8.26611 1.95247 8.10782 2.02394 7.84108L1.05802 7.58226ZM16.1416 6.47893C16.4061 6.5585 16.6849 6.40864 16.7645 6.14421L18.0612 1.83506C18.1407 1.57063 17.9909 1.29177 17.7264 1.2122C17.462 1.13263 17.1831 1.28249 17.1036 1.54692L15.951 5.37727L12.1206 4.22469C11.8562 4.14512 11.5773 4.29498 11.4978 4.55941C11.4182 4.82384 11.5681 5.10271 11.8325 5.18228L16.1416 6.47893ZM2.02394 7.84108C3.09366 3.84885 7.19718 1.47968 11.1894 2.5494L11.4482 1.58347C6.92253 0.370814 2.27067 3.05656 1.05802 7.58226L2.02394 7.84108ZM11.1894 2.5494C13.2653 3.10564 14.9019 4.48138 15.8453 6.23682L16.7261 5.76345C15.6577 3.77521 13.8015 2.21402 11.4482 1.58347L11.1894 2.5494Z"
    />
  </Icon>
);

export default RefreshIcon;
