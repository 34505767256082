import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Flex, Icon, Text } from '@chakra-ui/react';

import { useAppContext } from '../../contexts/AppProvider';
import { useConfigContext } from '../../contexts/ConfigProvider';
import { useFiltersContext } from '../../contexts/FiltersProvider';
import useDevice from '../../hooks/useDevice';
import useNavigate from '../../hooks/useNavigate';
import { Conforme, ConformeSmall } from '../../icons';
import { getInitials } from '../../utils/helpers';
import Can from '../can';
import NavigationLeftItem from './NavigationLeftItem';
import NavigationLeftItemTablet from './NavigationLeftItemTablet';

const NavigationLeft = () => {
  const history = useHistory();
  const { navigateTo, isPathActive } = useNavigate();
  const { cleanFilters, showFiltersPanel } = useFiltersContext();
  const { module, user } = useAppContext();
  const { isBusinessAreaOwner, isBusinessGroupOwner } = user || {};
  const { menuItems } = useConfigContext();
  const [subsectionOpen, setSubsectionOpen] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const device = useDevice();

  useEffect(() => {
    if (!(isPathActive('/', { exact: true }) || isPathActive('/items')) && cleanFilters) cleanFilters();
  }, [history.location.pathname]);

  return (
    <>
      <Box
        bg="navigationLeft.bg"
        display={['none', 'block', 'block']}
        fontWeight="semibold"
        h="100vh"
        w={showFiltersPanel ? ['0px', '80px', '80px'] : ['0px', '80px', '270px']}
      >
        <Box alignItems="center" cursor="pointer" display="flex" h="80px" onClick={() => navigateTo('/')}>
          <Text color="navigationLeft.organizationNameFontColor" fontSize="16px" fontWeight="bold" ml="24px" w="full">
            {showFiltersPanel || device === 'tablet' ? getInitials(module?.name) : module?.name}
          </Text>
        </Box>
        <Flex direction="column" h="calc(100% - 80px)" justify="space-between" pt={['0px', '10px']}>
          <Box>
            {menuItems?.map((menuItem: any, i) => (
              <Can
                action={menuItem.permission}
                data={{ isBusinessAreaOwner, isBusinessGroupOwner }}
                key={`menu${i}`}
                yes={() => {
                  if (device === 'desktop') return <NavigationLeftItem menuItem={menuItem} />;

                  if (device === 'tablet') {
                    return (
                      <NavigationLeftItemTablet
                        filtersOpen={filtersOpen}
                        menuItem={menuItem}
                        setFiltersOpen={setFiltersOpen}
                        setSubsectionOpen={setSubsectionOpen}
                        subsectionOpen={subsectionOpen}
                      />
                    );
                  }
                  return <></>;
                }}
              />
            ))}
          </Box>
          {device === 'desktop' && (
            <Icon as={showFiltersPanel ? ConformeSmall : Conforme} h="30px" mb="20px" ml="20px" w={showFiltersPanel ? '27px' : '103px'} />
          )}
          {device === 'tablet' && <Icon as={ConformeSmall} h="30px" mb="20px" ml="20px" w="27px" />}
        </Flex>
      </Box>
    </>
  );
};

export default NavigationLeft;

export const navigationLeftStyles = {
  navigationLeft: {
    bg: '#FFFFFF',
    organizationNameFontColor: '#282F36',
  },
};
