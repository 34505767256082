import { createIcon } from '@chakra-ui/icons';

const CircledTickBold = createIcon({
  path: (
    <g>
      <path
        d="M11 5.543v.46a5 5 0 11-2.965-4.57"
        fill="none"
        stroke="#41B916"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M11 2.003L6 7.008l-1.5-1.5" fill="none" stroke="#41B916" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
  viewBox: '0 0 12 12',
});

export default CircledTickBold;
