import { createIcon } from '@chakra-ui/icons';

const Save = createIcon({
  viewBox: '0 0 15 16',
  path: (
    <g>
      <path
        d="M13.4583 14.875H1.54167C1.29855 14.875 1.06539 14.7784 0.893485 14.6065C0.721577 14.4346 0.625 14.2014 0.625 13.9583V5.09294C0.625104 4.60675 0.818326 4.14051 1.16217 3.79678L3.29617 1.66217C3.46644 1.49184 3.66861 1.35673 3.89111 1.26457C4.11362 1.1724 4.3521 1.12497 4.59294 1.125H13.4583C13.7014 1.125 13.9346 1.22158 14.1065 1.39349C14.2784 1.56539 14.375 1.79855 14.375 2.04167V13.9583C14.375 14.2014 14.2784 14.4346 14.1065 14.6065C13.9346 14.7784 13.7014 14.875 13.4583 14.875Z"
        fill="#ffffff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.125 8.45833C6.125 8.94456 6.31815 9.41088 6.66197 9.7547C7.00579 10.0985 7.4721 10.2917 7.95833 10.2917C8.44456 10.2917 8.91088 10.0985 9.2547 9.7547C9.59851 9.41088 9.79167 8.94456 9.79167 8.45833C9.79167 7.9721 9.59851 7.50579 9.2547 7.16197C8.91088 6.81815 8.44456 6.625 7.95833 6.625C7.4721 6.625 7.00579 6.81815 6.66197 7.16197C6.31815 7.50579 6.125 7.9721 6.125 8.45833Z"
        fill="#dc0043"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.20837 1.125V2.95833C5.20837 3.20145 5.30495 3.43461 5.47686 3.60651C5.64877 3.77842 5.88193 3.875 6.12504 3.875H10.7084C10.9515 3.875 11.1846 3.77842 11.3566 3.60651C11.5285 3.43461 11.625 3.20145 11.625 2.95833V1.125"
        fill="#dc0043"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default Save;
