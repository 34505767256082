import { useEffect, useState } from 'react';

import { Flex, HStack, Stack } from '@chakra-ui/react';

import { actionStatuses } from '../../bootstrap/config';
import { IAction } from '../../interfaces/IAction';
import ActionSquare from './ActionSquare';

const ActionsGroup = ({ actions }: { actions: IAction[] }) => {
  const [filteredResults, setFilteredResults] = useState<any>({});

  useEffect(() => {
    const filteredActions: any = {};

    filteredActions.open = actions.filter((action) => action.status === 'open');
    filteredActions.closed = actions.filter((action) => action.status === 'closed');
    filteredActions.overdue = actions.filter((action) => action.status === 'overdue');
    filteredActions['under review'] = actions.filter((action) => action.status === 'under review');

    setFilteredResults(filteredActions);
  }, [actions]);

  const renderGroup = (group: string) => (
    <Flex direction="column" key={group} minW="calc(347px + 1rem)">
      <Flex
        align="center"
        bg={`actionsGroup.${group}`}
        color="#FFFFFF"
        fontWeight="700"
        justify="space-between"
        mb={4}
        minH="40px"
        pl={5}
        pr={4}
        rounded="full"
        w="full"
      >
        {actionStatuses[group]}
      </Flex>
      <Stack align="center" direction="column" pb={5} spacing={6} w="full">
        {filteredResults[group]?.map((action: IAction) => (
          <ActionSquare action={action} key={action._id} />
        ))}
      </Stack>
    </Flex>
  );

  return (
    <HStack align="flex-start" h="full" spacing={4} w="auto">
      {Object.keys(actionStatuses).map((status) => renderGroup(status))}
    </HStack>
  );
};

export default ActionsGroup;

export const actionsGroupStyles = {
  actionsGroup: {
    open: '#3C6CE9',
    closed: '#41B916',
    overdue: '#E93C44',
    'under review': '#FF9A00',
  },
};
