import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { Box, Flex, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { capitalize, flatten, pick } from 'lodash';
import pluralize from 'pluralize';
import { v4 as uuidv4 } from 'uuid';

import { actionsSeveritiesByAuditType, auditTypesNames } from '../../bootstrap/config';
import { Dropdown } from '../Forms';

const ActionsSeverityTable = ({
  loading,
  title,
  auditType,
  data,
  filterBy,
  filters,
  refetch,
  selectedActionTypes = [['IA', 'UKAS', 'LRQA']],
  ...props
}: {
  title: string;
  auditType?: 'IA' | 'UKAS' | 'LRQA' | 'ISO' | 'IINC' | 'WA' | 'SHE';
  data?: any;
  loading?: boolean;
  filterBy?: string;
  filters?: any;
  refetch?: any;
  selectedActionTypes?: string[][];
  [x: string]: any;
}) => {
  const { control, watch, reset } = useForm({
    defaultValues: {
      filterBy: '',
    },
    mode: 'all',
  });
  const values = watch();

  useEffect(() => {
    reset();
  }, [filterBy]);

  useEffect(() => {
    if (refetch) {
      refetch(
        filterBy
          ? {
            actionsBySeverityRatingQuery: {
              [filterBy]: values.filterBy,
            },
          }
          : {},
      );
    }
  }, [values]);

  const headerElements = useMemo(() => {
    if (auditType) return [actionsSeveritiesByAuditType[auditType]];
    return selectedActionTypes.map((selectedActionType) =>
      flatten(Object.values(pick(actionsSeveritiesByAuditType, selectedActionType))));
  }, [auditType, selectedActionTypes]);

  return (
    <Box p={6} {...props}>
      <Flex align="center" h="42px" justify="space-between" mb={4}>
        <Text color="#1E1836" fontSize="18px" fontWeight="700">
          {title}
        </Text>
        {(filters || [])?.length > 0 && filterBy && (
          <Flex align="center">
            <Text color="#1E1836" mr={3} opacity="0.4">
              Filter by
            </Text>
            <Flex mt={-2}>
              <Dropdown
                control={control}
                name="filterBy"
                options={[
                  {
                    label: `All ${filterBy === 'businessUnitId'
                      ? capitalize(pluralize('business group'))
                      : filterBy === 'locationId'
                        ? capitalize(pluralize('business area'))
                        : capitalize(pluralize('user'))
                      }`,
                    value: '',
                  },
                  ...filters,
                ]}
              />
            </Flex>
          </Flex>
        )}
      </Flex>

      <Stack>
        {(headerElements || []).map((headers, index) => (
          <TableContainer>
            <Table
              sx={{
                'td, th': {
                  fontSize: '14px',
                  fontFamily: 'Helvetica',
                },
                th: {
                  border: '1px solid #787486',
                  textTransform: 'none',
                  textAlign: 'center',
                  padding: '8px 14px',
                },
                td: {
                  border: '1px solid #D2D1D7',
                  textAlign: 'center',
                  padding: '0px',
                },
              }}
            >
              <Thead>
                {!auditType && (
                  <Tr bg="#1E1836">
                    <Th color="#FFFFFF" rowSpan={2} />
                    {selectedActionTypes[index].map((actionType) => (
                      <Th color="#FFFFFF" colSpan={Object.values(pick(actionsSeveritiesByAuditType, actionType))[0].length} whiteSpace="normal">
                        {auditTypesNames[actionType] || actionType}
                      </Th>
                    ))}
                  </Tr>
                )}
                <Tr bg="#1E1836" color="#FFFFFF">
                  {auditType && <Th rowSpan={1} />}
                  {headers.map((severity, index) => (
                    <Th color="#FFFFFF" key={`${severity}-${index}`} whiteSpace="normal">
                      <Tooltip
                        arrowShadowColor="rgba(255,255,255,.4)"
                        bg="#1E1836"
                        borderRadius="8px"
                        boxShadow="0 0 0 1px rgba(255,255,255,.4)"
                        color="#FFFFFF"
                        hasArrow
                        label={severity === 'OFI' && <Text color="white">Opportunity for Improvement</Text>}
                        placement="top-start"
                      >
                        {severity}
                      </Tooltip>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Th bg="#1E1836" color="#DC0043" w="100px">
                    Overdue
                  </Th>
                  {!loading && auditType
                    ? actionsSeveritiesByAuditType[auditType].map((severity) => (
                      <Td color="#DC0043" key={uuidv4()}>
                        {data?.[severity?.toLowerCase().replace(/\/|\.| /g, '')]?.[0]}
                      </Td>
                    ))
                    : selectedActionTypes[index].map((key) =>
                      actionsSeveritiesByAuditType[key].map((severity) => (
                        <Td color="#DC0043" key={uuidv4()}>
                          {data?.[key]?.[severity?.toLowerCase().replace(/\/|\.| /g, '')]?.[0]}
                        </Td>
                      )),
                    )}
                </Tr>
                <Tr>
                  <Th bg="#1E1836" color="#FFFFFF">
                    Open (total)
                  </Th>
                  {!loading && auditType
                    ? actionsSeveritiesByAuditType?.[auditType].map((severity) => (
                      <Td key={uuidv4()}>{data?.[severity?.toLowerCase().replace(/\/|\.| /g, '')]?.[1]}</Td>
                    ))
                    : selectedActionTypes[index].map((key) =>
                      actionsSeveritiesByAuditType[key].map((severity) => (
                        <Td key={uuidv4()}>{data?.[key]?.[severity?.toLowerCase().replace(/\/|\.| /g, '')]?.[1]}</Td>
                      )),
                    )}
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        ))}
      </Stack>
    </Box>
  );
};

export default ActionsSeverityTable;
