import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

interface IAuditDeleteModal {
  isDeleteModalOpen: boolean;
  onDeleteModalClose: () => void;
  handleOnDelete: () => void;
}

const NoteConfirmDeleteModal = ({ isDeleteModalOpen, onDeleteModalClose, handleOnDelete }: IAuditDeleteModal) => (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isDeleteModalOpen}
      onClose={onDeleteModalClose}
      onEsc={onDeleteModalClose}
      variant="deleteModal"
    >
      <ModalOverlay />
      <ModalContent rounded="20px">
        <ModalHeader>
          <Text color="deleteModal.font.color" fontSize="lg" fontWeight="bold">
            Confirm deletion
          </Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Stack lineHeight="20px">
            <Text color="deleteModal.font.color" fontSize="smm" fontWeight="semi_medium">
              Are you sure you want to delete the note?
            </Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end" spacing={4} w="full">
            <Button
              _hover={{ opacity: 0.7 }}
              bg="deleteModal.cancelButton.bg"
              color="deleteModal.cancelButton.color"
              onClick={onDeleteModalClose}
              rounded="10px"
            >
              Cancel
            </Button>
            <Button
              _hover={{ opacity: 0.7 }}
              bg="deleteModal.deleteButton.bg"
              color="deleteModal.deleteButton.color"
              onClick={async () => {
                handleOnDelete();
                onDeleteModalClose();
              }}
              rounded="10px"
            >
              Delete
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );

export default NoteConfirmDeleteModal;

export const noteConfirmDeleteModalStyles = {
  deleteModal: {
    deleteButton: {
      color: '#ffffff',
      bg: '#dc0043',
    },
    cancelButton: {
      color: '#787486',
      bg: '#F0F2F5',
    },
    font: {
      color: '#1E1836',
    },
  },
};
