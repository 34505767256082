import { useEffect, useMemo, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { useAppContext } from '../../contexts/AppProvider';
import AuditModalProvider from '../../contexts/AuditModalProvider';
import useDevice from '../../hooks/useDevice';
import { Close, SearchIcon } from '../../icons';
import { IAnswer } from '../../interfaces/IAnswer';
import { IAudit } from '../../interfaces/IAudit';
import AuditModal from '../AuditModal/AuditModal';
import { isPermitted } from '../can';
import FindingModal from '../Finding/FindingModal';
import Loader from '../Loader';
import SelectionListItem from './SelectionListItem';

const GET_AUDIT = gql`
  query GetAudit($auditQueryInput: AuditQueryInput) {
    audits(auditQueryInput: $auditQueryInput) {
      _id
      title
      reference
      locationId
      auditType { 
        name
        type
        reviewersIds
      }
    }
  }
`;

const GET_FINDING = gql`
  query GetFinding($answerQuery: AnswerQuery) {
    answers(answerQuery: $answerQuery) {
      _id
      finding
      severity
      audit {
        auditType {
          name
          type
          reviewersIds
        }
      }
    }
  }
`;

const SelectionModal = ({ selectModalCategory, isSelectionModalOpen, onSelectionModalClose, setAddSelectedData, scope }) => {
  const { user } = useAppContext();
  const device = useDevice();
  const [searchText, setSearchText] = useState<string>('');
  const { isOpen: isCreateModalOpen, onClose: onCloseCreateModal, onOpen: onOpenCreateModal } = useDisclosure();
  const {
    data: auditsdata,
    loading: auditLoading,
    refetch: auditRefetch,
  } = useQuery(GET_AUDIT, { variables: { auditQueryInput: { reference: searchText } }, skip: !(selectModalCategory === 'audit') });
  const {
    data: findingsdata,
    loading: findingLoading,
    refetch: findingRefetch,
  } = useQuery(GET_FINDING, {
    variables: { answerQuery: { finding: searchText, ...(scope && { scope }) } },
    skip: !(selectModalCategory === 'finding'),
  });

  const availableItems = useMemo<Partial<IAudit>[] | Partial<IAnswer>[]>(() => {
    if (auditsdata && selectModalCategory === 'audit') {
      if (isPermitted({ user, action: 'auditTypes.edit' })) return auditsdata?.audits || []; // Only admins can edit audit types
      return (auditsdata?.audits || []).filter(({ auditType }) => auditType.reviewersIds.includes(user?._id));
    }

    if (findingsdata && selectModalCategory === 'finding') {
      if (isPermitted({ user, action: 'auditTypes.edit' })) return findingsdata?.answers || []; // Only admins can edit audit types
      return (findingsdata?.answers || []).filter(({ audit }) => audit.auditType.reviewersIds.includes(user?._id));
    }

    return [];
  }, [JSON.stringify(auditsdata), JSON.stringify(findingsdata), selectModalCategory]);

  const [selectedItem, setSelectedItem] = useState<Partial<IAudit> | Partial<IAnswer> | undefined>();

  /**
   * Function to check if user is selected participant
   * @param _id _id of data
   * @returns True if option is selected
   */
  const isSelected = (id: string) => {
    if (selectedItem?._id === id) return true;
    return false;
  };

  // Function to close modal and set data to empty
  const handleCloseModal = () => {
    onSelectionModalClose();
    onCloseCreateModal();
    setSelectedItem(undefined);
  };

  // handle selection of modal
  const handleSelection = (data: Partial<IAudit> | Partial<IAnswer>) => {
    setAddSelectedData(data);
    handleCloseModal();
  };

  // reset the value of selected data to empty
  useEffect(() => () => setAddSelectedData({}), []);

  // refresh the to get new values before reset
  useEffect(() => {
    if (selectModalCategory === 'audit') auditRefetch();
    if (selectModalCategory === 'finding') findingRefetch();
  }, [!isCreateModalOpen]);

  const renderCreateModal = (): JSX.Element => (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isCreateModalOpen}
      onClose={onCloseCreateModal}
      size={device === 'desktop' || device === 'tablet' ? 'sm' : 'full'}
      variant="modalOverModal"
    >
      <ModalOverlay />
      {selectModalCategory === 'audit' && (
        <AuditModalProvider>
          <AuditModal handleCreatedAudit={handleSelection} modalType="add" onCloseModal={onCloseCreateModal} />
        </AuditModalProvider>
      )}
      {selectModalCategory === 'finding' && (
        <FindingModal handleCreatedFinding={handleSelection} isOpenInActionModal modalType="add" onCloseModal={onCloseCreateModal} />
      )}
    </Modal>
  );

  return (
    <>
      {isCreateModalOpen && renderCreateModal()}
      <Modal closeOnOverlayClick={false} isCentered isOpen={isSelectionModalOpen} onClose={handleCloseModal} size="sm">
        <ModalOverlay />
        <ModalContent bg="selectionModal.bg.modalContent" h="502px" m="0" overflow="hidden" p={4} rounded="20px">
          <ModalHeader alignItems="center" fontSize="xxl" fontWeight="bold" p="0">
            <Flex justifyContent="space-between" pb={3}>
              <Text alignItems="center" color="selectionModal.textColor.header" fontSize="smm">
                Select {selectModalCategory === 'audit' ? 'an audit' : 'a finding'}
              </Text>
              <Flex alignItems="center">
                <Close cursor="pointer" h="15px" onClick={handleCloseModal} stroke="selectionModal.icon.close" w="15px" />
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalBody mb={2} overflow="hidden" p={0}>
            <InputGroup display="block" maxW="100%" pb="14px" zIndex={1}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon fill="none" opacity="1" stroke="selectionModal.icon.search" />
              </InputLeftElement>
              <Input
                bg="selectionModal.bg.search"
                border="1px solid rgba(129, 129, 151, 0.4)"
                fontSize="smm"
                fontWeight="semi_medium"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                placeholder={`Search ${selectModalCategory === 'audit' ? 'audit reference' : 'finding'}`}
                rounded="10px"
                value={searchText}
              />
            </InputGroup>
            <Box h="calc(100% - 54px)" overflowX="hidden" overflowY="auto">
              {!isEmpty(selectedItem) && (
                <Box mb={2}>
                  <SelectionListItem
                    fontColor="selectionModal.color.selectedText"
                    isSelected={isSelected(selectedItem._id as string)}
                    onSelectData={setSelectedItem}
                    selectionData={selectedItem}
                    selectionType={selectModalCategory}
                  />
                </Box>
              )}
              {availableItems.length > 0 && (
                <VStack align="start" alignItems="flex-start" spacing={2}>
                  {[...(availableItems as { _id: string }[])?.filter(({ _id }) => !isSelected(_id))].map((data) => (
                    <SelectionListItem
                      fontColor={`selectionModal.color.${isEmpty(selectedItem) ? 'selectedText' : 'nonSelectedText'}`}
                      isSelected={isSelected(data._id)}
                      key={data._id}
                      onSelectData={setSelectedItem}
                      selectionData={data}
                      selectionType={selectModalCategory}
                    />
                  ))}
                </VStack>
              )}
            </Box>
            {auditLoading || findingLoading ? (
              <Loader center size="lg" />
            ) : (
              availableItems.length === 0 && (
                <Flex align="center" fontSize="smm" h="40px">
                  No results found
                </Flex>
              )
            )}
          </ModalBody>
          <ModalFooter alignItems="center" justifyContent="space-between" p={0}>
            <Text
              alignItems="center"
              color="selectionModal.textColor.footer"
              cursor="pointer"
              fontSize="ssm"
              fontWeight="bold"
              onClick={() => {
                onSelectionModalClose();
                onOpenCreateModal();
              }}
            >
              Or create a new {selectModalCategory}
            </Text>
            <Button
              _hover={{ bg: 'selectionModal.hover.selectButton' }}
              bg="selectionModal.bg.selectButton"
              color="selectionModal.textColor.selectButton"
              disabled={!selectedItem}
              fontSize="smm"
              fontWeight="bold"
              onClick={() => selectedItem && handleSelection(selectedItem)}
              p="10px 20px"
              rounded="10px"
            >
              Select
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SelectionModal;

export const selectionModalStyles = {
  selectionModal: {
    bg: {
      modalBody: '#F0F2F5',
      modalContent: '#ffffff',
      selectButton: '#DC0043',
      search: '#FFFFFF',
    },
    color: {
      divider: '#F0F0F0',
      selectedText: '#1E1836',
      nonSelectedText: '#787486',
    },
    textColor: {
      header: '#1E1836',
      selectButton: '#FFFFFF',
      footer: '#462AC4',
    },
    hover: {
      selectButton: '#DC0043',
    },
    icon: {
      close: '#1E1836',
      search: '#787486',
    },
  },
};
