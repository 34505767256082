import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { gql, useMutation, useQuery } from '@apollo/client';
import { useDisclosure, useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { capitalize } from 'lodash';

import useNavigate from '../hooks/useNavigate';
import { IAction } from '../interfaces/IAction';
import { IAnswer } from '../interfaces/IAnswer';
import { IAuditContext } from '../interfaces/IAuditContext';
import { IQuestion } from '../interfaces/IQuestion';
import { parseAction, parseFinding } from '../utils/helpers';

export const AuditContext = createContext({} as IAuditContext);

const GET_AUDIT = gql`
  query GetAudit($auditQueryInput: AuditQueryInput) {
    audits(auditQueryInput: $auditQueryInput) {
      _id
      title
      description
      auditTypeId
      reference
      auditeeId
      auditorId
      impartialityReviewed
      auditDate
      reportDate
      typeOfAssessment
      businessUnitId
      leadAuditorId
      leadAuditorName
      locationId
      auditType {
        _id
        name
        type
        reviewersIds
      }
      location {
        _id
        name
        ownerId
      }
      businessUnit {
        _id
        name
      }
      auditor {
        _id
        displayName
        imgUrl
        email
        jobTitle
      }
      leadAuditor {
        _id
        displayName
        imgUrl
      }
      auditee {
        _id
        displayName
        imgUrl
        email
        jobTitle
      }
      attachments {
        id
        name
        addedAt
      }
    }
  }
`;
const UPDATE_AUDIT = gql`
  mutation UpdateAudit($audit: AuditModifyInput!) {
    updateAudit(auditInput: $audit) {
      _id
    }
  }
`;
const SUBMIT_AUDIT = gql`
  mutation SubmitAudit($auditId: ID!) {
    submitAudit(auditId: $auditId)
  }
`;
const GET_FINDINGS = gql`
  query ($answerQuery: AnswerQuery) {
    answers(answerQuery: $answerQuery) {
      _id
      reference
      businessUnitId
      businessUnit {
        name
      }
      finding
      severity
      date
      status
      details
      actions {
        title
        dueDate
        assigneeId
        status
      }
      scope {
        _id
      }
      metatags {
        addedAt
        addedBy
      }
    }
  }
`;
const GET_ACTIONS = gql`
  query ($actionQueryInput: ActionQueryInput) {
    actions(actionQueryInput: $actionQueryInput) {
      _id
      reference
      title
      standard
      classification
      dueDate
      status
      submittedDate
      submittedComment
      originalDueDate
      rootCauseAnalysis
      rootCause
      completedDate
      daysOverdue
      daysOpen
      findingId
      assigneeId
      document
      comments
      clauses
      groupWide
      locationId
      businessUnitId
      assignee {
        _id
        displayName
        imgUrl
        jobTitle
      }
      evidence {
        id
        name
        addedAt
      }
      extent
      creator {
        _id
        displayName
        imgUrl
      }
      extensions {
        dueDate
        reason
        isActive
        status
        requestedById
        extendedById
      }
      notes {
        submittedById
        note
        addedAt
        submittedBy
        isEditedByAdmin
      }
      metatags {
        addedAt
        addedBy
        updatedAt
      }
    }
  }
`;
const GET_APPLICABLE_ACTIONS = gql`
  query ($auditId: ID!) {
    auditApplicableActions(auditId: $auditId) {
      mandatory
      recommendation
      severe
      major
      minor
      ofi
      internal
      cutssharps
      entraptedpinchedcaughtbyobjectequipment
      exposurecontactwithharmfulsubstance
      fireorexplosion
      manualhandling
      miscellaneousother
      peopleobjectsfallingfromheight
      repetitivestraininjury
      roadtrafficaccident
      slipsandtrips
      struckbyagainst
      temperaturerelatedegburnsorscalds
    }
  }
`;

export type TQuestionWithAnswer = IQuestion<any> & {
  answer: IAnswer;
};

export interface IQuestionsByCategories {
  [categoryId: string]: TQuestionWithAnswer[];
}

export const useAuditContext = () => {
  const context = useContext(AuditContext);
  if (!context) throw new Error('useAuditContext must be used within the AuditProvider');

  return context;
};

const AuditProvider = ({ children }) => {
  const toast = useToast();
  const { id }: { id: string } = useParams();
  const { navigateTo } = useNavigate();

  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const [submitAudit] = useMutation(SUBMIT_AUDIT);

  const [selectedDocument, setSelectedDocument] = useState('');
  const [selectedFinding, setSelectedFinding] = useState<IAnswer>({} as IAnswer);
  const [auditDetailsModalType, setAuditDetailsModalType] = useState<'edit' | 'add' | 'delete' | ''>('');
  const [auditTab, setAuditTab] = useState<string>('');

  const { isOpen: isOpenMessage, onOpen: handleOpenMessage, onClose: handleCloseMessage } = useDisclosure();

  const {
    isOpen: isActionChangesModalOpen,
    onClose: handleActionChangesModalClose,
    onOpen: handleActionChangesModalOpen,
  } = useDisclosure();

  const {
    data,
    loading,
    error,
    refetch: refetchAudit,
  } = useQuery(GET_AUDIT, {
    variables: { auditQueryInput: { _id: id } },
  });

  const audit = data?.audits[0];
  const auditType = audit?.auditType;
  const location = audit?.location;
  const businessUnit = audit?.businessUnit;
  const auditor = audit?.auditor;
  const participants = audit?.participants;

  const { data: findingsData, refetch: refetchFindings } = useQuery(GET_FINDINGS, {
    variables: {
      answerQuery: {
        scope: { _id: audit?._id },
      },
    },
  });
  const findings: IAnswer[] = (findingsData?.answers || []).map(finding => parseFinding(finding));

  const { data: actionsData, refetch: refetchActions } = useQuery(GET_ACTIONS, {
    variables: {
      actionQueryInput: {
        findingsIds: findings.map(({ _id }) => _id),
      },
    },
    skip: findings.length === 0,
  });
  const actions: IAction[] = (actionsData?.actions || []).map(action => parseAction(action));

  const {
    data: applicableActionsData,
    loading: loadingApplicableActions,
    refetch: refetchApplicableActions,
  } = useQuery(GET_APPLICABLE_ACTIONS, {
    variables: {
      auditId: audit?._id,
    },
    skip: !audit,
  });

  useEffect(() => {
    if (!loading && error) {
      toast({
        title: `${capitalize(t('audit'))} not found`,
        description: `${capitalize(t('audit'))} does not exist`,
      });
      navigateTo('/');
    }
  }, [error]);

  const value = useMemo(
    () => ({
      loading,
      error,
      audit,
      auditType,
      auditor,
      participants,
      location,
      businessUnit,
      findings,
      actions: actions.map((action) => ({
        ...action,
        answer: findings.find(({ _id }) => action.findingId === _id),
      })),
      applicableActions: applicableActionsData?.auditApplicableActions,
      loadingApplicableActions,
      isActionChangesModalOpen,
      isOpenMessage,
      selectedFinding,
      selectedDocument,
      auditDetailsModalType,
      auditTab,
      setAuditTab,
      setSelectedFinding,
      setSelectedDocument,
      setAuditDetailsModalType,
      handleOpenMessage,
      handleCloseMessage,
      handleActionChangesModalClose,
      handleActionChangesModalOpen,
      updateAudit,
      submitAudit,
      refetchAudit,
      refetchFindings,
      refetchActions,
      refetchApplicableActions,
    }),
    [
      auditTab,
      loading,
      error,
      JSON.stringify(findings),
      JSON.stringify(actions),
      audit,
      auditType,
      auditor,
      participants,
      location,
      businessUnit,
      isActionChangesModalOpen,
      isOpenMessage,
      selectedDocument,
      selectedFinding,
      auditDetailsModalType,
      JSON.stringify(applicableActionsData),
      loadingApplicableActions,
      refetchApplicableActions,
    ],
  );

  return <AuditContext.Provider value={value}>{children}</AuditContext.Provider>;
};

export default AuditProvider;
