import { createIcon } from '@chakra-ui/icons';

const Audits = createIcon({
  displayName: 'Audits',
  viewBox: '0 0 22 22',

  path: (
    <g>
      <path
        d="M18.3333 17.8141L19.6666 20.4808L20.9999 17.8141V9.14742C20.9999 8.7938 20.8594 8.45466 20.6094 8.20461C20.3593 7.95456 20.0202 7.81409 19.6666 7.81409C19.313 7.81409 18.9738 7.95456 18.7238 8.20461C18.4737 8.45466 18.3333 8.7938 18.3333 9.14742V17.8141Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M11.6667 5.14754H14.3333C14.687 5.14754 15.0261 5.28802 15.2761 5.53807C15.5262 5.78811 15.6667 6.12725 15.6667 6.48088V19.1475C15.6667 19.5012 15.5262 19.8403 15.2761 20.0904C15.0261 20.3404 14.687 20.4809 14.3333 20.4809H2.33333C1.97971 20.4809 1.64057 20.3404 1.39052 20.0904C1.14048 19.8403 1 19.5012 1 19.1475V6.48088C1 6.12725 1.14048 5.78811 1.39052 5.53807C1.64057 5.28802 1.97971 5.14754 2.33333 5.14754H5C5 4.26349 5.35119 3.41564 5.97631 2.79052C6.60143 2.1654 7.44928 1.81421 8.33333 1.81421C9.21739 1.81421 10.0652 2.1654 10.6904 2.79052C11.3155 3.41564 11.6667 4.26349 11.6667 5.14754V5.14754Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M8.33333 4.48096C8.39926 4.48096 8.46371 4.50051 8.51852 4.53713C8.57334 4.57376 8.61606 4.62582 8.64129 4.68673C8.66652 4.74764 8.67312 4.81466 8.66026 4.87932C8.6474 4.94398 8.61565 5.00337 8.56904 5.04999C8.52242 5.09661 8.46302 5.12836 8.39836 5.14122C8.3337 5.15408 8.26668 5.14748 8.20577 5.12225C8.14486 5.09702 8.0928 5.0543 8.05618 4.99948C8.01955 4.94466 8 4.88022 8 4.81429C8 4.72588 8.03512 4.6411 8.09763 4.57859C8.16014 4.51608 8.24493 4.48096 8.33333 4.48096"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
});

export default Audits;
