import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Button, Flex, Grid, HStack, Text } from '@chakra-ui/react';
import { capitalize, isEmpty } from 'lodash';

import { actionsSortOptions, actionStatuses, auditDetailsTableHeaders } from '../bootstrap/config';
import ActionsGroup from '../components/Actions/ActionsGroup';
import ActionSquare from '../components/Actions/ActionSquare';
import ActionTrackerListItem from '../components/Actions/ActionTrackerListItem';
import AuditDetailsTable from '../components/Audit/AuditDetailsTable';
import ChangeViewButton from '../components/ChangeViewButton';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import Loader from '../components/Loader';
import SortButton from '../components/SortButton';
import ActionProvider from '../contexts/ActionProvider';
import { useDataContext } from '../contexts/DataProvider';
import { useFiltersContext } from '../contexts/FiltersProvider';
import { useViewModalContext } from '../contexts/ViewModalProvider';
import useFiltersUtils from '../hooks/useFiltersUtils';
import useSort from '../hooks/useSort';
import { IAction } from '../interfaces/IAction';
import { TViewMode } from '../interfaces/TViewMode';

const Actions = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    filtersValues,
    setUsedFilters,
    setFilters,
    setResourceToFilter,
    setShowFiltersPanel,
    actionFiltersValue,
    setActionFiltersValue,
    usedFilters,
  } = useFiltersContext();
  const { parseActionsFilters } = useFiltersUtils();
  const { editAction, isViewModalOpen } = useViewModalContext();

  const { actions, actionsCount, actionsLoading, fetchAction, refetchAction } = useDataContext();
  const { sortedData: sortedActions, sortOrder, sortType, setSortType, setSortOrder } = useSort(actions || [], 'title');
  const [viewMode, setViewMode] = useState<TViewMode>('grid');
  const allowedFilters = ['auditTypesIds', 'status', 'locationsIds', 'businessUnitsIds', 'usersIds', 'dueDate'];
  const [activeTab, setActiveTab] = useState<number>(1);
  useEffect(() => {
    setUsedFilters(allowedFilters);
    setResourceToFilter('actions');
    if (!actionFiltersValue || isEmpty(actionFiltersValue)) setActionFiltersValue({ status: { value: ['open'] } });
    return () => {
      setShowFiltersPanel(false);
      setUsedFilters([]);
    };
  }, []);

  // Set pre-defined filters
  useEffect(() => {
    if (actionFiltersValue && !isEmpty(actionFiltersValue) && !isEmpty(filtersValues) && !isEmpty(usedFilters)) {
      // Delay setting filters by 100ms to make sure that other useEffects finished and filters won't be cleared
      const delayFilters = setTimeout(() => {
        setFilters(Object.entries(actionFiltersValue).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }), {}));
        setActionFiltersValue({});
        clearTimeout(delayFilters);
      }, 100);
    }
  }, [filtersValues, usedFilters, setActionFiltersValue, actionFiltersValue, setFilters]);

  useEffect(() => {
    // Parse filters to format expected by GraphQL Query
    const parsedFilters = parseActionsFilters(filtersValues, allowedFilters);
    if (parsedFilters) refetchAction(parsedFilters);
  }, [filtersValues]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const actionId = queryParams.get('id');

    if (actionId) {
      const getAction = async () => {
        let action = (actions || []).find(({ _id }) => _id === actionId);
        if (!action) {
          const actionData = await fetchAction({
            variables: {
              actionQueryInput: {
                _id: actionId,
              },
            },
          });
          if (actionData?.data?.actions?.length) [action] = actionData.data.actions;
        }
        if (action) editAction(action);
      };
      getAction();
    }
  }, [JSON.stringify(actions)]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const actionId = queryParams.get('id');
    if (actionId) {
      queryParams.delete('id');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [isViewModalOpen]);

  const setQuickFilter = (filterName: string, filterValue) => {
    // cleanFilters();
    setFilters({ [filterName]: filterValue });
  };

  useEffect(() => {
    if (!filtersValues || !filtersValues?.status || !filtersValues?.status?.value) return;

    if (filtersValues?.status?.value?.length === 0) {
      setActiveTab(0);
      return;
    }
    if (filtersValues?.status?.value?.length > 1) {
      setActiveTab(-1);
      return;
    }
    switch (filtersValues?.status?.value?.[0]) {
      case 'open':
        setActiveTab(1);
        break;
      case 'closed':
        setActiveTab(2);
        break;
      case 'under review':
        setActiveTab(3);
        break;
      case 'overdue':
        setActiveTab(4);
        break;
      default:
        break;
    }

    return () => {
      setActiveTab(0);
    };
  }, [JSON.stringify(filtersValues)]);

  return (
    <>
      <Header breadcrumbs={['Actions']} category="action" mobileBreadcrumbs={['Actions']} />
      <Flex align="center" justify="space-between">
        {viewMode !== 'group' ? (
          <HStack px={[4, 6]} spacing={2}>
            <Button
              _active={{
                bg: 'actions.quickFilter.active.bg',
                color: 'actions.quickFilter.active.color',
              }}
              _hover={{
                bg: 'none',
              }}
              bg="actions.quickFilter.default.bg"
              borderRadius="10px"
              color="actions.quickFilter.default.color"
              fontSize="smm"
              fontWeight="bold"
              h="32px"
              isActive={activeTab === 0}
              onClick={() => {
                setQuickFilter('status', []);
                setActiveTab(0);
              }}
              variant="ghost"
            >
              {`All ${actionsCount?.total ? `(${actionsCount?.total})` : '(0)'}`}
            </Button>
            {Object.entries(actionStatuses).map(([key, value], index) => (
              <Button
                _active={{
                  bg: 'actions.quickFilter.active.bg',
                  color: 'actions.quickFilter.active.color',
                }}
                _hover={{
                  bg: 'none',
                }}
                bg="actions.quickFilter.default.bg"
                borderRadius="10px"
                color="actions.quickFilter.default.color"
                fontSize="smm"
                fontWeight="bold"
                h="32px"
                isActive={activeTab === index + 1}
                key={key}
                onClick={() => setQuickFilter('status', [key])}
                size="sm"
              >
                <Flex align="center">
                  <Box bg={`actions.quickFilter.statuses.${key}`} h="12px" mr="5px" rounded="50%" w="12px" />
                  <Text>{`${capitalize(value)} ${actionsCount?.[key] ? `(${actionsCount?.[key]})` : '(0)'}`}</Text>
                </Flex>
              </Button>
            ))}
          </HStack>
        ) : (
          <Box />
        )}
        <HStack mr={6} spacing={4}>
          <ChangeViewButton setViewMode={setViewMode} viewMode={viewMode} views={['grid', 'list', 'group']} />
          <SortButton setSortOrder={setSortOrder} setSortType={setSortType} sortBy={actionsSortOptions} sortOrder={sortOrder} sortType={sortType} />
          <FilterButton />
        </HStack>
      </Flex>
      <Flex h={['calc(100vh - 80px)', 'calc(100vh - 150px)']} mt={6} overflow="auto" pb={4} px={6}>
        {actionsLoading ? (
          <Loader center />
        ) : (
          <ActionProvider>
            {viewMode === 'grid' && (
              <Grid
                display={['grid', 'grid', 'flex']}
                flexWrap="wrap"
                gap={[4, 4, 6]}
                h="fit-content"
                pb={[14, 8]}
                templateColumns={['repeat(auto-fill, minmax(250px, 1fr))', '']}
                w="full"
              >
                {sortedActions.length > 0 ? (
                  sortedActions?.map((action: IAction) => <ActionSquare action={action} key={action._id} />)
                ) : (
                  <Flex fontSize="18px" fontStyle="italic" h="full" w="full">
                    No actions found
                  </Flex>
                )}
              </Grid>
            )}
            {viewMode === 'list' && (
              <Box w="full">
                <AuditDetailsTable
                  childrenPadding="0"
                  headerElements={auditDetailsTableHeaders.actions}
                  setSortOrder={setSortOrder}
                  setSortType={setSortType}
                  sortOrder={sortOrder}
                  sortType={sortType}
                >
                  <Flex
                    flexDir="column"
                    h={['full', 'calc(100vh - 285px - 1.5rem)', 'calc(100vh - 285px - 1.5rem)']}
                    overflowY="auto"
                    w="full"
                  >
                    {sortedActions.length > 0 ? (
                      sortedActions?.map((action: IAction) => <ActionTrackerListItem action={action} key={action._id} px="25px" />)
                    ) : (
                      <Flex fontSize="18px" fontStyle="italic" h="full" p={6} w="full">
                        No actions found
                      </Flex>
                    )}
                  </Flex>
                </AuditDetailsTable>
              </Box>
            )}
            {viewMode === 'group' && <ActionsGroup actions={sortedActions} />}
          </ActionProvider>
        )}
      </Flex>
    </>
  );
};

export default Actions;

export const actionsStyles = {
  actions: {
    header: {
      menuButtonBg: 'white',
      rightIcon: '#9A9EA1',
      menuItemFocus: '#462AC4',
      menuItemFontSelected: '#462AC4',
      menuItemFont: '#9A9EA1',
    },
    quickFilter: {
      default: {
        bg: 'transparent',
        color: '#1E1836',
      },
      active: {
        bg: '#1E1836',
        color: '#FFFFFF',
      },
      statuses: {
        open: '#3C6CE9',
        closed: '#41B916',
        overdue: '#E93C44',
        'under review': '#FF9A00',
      },
    },
  },
};
