import { createIcon } from '@chakra-ui/icons';

const Admin = createIcon({
  displayName: 'Admin',
  viewBox: '0 0 16 16',
  path: (
    <g>
      <path
        d="M15.3333 6.65531C15.3336 8.73513 14.5972 10.7478 13.2548 12.3364C11.9124 13.925 10.0507 14.9868 7.99996 15.3334C5.94923 14.9868 4.08754 13.925 2.74513 12.3364C1.40272 10.7478 0.666346 8.73513 0.666626 6.65531V1.64453C0.666626 1.3852 0.769642 1.1365 0.953011 0.953133C1.13638 0.769764 1.38508 0.666748 1.6444 0.666748H14.3555C14.6148 0.666748 14.8635 0.769764 15.0469 0.953133C15.2303 1.1365 15.3333 1.3852 15.3333 1.64453V6.65531Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.79993 5.8001C5.79993 6.38357 6.03171 6.94315 6.44429 7.35573C6.85687 7.76831 7.41645 8.0001 7.99993 8.0001C8.5834 8.0001 9.14298 7.76831 9.55556 7.35573C9.96814 6.94315 10.1999 6.38357 10.1999 5.8001C10.1999 5.21662 9.96814 4.65704 9.55556 4.24446C9.14298 3.83188 8.5834 3.6001 7.99993 3.6001C7.41645 3.6001 6.85687 3.83188 6.44429 4.24446C6.03171 4.65704 5.79993 5.21662 5.79993 5.8001V5.8001Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5115 11.0963C11.1838 10.4459 10.682 9.89936 10.062 9.51746C9.44201 9.13557 8.72812 8.93335 7.99993 8.93335C7.27174 8.93335 6.55785 9.13557 5.93783 9.51746C5.31782 9.89936 4.81603 10.4459 4.4884 11.0963"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2 5.8724C9.47391 6.08149 8.70624 6.10036 7.97075 5.92718C7.23526 5.75401 6.55666 5.39463 6.00012 4.88354"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default Admin;
