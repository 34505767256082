import { useEffect, useMemo, useState } from 'react';

import { Flex, Grid, HStack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { auditsSortOptions } from '../bootstrap/config';
import AuditsList from '../components/Audit/AuditsList';
import AuditSquare from '../components/Audit/AuditSquare';
import ChangeViewButton from '../components/ChangeViewButton';
import FilterButton from '../components/FilterButton';
import Header from '../components/Header';
import Loader from '../components/Loader';
import SortButton from '../components/SortButton';
import { useAppContext } from '../contexts/AppProvider';
import AuditModalProvider, { useAuditModalContext } from '../contexts/AuditModalProvider';
import { useDataContext } from '../contexts/DataProvider';
import { useFiltersContext } from '../contexts/FiltersProvider';
import useSort from '../hooks/useSort';
import { IAudit } from '../interfaces/IAudit';
import { TViewMode } from '../interfaces/TViewMode';

const Audits = () => {
  const {
    filtersValues,
    setUsedFilters,
    setFilters,
    setDefaultFilters,
    setShowFiltersPanel,
    auditFiltersValue,
    setAuditFiltersValue,
    usedFilters,
    setResourceToFilter,
  } = useFiltersContext();
  const { module } = useAppContext();
  const { audits, auditsError, auditsLoading, refetchAudit } = useDataContext();
  const { auditTypes } = useAuditModalContext();
  const [selectedQuickFilters, setSelectedQuickFilters] = useState(0);
  const { sortedData: sortedAudits, sortOrder, sortType, setSortType, setSortOrder } = useSort(audits || [], 'dueDate', 'desc');
  const [viewMode, setViewMode] = useState<TViewMode>('grid');

  useEffect(() => {
    if (viewMode === 'group') setViewMode('grid');
  }, [viewMode]);

  const allowedFilters = useMemo(
    () => ['auditTypesIds', 'locationsIds', 'businessUnitsIds', 'usersIds', 'reportDate', 'auditDate'],
    [],
  );

  // set audit types for quick filters
  const quickFilters = useMemo(() => [{ _id: 'all', name: 'All' }, ...(auditTypes || [])], [auditTypes]);

  useEffect(() => {
    setUsedFilters(['auditTypesIds', 'locationsIds', 'businessUnitsIds', 'usersIds', 'reportDate', 'auditDate']);
    setResourceToFilter('audits');

    return () => {
      setDefaultFilters({});
      setShowFiltersPanel(false);
      setUsedFilters([]);
    };
  }, []);

  // Set pre-defined filters
  useEffect(() => {
    if (auditFiltersValue && !isEmpty(auditFiltersValue) && !isEmpty(filtersValues) && !isEmpty(usedFilters)) {
      // Delay setting filters by 100ms to make sure that other useEffects finished and filters won't be cleared
      const delayFilters = setTimeout(() => {
        setFilters(Object.entries(auditFiltersValue).reduce((acc, [key, value]) => ({ ...acc, [key]: value.value }), {}));
        setAuditFiltersValue({});
        clearTimeout(delayFilters);
      }, 100);
    }
  }, [filtersValues, usedFilters, setAuditFiltersValue, auditFiltersValue, setFilters]);

  // Set default filters
  useEffect(() => {
    if (!isEmpty(module?.defaultFilters?.audits)) {
      /**
       * Convert filters from
       *
       * {
       *  filterName: ["filterValue"]
       * }
       *
       * to
       *
       * {
       *  filterName: {
       *    value: ["filterValue"]
       *  }
       * }
       */
      const defaultFilters = Object.entries(module!.defaultFilters.audits!).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: {
            value,
          },
        }),
        {},
      );
      setDefaultFilters(Object.entries(module!.defaultFilters.audits!).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}));
      setAuditFiltersValue((curr) => ({ ...curr, ...defaultFilters }));
    }
  }, []);

  useEffect(() => {
    // Parse filters to format expected by GraphQL Query
    const parsedFilters = Object.entries(filtersValues).reduce((acc, filter) => {
      if (!filter || !filter[1] || !allowedFilters.includes(filter[0])) return { ...acc };

      const [key, value] = filter;

      if (
        !value.value ||
        (Array.isArray(value.value) && value.value.length === 0) ||
        (key === 'usersIds' && (value.value?.auditeesIds || []).length === 0 && (value.value?.leadAuditorsIds || []).length === 0)
      )
        return acc;

      return {
        ...acc,
        [key]: value?.value,
      };
    }, {});

    if (Object.keys(filtersValues).length === allowedFilters.length) refetchAudit({ auditQueryInput: parsedFilters });
  }, [JSON.stringify(filtersValues)]);

  useEffect(() => {
    setFilters({
      auditTypesIds: quickFilters[selectedQuickFilters]._id !== 'all' ? [quickFilters[selectedQuickFilters]._id] : [],
    });
  }, [selectedQuickFilters]);

  return (
    <Tabs index={selectedQuickFilters} isLazy onChange={(index) => setSelectedQuickFilters(index)} variant="unstyled">
      <Header breadcrumbs={['Audits and Assessments']} category="audit" mobileBreadcrumbs={['Audits and Assessments']} />
      <Flex justifyContent="space-between" px={[4, 6]}>
        <TabList>
          <VStack>
            <HStack>
              {quickFilters.slice(0, 4).map((tab) => (
                <Tab
                  _selected={{
                    bg: 'insights.tabBg',
                    color: 'insights.tabColor',
                  }}
                  borderRadius="10px"
                  fontSize="smm"
                  fontWeight="bold"
                  h="50px"
                  key={tab?._id}
                  mr={2}
                  mt="4px"
                >
                  {tab.name}
                </Tab>
              ))}
            </HStack>
            <HStack>
              {quickFilters.slice(4).map((tab) => (
                <Tab
                  _selected={{
                    bg: 'insights.tabBg',
                    color: 'insights.tabColor',
                  }}
                  borderRadius="10px"
                  fontSize="smm"
                  fontWeight="bold"
                  h="50px"
                  key={tab?._id}
                  mr={2}
                  mt="4px"
                >
                  {tab.name}
                </Tab>
              ))}
            </HStack>
          </VStack>
        </TabList>
        <HStack spacing={4}>
          <ChangeViewButton setViewMode={setViewMode} viewMode={viewMode} views={['grid', 'list']} />
          <SortButton setSortOrder={setSortOrder} setSortType={setSortType} sortBy={auditsSortOptions} sortOrder={sortOrder} sortType={sortType} />
          <FilterButton />
        </HStack>
      </Flex>
      <Flex h={['calc(100vh - 80px)', `calc(100vh - ${190 + (quickFilters.length > 4 ? 115 : 55)}px)`]} mt={6} overflow="auto" pb={4} px={6}>
        {auditsError ? (
          <Text>{auditsError.message}</Text>
        ) : auditsLoading ? (
          <Loader center />
        ) : (
          <TabPanels>
            {quickFilters?.map((panel) => (
              <TabPanel h="full" key={panel._id} p={0}>
                {auditsLoading ? (
                  <Loader center />
                ) : (
                  <>
                    {viewMode === 'grid' && (
                      <Grid
                        display={['grid', 'grid', 'flex']}
                        flexWrap="wrap"
                        gap={[4, 4, 6]}
                        h="fit-content"
                        templateColumns={['repeat(auto-fill, minmax(250px, 1fr))', '']}
                        w="full"
                      >
                        {sortedAudits.length === 0 ? (
                          <Flex fontSize="18px" fontStyle="italic" h="full" w="full">
                            No audits found
                          </Flex>
                        ) : (
                          sortedAudits?.map((audit: IAudit) => <AuditSquare audit={audit} key={audit._id} />)
                        )}
                      </Grid>
                    )}
                    {viewMode === 'list' && (
                      <AuditsList
                        audits={sortedAudits}
                        setSortOrder={setSortOrder}
                        setSortType={setSortType}
                        sortOrder={sortOrder}
                        sortType={sortType}
                      />
                    )}
                  </>
                )}
              </TabPanel>
            ))}
          </TabPanels>
        )}
      </Flex>
    </Tabs>
  );
};

const AuditsWithContext = () => (
  <AuditModalProvider>
    <Audits />
  </AuditModalProvider>
);

export default AuditsWithContext;

export const auditsStyles = {
  auditsItems: {
    header: {
      menuButtonBg: 'white',
      rightIcon: '#9A9EA1',
      menuItemFocus: '#462AC4',
      menuItemFontSelected: '#462AC4',
      menuItemFont: '#9A9EA1',
    },
  },
};
