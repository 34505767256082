import { Flex, Text } from '@chakra-ui/react';

import { answerSeveritiesLabels } from '../../bootstrap/config';
import { TickIcon } from '../../icons';
import { IAnswer } from '../../interfaces/IAnswer';
import { IAudit } from '../../interfaces/IAudit';

const SelectionListItem = ({
  selectionData,
  selectionType,
  isSelected,
  onSelectData,
  fontColor,
}: {
  selectionData: Partial<IAudit> | Partial<IAnswer>;
  isSelected: boolean;
  selectionType: 'audit' | 'finding' | undefined;
  onSelectData: (selectionData: Partial<IAudit> | Partial<IAnswer>) => void;
  fontColor: string;
}) => (
  <>
    {selectionType !== undefined && (
      <Flex align="center" cursor="pointer" key={selectionData._id} onClick={() => onSelectData(selectionData)} w="full">
        <Flex
          align="center"
          basis="20px"
          bg={`selectionListItemItem.checkbox.${isSelected ? 'selected' : 'nonSelected'}`}
          borderColor={fontColor}
          borderRadius="full"
          borderWidth="1px"
          h="20px"
          justify="center"
          pt="1"
          shrink={0}
        >
          <TickIcon h="10px" stroke="white" w="10px" />
        </Flex>
        <Flex direction="column" ml="2" w="full">
          <Text
            color={fontColor}
            fontSize="smm"
            fontWeight="bold"
            overflow="hidden"
            textOverflow="ellipsis"
            w="90%"
            whiteSpace="nowrap"
            wordBreak="break-word"
          >
            {selectionType === 'audit'
              ? `${(selectionData as IAudit).reference} - ${(selectionData as IAudit).title}`
              : (selectionData as IAnswer).finding}
          </Text>
          <Text color={fontColor} fontSize="ssm" fontWeight="semi_medium" top="-4px" wordBreak="break-word">
            {selectionType === 'audit'
              ? (selectionData as IAudit).auditType?.type
              : (selectionData as IAnswer).severity && answerSeveritiesLabels[(selectionData as IAnswer).severity]}
          </Text>
        </Flex>
      </Flex>
    )}
  </>
);

export default SelectionListItem;

export const selectionListItemStyles = {
  selectionListItemItem: {
    checkbox: {
      selected: '#1E1836',
      nonSelected: '#FFFFFF',
    },
  },
};
