import { createIcon } from '@chakra-ui/icons';

const QuestionMark = createIcon({
  viewBox: '0 0 12 12',
  path: (
    <g>
      <path d="M11.2 9.6H5.6L2.4 12V9.6H0.8C0.587827 9.6 0.384344 9.51571 0.234315 9.36569C0.0842855 9.21566 0 9.01217 0 8.8V0.8C0 0.587827 0.0842855 0.384344 0.234315 0.234315C0.384344 0.0842855 0.587827 0 0.8 0H11.2C11.4122 0 11.6157 0.0842855 11.7657 0.234315C11.9157 0.384344 12 0.587827 12 0.8V8.8C12 9.01217 11.9157 9.21566 11.7657 9.36569C11.6157 9.51571 11.4122 9.6 11.2 9.6Z" />
      <path
        d="M5.80835 2.87715C5.65422 2.86578 5.49874 2.89958 5.35824 2.97714C5.21742 3.05487 5.09551 3.17458 5.00883 3.3265C4.92206 3.47858 4.87503 3.65542 4.875 3.83742C4.87496 4.07904 4.67905 4.27489 4.43742 4.27484C4.1958 4.2748 3.99996 4.07889 4 3.83726C4.00006 3.50592 4.08541 3.17932 4.24883 2.89288C4.41234 2.60629 4.6488 2.36929 4.93538 2.2111C5.22228 2.05273 5.54676 1.98048 5.87271 2.00452C6.19859 2.02856 6.50938 2.14759 6.77137 2.34525C7.03307 2.5427 7.23546 2.8104 7.35978 3.11665C7.48406 3.42278 7.52661 3.75801 7.48389 4.08646C7.44117 4.41495 7.31435 4.72721 7.11441 4.98859C6.91431 5.25018 6.64785 5.4516 6.34189 5.567C6.30322 5.58158 6.26466 5.61057 6.23505 5.65526C6.20524 5.70026 6.18747 5.7571 6.1875 5.81781V5.9375C6.1875 6.17913 5.99162 6.375 5.75 6.375C5.50838 6.375 5.3125 6.17913 5.3125 5.9375L5.3125 5.81824C5.3125 5.81817 5.3125 5.81831 5.3125 5.81824C5.31243 5.58937 5.37874 5.3635 5.5056 5.17201C5.6327 4.98015 5.81578 4.83026 6.03311 4.74829C6.18085 4.69257 6.31527 4.59312 6.41942 4.45698C6.52372 4.32062 6.59279 4.15359 6.6162 3.9736C6.63962 3.79358 6.616 3.61072 6.54904 3.44578C6.48213 3.28096 6.37557 3.14273 6.24438 3.04376C6.11348 2.94499 5.96255 2.88852 5.80835 2.87715Z"
        fill="white"
      />
      <path
        d="M5.75 6.8125C5.66347 6.8125 5.57888 6.83816 5.50694 6.88623C5.43499 6.93431 5.37892 7.00263 5.3458 7.08258C5.31269 7.16252 5.30403 7.25049 5.32091 7.33535C5.33779 7.42022 5.37945 7.49817 5.44064 7.55936C5.50183 7.62055 5.57978 7.66221 5.66465 7.67909C5.74951 7.69597 5.83748 7.68731 5.91742 7.6542C5.99737 7.62108 6.06569 7.56501 6.11377 7.49306C6.16184 7.42112 6.1875 7.33653 6.1875 7.25C6.1875 7.13397 6.14141 7.02269 6.05936 6.94064C5.97731 6.85859 5.86603 6.8125 5.75 6.8125Z"
        fill="white"
      />
    </g>
  ),
});

export default QuestionMark;
