import { Box, Flex } from '@chakra-ui/react';
import { t } from 'i18next';
import { capitalize } from 'lodash';

import { IAudit } from '../../interfaces/IAudit';
import AdminTableHeader from '../Admin/AdminTableHeader';
import AdminTableHeaderElement from '../Admin/AdminTableHeaderElement';
import AuditListItem from './AuditListItem';

const AuditsList = ({
  audits,
  sortOrder,
  sortType,
  setSortType,
  setSortOrder,
}: {
  audits: IAudit[];
  sortOrder: 'asc' | 'desc';
  sortType: string;
  setSortType: (key: string) => void;
  setSortOrder: (order: 'asc' | 'desc') => void;
}) => (
  <Box h="full" overflow="none" w="full">
    <Box bg="auditsList.bg" borderRadius="20px" h="fit-content" minH="full" w="full">
      <AdminTableHeader>
        <AdminTableHeaderElement
          label="Ref"
          onClick={() => {
            setSortType('reference');
            setSortOrder(sortOrder === 'asc' && sortType === 'reference' ? 'desc' : 'asc');
          }}
          showSortingIcon={sortType === 'reference'}
          sortOrder={sortType === 'reference' ? sortOrder : undefined}
          w="10%"
        />
        <AdminTableHeaderElement
          label="Title"
          onClick={() => {
            setSortType('title');
            setSortOrder(sortOrder === 'asc' && sortType === 'title' ? 'desc' : 'asc');
          }}
          showSortingIcon={sortType === 'title'}
          sortOrder={sortType === 'title' ? sortOrder : undefined}
          w="15%"
        />
        <AdminTableHeaderElement
          label={capitalize(t('location'))}
          onClick={() => {
            setSortType('location.name');
            setSortOrder(sortOrder === 'asc' && sortType === 'location.name' ? 'desc' : 'asc');
          }}
          showSortingIcon={sortType === 'location.name'}
          sortOrder={sortType === 'location.name' ? sortOrder : undefined}
          w="14%"
        />
        <AdminTableHeaderElement
          label={capitalize(t('business unit'))}
          onClick={() => {
            setSortType('businessUnit.name');
            setSortOrder(sortOrder === 'asc' && sortType === 'businessUnit.name' ? 'desc' : 'asc');
          }}
          showSortingIcon={sortType === 'businessUnit.name'}
          sortOrder={sortType === 'businessUnit.name' ? sortOrder : undefined}
          w="14%"
        />
        <AdminTableHeaderElement
          label="Report date"
          onClick={() => {
            setSortType('reportDate');
            setSortOrder(sortOrder === 'asc' && sortType === 'reportDate' ? 'desc' : 'asc');
          }}
          showSortingIcon={sortType === 'reportDate'}
          sortOrder={sortType === 'reportDate' ? sortOrder : undefined}
          w="10%"
        />
        <AdminTableHeaderElement
          label="Key contact"
          onClick={() => {
            setSortType('leadAuditor.displayName');
            setSortOrder(sortOrder === 'asc' && sortType === 'leadAuditor.displayName' ? 'desc' : 'asc');
          }}
          showSortingIcon={sortType === 'leadAuditor.displayName'}
          sortOrder={sortType === 'leadAuditor.displayName' ? sortOrder : undefined}
          w="20%"
        />
        <AdminTableHeaderElement
          label="Actions"
          onClick={() => {
            setSortType('actions');
            setSortOrder(sortOrder === 'asc' && sortType === 'actions' ? 'desc' : 'asc');
          }}
          showSortingIcon={sortType === 'actions'}
          sortOrder={sortType === 'actions' ? sortOrder : undefined}
          w="14%"
        />
        <AdminTableHeaderElement w="3%" />
      </AdminTableHeader>
      <Flex flexDir="column" h={['full', 'calc(100vh - 270px - 1.5rem)', 'calc(100vh - 270px - 1.5rem)']} overflowY="auto" w="full">
        {audits?.map((audit) => (
          <AuditListItem audit={audit} key={audit._id} />
        ))}
      </Flex>
    </Box>
  </Box>
);

export default AuditsList;

export const auditsListStyles = {
  auditsList: {
    bg: 'white',
    completed: '#62C240',
    missed: '#FC5960',
    upcoming: '#FFA012',
    fontColor: '#1E1836',
    buildingIcon: '#2B3236',
    crossIcon: '#FC5960',
    tickIcon: '#41BA17',
    imageBg: '#ffffff',
    evidenceFontColor: '#818197',
    headerBorderColor: '#F0F0F0',
    secondary: {
      fontColor: '#787486',
    },
  },
};
