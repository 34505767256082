import { createIcon } from '@chakra-ui/icons';

const AuditIcon = createIcon({
  path: (
    <g>
      <path
        d="M13.3333 12.4445V5.11119M10.3333 14.889H3.37774C2.985 14.889 2.66663 14.4645 2.66663 13.9408V1.6149C2.66663 1.09125 2.985 0.666748 3.37774 0.666748H8.88885M8.88885 0.666748V4.2223C8.88885 4.66675 9.33329 5.11119 9.77774 5.11119H13.3333M8.88885 0.666748L13.3333 5.11119"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M4.66663 11.1111H5.33329" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.66663 9.11108H5.99996" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.66663 7.11108H11.3333" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M7.44616 11.8379C7.65291 12.3244 8.04444 12.7088 8.53462 12.9066C9.0248 13.1044 9.57348 13.0994 10.06 12.8926C10.5464 12.6859 10.9308 12.2943 11.1286 11.8042C11.3264 11.314 11.3214 10.7653 11.1147 10.2788C10.9079 9.79236 10.5164 9.40794 10.0262 9.21015C9.53603 9.01235 8.98735 9.01738 8.50088 9.22412C8.0144 9.43087 7.62998 9.8224 7.43219 10.3126C7.23439 10.8028 7.23942 11.3514 7.44616 11.8379V11.8379Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.9995 12.7778L12.9995 14.7778" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
  viewBox: '0 0 16 16',
  displayName: 'Audit icon',
});

export default AuditIcon;
