import { createIcon } from '@chakra-ui/icons';

const Briefcase = createIcon({
  viewBox: '0 0 16 14',
  path: (
    <path
      clipRule="evenodd"
      d="M6.30039 0C5.74344 0 5.20929 0.221249 4.81547 0.615076C4.42164 1.0089 4.20039 1.54305 4.20039 2.1V2.8001H2.1C0.940202 2.8001 0 3.7403 0 4.9001V11.9001C0 13.0599 0.940202 14.0001 2.1 14.0001H13.3C14.4598 14.0001 15.4 13.0599 15.4 11.9001V4.9001C15.4 3.7403 14.4598 2.8001 13.3 2.8001H11.2004V2.1C11.2004 1.54305 10.9791 1.0089 10.5853 0.615076C10.1915 0.221249 9.65734 0 9.10039 0H6.30039ZM9.80039 2.8001V2.1C9.80039 1.91435 9.72664 1.7363 9.59537 1.60503C9.46409 1.47375 9.28604 1.4 9.10039 1.4H6.30039C6.11474 1.4 5.93669 1.47375 5.80542 1.60503C5.67414 1.7363 5.60039 1.91435 5.60039 2.1V2.8001H9.80039ZM5.60039 4.2001H9.80039V12.6001H5.60039V4.2001ZM4.20039 4.2001H2.1C1.7134 4.2001 1.4 4.5135 1.4 4.9001V11.9001C1.4 12.2867 1.7134 12.6001 2.1 12.6001H4.20039V4.2001ZM11.2004 12.6001V4.2001H13.3C13.6866 4.2001 14 4.5135 14 4.9001V11.9001C14 12.2867 13.6866 12.6001 13.3 12.6001H11.2004Z"
      fillRule="evenodd"
    />
  ),
});

export default Briefcase;
