import { createIcon } from '@chakra-ui/icons';

const NoteIcon = createIcon({
  viewBox: '0 0 12 12',
  path: (
    <g>
      <path
        d="M1.19038 5.21973C1.04259 5.17804 0.912052 5.0901 0.817899 4.9688C0.723745 4.8475 0.670932 4.69922 0.667204 4.54571C0.663476 4.3922 0.709028 4.24154 0.797182 4.11581C0.885336 3.99008 1.01145 3.89591 1.15704 3.84709L10.8544 0.684371C10.9179 0.663663 10.9859 0.660897 11.0509 0.676377C11.1159 0.691857 11.1754 0.724983 11.2228 0.772102C11.2701 0.819221 11.3036 0.878505 11.3194 0.943418C11.3352 1.00833 11.3328 1.07635 11.3124 1.13998L8.15213 10.8422C8.10353 10.988 8.00941 11.1144 7.88361 11.2028C7.75781 11.2911 7.60698 11.3367 7.4533 11.333C7.29963 11.3292 7.15122 11.2762 7.02993 11.1817C6.90863 11.0873 6.82085 10.9564 6.77949 10.8084L5.69433 6.30006L1.19038 5.21973Z"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M11.2231 0.771484L5.69434 6.30021" fill="transparent" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
});

export default NoteIcon;
