import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { gql, useQuery } from '@apollo/client';
import { Flex, Icon, Table, TableContainer, Tbody, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import { useAppContext } from '../../contexts/AppProvider';
import { ArrowDownIcon, ArrowUpIcon } from '../../icons';
import { isPermitted } from '../can';
import { Dropdown } from '../Forms';
import ActionInsightsTableList from './ActionInsightsTableList';

export interface ActionInsightsTableRow {
  _id: string;
  userId?: string;
  locationId?: string;
  label: string;
  supLabel?: string;
  overdue: number;
  open: number;
  closedThisYear: number;
  closedAllTime: number;
}

export interface ActionInsightsTableProps {
  my: number | string | {} | [];
  label: string;
  loading: boolean;
  subLabel?: string;
  rows: ActionInsightsTableRow[];
  sortOrder: 'asc' | 'desc';
  setSortOrder: (order: 'asc' | 'desc') => void;
  sortType: string;
  setSortType: (type: string) => void;
  selectedPanel: ISelectedPanel;
  filterBy?: string;
  filters?: any;
  refetch?: any;
  appliedActionsInsightsQuery?: any;
  selectedAuditTypeId?: string;
}

export type ISelectedPanel = 'location' | 'businessUnit' | 'user' | string;

const GET_LOCATIONS = gql`
  query {
    locations(locationQueryInput: $locationQueryInput) {
      _id
      name
    }
  }
`;

const ActionInsightsTable = ({
  my = 0,
  label,
  loading,
  subLabel,
  rows = [],
  sortOrder,
  setSortOrder,
  sortType,
  setSortType,
  selectedPanel,
  filterBy,
  refetch,
  appliedActionsInsightsQuery,
  selectedAuditTypeId,
}: ActionInsightsTableProps) => {
  const { user } = useAppContext();
  const {
    data,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(GET_LOCATIONS, {
    fetchPolicy: 'cache-only',
    variables: {
      locationQueryInput: {
        ...(isPermitted({ user, action: 'dashboard.viewAll' }) ? {} : { ownerId: user?._id }),
      },
    },
  });

  const { control, watch } = useForm({
    defaultValues: {
      locationId: '',
    },
    mode: 'all',
  });

  const values = watch();

  useEffect(() => {
    if (refetch) {
      refetch(
        filterBy
          ? {
            actionsInsightsQuery: {
              ...appliedActionsInsightsQuery,
              ...(values.locationId && { [filterBy]: [values.locationId] }),
            },
          }
          : {},
      );
    }
  }, [values]);

  return (
    <Flex bg="#FFFFFF" borderRadius="20px" direction="column" my={my} p="25px 30px" w="full">
      <Flex h="42px" isolation="isolate" justify="space-between" mb={4} position="relative">
        <Text color="#1E1836" fontSize="18px" fontWeight="700">
          Actions per {label}
        </Text>
        {filterBy && refetch && !locationsLoading && !locationsError && (data?.locations || []).length > 0 && (
          <Flex align="center" zIndex={2}>
            <Text color="#1E1836" mr={3} opacity="0.4" textTransform="none">
              Filter by
            </Text>
            <Flex mt={-2}>
              <Dropdown
                control={control}
                name="locationId"
                options={[
                  {
                    label: `All ${capitalize(pluralize('business areas'))}`,
                    value: '',
                  },
                  ...data?.locations?.map((location) => ({ label: location?.name, value: location?._id })),
                ]}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
      <TableContainer w="full">
        <Table variant="striped" w="full">
          <Thead>
            <Tr bg="#1E1836" borderRadius="6px 6px 0px 0px">
              <Th borderRadius="6px 0px 0px 0px">
                <Flex
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => {
                    setSortType('label');
                    setSortOrder(sortOrder === 'asc' && sortType === 'label' ? 'desc' : 'asc');
                  }}
                  userSelect="none"
                >
                  <Text color="#FFFFFF" fontSize="14px" fontWeight="700" lineHeight="20px" textTransform="capitalize">
                    {label === 'user' ? 'Full name' : label}
                  </Text>
                  {subLabel && (
                    <Text as="sub" color="#FFFFFF" fontSize="10px" fontWeight="700" lineHeight="16px" ml={1} mt="-4px" textTransform="none">
                      {subLabel}
                    </Text>
                  )}
                  {sortType === 'label' &&
                    (
                      sortOrder === 'desc' ?
                        <ArrowDownIcon color="#FFFFFF" ml="10px" /> :
                        <ArrowUpIcon color="#FFFFFF" ml="10px" />
                    )
                  }
                </Flex>
              </Th>

              <Th
                alignItems="center"
                color="#DC0043"
                fontSize="14px"
                fontWeight="700"
                lineHeight="20px"
                onClick={() => {
                  setSortType('overdue');
                  setSortOrder(sortOrder === 'asc' && sortType === 'overdue' ? 'desc' : 'asc');
                }}
                textAlign="center"
                textTransform="none"
                userSelect="none"
                w="115px"
              >
                Overdue
                {sortType === 'overdue' &&
                  (
                    sortOrder === 'desc' ?
                      <ArrowDownIcon color="#FFFFFF" ml="10px" /> :
                      <ArrowUpIcon color="#FFFFFF" ml="10px" />
                  )
                }
              </Th>
              <Th
                alignItems="center"
                color="#FFFFFF"
                fontSize="14px"
                fontWeight="700"
                lineHeight="20px"
                onClick={() => {
                  setSortType('open');
                  setSortOrder(sortOrder === 'asc' && sortType === 'open' ? 'desc' : 'asc');
                }}
                textAlign="center"
                textTransform="none"
                userSelect="none"
                w="115px"
              >
                Open
                {sortType === 'open' &&
                  (
                    sortOrder === 'desc' ?
                      <ArrowDownIcon color="#FFFFFF" ml="10px" /> :
                      <ArrowUpIcon color="#FFFFFF" ml="10px" />
                  )
                }
              </Th>
              <Th
                alignItems="center"
                borderRadius="0px 6px 0px 0px"
                color="#FFFFFF"
                fontSize="14px"
                fontWeight="700"
                lineHeight="20px"
                onClick={() => {
                  setSortType('closedThisYear');
                  setSortOrder(sortOrder === 'asc' && sortType === 'closedThisYear' ? 'desc' : 'asc');
                }}
                textAlign="center"
                textTransform="none"
                userSelect="none"
                w="115px"
              >
                <Tooltip
                  bg="#1E1836"
                  borderRadius="8px"
                  fontSize="sm"
                  gutter={18}
                  hasArrow
                  label="Closed current year (Total closed all-time)"
                  placement="bottom-end"
                >
                  <Text>
                    Closed <Icon h="12px" mb={1} name="info" />
                    {sortType === 'closedThisYear' &&
                      (
                        sortOrder === 'desc' ?
                          <ArrowDownIcon color="#FFFFFF" ml="10px" /> :
                          <ArrowUpIcon color="#FFFFFF" ml="10px" />
                      )
                    }
                  </Text>
                </Tooltip>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <ActionInsightsTableList
              loading={loading}
              rows={rows.filter(({ locationId }) => !values.locationId || locationId === values.locationId)}
              selectedAuditTypeId={selectedAuditTypeId}
              selectedPanel={selectedPanel}
            />
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};
export default ActionInsightsTable;
