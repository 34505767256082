import { createContext, useContext, useMemo, useState } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';

import { IAction } from '../interfaces/IAction';
import { IAnswer } from '../interfaces/IAnswer';
import { IAudit } from '../interfaces/IAudit';
import { IViewModalContext } from '../interfaces/IViewModalContext';

export const ViewModalContext = createContext({} as IViewModalContext);

export const useViewModalContext = () => {
  const context = useContext(ViewModalContext);
  if (!context) throw new Error('useViewModalContext must be used within the ViewModalProvider');

  return context;
};

const DELETE_ACTION = gql`
  mutation DeleteAction($_id: ID!) {
    deleteAction(_id: $_id)
  }
`;
const DELETE_FINDING = gql`
  mutation DeleteAnswer($_id: ID!) {
    deleteAnswer(_id: $_id)
  }
`;
const DELETE_AUDIT = gql`
  mutation DeleteAudit($_id: String!) {
    deleteAudit(_id: $_id)
  }
`;
const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($auditDeleteDocumentInput: AuditDeleteDocumentInput!) {
    deleteAuditDocument(auditDeleteDocumentInput: $auditDeleteDocumentInput)
  }
`;

const ViewModalProvider = ({ children }) => {
  const { isOpen: isViewModalOpen, onClose: handleViewModalClose, onOpen: openModal } = useDisclosure();
  const [viewModalState, setViewModalState] = useState<string>('');
  const [selectedAction, setSelectedAction] = useState<IAction>({} as IAction);
  const [selectedFinding, setSelectedFinding] = useState<IAnswer>({} as IAnswer);
  const [selectedAudit, setSelectedAudit] = useState<IAudit>({} as IAudit);
  const [deleteActionMethod] = useMutation(DELETE_ACTION);
  const [deleteFindingMethod] = useMutation(DELETE_FINDING);
  const [deleteAuditMethod] = useMutation(DELETE_AUDIT);
  const [deleteDocumentMethod] = useMutation(DELETE_DOCUMENT);
  const [callback, setCallback] = useState<Function>();

  /**
   *
   * This function is used to handle adding/editing/deleting of an action
   *
   * @param action
   */
  const addAction = (callback?: () => void) => {
    setViewModalState('action');
    if (callback) setCallback(() => callback);
    openModal();
  };
  const editAction = (action: IAction, callback?: () => void) => {
    setSelectedAction(action);
    setViewModalState('action');
    if (callback) setCallback(() => callback);
    openModal();
  };
  const deleteAction = (action: IAction, callback?: () => void) => {
    setSelectedAction(action);
    setViewModalState('deleteAction');
    if (callback) setCallback(() => callback);
    openModal();
  };

  /**
   *
   * This function is used to handle adding/editing/deleting of a finding
   *
   * @param finding
   */
  const addFinding = (callback?: () => void) => {
    setViewModalState('finding');
    if (callback) setCallback(() => callback);
    openModal();
  };
  const editFinding = (finding: IAnswer, callback?: () => void) => {
    setSelectedFinding(finding);
    setViewModalState('finding');
    if (callback) setCallback(() => callback);
    openModal();
  };
  const deleteFinding = (finding: IAnswer, callback?: () => void) => {
    setSelectedFinding(finding);
    setViewModalState('deleteFinding');
    if (callback) setCallback(() => callback);
    openModal();
  };

  /**
   *
   * This function is used to handle editing of an audit
   *
   * @param audit
   */
  const addAudit = (callback?: () => void) => {
    setViewModalState('audit');
    if (callback) setCallback(() => callback);
    openModal();
  };
  const editAudit = (audit: IAudit, callback?: () => void) => {
    setSelectedAudit(audit);
    setViewModalState('audit');
    if (callback) setCallback(() => callback);
    openModal();
  };
  const deleteAudit = (audit: IAudit, callback?: () => void) => {
    setSelectedAudit(audit);
    setViewModalState('deleteAudit');
    if (callback) setCallback(() => callback);
    openModal();
  };

  const closeModal = async () => {
    handleViewModalClose();
    if (callback) callback();
  };

  const value = useMemo(
    () => ({
      viewModalState,
      setViewModalState,

      isViewModalOpen,
      closeModal,
      openModal,

      selectedAction,
      setSelectedAction,
      addAction,
      editAction,
      deleteAction,
      deleteActionMethod,

      selectedFinding,
      setSelectedFinding,
      addFinding,
      editFinding,
      deleteFinding,
      deleteFindingMethod,

      selectedAudit,
      setSelectedAudit,
      addAudit,
      editAudit,
      deleteAudit,
      deleteAuditMethod,
      deleteDocumentMethod,

      setCallback,
    }),
    [selectedAudit, selectedAction, viewModalState, isViewModalOpen],
  );

  return <ViewModalContext.Provider value={value}>{children}</ViewModalContext.Provider>;
};

export default ViewModalProvider;
