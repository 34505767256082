import { createIcon } from '@chakra-ui/icons';

const DeleteIcon = createIcon({
  displayName: 'Delete',
  viewBox: '0 0 16 18',

  path: (
    <g>
      <path
        d="M12.4251 17.25H4.72501C4.43327 17.25 4.15348 17.1341 3.94719 16.9278C3.74089 16.7215 3.625 16.4417 3.625 16.15V6.24989H13.5251V16.15C13.5251 16.4417 13.4092 16.7215 13.2029 16.9278C12.9966 17.1341 12.7168 17.25 12.4251 17.25Z"
        fill='none'
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.92529 13.95V9.54993"
        fill='none'
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2251 13.95V9.54993"
        fill='none'
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.875 4.75534L14.8627 1.78238"
        fill='none'
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.02538 0.77404L5.79868 1.45971C5.65705 1.4896 5.52271 1.54714 5.40337 1.62905C5.28402 1.71095 5.18201 1.8156 5.10319 1.937C5.02436 2.05841 4.97028 2.19417 4.94402 2.33652C4.91777 2.47886 4.91987 2.62499 4.9502 2.76653L5.18194 3.84087L10.5617 2.69686L10.33 1.62105C10.2693 1.33574 10.0977 1.08624 9.85307 0.927398C9.60842 0.768559 9.3107 0.713396 9.02538 0.77404V0.77404Z"
        fill='none'
        strokeLinecap="round"
        strokeLinejoin="round"
      />

    </g>
  ),
});

export default DeleteIcon;
