import { useForm } from 'react-hook-form';

import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Divider,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import { useAppContext } from '../../contexts/AppProvider';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useDataContext } from '../../contexts/DataProvider';
import useNavigate from '../../hooks/useNavigate';
import { IAction } from '../../interfaces/IAction';
import { serializeDate } from '../../utils/helpers';
import { Datepicker, TextInputMultiline } from '../Forms';

interface IActionExtensionModal {
  action: IAction;
  isExtendModalOpen: boolean;
  extensionNumber: number;
  onExtendModalClose: () => void;
  onOuterModalClose: () => void;
}

const EXTEND_ACTION = gql`
  mutation ExtendAction($action: ExtendActionInput!) {
    extendAction(extendActionInput: $action)
  }
`;

const ActionExtensionModal = ({
  action,
  extensionNumber,
  isExtendModalOpen,
  onExtendModalClose,
  onOuterModalClose,
}: IActionExtensionModal) => {
  const toast = useToast();
  const { user } = useAppContext();
  const { isPathActive } = useNavigate();
  const { refetchAction } = useDataContext();
  const { refetchActions } = useAuditContext();
  const [extendAction] = useMutation(EXTEND_ACTION);

  // forms
  const {
    control,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'all',
  });

  const handleExtendAction = async () => {
    try {
      const values = getValues();
      await extendAction({
        variables: {
          action: {
            ...values,
            _id: action._id,
            requestedById: user?._id,
            actionTimestamp: action?.metatags?.updatedAt ? serializeDate(action?.metatags?.updatedAt) : serializeDate(action?.metatags?.addedAt),
          },
        },
      });
      toast({
        ...toastSuccess,
        description: `Extend an action request is added`,
      });
      if (isPathActive('/actions')) refetchAction();
      else if (isPathActive('/audits/')) refetchActions();
      onExtendModalClose();
      onOuterModalClose();
    } catch (e: any) {
      toast({
        ...toastFailed,
        description: e.message,
      });
    } finally {
      onExtendModalClose();
    }
  };

  return (
    <Modal isCentered isOpen={isExtendModalOpen} onClose={onExtendModalClose} onEsc={onExtendModalClose} variant="deleteModal">
      <ModalOverlay />
      <ModalContent rounded="20px">
        <ModalHeader pb="14px">
          <Text color="submitActionModal.font.color" fontSize="lg" fontWeight="bold">
            Requesting extension #{extensionNumber}
          </Text>
          <ModalCloseButton />
          <Divider color="submitActionModal.divider.color" mt="14px" orientation="horizontal" />
        </ModalHeader>
        <ModalBody pt={0}>
          <Stack spacing={2}>
            <Datepicker
              color="actionModal.textColor.field"
              control={control}
              label="Select new due date"
              name="dueDate"
              placeholder="Due date"
              validations={{
                notEmpty: true,
              }}
              variant="secondaryVariant"
            />
            <TextInputMultiline
              control={control}
              label="Extension reason"
              name="reason"
              placeholder="Please provide a reason for changing action due date"
              validations={{
                notEmpty: true,
                minLength: 20,
              }}
            />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end" spacing={4} w="full">
            <Button
              _hover={{ opacity: 0.7 }}
              bg="submitActionModal.deleteButton.bg"
              color="submitActionModal.deleteButton.color"
              disabled={!isEmpty(errors) || Object.keys(dirtyFields).length < 2}
              onClick={handleExtendAction}
              rounded="10px"
            >
              Request
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ActionExtensionModal;

export const actionExtensionModalStyles = {
  actionExtensionModal: {
    deleteButton: {
      color: '#ffffff',
      bg: '#dc0043',
    },
    cancelButton: {
      color: '#787486',
      bg: '#F0F2F5',
    },
    font: {
      color: '#1E1836',
    },
    divider: {
      color: '#F0F0F0',
    },
  },
};
