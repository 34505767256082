import { useEffect, useMemo, useState } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Box, Button, Flex, Link, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import { isPermitted } from '../../components/can';
import ActionInsightsTable from '../../components/Insights/ActionInsightsTable';
import ActionsSeverityTable from '../../components/Insights/ActionsSeverityTable';
import InsightsCard from '../../components/Insights/InsightsCard';
import Loader from '../../components/Loader';
import { useAppContext } from '../../contexts/AppProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useSort from '../../hooks/useSort';
import { ExportIcon } from '../../icons';

const GET_DATA = gql`
  query getData($locationQueryInput: LocationQueryInput, $businessUnitQueryInput: BusinessUnitQueryInput) {
    auditTypes {
      _id
      type
      name
    }
    businessUnits(businessUnitQueryInput: $businessUnitQueryInput) {
      _id
      name
      location {
        _id
        name
      }
    }
    locations(locationQueryInput: $locationQueryInput) {
      _id
      name
    }
    users {
      _id
      displayName
    }
  }
`;
const GET_ACTIONS_AUDIT_TYPES_INSIGHTS = gql`
  query getAuditTypesInsights($actionsInsightsQuery: ActionsInsightsQuery) {
    auditTypesInsights: actionsInsights(actionsInsightsQuery: $actionsInsightsQuery) {
      grouped {
        _id
        open
        overdue
      }
    }
  }
`;
const GET_ACTIONS_INSIGHTS = gql`
  query getAuditTypesInsights($actionsInsightsQuery: ActionsInsightsQuery) {
    insights: actionsInsights(actionsInsightsQuery: $actionsInsightsQuery) {
      grouped {
        _id
        open
        overdue
        closedThisYear
        closedAllTime
      }
    }
  }
`;

const GET_ACTIONS_BY_SEVERITY_RATING = gql`
  query actionsBySeverityRating($actionsBySeverityRatingQuery: ActionsBySeverityRatingQuery) {
    actionsBySeverityRating(actionsBySeverityRatingQuery: $actionsBySeverityRatingQuery) {
      IA {
        severe
        major
        minor
        ofi
      }
      UKAS {
        mandatory
        recommendation
      }
      LRQA {
        major
        minor
        ofi
      }
      ISO {
        major
        minor
        ofi
      }
      IINC {
        internal
      }
      WA {
        major
        minor
        ofi
      }
      SHE {
        cutssharps
        entraptedpinchedcaughtbyobjectequipment
        exposurecontactwithharmfulsubstance
        fireorexplosion
        manualhandling
        miscellaneousother
        peopleobjectsfallingfromheight
        repetitivestraininjury
        roadtrafficaccident
        slipsandtrips
        struckbyagainst
        temperaturerelatedegburnsorscalds
      }
    }
  }
`;

const ActionsInsights = () => {
  const { user } = useAppContext();
  const panels = useMemo(() => {
    const locationsPanel = { _id: 'location', name: `${capitalize('business area')}` };
    const businessUnitsPanel = { _id: 'businessUnit', name: `${capitalize('business group')}` };
    const usersPanel = { _id: 'user', name: `${capitalize('user')}` };
    if (!isPermitted({ user, action: 'dashboard.viewAll' })) {
      if (user?.isBusinessAreaOwner) return [locationsPanel, businessUnitsPanel];
      return [businessUnitsPanel];
    }
    return [locationsPanel, businessUnitsPanel, usersPanel];
  }, [user?._id, user?.isBusinessAreaOwner, user?.isBusinessGroupOwner]);
  const [selectedPanel, setSelectedPanel] = useState(0);
  const [selectedActionsStatsCount, setSelectedActionsStatsCount] = useState('all');

  const { data, loading: dataLoading } = useQuery(GET_DATA, {
    variables: {
      locationQueryInput: {
        ...(isPermitted({ user, action: 'dashboard.viewAll' }) ? {} : { ownerId: user?._id }),
      },
      businessUnitQueryInput: {
        ...(isPermitted({ user, action: 'dashboard.viewAll' }) ? {} : { ownerId: user?._id }),
      },
    },
    fetchPolicy: 'network-only',
  });
  const selectedAuditTypeId = useMemo(
    () => data?.auditTypes?.find(({ type }) => type === selectedActionsStatsCount)?._id,
    [JSON.stringify(data?.auditTypes), selectedActionsStatsCount],
  );

  const {
    data: auditTypesInsights,
    loading: auditTypesInsightsLoading,
    refetch: refetchAuditTypesInsights,
  } = useQuery(GET_ACTIONS_AUDIT_TYPES_INSIGHTS, {
    variables: {
      actionsInsightsQuery: {
        groupBy: 'auditType',
        ...(isPermitted({ user, action: 'dashboard.viewAll' }) ? {} : { limitTo: 'owned' }),
      },
    },
    fetchPolicy: 'network-only',
  });

  const actionsInsightsQuery = useMemo(
    () => ({
      groupBy: panels[selectedPanel]._id,
      auditType: selectedActionsStatsCount !== 'all' ? selectedActionsStatsCount : undefined,
      ...(isPermitted({ user, action: 'dashboard.viewAll' }) ? {} : { limitTo: 'owned' }),
    }),
    [selectedActionsStatsCount, selectedPanel],
  );
  const {
    data: insights,
    loading: insightsLoading,
    refetch: refetchInsights,
  } = useQuery(GET_ACTIONS_INSIGHTS, {
    variables: {
      actionsInsightsQuery,
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: actionsBySeverityRating,
    loading: actionsBySeverityRatingLoading,
    refetch: refetchActionsBySeverityRating,
  } = useQuery(GET_ACTIONS_BY_SEVERITY_RATING);

  const { setCallback } = useViewModalContext();
  useEffect(() => {
    const callback = () => {
      refetchAuditTypesInsights();
      refetchInsights();
      refetchActionsBySeverityRating();
    };
    setCallback(() => callback);
  }, []);

  const actionsStatsCounts = useMemo(() => {
    const UKAS = auditTypesInsights?.auditTypesInsights?.grouped?.find(({ _id }) => _id === 'UKAS');
    const LRQA = auditTypesInsights?.auditTypesInsights?.grouped?.find(({ _id }) => _id === 'LRQA');
    const IA = auditTypesInsights?.auditTypesInsights?.grouped?.find(({ _id }) => _id === 'IA');
    const ISO = auditTypesInsights?.auditTypesInsights?.grouped?.find(({ _id }) => _id === 'ISO');
    const IINC = auditTypesInsights?.auditTypesInsights?.grouped?.find(({ _id }) => _id === 'IINC');
    const WA = auditTypesInsights?.auditTypesInsights?.grouped?.find(({ _id }) => _id === 'WA');
    const SHE = auditTypesInsights?.auditTypesInsights?.grouped?.find(({ _id }) => _id === 'SHE');
    return [
      [
        {
          type: 'all',
          open: (UKAS?.open || 0) + (LRQA?.open || 0) + (IA?.open || 0) + (ISO?.open || 0) + (IINC?.open || 0)
            + (WA?.open || 0) + (SHE?.open || 0),
          overdue: (UKAS?.overdue || 0) + (LRQA?.overdue || 0) + (IA?.overdue || 0) + (ISO?.overdue || 0) + (IINC?.overdue || 0)
            + (WA?.overdue || 0) + (SHE?.overdue || 0),
        },
        {
          type: 'UKAS',
          open: UKAS?.open || 0,
          overdue: UKAS?.overdue || 0,
        },
        {
          type: 'LRQA',
          open: LRQA?.open || 0,
          overdue: LRQA?.overdue || 0,
        },
        {
          type: 'IA',
          open: IA?.open || 0,
          overdue: IA?.overdue || 0,
        },
      ],
      [
        {
          type: 'ISO',
          open: ISO?.open || 0,
          overdue: ISO?.overdue || 0,
        },
        {
          type: 'IINC',
          open: IINC?.open || 0,
          overdue: IINC?.overdue || 0,
        },
        {
          type: 'WA',
          open: WA?.open || 0,
          overdue: WA?.overdue || 0,
        },
        {
          type: 'SHE',
          open: SHE?.open || 0,
          overdue: SHE?.overdue || 0,
        },
      ],
    ];
  }, [JSON.stringify(auditTypesInsights)]);

  const tableRows = useMemo(() => {
    const panel = panels[selectedPanel];
    switch (panel._id) {
      case 'location':
        return data?.locations?.map(({ _id, name }) => {
          const locationInsights = insights?.insights?.grouped?.find((group) => group._id === _id);
          return {
            _id,
            label: name,
            open: locationInsights?.open || 0,
            overdue: locationInsights?.overdue || 0,
            closedThisYear: locationInsights?.closedThisYear || 0,
            closedAllTime: locationInsights?.closedAllTime || 0,
          };
        });
      case 'businessUnit':
        return data?.businessUnits?.map(({ _id, name, location }) => {
          const businessUnitInsights = insights?.insights?.grouped?.find((group) => group._id === _id);
          return {
            _id,
            locationId: location?._id,
            label: name,
            supLabel: location?.name,
            open: businessUnitInsights?.open || 0,
            overdue: businessUnitInsights?.overdue || 0,
            closedThisYear: businessUnitInsights?.closedThisYear || 0,
            closedAllTime: businessUnitInsights?.closedAllTime || 0,
          };
        });
      case 'user':
        return data?.users?.map(({ _id, displayName }) => {
          const userInsights = insights?.insights?.grouped?.find((group) => group._id === _id);
          return {
            _id,
            userId: _id,
            label: displayName,
            open: userInsights?.open || 0,
            overdue: userInsights?.overdue || 0,
            closedThisYear: userInsights?.closedThisYear || 0,
            closedAllTime: userInsights?.closedAllTime || 0,
          };
        }).sort((a, b) => a.label.localeCompare(b.label));
      default:
        return [];
    }
  }, [JSON.stringify(data), JSON.stringify(insights), selectedPanel]);

  const { sortedData: sortedActions, sortOrder, setSortOrder, sortType, setSortType } = useSort(tableRows ?? [], 'label');
  return (
    <Tabs
      defaultIndex={selectedPanel}
      isLazy
      onChange={(index) => setSelectedPanel(index)}
      overflow="auto"
      pt="3"
      px={[4, 6]}
      variant="unstyled"
      w="full"
    >
      <TabList justifyContent="space-between" mt={4}>
        <Flex align="center">
          <Text color="#1E1836" fontSize="16px" fontWeight="700" lineHeight="20px" ml="30px" mr="6">
            Show actions by:
          </Text>
          {panels?.map((panel) => (
            <Tab
              _selected={{
                bg: 'insights.tabBg',
                color: 'insights.tabColor',
              }}
              borderRadius="10px"
              fontSize="smm"
              fontWeight="bold"
              key={panel._id}
              mr={[1, 2]}
              p="6px 13px"
            >
              {pluralize(panel.name)}
            </Tab>
          ))}
        </Flex>
        <Link href={`${process.env.REACT_APP_API_URL}/actions/stats`} isExternal sx={{ '&:hover': { textDecoration: 'none' } }}>
          <Button color="#1E1836" fontSize="14px" fontWeight="700" leftIcon={<ExportIcon fill="white" />} p="6px 11px">
            Export Report
          </Button>
        </Link>
      </TabList>

      {dataLoading && auditTypesInsightsLoading && insightsLoading ? (
        <Box h="100vh">
          <Loader center />
        </Box>
      ) : (
        <>
          <Flex bg="#FFFFFF" borderRadius="20px" direction="column" mb="4" mt={['15px', '25px']} p="25px 30px" w="full">
            <Text color="#1E1836" fontSize="18px" w="full">
              <strong>Overview (open / overdue)</strong> as of {format(new Date(), 'd MMMM y')}
            </Text>
            <Flex overflowX="auto" pt="12px">
              {actionsStatsCounts[0].map((filter) => (
                <InsightsCard
                  count={filter.open}
                  key={filter.type}
                  label={data?.auditTypes?.find(({ type }) => type === filter.type)?.name || 'All'}
                  onSelect={setSelectedActionsStatsCount}
                  overDues={filter.overdue}
                  selected={selectedActionsStatsCount === filter.type}
                  type={filter.type}
                />
              ))}
            </Flex>
            <Flex overflowX="auto" pt="12px">
              {actionsStatsCounts[1].map((filter) => (
                <InsightsCard
                  count={filter.open}
                  key={filter.type}
                  label={data?.auditTypes?.find(({ type }) => type === filter.type)?.name || 'All'}
                  onSelect={setSelectedActionsStatsCount}
                  overDues={filter.overdue}
                  selected={selectedActionsStatsCount === filter.type}
                  type={filter.type}
                />
              ))}
            </Flex>
          </Flex>
        </>
      )}
      <Flex align="center" bg="#FFFFFF" borderRadius="20px" mt="25px">
        <ActionsSeverityTable
          data={actionsBySeverityRating?.actionsBySeverityRating}
          filterBy={`${panels[selectedPanel]._id}Id`}
          filters={tableRows?.map((row) => ({ label: row?.label, value: row?._id }))}
          loading={actionsBySeverityRatingLoading}
          refetch={refetchActionsBySeverityRating}
          selectedActionTypes={[
            ['UKAS', 'LRQA', 'IA'],
            ['ISO', 'IINC', 'WA'],
            ['SHE'],
          ]}
          title="Severity rating by action type"
          w="full"
        />
      </Flex>
      <TabPanels>
        {panels?.map((panel) => (
          <TabPanel key={panel._id} p={0}>
            <Box>
              {dataLoading && auditTypesInsightsLoading && insightsLoading ? (
                <Box h="100vh">
                  <Loader center />
                </Box>
              ) : (
                <>
                  <ActionInsightsTable
                    {...(panels[selectedPanel]._id === 'businessUnit' && {
                      filterBy: 'locationsIds',
                      refetch: refetchInsights,
                      appliedActionsInsightsQuery: actionsInsightsQuery,
                    })}
                    label={panels[selectedPanel].name}
                    loading={insightsLoading}
                    my={['15px', '25px']}
                    rows={sortedActions}
                    selectedAuditTypeId={selectedAuditTypeId}
                    selectedPanel={panels[selectedPanel]?._id}
                    setSortOrder={setSortOrder}
                    setSortType={setSortType}
                    sortOrder={sortOrder}
                    sortType={sortType}
                    subLabel={selectedPanel === 2 ? '(assigned to)' : ''}
                  />
                </>
              )}
            </Box>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default ActionsInsights;

export const actionsInsightsStyles = {
  actionsInsights: {
    mostAddedBy: {
      bg: 'white',
    },
    list: {
      bg: 'white',
      headerBorderColor: '#F0F0F0',
    },
  },
};
