import { createIcon } from '@chakra-ui/icons';

const SpreadIcon = createIcon({
  displayName: 'Spread',
  viewBox: '0 0 12 4',
  path: (
    <g>
      <path
        d="M10.0362 0.65806C9.31983 0.65806 8.73912 1.23878 8.73912 1.95513C8.73912 2.67148 9.31983 3.2522 10.0362 3.2522C10.7525 3.2522 11.3333 2.67148 11.3333 1.95513C11.3333 1.23878 10.7525 0.65806 10.0362 0.65806Z"
        fill="currentColor"
      />
      <path
        d="M6.00005 0.658061C5.2837 0.658061 4.70298 1.23878 4.70298 1.95513C4.70298 2.67148 5.2837 3.2522 6.00005 3.2522C6.7164 3.2522 7.29712 2.67148 7.29712 1.95513C7.29712 1.23878 6.7164 0.658061 6.00005 0.658061Z"
        fill="currentColor"
      />
      <path
        d="M1.96367 0.65806C1.24732 0.65806 0.666606 1.23878 0.666606 1.95513C0.666606 2.67148 1.24732 3.2522 1.96367 3.2522C2.68002 3.2522 3.26074 2.67148 3.26074 1.95513C3.26074 1.23878 2.68003 0.65806 1.96367 0.65806Z"
        fill="currentColor"
      />
    </g>
  ),
});

export default SpreadIcon;
