import { t } from 'i18next';
import { capitalize, isEmpty } from 'lodash';

import IFilter from '../interfaces/IFilter';
import IFilters, { IActionFilters, IAuditFilters, IWalkItemFilters } from '../interfaces/IFilters';

export const auditWalkTypes = {
  virtual: 'Virtual',
  physical: 'Physical',
};

export const auditsFilterDates = {
  thisWeek: 'This week',
  thisMonth: 'This month',
  thisYear: 'This year',
  exactDate: 'Exact date',
  dateRange: 'Date Range',
};

export const actionsFilterDates = {
  thisWeek: 'This week',
  thisMonth: 'This month',
  thisYear: 'This year',
  exactDate: 'Exact date',
  dateRange: 'Date Range',
};

export const trackerFilterDates = {
  noDueDate: 'No due date',
  thisWeek: 'This week',
  thisMonth: 'This month',
  exactDate: 'Exact date',
  dateRange: 'Date Range',
};

export const collections = {
  'tracker-items': 'Tracker items',
  responses: 'Responses',
  'regulatory-bodies': 'Regulatory bodies',
  categories: 'Categories',
  'business-units': 'Business units',
  settings: 'Settings',
};

export const actions = {
  add: 'Added',
  update: 'Updated',
  delete: 'Deleted',
};

export const actionPriorities = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
};

const useFiltersUtils = () => {

  // Constants needs to be inside of useFiltersUtils in order to have working translations
  const trackerItemStatuses = {
    compliant: capitalize(t('compliant')),
    nonCompliant: capitalize(t('non-compliant')),
    notStarted: 'Not started',
    inProgress: 'In progress',
    completed: 'Completed',
    noDueDate: 'No due date',
    comingUp: 'Coming Up',
    missed: 'Missed',
  };
  const initialAuditFilters: IAuditFilters = {
    auditTypesIds: {
      name: 'Audit types',
      value: [],
    },
    locationsIds: {
      name: capitalize(t('location')),
      value: [],
    },
    businessUnitsIds: {
      name: capitalize(t('business unit')),
      value: [],
    },
    usersIds: {
      name: 'User',
      value: {
        auditeesIds: [],
        leadAuditorsIds: [],
      },
    },
    reportDate: {
      name: 'Report date',
      value: [],
    },
    auditDate: {
      name: 'Audit date',
      value: [],
    },
  };
  const initialActionFilters: IActionFilters = {
    auditTypesIds: {
      name: 'Audit type',
      value: [],
    },
    status: {
      name: 'Status',
      value: [],
    },
    priority: {
      name: 'Priority',
      value: [],
    },
    locationsIds: {
      name: capitalize(t('location')),
      value: [],
    },
    businessUnitsIds: {
      name: capitalize(t('business unit')),
      value: [],
    },
    usersIds: {
      name: 'User',
      value: {
        assigneesIds: [],
      },
    },
    dueDate: {
      name: 'Due date',
      value: [],
    },
  };
  const initialFindingFilters: IWalkItemFilters = {
    status: {
      name: 'Status',
      value: [],
    },
    questionsCategoriesIds: {
      name: 'Type',
      value: [],
      hideFromPanel: true,
    },
    locationsIds: {
      name: capitalize(t('location')),
      value: [],
    },
    businessUnitsIds: {
      name: capitalize(t('business unit')),
      value: [],
    },
    usersIds: {
      name: 'User',
      value: {
        addedByIds: [],
      },
    },
    createdDate: {
      name: 'Date added',
      value: [],
    },
    date: {
      name: 'Date',
      value: [],
    },
    severity: {
      name: 'Severity',
      value: [],
    },
  };

  const getFilters = ({
    usedFilters = [],
    currentFilters = {},
    newFilters = {},
    isCleanFilters = false,
    resourceToFilter = '',
  }: {
    usedFilters?: string[];
    currentFilters?: object;
    newFilters?: object;
    isCleanFilters?: boolean;
    resourceToFilter?: string;
  } = {}) => {
    const filters = {};
    for (const filterName of usedFilters) {
      // Get filter config from existing or initial filters
      let filter: IFilter = currentFilters[filterName];
      if (!filter) {
        let cleanFilters = {};
        switch (resourceToFilter) {
          case 'audits':
            cleanFilters = JSON.parse(JSON.stringify(initialAuditFilters));
            break;
          case 'findings':
            cleanFilters = JSON.parse(JSON.stringify(initialFindingFilters));
            break;
          case 'actions':
            cleanFilters = JSON.parse(JSON.stringify(initialActionFilters));
            break;
          default:
            break;
        }
        filter = cleanFilters[filterName];
      }
      if (!filter) continue;

      // Check if value was set
      if (newFilters[filterName] !== undefined) filter.value = newFilters[filterName];

      // Clear all values
      if (isCleanFilters) filter.value = filterName === 'usersIds' ? ({} as string) : ([] as string[]);

      // Check if default value exists and set filters to defaultFilters value
      if (isCleanFilters && !isEmpty(currentFilters) && currentFilters[filterName] !== undefined) filter.value = currentFilters[filterName];

      // Set new filter
      filters[filterName] = filter;
    }

    return filters;
  };

  const parseActionsFilters = (filtersValues: IFilters, allowedFilters?: string[]) => Object.entries(filtersValues).reduce(
    (acc, filter) => {
      if (!filter || !filter[1] || (allowedFilters && !allowedFilters.includes(filter[0]))) return { ...acc };

      const [key, value] = filter;

      if (
        !value.value ||
        (Array.isArray(value.value) && value.value.length === 0) ||
        (key === 'usersIds' && (value.value?.assigneesIds || []).length === 0)
      )
        return acc;

      return {
        ...acc,
        [key]: value?.value,
      };
    },
    { status: [] },
  );

  return {
    trackerItemStatuses,
    getFilters,
    parseActionsFilters,
  };
};

export default useFiltersUtils;
