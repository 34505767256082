import { createIcon } from '@chakra-ui/icons';

const ConformeSmall = createIcon({
  path: (
    <g>
      <path
        d="M-2.32787e-08 14.7083C-2.04211e-08 12.9028 0.33888 11.1389 1.01664 9.41667C1.72264 7.66667 2.73928 6.08333 4.06657 4.66667C5.39385 3.25 7.00353 2.125 8.89561 1.29167C10.8159 0.430556 12.9763 0 15.3767 0C18.2572 0 20.7705 0.597223 22.9168 1.79167C25.063 2.95833 26.6586 4.51389 27.7035 6.45833L21.3918 10.875C21.0247 9.90278 20.4881 9.15278 19.7821 8.625C19.1044 8.09722 18.356 7.73611 17.5371 7.54167C16.7463 7.31944 15.9697 7.20833 15.2073 7.20833C13.6258 7.20833 12.3268 7.59722 11.3101 8.375C10.3217 9.15278 9.58749 10.1389 9.10741 11.3333C8.62733 12.5 8.38729 13.7083 8.38729 14.9583C8.38729 16.3194 8.65557 17.5972 9.19213 18.7917C9.75693 19.9861 10.5477 20.9583 11.5643 21.7083C12.6092 22.4306 13.8517 22.7917 15.292 22.7917C16.0545 22.7917 16.8311 22.6806 17.6218 22.4583C18.4125 22.2083 19.1326 21.8194 19.7821 21.2917C20.4317 20.7639 20.9259 20.0556 21.2647 19.1667L28 23.125C27.407 24.5694 26.4186 25.8056 25.0348 26.8333C23.651 27.8611 22.0837 28.6528 20.3328 29.2083C18.5819 29.7361 16.8452 30 15.1225 30C12.8916 30 10.8442 29.5694 8.98033 28.7083C7.14473 27.8194 5.54917 26.6528 4.19365 25.2083C2.86636 23.7361 1.8356 22.0972 1.10136 20.2917C0.36712 18.4583 -2.62683e-08 16.5972 -2.32787e-08 14.7083Z"
        fill="#282F36"
      />
    </g>
  ),
  viewBox: '0 0 28 30',
});

export default ConformeSmall;
