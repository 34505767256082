import { createIcon } from '@chakra-ui/icons';

const Move = createIcon({
  viewBox: '0 0 12 9',
  path: (
    <g opacity="0.5">
      <path d="M1 4.5H11.1111" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M1 1H11.1111" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M1 8H11.1111" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default Move;
