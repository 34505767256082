import { createIcon } from '@chakra-ui/icons';

const AreaInfoIcon = createIcon({
  path: (
    <>
      <path
        d="M16.7499 6.309C16.7499 9.379 11.4999 14.25 11.4999 14.25C11.4999 14.25 6.24989 9.379 6.24989 6.309C6.21006 4.87595 6.74055 3.48567 7.72488 2.44341C8.70921 1.40115 10.0669 0.792102 11.4999 0.75C12.9328 0.792364 14.2903 1.40149 15.2746 2.44369C16.2589 3.48589 16.7895 4.87603 16.7499 6.309V6.309Z"
        fill="none"
        stroke="#434B4F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M19.75 5.25H21.022C21.2811 5.25079 21.5294 5.35408 21.7127 5.53732C21.8959 5.72056 21.9992 5.96886 22 6.228V22.272C21.9992 22.5311 21.8959 22.7794 21.7127 22.9627C21.5294 23.1459 21.2811 23.2492 21.022 23.25H1.978C1.71886 23.2492 1.47056 23.1459 1.28732 22.9627C1.10408 22.7794 1.00079 22.5311 1 22.272V6.228C1.00079 5.96886 1.10408 5.72056 1.28732 5.53732C1.47056 5.35408 1.71886 5.25079 1.978 5.25H3.25"
        fill="none"
        stroke="#434B4F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </>
  ),
  viewBox: '0 0 23 24',
});

export default AreaInfoIcon;
