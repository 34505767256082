import { Avatar, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';

import { useFiltersContext } from '../contexts/FiltersProvider';
import useDevice from '../hooks/useDevice';
import useNavigate from '../hooks/useNavigate';
import { ArrowCount, DeleteIcon, EditIcon } from '../icons';
import { ILocation } from '../interfaces/ILocation';

const LocationListItem = ({ location, openLocationModal }: { location: ILocation; openLocationModal }) => {
  const device = useDevice();
  const { navigateTo } = useNavigate();
  const { setAuditFiltersValue } = useFiltersContext();

  return (
    <Flex
      align="center"
      bg="adminTrackerItems.element.bg"
      borderBottom="1px solid"
      borderColor="adminTableHeader.border"
      color="adminTrackerItems.element.font"
      cursor="pointer"
      flexShrink={0}
      fontSize="smm"
      fontWeight="semi_medium"
      h="73px"
      mt="0px"
      overflow="hidden"
      p={4}
      w="full"
    >
      <Flex onClick={() => openLocationModal('edit', location)} pl={1} w={['80%', '40%']}>
        {location.name}
      </Flex>
      {device !== 'mobile' && (
        <Flex w="40%">
          <Avatar
            bg="userMenu.avatar.bg"
            color="userMenu.avatar.color"
            h="24px"
            mr="10px"
            name={location?.owner?.displayName}
            rounded="full"
            size="sm"
            src={location?.owner?.imgUrl}
            w="24px"
          />
          {location?.owner?.displayName}
        </Flex>
      )}
      <Flex alignItems="center" w="10%">
        <Text>{location.totalAuditsCount}</Text>
        <Tooltip fontSize="sm" label="Show audits">
          <ArrowCount
            cursor="pointer"
            h="10px"
            ml="13px"
            onClick={() => {
              setAuditFiltersValue({ locationsIds: { value: [location._id] } });
              navigateTo('/audits');
            }}
            stroke="locations.tooltipStroke"
            w="10px"
          />
        </Tooltip>
      </Flex>
      <HStack align="center" justify="flex-end" pl={1} w="10%">
        <Tooltip fontSize="sm" label="Edit business area">
          <EditIcon
            cursor="pointer"
            h="16px"
            onClick={() => openLocationModal('edit', location)}
            stroke="auditTypesAdmin.iconColor"
            w="16px"
          />
        </Tooltip>
        <Tooltip fontSize="sm" label="Delete business area">
          <DeleteIcon
            cursor="pointer"
            h="16px"
            onClick={() => openLocationModal('delete', location)}
            stroke="auditTypesAdmin.iconColor"
            w="16px"
          />
        </Tooltip>
      </HStack>
    </Flex>
  );
};

export default LocationListItem;
