import { createIcon } from '@chakra-ui/icons';

const NotesIcon = createIcon({
  viewBox: '0 0 39 38',
  path: (
    <g>
      <path
        d="M37.6683 34.4288C37.6899 35.0878 37.4495 35.7284 36.9996 36.2103C36.5497 36.6923 35.9272 36.9763 35.2683 37H4.06833C3.40948 36.9763 2.78692 36.6923 2.33706 36.2103C1.8872 35.7284 1.64673 35.0878 1.66833 34.4288V8.37125C1.64673 7.71232 1.8872 7.0717 2.33706 6.58976C2.78692 6.10781 3.40948 5.82383 4.06833 5.80005H35.2683C35.9272 5.82383 36.5497 6.10781 36.9996 6.58976C37.4495 7.0717 37.6899 7.71232 37.6683 8.37125V34.4288Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M8.86816 1V8.2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M16.0684 1V8.2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M23.2681 1V8.2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M30.4683 1V8.2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default NotesIcon;
