import { createIcon } from '@chakra-ui/icons';

const AddIcon = createIcon({
  path: (
    <>
      <path d="M9.1042 0.629102V21.3710" fill="none" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M0.33334 9H22.0751" fill="none" strokeLinecap="round" strokeLinejoin="round" />
    </>
  ),
  viewBox: '0 0 18 18',
});

export default AddIcon;
