import { useCallback } from 'react';

import { EditIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import { answerSeveritiesLabels } from '../../bootstrap/config';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import { CheckIcon, Trashcan, WarningIcon } from '../../icons';
import Can from '../can';

const findingListItem = {
  columnWidth: {
    ia: {
      finding: '25%',
      severity: '20%',
      date: '20%',
      status: '19%',
      actions: '13%',
    },
    finding: '22%',
    severity: '13%',
    date: '13%',
    businessGroup: '18%',
    status: '18%',
    actions: '13%',
    options: '3%',
  },
};

const FindingListItem = ({ finding }) => {
  const { editFinding, deleteFinding, setSelectedAudit } = useViewModalContext();
  const { audit, refetchFindings, refetchActions, refetchApplicableActions } = useAuditContext();
  const overDueActions = finding.actions?.filter((action) => action.status === 'overdue')?.length ?? 0;
  const completedActions = finding.actions?.filter((action) => action.status === 'closed')?.length ?? 0;
  const openActions = finding.actions?.filter((action) => ['open', 'under review'].includes(action.status))?.length ?? 0;

  const getColumnWidth = useCallback(
    (columnName) => (audit.auditType?.type === 'IA' ? findingListItem.columnWidth.ia[columnName] : findingListItem.columnWidth[columnName]),
    [audit.auditType?.type],
  );

  return (
    <Box bg="#FFFFFF" borderBottomColor="actionsList.headerBorderColor" borderBottomWidth="1px" py={[1, 0]} w="full">
      <Flex align="center" h={['full', '73px']} position="relative" w="full">
        <Flex flexDir="column" w={getColumnWidth('finding')}>
          <Flex
            align="flex-start"
            color="actionsList.fontColor"
            fontSize="smm"
            fontWeight="400"
            h="50%"
            lineHeight="18px"
            noOfLines={2}
            opacity="1"
            pt="3px"
            textOverflow="ellipsis"
            w="90%"
          >
            <Text>{finding?.finding}</Text>
          </Flex>
        </Flex>
        <Flex flexDir="column" w={getColumnWidth('severity')}>
          <Flex
            align="flex-start"
            color={`severities.${finding?.severity}`}
            fontSize="smm"
            fontWeight="400"
            h="50%"
            lineHeight="18px"
            noOfLines={1}
            opacity="1"
            pt="3px"
            textOverflow="ellipsis"
          >
            {finding?.severity && answerSeveritiesLabels[finding?.severity]}
          </Flex>
        </Flex>
        <Flex w={getColumnWidth('date')}>
          <Flex color="actionsList.fontColor" fontSize="smm" fontWeight="400" opacity="1">
            {(finding?.date && format(new Date(finding?.date), 'd/MM/yyyy')) ?? '-'}
          </Flex>
        </Flex>
        <Flex w={getColumnWidth('status')}>
          <Flex align="center">
            <Flex color="#000000" fontSize="smm" fontWeight="700">
              {capitalize(finding?.status)}
            </Flex>
          </Flex>
        </Flex>
        {audit?.auditType?.type !== 'IA' && (
          <Flex w={getColumnWidth('businessGroup')}>
            <Flex align="center">
              <Flex color="#000000" fontSize="smm" fontWeight="700">
                {capitalize(finding.businessUnit?.name ?? '-')}
              </Flex>
            </Flex>
          </Flex>
        )}
        <Flex w={getColumnWidth('actions')}>
          <Flex align="center">
            <Flex color="#1E1836" fontWeight="400">
              {completedActions === finding.actions?.length ? (
                <Flex align="center">
                  <CheckIcon stroke="#41B916" w="14px" />
                  <Text fontSize="smm" ml="1.5">
                    No open actions
                  </Text>
                </Flex>
              ) : (
                <Flex align="center">
                  {overDueActions > 0 && (
                    <Tooltip bg="#E93C44" color="#FFFFFF" label={`${overDueActions} Overdue ${pluralize('action', overDueActions)}`}>
                      <WarningIcon bg="#E93C44" borderRadius="3px" fill="#E93C44" mr="1.5" p="2px" stroke="white" />
                    </Tooltip>
                  )}
                  <Text color="actionSquare.section.text" fontSize="14px">
                    {openActions + overDueActions}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex w="3%">
          <EditIcon
            color="#1E1836"
            cursor="pointer"
            onClick={() => {
              setSelectedAudit(audit);
              editFinding(finding, refetchFindings);
            }}
          />
          <Can
            action="answers.delete"
            data={{ auditType: audit.auditType }}
            yes={() => (
              <Trashcan
                cursor="pointer"
                ml="2"
                onClick={() => {
                  setSelectedAudit(audit);
                  deleteFinding(finding, () => {
                    refetchFindings();
                    refetchActions();
                    refetchApplicableActions();
                  });
                }}
                stroke="#1E1836"
              />
            )}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default FindingListItem;
