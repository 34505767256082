import { createIcon } from '@chakra-ui/icons';

const GroupIcon = createIcon({
  path: (
    <g>
      <path
        d="M7.26923 1H1.73077C1.05103 1 0.5 1.55103 0.5 2.23077V17.7692C0.5 18.449 1.05103 19 1.73077 19H7.26923C7.94897 19 8.5 18.449 8.5 17.7692V2.23077C8.5 1.55103 7.94897 1 7.26923 1Z"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2692 1H11.7308C11.051 1 10.5 1.55103 10.5 2.23077V10.7692C10.5 11.449 11.051 12 11.7308 12H17.2692C17.949 12 18.5 11.449 18.5 10.7692V2.23077C18.5 1.55103 17.949 1 17.2692 1Z"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 19 20',
});

export default GroupIcon;
