import { createIcon } from '@chakra-ui/icons';

const MessageSquareIcon = createIcon({
  viewBox: '0 0 18 18',
  path: (
    <g>
      <path
        d="M15.9333 13.8H8.46667L4.2 17V13.8H2.06667C1.78377 13.8 1.51246 13.6876 1.31242 13.4876C1.11238 13.2875 1 13.0162 1 12.7333V2.06667C1 1.78377 1.11238 1.51246 1.31242 1.31242C1.51246 1.11238 1.78377 1 2.06667 1H15.9333C16.2162 1 16.4875 1.11238 16.6876 1.31242C16.8876 1.51246 17 1.78377 17 2.06667V12.7333C17 13.0162 16.8876 13.2875 16.6876 13.4876C16.4875 13.6876 16.2162 13.8 15.9333 13.8Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default MessageSquareIcon;
