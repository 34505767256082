import { createIcon } from '@chakra-ui/icons';

const ThumbUpIcon = createIcon({
  viewBox: '0 0 38 38',
  path: (
    <g>
      <path
        d="M8.18213 34.5165C9.02547 34.545 9.86191 34.6791 10.6719 34.9155C17.056 36.735 17.4072 36.9425 19.0989 36.9425H26.9354C29.3176 36.9544 31.6188 36.078 33.3895 34.4843C35.1602 32.8907 36.2734 30.6943 36.5116 28.3239L36.9904 20.1842C37.0766 18.5231 36.5823 16.8837 35.5923 15.5472C34.6022 14.2106 33.178 13.26 31.5639 12.8585C29.8402 12.4275 27.4302 12.4275 27.4302 10.0175V4.59106C27.4302 3.63865 27.0518 2.72525 26.3784 2.0518C25.7049 1.37834 24.7915 1 23.8391 1C22.8867 1 21.9733 1.37834 21.2999 2.0518C20.6264 2.72525 20.2481 3.63865 20.2481 4.59106C20.2481 4.75066 21.3015 19.0351 8.27789 19.0351L8.18213 34.5165Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M8.18211 15.3643H1V36.9106H8.18211V15.3643Z" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default ThumbUpIcon;
