import { createIcon } from '@chakra-ui/icons';

const RequiredIcon = createIcon({
  viewBox: '0 0 11 11',
  path: (
    <g>
      <path d="M5.5 1V10" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M10 5.5H1" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M8.68179 2.31787L2.31836 8.68173" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M8.68179 8.68173L2.31836 2.31787" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default RequiredIcon;
