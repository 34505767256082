import { Flex, Heading, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';

import Header from '../components/Header';

const Help = () => (
  <Flex flexDirection="column" h="full" w="full">
    <Header breadcrumbs={['Home', 'Help']} mobileBreadcrumbs={['Help']} />
    <Flex
      bg="white"
      borderRadius="20px"
      flexDirection="column"
      h="full"
      maxWidth="full"
      mb={['25px', '25px']}
      ml="7"
      mr="25px"
      overflowY="auto"
      px={8}
      py={12}
    >
      <VStack spacing={12}>
        <HStack spacing={6}>
          <VStack w="450px">
            <Heading size="md">Introduction to the audit actions tracker</Heading>
            <Link href="https://dew1-share.percipio.com/cd/zQldpdWBP" isExternal>
              <VStack>
                <Image src={`${process.env.REACT_APP_API_URL}/images/precipio_thumb_introduction.png`} width="200px" />
                <Text>https://dew1-share.percipio.com/cd/zQldpdWBP</Text>
              </VStack>
            </Link>
          </VStack>

          <VStack w="450px">
            <Heading size="md">Navigating the audit actions tracker platform</Heading>
            <Link href="https://dew1-share.percipio.com/cd/bIaczd0Q9" isExternal>
              <VStack>
                <Image src={`${process.env.REACT_APP_API_URL}/images/precipio_thumb_navigating_the_platform.png`} width="200px" />
                <Text>https://dew1-share.percipio.com/cd/bIaczd0Q9</Text>
              </VStack>
            </Link>
          </VStack>
        </HStack>

        <HStack spacing={6}>
          <VStack w="450px">
            <Heading size="md">Managing actions in the audit actions tracker</Heading>
            <Link href="https://dew1-share.percipio.com/cd/FxwLiAq_j" isExternal>
              <VStack>
                <Image src={`${process.env.REACT_APP_API_URL}/images/precipio_thumb_managing_actions.png`} width="200px" />
                <Text>https://dew1-share.percipio.com/cd/FxwLiAq_j</Text>
              </VStack>
            </Link>
          </VStack>

          <VStack w="450px">
            <Heading size="md">Leaders Dashboard for the audit actions tracker</Heading>
            <Link href="https://dew1-share.percipio.com/cd/Vi6SfXfhG" isExternal>
              <VStack>
                <Image src={`${process.env.REACT_APP_API_URL}/images/precipio_thumb_leaders_dashboard.png`} width="200px" />
                <Text>https://dew1-share.percipio.com/cd/Vi6SfXfhG</Text>
              </VStack>
            </Link>
          </VStack>
        </HStack>

        <HStack spacing={6}>
          <VStack w="450px">
            <Heading size="md">User guide</Heading>
            <Link href={`${process.env.REACT_APP_API_URL}/images/User guide v1 - Audit Action Tracker - BRE - Cielo Costa.pdf`} isExternal>
              <VStack>
                <Image src={`${process.env.REACT_APP_API_URL}/images/user_admin_guide_thumb.png`} width="200px" />
                <Text>User guide v1 - Audit Action Tracker - BRE - Cielo Costa.pdf</Text>
              </VStack>
            </Link>
          </VStack>

          <VStack w="450px">
            <Heading size="md">Admin guide</Heading>
            <Link href={`${process.env.REACT_APP_API_URL}/images/Admin guide v1 - Audit Action Tracker - BRE - Cielo Costa.pdf`} isExternal>
              <VStack>
                <Image src={`${process.env.REACT_APP_API_URL}/images/user_admin_guide_thumb.png`} width="200px" />
                <Text>Admin guide v1 - Audit Action Tracker - BRE - Cielo Costa.pdf</Text>
              </VStack>
            </Link>
          </VStack>
        </HStack>
      </VStack>
    </Flex>
  </Flex>
);

export default Help;
