import { createIcon } from '@chakra-ui/icons';

const DetailIcon = createIcon({
  viewBox: '0 0 14 14',
  displayName: 'Detail Icon',
  path: (
    <g>
      <path
        d="M0.777771 7.00005C0.777771 7.81717 0.938714 8.62628 1.25141 9.38119C1.56411 10.1361 2.02243 10.822 2.60022 11.3998C3.178 11.9776 3.86394 12.4359 4.61885 12.7486C5.37377 13.0613 6.18288 13.2223 6.99999 13.2223C7.81711 13.2223 8.62622 13.0613 9.38113 12.7486C10.136 12.4359 10.822 11.9776 11.3998 11.3998C11.9776 10.822 12.4359 10.1361 12.7486 9.38119C13.0613 8.62628 13.2222 7.81717 13.2222 7.00005C13.2222 6.18294 13.0613 5.37383 12.7486 4.61891C12.4359 3.864 11.9776 3.17807 11.3998 2.60028C10.822 2.02249 10.136 1.56417 9.38113 1.25147C8.62622 0.938775 7.81711 0.777832 6.99999 0.777832C6.18288 0.777832 5.37377 0.938775 4.61885 1.25147C3.86394 1.56417 3.178 2.02249 2.60022 2.60028C2.02243 3.17807 1.56411 3.864 1.25141 4.61891C0.938714 5.37383 0.777771 6.18294 0.777771 7.00005V7.00005Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M5.96295 5.96313H6.99999V10.1113" fill="none" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.96295 10.1111H8.03703" fill="none" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M6.99998 3.37256C6.9487 3.37256 6.89858 3.38776 6.85594 3.41625C6.81331 3.44474 6.78008 3.48523 6.76046 3.5326C6.74083 3.57998 6.7357 3.63211 6.7457 3.6824C6.75571 3.73269 6.7804 3.77888 6.81666 3.81514C6.85292 3.8514 6.89911 3.87609 6.9494 3.8861C6.99969 3.8961 7.05182 3.89097 7.0992 3.87134C7.14657 3.85172 7.18706 3.81849 7.21555 3.77585C7.24403 3.73322 7.25924 3.68309 7.25924 3.63182C7.25924 3.56306 7.23193 3.49711 7.18331 3.44849C7.13468 3.39987 7.06874 3.37256 6.99998 3.37256Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default DetailIcon;
