import { createContext, useContext, useMemo } from 'react';

import { gql, useMutation } from '@apollo/client';
import { useDisclosure, useToast } from '@chakra-ui/react';

import { toastFailed, toastSuccess } from '../bootstrap/config';
import { IActionContext } from '../interfaces/IActionContext';
import { useDataContext } from './DataProvider';

export const ActionContext = createContext({} as IActionContext);

const CREATE_ACTION = gql`
  mutation AddAction($action: ActionCreateInput!) {
    createAction(action: $action) {
      _id
      title
      assigneeId
      dueDate
      status
    }
  }
`;

const UPDATE_ACTION = gql`
  mutation SaveAction($action: ActionModifyInput!) {
    updateAction(actionInput: $action) {
      _id
      metatags {
        updatedAt
      }
    }
  }
`;

const DELETE_ACTION = gql`
  mutation DeleteAction($_id: ID!) {
    deleteAction(_id: $_id)
  }
`;

export const useActionContext = () => {
  const context = useContext(ActionContext);
  if (!context) throw new Error('useActionModalContext must be used within the ActionModalProvider');

  return context;
};

const ActionProvider = ({ children }) => {
  const toast = useToast();
  const { refetchAction } = useDataContext();
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();

  // CRUD Operations
  const [createAction] = useMutation(CREATE_ACTION);
  const [updateAction] = useMutation(UPDATE_ACTION);
  const [deleteAction] = useMutation(DELETE_ACTION);

  const handleDeleteAction = async (id: string) => {
    // TODO: Should be moved to custom hook
    try {
      await deleteAction({
        variables: {
          _id: id,
        },
      });
      toast({
        ...toastSuccess,
        description: `Action successfully deleted`,
      });
      refetchAction();
      return true;
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    }
    return false;
  };

  const value = useMemo(
    () => ({
      isDeleteModalOpen,
      createAction,
      updateAction,
      handleDeleteAction,
      onDeleteModalClose,
      onDeleteModalOpen,
    }),
    [isDeleteModalOpen],
  );

  return <ActionContext.Provider value={value}>{children}</ActionContext.Provider>;
};

export default ActionProvider;
