import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode } from '@apollo/client';
import { Tab, TabList, Tabs } from '@chakra-ui/react';
import { capitalize } from 'lodash';

import { auditDetailsTabs } from '../../bootstrap/config';
import AuditDataDisplay from '../../components/Audit/AuditDataDisplay';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';

export interface UseQueryParams {
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>;
  options: QueryHookOptions<any, OperationVariables> | undefined;
  queryInputName: string;
}

const Audit = () => {
  const { audit, findings, setAuditTab } = useAuditContext();
  const history = useHistory();
  const location = useLocation();
  const { editFinding, setSelectedAudit } = useViewModalContext();

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedPageType, selectedPageOptions] = Object.entries(auditDetailsTabs)[selectedTab];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const findingId = queryParams.get('findingId');
    const selectedTabParam = queryParams.get('selectedTab');

    if (selectedTabParam) {
      setSelectedTab(Object.keys(auditDetailsTabs).findIndex((tab) => tab === selectedTabParam));
      window.history.pushState({}, '', location.pathname);
    }

    if (findingId) {
      const finding = findings.find(({ _id }) => _id === findingId);
      if (finding) {
        setSelectedAudit(audit);
        editFinding(finding);
        queryParams.delete('findingId');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
  }, [location.search, JSON.stringify(findings)]);

  useEffect(() => {
    setAuditTab(selectedPageType);
  }, [selectedPageType])

  return (
    <Tabs index={selectedTab} isLazy onChange={(index) => setSelectedTab(index)} variant="unstyled" w="full">
      <TabList mb={4}>
        {Object.keys(auditDetailsTabs).map((key) => (
          <Tab
            _selected={{
              bg: 'insights.tabBg',
              color: 'insights.tabColor',
            }}
            borderRadius="10px"
            fontSize="smm"
            fontWeight="bold"
            key={key}
            mr={[1, 2]}
          >
            {capitalize(key)}
          </Tab>
        ))}
      </TabList>
      <AuditDataDisplay options={selectedPageOptions} type={selectedPageType} />
    </Tabs>
  );
};

export default Audit;

export const auditItemStyles = {
  auditItem: {
    tab: {
      addButton: {
        bg: '#DC0043',
        color: '#FFFFFF',
      },
      bg: '#FFFFFF',
      action: {
        icon: '#D2D1D7',
        color: '#1E183650',
      },
      editIcon: '#1E1836',
      deleteIcon: '#DC0043',
    },
  },
};
