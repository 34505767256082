import { createIcon } from '@chakra-ui/icons';

const ResetIcon = createIcon({
  viewBox: '0 0 16 17',
  path: (
    <g>
      <path
        d="M5.16956 6.02964L2.78167 7.22947L1.58184 4.84159"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M2.70489 7.08012C3.15062 5.82798 4.01344 4.76723 5.14858 4.07585C6.28372 3.38448 7.62211 3.10454 8.93918 3.28302C10.2563 3.4615 11.4719 4.08753 12.3821 5.05607C13.2923 6.02462 13.8417 7.27674 13.9381 8.60235"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M2.7064 7.07613C3.18508 5.73085 4.14539 4.61074 5.40178 3.93222C6.65817 3.25369 8.12146 3.06492 9.50891 3.40238C10.8964 3.73984 12.1095 4.57957 12.9139 5.75933C13.7183 6.93908 14.0569 8.37512 13.8643 9.78997C13.6716 11.2048 12.9614 12.498 11.8709 13.4198C10.7803 14.3415 9.38686 14.8263 7.95969 14.7806C6.53252 14.7348 5.17297 14.1617 4.14372 13.172"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
});

export default ResetIcon;
