import { createIcon } from '@chakra-ui/icons';

const ArrowRight = createIcon({
  displayName: 'Arrow right',
  viewBox: '0 0 8 14',
  path: (
    <path
      d="M1 1L4.81156 4.81156C4.83632 4.83629 4.85598 4.86566 4.86938 4.898C4.88279 4.93033 4.88969 4.965 4.88969 5C4.88969 5.035 4.88279 5.06967 4.86938 5.102C4.85598 5.13434 4.83632 5.16371 4.81156 5.18844L1 9"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export default ArrowRight;
