import { Flex } from '@chakra-ui/react';

import FiltersPanel from '../components/Filters/FiltersPanel';
// import ModuleSwitcher from '../components/ModuleSwitcher';
import ViewModal from '../components/Modals/ViewModal';
import NavigationBottomMobile from '../components/NavigationBottomMobile';
import NavigationLeft from '../components/NavigationLeft/NavigationLeft';
import NavigationTop from '../components/NavigationTop';
import ShareModal from '../components/ShareModal';
import DataProvider from '../contexts/DataProvider';
import { useFiltersContext } from '../contexts/FiltersProvider';
import ShareProvider from '../contexts/ShareProvider';
import { useViewModalContext } from '../contexts/ViewModalProvider';
import useDevice from '../hooks/useDevice';

const DefaultLayout = ({ component: Component }: { component: any }) => {
  const { usedFilters } = useFiltersContext();
  const { isViewModalOpen } = useViewModalContext();
  const device = useDevice();
  return (
    <ShareProvider>
      <DataProvider>
        <Flex minH="100vh">
          {/* <ModuleSwitcher /> */}
          <NavigationLeft />
          <Flex direction="column" flexBasis="auto" flexGrow={1} position="relative">
            {isViewModalOpen && <ViewModal />}
            <NavigationTop />
            <ShareModal />
            <Flex bg="layout.bg" flexDirection="column" h="calc(100vh - 80px)" overflow="hidden" position="absolute" top="80px" w="full">
              <Component />
            </Flex>
            {device === 'mobile' && <NavigationBottomMobile />}
          </Flex>
          {usedFilters?.length > 0 && <FiltersPanel />}
        </Flex>
      </DataProvider>
    </ShareProvider>
  );
};

export default DefaultLayout;
