import { createIcon } from '@chakra-ui/icons';

const Asterisk = createIcon({
  viewBox: '0 0 11 12',
  displayName: 'Asterisk Question',
  path: (
    <g>
      <path d="M5.5 1.5V10.5" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M10 6H1" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M8.68179 2.81787L2.31836 9.18173" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M8.68179 9.18173L2.31836 2.81787" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
});

export default Asterisk;
