import { Box, Flex, Icon, Text } from '@chakra-ui/react';

import { useAdminContext } from '../../contexts/AdminProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useNavigate from '../../hooks/useNavigate';
import { ISubSection } from '../../interfaces/INavItem';

const SubSection = ({
  subsection,
  setMenuOpen,
  menuOpen,
  showIcon,
}: {
  subsection: ISubSection;
  menuOpen?: boolean;
  showIcon?: boolean;
  setMenuOpen?: (value: boolean) => void;
}) => {
  const { navigateTo, isPathActive } = useNavigate();
  const { key, label, icon, url } = subsection;
  const { setAdminModalState } = useAdminContext();
  const { addAudit, addFinding, addAction } = useViewModalContext();

  return (
    <Flex
      alignItems="center"
      color={isPathActive(url || '', { exact: true }) && !showIcon ? 'subSection.selectedFontColor' : 'subSection.unselectedFontColor'}
      cursor="pointer"
      fontSize="14px"
      fontWeight="400"
      key={label}
      lineHeight="40px"
      ml={[menuOpen ? '35px' : '10px', '20px', showIcon ? 6 : '35px']}
      onClick={() => {
        if (url) navigateTo(url);
        if (showIcon) setAdminModalState('add');
        if (setMenuOpen) setMenuOpen(!menuOpen);
        switch (key) {
          case 'audit':
            addAudit();
            break;
          case 'finding':
            addFinding();
            break;
          case 'action':
            addAction();
            break;

          default:
            break;
        }
      }}
    >
      {!showIcon && (
        <Box
          bg={isPathActive(url || '', { exact: true }) && !showIcon ? 'subSection.selectedIndicator' : 'subSection.unselectedIndicator'}
          h="8px"
          rounded="50%"
          w="8px"
        />
      )}

      {showIcon && <Icon as={icon as any} fill="transparent" h="16px" stroke="subSection.iconStroke" w="16px" />}
      <Text fontSize="smm" ml="25px">
        {label}
      </Text>
    </Flex>
  );
};

export default SubSection;

export const subSectionStyles = {
  subSection: {
    selectedFontColor: '#282F36',
    unselectedFontColor: '#818197',
    selectedIndicator: '#462AC4',
    unselectedIndicator: '#ffffff',
    iconStroke: '#818197',
  },
};
