import { RepeatClockIcon } from '@chakra-ui/icons';

import { AuditIcon, ProgressIcon, TeamsIcon } from '../icons';

export const toastSuccess: any = {
  title: 'Success',
  status: 'success',
  duration: 5000,
  isClosable: true,
  position: 'top',
};

export const toastWarning: any = {
  title: 'Warning',
  status: 'warning',
  duration: 5000,
  isClosable: true,
  position: 'top',
};

export const toastFailed: any = {
  title: 'Failed',
  status: 'error',
  duration: 5000,
  isClosable: true,
  position: 'top',
};

export const actionStatuses = {
  open: 'Open',
  closed: 'Closed',
  'under review': 'Under review',
  overdue: 'Overdue',
};

export const answerStatuses = {
  open: 'Open',
  closed: 'Closed',
};

export const walkItemStatuses = {
  open: 'Open',
  closed: 'Closed',
};

export const actionsInsightsTypes = {
  total: 'Total',
  completed: 'Closed',
  inProgress: 'Open',
  overdue: 'Overdue',
};

export const auditsInsightsTypes = {
  total: 'Total',
  completed: 'Completed',
  upcoming: 'Upcoming',
  missed: 'Missed',
};

export const userMenus = [
  // {
  //   label: 'Terms and conditions',
  //   url: '/terms-and-conditions',
  // },
  // {
  //   label: 'Privacy policy',
  //   url: '/privacy-policy',
  // },
  {
    label: 'Help',
    url: '/help',
  },
  // {
  //   label: 'Settings',
  //   url: '/admin/settings',
  //   permission: 'adminPanel.view',
  // },
];

export const chartColors = [
  '#336699',
  '#99CCFF',
  '#999933',
  '#666699',
  '#CC9933',
  '#006666',
  '#3399FF',
  '#993300',
  '#CCCC99',
  '#666666',
  '#FFCC66',
  '#6699CC',
  '#663366',
  '#9999CC',
  '#CCCCCC',
  '#669999',
  '#CCCC66',
  '#CC6600',
  '#9999FF',
  '#0066CC',
  '#99CCCC',
  '#999999',
  '#FFCC00',
  '#009999',
  '#99CC33',
  '#FF9900',
  '#999966',
  '#66CCCC',
  '#339966',
  '#CCCC33',
  '#003f5c',
  '#665191',
  '#a05195',
  '#d45087',
  '#2f4b7c',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#EF6F6C',
  '#465775',
  '#56E39F',
  '#59C9A5',
  '#5B6C5D',
  '#0A2342',
  '#2CA58D',
  '#84BC9C',
  '#CBA328',
  '#F46197',
  '#DBCFB0',
  '#545775',
];

export const settingsTabs = [
  {
    index: 0,
    label: 'Defaults',
  },
  {
    index: 1,
    label: 'Email templates',
  },
  // {
  //   index: 2,
  //   label:"Notifications"
  // }
];

export const auditTabs = [
  {
    index: 0,
    label: 'All logs',
  },
  {
    index: 1,
    label: 'Renewals',
  },
  {
    index: 2,
    label: 'Team changes',
  },
];

export const navigationTabs = [
  {
    label: 'Progress',
    url: '',
    icon: ProgressIcon,
  },
  {
    label: 'Change log',
    url: '/change-log',
    icon: AuditIcon,
  },
  {
    label: 'Participants',
    url: '/participants',
    icon: TeamsIcon,
  },
  {
    label: 'Review history',
    url: '/history',
    icon: RepeatClockIcon,
  },
];

export const userRoles = [
  {
    value: 'responsible',
    label: 'Responsible',
  },
  {
    value: 'accountable',
    label: 'Accountable',
  },
  {
    value: 'contributor',
    label: 'Contributor',
  },
  {
    value: 'follower',
    label: 'Follower',
  },
];

export const auditUserRoles = [
  {
    value: 'auditee',
    label: 'Auditee',
  },
  {
    value: 'leadAuditor',
    label: 'Key contact',
  },
];

export const actionUserRoles = [
  {
    value: 'assignee',
    label: 'Assignee',
  },
];

export const questionsUserRoles = [
  {
    value: 'addedBy',
    label: 'Added by',
  },
];

export const questionTypes = [
  {
    value: 'text',
    label: 'Text input',
  },
  {
    value: 'textMultiline',
    label: 'Multiple lines of text',
  },
  {
    value: 'switch',
    label: 'Yes / No answer',
  },
  {
    value: 'datepicker',
    label: 'Date input',
  },
  {
    value: 'multipleChoice',
    label: 'Multiple choices',
  },
  {
    value: 'singleChoice',
    label: 'Single choice',
  },
  {
    value: 'url',
    label: 'URL',
  },
  // {
  //   value: "email",
  //   label: "Email address"
  // },
  // {
  //   value: "phoneNumber",
  //   label: "Phone number"
  // },
  // {
  //   value: "numeric",
  //   label: "Numeric"
  // }
];

export const SwitchOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

export const priorities = [
  {
    value: 'low',
    label: 'Low',
  },
  {
    value: 'medium',
    label: 'Medium',
  },
  {
    value: 'high',
    label: 'High',
  },
];

export const availableOptions = {
  INFORM_HSE: {
    type: 'notification',
    name: 'Inform Health Safety Environment',
    setting: 'hseEmailAddress',
    value: 'INFORM_HSE',
  },
  INFORM_ESTATES: {
    type: 'notification',
    name: 'Inform Estates',
    setting: 'estatesEmailAddress',
    value: 'INFORM_ESTATES',
  },
};

export const actionsSeveritiesByAuditType = {
  UKAS: ['Mandatory', 'Recommendation'],
  LRQA: ['Major', 'Minor', 'OFI'],
  IA: ['Severe', 'Major', 'Minor', 'OFI'],
  ISO: ['Major', 'Minor', 'OFI'],
  IINC: ['Internal'],
  WA: ['Major', 'Minor', 'OFI'],
  SHE: [
    'Cuts/Sharps',
    'Entrapted/pinched/caught by object/equipment',
    'Exposure/contact with harmful substance',
    'Fire or explosion',
    'Manual Handling',
    'Miscellaneous other',
    'People/objects falling from height',
    'Repetitive Strain Injury',
    'Road Traffic Accident',
    'Slips and Trips',
    'Struck by/against',
    'Temperature related e.g. burns or scalds',
  ],
};

export const auditDetailsTabs = {
  findings: {
    filters: true,
    sorts: true,
    displays: ['grid', 'list'],
    quickFilters: ['open', 'closed'],
    allowedFilters: ['status', 'severity', 'date'],
  },
  actions: {
    filters: true,
    sorts: true,
    displays: ['grid', 'list'],
    quickFilters: ['overdue', 'open', 'closed', 'under review'],
    allowedFilters: ['status', 'usersIds', 'dueDate'],
  },
  documents: {
    filters: false,
    sorts: true,
    displays: [],
    quickFilters: [],
    allowedFilters: [],
  },
};

export const actionsSortOptions = [
  { key: 'reference', label: 'Action reference' },
  { key: 'title', label: 'Action title' },
  { key: 'answer.severity', label: 'Severity' },
  { key: 'classification', label: 'Action source' },
  { key: 'answer.finding', label: 'Applicable finding' },
  { key: 'dueDate', label: 'Due date' },
  { key: 'daysOverdue', label: 'Days overdue' },
  { key: 'daysOpen', label: 'Days open' },
  { key: 'status', label: 'Status' },
  { key: 'metatags.addedAt', label: 'Action age' },
];

export const findingsSortOptions = [
  { key: 'reference', label: 'Finding reference' },
  { key: 'finding', label: 'Finding' },
  { key: 'severity', label: 'Severity' },
  { key: 'date', label: 'Date of finding' },
  { key: 'status', label: 'Status' },
  { key: 'businessGroup', label: 'Business Group' },
  { key: 'actions.length', label: 'Actions' },
];

export const auditsSortOptions = [
  { key: 'reference', label: 'Reference' },
  { key: 'title', label: 'Title' },
  { key: 'businessArea.name', label: 'Business area' },
  { key: 'businessGroup.name', label: 'Business group' },
  { key: 'reportDate', label: 'Report date' },
  { key: 'leadAuditor.displayName', label: 'Key contact' },
  { key: 'actions', label: 'Actions' },
];

export const documentsSortOptions = [
  { key: 'name', label: 'Document name' },
  { key: 'addedAt', label: 'Date uploaded' },
];

export const auditDetailsTableHeaders = {
  actions: [
    { name: '', label: '', w: '5%' },
    { name: 'title', label: 'Action title', w: '15%' },
    { name: 'answer.severity', label: 'Severity', w: '10%' },
    { name: 'classification', label: 'Action source', w: '9%' },
    { name: 'answer.finding', label: 'Applicable finding', w: '15%' },
    { name: 'dueDate', label: 'Due date', w: '8%' },
    { name: 'daysOverdue', label: 'Days overdue', w: '7.5%' },
    { name: 'daysOpen', label: 'Days open', w: '7.5%' },
    { name: 'status', label: 'Status', w: '10%' },
    { name: 'metatags.addedAt', label: 'Action age', w: '10%' },
    { name: 'options', label: '', w: '3%' },
  ],
  findings: [
    { name: 'finding', label: 'Finding', w: '22%' },
    { name: 'severity', label: 'Severity', w: '13%' },
    { name: 'date', label: 'Date of finding', w: '13%' },
    { name: 'status', label: 'Status', w: '18%' },
    { name: 'businessGroup', label: 'Business Group', w: '18%' },
    { name: 'actions.length', label: 'Actions', w: '13%' },
    { name: 'options', label: '', w: '3%' },
  ],
  iaFindings: [
    { name: 'finding', label: 'Finding', w: '25%' },
    { name: 'severity', label: 'Severity', w: '20%' },
    { name: 'date', label: 'Date of finding', w: '20%' },
    { name: 'status', label: 'Status', w: '19%' },
    { name: 'actions.length', label: 'Actions', w: '13%' },
    { name: 'options', label: '', w: '3%' },
  ],
  documents: [
    { name: '', label: '', w: '5%' },
    { name: 'name', label: 'Document name', w: '45%' },
    { name: 'addedAt', label: 'Date uploaded', w: '50%' },
  ],
};
export const actionClassificationTypes = {
  UKAS: ['mandatory', 'improvement'],
  IA: ['correction', 'corrective'],
  LRQA: ['correction', 'corrective'],
  ISO: ['correction', 'corrective'],
  IINC: ['correction', 'corrective'],
  WA: ['correction', 'corrective'],
  SHE: ['correction', 'corrective'],
};

export const answerSeveritiesLabels = {
  mandatory: 'Mandatory',
  recommendation: 'Recommendation',
  severe: 'Severe',
  major: 'Major',
  minor: 'Minor',
  ofi: 'OFI',
  internal: 'Internal',
  cutssharps: 'Cuts/Sharps',
  entraptedpinchedcaughtbyobjectequipment: 'Entrapted/pinched/caught by object/equipment',
  exposurecontactwithharmfulsubstance: 'Exposure/contact with harmful substance',
  fireorexplosion: 'Fire or explosion',
  manualhandling: 'Manual Handling',
  miscellaneousother: 'Miscellaneous other',
  peopleobjectsfallingfromheight: 'People/objects falling from height',
  repetitivestraininjury: 'Repetitive Strain Injury',
  roadtrafficaccident: 'Road Traffic Accident',
  slipsandtrips: 'Slips and Trips',
  struckbyagainst: 'Struck by/against',
  temperaturerelatedegburnsorscalds: 'Temperature related e.g. burns or scalds',
};

export const actionStandards = ['N/A', '9001', '14001', '17020', '17021', '17024', '17025', '17065', '45001'];

export const actionRootCauses = [
  'awareness', 'communication', 'competence', 'infrastructure', 'process', 'resourcing', 'training', // Initial
  'environment (2)', 'equipment (2)', 'management (2)', 'materials (2)', 'people (2)', 'process (2)', // Added
];

export const auditTypesNames = {
  IA: 'Internal Audit',
  ISO: 'ISO Compliance Internal Audits',
  IINC: 'Internally Identified Non-Conformance',
  WA: 'Witnessed Assessments',
  SHE: 'SHE Incident Investigation',
};
