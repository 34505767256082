import { createIcon } from '@chakra-ui/icons';

const TeamsIcon = createIcon({
  path: (
    <g>
      <path
        d="M10.9374 8.33905C11.4741 8.33905 11.9092 7.90398 11.9092 7.36728C11.9092 6.83059 11.4741 6.39551 10.9374 6.39551C10.4007 6.39551 9.96564 6.83059 9.96564 7.36728C9.96564 7.90398 10.4007 8.33905 10.9374 8.33905Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5248 9.55199C12.3373 9.31024 12.097 9.11458 11.8222 8.97997C11.5475 8.84537 11.2456 8.77539 10.9396 8.77539C10.6337 8.77539 10.3318 8.84537 10.0571 8.97997C9.78231 9.11458 9.542 9.31024 9.35449 9.55199"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.06259 8.33905C3.59929 8.33905 4.03437 7.90398 4.03437 7.36728C4.03437 6.83059 3.59929 6.39551 3.06259 6.39551C2.5259 6.39551 2.09082 6.83059 2.09082 7.36728C2.09082 7.90398 2.5259 8.33905 3.06259 8.33905Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.47467 9.55154C1.66185 9.30973 1.90184 9.11391 2.17629 8.97906C2.45074 8.8442 2.75239 8.77387 3.05818 8.77344C3.36397 8.77301 3.66582 8.8425 3.94064 8.97659C4.21547 9.11068 4.456 9.30583 4.64386 9.54712"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.47837 10.3185C9.47837 9.66116 9.21725 9.03077 8.75247 8.56599C8.28768 8.1012 7.65729 7.84009 6.99999 7.84009C6.34268 7.84009 5.71229 8.1012 5.24751 8.56599C4.78272 9.03077 4.52161 9.66116 4.52161 10.3185"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.97452 7.16376C7.78338 7.16376 8.43909 6.50805 8.43909 5.69919C8.43909 4.89033 7.78338 4.23462 6.97452 4.23462C6.16566 4.23462 5.50995 4.89033 5.50995 5.69919C5.50995 6.50805 6.16566 7.16376 6.97452 7.16376Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.99999 13.2223C10.4364 13.2223 13.2222 10.4365 13.2222 7.00005C13.2222 3.56362 10.4364 0.777832 6.99999 0.777832C3.56355 0.777832 0.777771 3.56362 0.777771 7.00005C0.777771 10.4365 3.56355 13.2223 6.99999 13.2223Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
  viewBox: '0 0 14 14',
  displayName: 'Teams Icon',
});

export default TeamsIcon;
