import { Box, Flex, Heading, SimpleGrid, Spacer, Text } from '@chakra-ui/react';

import { InsightsCardDots } from '../../icons';

const InsightsCard = ({
  count,
  overDues,
  onSelect,
  selected = false,
  type = 'all',
  label = '',
}: {
  count: number;
  overDues: number;
  onSelect: (type: string) => void;
  selected?: boolean;
  type: string;
  label: string;
}) => (
  <Flex align="center" cursor="pointer" direction="column" mr="20px" onClick={() => onSelect(type)}>
    <Box
      bg={`insightsCard.types.${selected ? 'selected' : 'unSelected'}`}
      h="211px"
      overflow="hidden"
      position="relative"
      rounded="20px"
      w="271px"
      zIndex="1"
    >
      <InsightsCardDots
        bottom="42.17%"
        h="175px"
        left="54.06%"
        opacity={selected ? '0.4' : '0.2'}
        position="absolute"
        right="-15.9%"
        stroke={`insightsCard.types.${selected ? 'unselected' : 'selected'}`}
        top="-18.26%"
        w="175px"
        zIndex="2"
      />
      <Flex direction="column" position="relative" px="30px" py="25px" textAlign="left" zIndex="3">
        <Text
          color={`insightsCard.${selected ? 'color' : 'accentColor'}`}
          fontSize="22px"
          fontWeight="700"
          lineHeight="20px"
          mb="15px"
          textAlign="center"
        >
          {label}
        </Text>
        <SimpleGrid
          _before={{
            content: `''`,
            w: '1px',
            bg: '#818197',
            h: 'full',
            position: 'absolute',
            right: '50%',
            opacity: '0.4',
          }}
          alignItems="center"
          columns={2}
          height="80px"
          mb="4px"
          position="relative"
        >
          <Heading color={`insightsCard.${selected ? 'color' : 'accentColor'}`} fontSize="40px" fontWeight="700" textAlign="center">
            {count}
          </Heading>
          <Heading color="insightsCard.colorDanger" fontSize="40px" fontWeight="700" textAlign="center">
            {overDues}
          </Heading>
        </SimpleGrid>
        <SimpleGrid alignItems="center" columns={2} height="auto" spacing={0}>
          <Text color={`insightsCard.${selected ? 'color' : 'accentColor'}`} fontSize="14px" fontWeight="700" textAlign="center">
            Open
          </Text>
          <Text color="insightsCard.colorDanger" fontSize="14px" fontWeight="700" textAlign="center">
            Overdue
          </Text>
        </SimpleGrid>
        <Spacer />
      </Flex>
      {selected && <Box bg={`insightsCard.${selected ? 'color' : 'accentColor'}`} h="3px" m="auto" mt="14px" rounded="100px" w="216px" />}
    </Box>
  </Flex>
);

export default InsightsCard;

export const insightsCardStyles = {
  insightsCard: {
    types: {
      selected: '#1E1836',
      unSelected: '#F4F3F5',
    },
    color: '#FFFFFF',
    colorAccent: '#787486',
    accentColor: '#1E1836',
    colorDanger: '#DC0043',
  },
};
