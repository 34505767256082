import { FunctionComponent, useMemo } from 'react';

import { Button, Flex, Text } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import { useViewModalContext } from '../contexts/ViewModalProvider';
import useDevice from '../hooks/useDevice';
import useNavigate from '../hooks/useNavigate';
import { AddIcon, ArrowRight } from '../icons';
import { getAorAn } from '../utils/helpers';
import Can from './can';

interface IHeader {
  breadcrumbs: string[];
  category?: string;
  mobileBreadcrumbs?: string[];
}

const Header: FunctionComponent<IHeader> = ({ breadcrumbs, mobileBreadcrumbs, category }) => {
  const device = useDevice();
  const { isPathActive } = useNavigate();
  const isShowAddButton =
    isPathActive('/dashboard') || isPathActive('/admin/audit-types') || isPathActive('/admin/audit-log') || isPathActive('/admin/users');
  const { setViewModalState, openModal } = useViewModalContext();
  const breadCrumbs = useMemo(() => {
    if (device === 'mobile') return mobileBreadcrumbs || [];

    return breadcrumbs;
  }, [device, breadcrumbs, mobileBreadcrumbs]);

  const renderBreadcrumb = (breadcrumb: string, i: number) => (
    <Flex align="center" h="full" key={`bc-${i}`}>
      {i > 0 && <ArrowRight color="#818197" display="flex" ml={2} mr={1} mt={['0px', '5px']} />}
      <Text
        color={i === breadCrumbs.length - 1 ? 'header.breadcrumbPrimary' : 'header.breadcrumbSecondary'}
        display={i === breadCrumbs.length - 1 ? 'flex' : 'flex'}
        fontSize="xxl"
        fontWeight={i === breadCrumbs.length - 1 ? '700' : '400'}
        mr={1}
      >
        {capitalize(breadcrumb)}
      </Text>
    </Flex>
  );

  return (
    <Flex align="center" h={['60px', '70px']} justify="space-between" position="relative" shrink={0}>
      <Flex display="flex" flexShrink={0} ml={6}>
        {breadCrumbs.map(renderBreadcrumb)}
      </Flex>
      {!isShowAddButton && category && (
        <Can
          action={`${pluralize(category)}.add`}
          yes={() => (
            <Flex justify="flex-end" mr={6}>
              <Button
                _hover={{ bg: 'addButton.bg' }}
                bg="addButton.bg"
                color="addButton.color"
                fontSize="smm"
                fontWeight="bold"
                onClick={() => {
                  setViewModalState(category);
                  openModal();
                }}
                rightIcon={<AddIcon h="13.5px" stroke="header.addIcon" w="13.5px" />}
                rounded="10px"
              >
                Add {getAorAn(category)} {category !== 'audit' ? category : 'IA or assessment'}
              </Button>
            </Flex>
          )}
        />
      )}
    </Flex>
  );
};

export default Header;

export const headerStyles = {
  header: {
    bg: '#2B3236',
    breadcrumbPrimary: '#282F36',
    breadcrumbSecondary: '#818197',
    countFontColor: '#424B50',
    filterBackgroundColor: '#282F36',
    selectedFilterColor: '#818197',
    addIcon: '#FFFFFF',
  },
  addButton: {
    bg: '#DC0043',
    color: '#ffffff',
  },
};
