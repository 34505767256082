import { createIcon } from '@chakra-ui/icons';

const ArrowUpIcon = createIcon({
  displayName: 'Arrow up',
  viewBox: '0 0 14 8',
  path: (
    <path
      d="M0.777344 6.72223L6.70643 0.793144C6.74491 0.754614 6.7906 0.724046 6.8409 0.70319C6.8912 0.682335 6.94511 0.6716 6.99957 0.6716C7.05402 0.6716 7.10793 0.682335 7.15823 0.70319C7.20853 0.724046 7.25423 0.754614 7.2927 0.793144L13.2218 6.72223"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});

export default ArrowUpIcon;
