import { createIcon } from '@chakra-ui/icons';

const CircledCross = createIcon({
  path: (
    <g>
      <path
        d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z"
        fill="none"
        stroke="#F75C63"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path d="M7.5 4.5L4.5 7.5" stroke="#F75C63" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M4.5 4.5L7.5 7.5" stroke="#F75C63" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </g>
  ),
  viewBox: '0 0 12 12',
});

export default CircledCross;
