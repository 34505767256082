import { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { FetchResult, gql, useQuery } from '@apollo/client';
import {
  Avatar,
  Box,
  Button,
  Divider,
  ExpandedIndex,
  Flex,
  HStack,
  IconButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { capitalize, isEmpty, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  actionClassificationTypes,
  actionRootCauses,
  actionStandards,
  answerSeveritiesLabels,
  toastFailed,
  toastSuccess,
} from '../../bootstrap/config';
import { useActionContext } from '../../contexts/ActionProvider';
import { useAppContext } from '../../contexts/AppProvider';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useDataContext } from '../../contexts/DataProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useNavigate from '../../hooks/useNavigate';
import { ChevronRight, Close, EditIcon, NoteIcon, OpenExternalIcon, RefreshIcon, Trashcan } from '../../icons';
import { IAction } from '../../interfaces/IAction';
import { IAnswer } from '../../interfaces/IAnswer';
import { serializeDate } from '../../utils/helpers';
import Can, { isPermitted } from '../can';
import DocumentUpload from '../Documents/DocumentUpload';
import DocumentUploaded from '../Documents/DocumentUploaded';
import { Datepicker, Dropdown, TextInput, Toggle } from '../Forms';
import MultiSelect from '../Forms/MultiSelect';
import PeoplePicker from '../Forms/PeoplePicker';
import RichTextEditor from '../Forms/RichTextEditor';
import TextInputMultiline from '../Forms/TextInputMultiline';
import DeleteModal from '../Modals/DeleteModal';
import NoteConfirmDeleteModal from '../Modals/NoteConfirmDeleteModal';
import SaveChangesModal from '../Modals/SaveChangesModal';
import SelectionModal from '../Selection/SelectionModal';
import ActionAccordion from './ActionAccordion';
import ActionExtensionModal from './ActionExtensionModal';
import SubmitActionModal from './SubmitActionModal';

interface IActionModal {
  action?: IAction;
  finding?: Partial<IAnswer>;
  onCloseModal: () => void;
  modalType: 'add' | 'edit';
  isOpenInFindingModal?: boolean;
  handleCreatedAction?: (action: IAction) => void;
}

const GET_FORM_DATA = gql`
  query {
    businessUnits {
      _id
      name
      locationId
    }
    locations {
      _id
      name
    }
  }
`;

const ActionModal = ({ action, finding, onCloseModal, modalType, isOpenInFindingModal, handleCreatedAction }: IActionModal) => {
  const toast = useToast();
  const { user } = useAppContext();
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const { isPathActive, openInNewTab } = useNavigate();
  const { data: formData } = useQuery(GET_FORM_DATA);
  const { audit, refetchActions, refetchApplicableActions } = useAuditContext();
  const { refetchAction } = useDataContext();
  const [accordionIndex, setAccordionIndex] = useState<ExpandedIndex>([]);
  const { setSelectedAction } = useViewModalContext();
  const { createAction, updateAction } = useActionContext();
  const { isOpen: isNoteDeleteModalOpen, onOpen: onNoteDeleteModalOpen, onClose: onNoteDeleteModalClose } = useDisclosure();
  const [actionModalType, setActionModalType] = useState<'submitModal' | 'extensionModal' | 'deleteModal'>('deleteModal');
  const [addSelectedData, setAddSelectedData] = useState<Partial<IAnswer>>(
    !isEmpty(action) ? { finding: action?.answer?.finding, severity: action?.answer?.severity, audit: action?.answer?.audit } : {},
  );
  const { isOpen: isSelectionModalOpen, onClose: handleSelectionModalClose, onOpen: handleSelectionModalOpen } = useDisclosure();
  const { isOpen: isSaveChangesModalOpen, onClose: handleSaveChangesModalClose, onOpen: handleSaveChangesModalOpen } = useDisclosure();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [savingAction, setSavingAction] = useState(false);
  const [editNote, setEditNote] = useState<{ index: number; isEditNote: boolean } | null>(null);
  const [deleteNoteIndex, setDeleteNoteIndex] = useState<number | null>(null);
  const auditType = useMemo(() => {
    if (addSelectedData?.audit?.auditType?.type) return addSelectedData?.audit?.auditType;
    if (finding?.audit?.auditType?.type) return finding?.audit?.auditType;
    if (audit?.auditType?.type) return audit?.auditType;
  }, [JSON.stringify(action), JSON.stringify(finding), JSON.stringify(addSelectedData)]);
  const classificationOptions = auditType?.type ? actionClassificationTypes[auditType?.type] : [];

  const locations = formData?.locations || [];
  const businessUnits = formData?.businessUnits || [];

  const defaultValues: Partial<Omit<IAction, 'answer'>> = {
    title: '',
    groupWide: false,
    status: 'open',
    assigneeId: '',
    approverId: '',
    standard: '',
    rootCauseAnalysis: '',
    rootCause: [],
    classification: '',
    document: '',
    evidence: [],
    extent: '',
    comments: '',
    clauses: '',
    locationId: '',
    businessUnitId: '',
    notes: [],
    noteInput: '',
  };

  const { control, formState, watch, reset, setValue } = useForm({
    mode: 'all',
    defaultValues,
  });
  const values = watch() as Partial<IAction>;
  const { isValid, isDirty } = formState;

  const { append: appendAttachment, remove: removeAttachment } = useFieldArray({
    control,
    name: 'evidence',
  });

  const {
    append: appendNote,
    remove: removeNote,
    update: updateNote,
  } = useFieldArray({
    control,
    name: 'notes',
  });

  const availableBusinessUnits = useMemo(
    () => (businessUnits || []).filter(({ locationId }) => locationId === values.locationId),
    [JSON.stringify(businessUnits), values.locationId],
  );

  const handleSelectionModalReset = () => {
    handleSelectionModalOpen();
    setAddSelectedData({});
  };

  useEffect(() => {
    reset({
      classification: action?.classification,
      title: action?.title,
      groupWide: !!action?.groupWide,
      dueDate: action?.dueDate,
      status: action?.status,
      assigneeId: action?.assigneeId,
      approverId: action?.approverId,
      completedDate: action?.completedDate,
      auditClosureDate: action?.auditClosureDate,
      standard: action?.standard,
      rootCauseAnalysis: action?.rootCauseAnalysis,
      rootCause: action?.rootCause,
      document: action?.document,
      evidence: action?.evidence,
      extent: action?.extent,
      comments: action?.comments,
      originalDueDate: action?.originalDueDate,
      clauses: action?.clauses,
      locationId: action?.locationId,
      businessUnitId: action?.businessUnitId,
      notes: action?.notes,
    });
  }, [JSON.stringify(action)]);

  const hasFullEditPermission = isPermitted({ user, action: 'actions.fullEdit', data: { auditType } });

  const handleActionOperations = async () => {
    try {
      const title = values?.title?.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '') || '';
      if (modalType === 'add') {
        if (!addSelectedData._id && !finding?._id) {
          if (handleCreatedAction) handleCreatedAction({ ...(values as IAction), findingId: 'replacedDuringCreation', title });
          toast({ ...toastSuccess, description: 'Action saved' });
          onCloseModal();
          return;
        }
        const filteredValues = omit(values, ['noteInput']);
        const { data } = await createAction({
          variables: {
            action: {
              ...filteredValues,
              notes: (values.notes || []).map(({ note, submittedById, isEditedByAdmin, addedAt }) => ({
                note,
                submittedById,
                addedAt,
                isEditedByAdmin,
              })),
              title,
              findingId: isOpenInFindingModal ? finding?._id : addSelectedData._id,
            },
          },
        });
        if (handleCreatedAction) handleCreatedAction(data.createAction);
      }
      if (modalType === 'edit') {
        let updatedAction: FetchResult;
        if (!hasFullEditPermission) {
          updatedAction = await updateAction({
            variables: {
              action: {
                _id: action?._id,
                rootCause: values.rootCause,
                rootCauseAnalysis: values.rootCauseAnalysis,
                evidence: (values.evidence || []).map(({ id, name, addedAt }) => ({
                  id,
                  name,
                  addedAt,
                })),
                actionTimestamp: action?.metatags?.updatedAt
                  ? serializeDate(action?.metatags?.updatedAt)
                  : serializeDate(action?.metatags?.addedAt),
                notes: (values.notes || []).map(({ note, submittedById, isEditedByAdmin, addedAt }) => ({
                  note,
                  submittedById,
                  addedAt,
                  isEditedByAdmin,
                })),
              },
            },
          });
        } else {
          updatedAction = await updateAction({
            variables: {
              action: {
                _id: action?._id,
                classification: values.classification,
                title,
                groupWide: values.groupWide,
                dueDate: values.dueDate,
                assigneeId: values.assigneeId,
                standard: values.standard,
                rootCause: values.rootCause,
                rootCauseAnalysis: values.rootCauseAnalysis,
                document: values.document,
                clauses: values.clauses,
                evidence: (values.evidence || []).map(({ id, name, addedAt }) => ({
                  id,
                  name,
                  addedAt,
                })),
                extent: values.extent,
                comments: values.comments,
                findingId: isOpenInFindingModal ? finding?._id : addSelectedData._id,
                actionTimestamp: action?.metatags?.updatedAt
                  ? serializeDate(action?.metatags?.updatedAt)
                  : serializeDate(action?.metatags?.addedAt),
                locationId: values.locationId,
                businessUnitId: values.businessUnitId,
                notes: (values.notes || []).map(({ note, submittedById, isEditedByAdmin, addedAt }) => ({
                  note,
                  submittedById,
                  addedAt,
                  isEditedByAdmin,
                })),
              },
            },
          });
        }
        if (updatedAction.data?.updateAction && action) {
          setSelectedAction({
            ...action,
            metatags: {
              ...action.metatags,
              updatedAt: updatedAction.data.updateAction.metatags.updatedAt,
            },
          });
        }
      }
      if (isPathActive('/actions')) refetchAction();
      if (audit) {
        refetchApplicableActions({ auditId: audit?._id });
        refetchActions();
      }
      toast({ ...toastSuccess, description: 'Action saved' });
      return true;
    } catch (e: any) {
      toast({
        ...toastFailed,
        description: e.message,
      });
      return false;
    }
  };

  const handleModalOpen = (type: 'submitModal' | 'extensionModal' | 'deleteModal') => {
    if (!action) return null;
    switch (type) {
      case 'submitModal':
        return (
          <SubmitActionModal action={action} isSubmitModalOpen={isOpen} onOuterModalClose={onCloseModal} onSubmitModalClose={onClose} />
        );
      case 'extensionModal':
        return (
          <ActionExtensionModal
            action={action}
            extensionNumber={(action?.extensions?.length || 0) + 1}
            isExtendModalOpen={isOpen}
            onExtendModalClose={onClose}
            onOuterModalClose={onCloseModal}
          />
        );
      case 'deleteModal':
        return (
          <DeleteModal
            id={action?._id as string}
            isDeleteModalOpen={isOpen}
            onDeleteModalClose={onClose}
            onOuterModalClose={onCloseModal}
            title={action?.reference!}
            type="action"
          />
        );
      default:
        break;
    }
  };

  const handleModal = (type: 'submitModal' | 'extensionModal' | 'deleteModal') => {
    onOpen();
    setActionModalType(type);
  };

  const handleEditNote = (index: number) => {
    const note = (values?.notes ?? [])[index];
    setEditNote({ index, isEditNote: true });
    setValue('noteInput', note.note);
  };

  const canUpdate = action?.status !== 'closed' || hasFullEditPermission;
  return (
    <>
      {isSaveChangesModalOpen && (
        <SaveChangesModal
          isSaveChangesModalOpen={isSaveChangesModalOpen}
          onCloseOuterModal={onCloseModal}
          onSaveChange={handleActionOperations}
          onSaveChangesModalClose={handleSaveChangesModalClose}
        />
      )}

      {isOpen && handleModalOpen(actionModalType)}
      <SelectionModal
        isSelectionModalOpen={isSelectionModalOpen}
        onSelectionModalClose={handleSelectionModalClose}
        scope={isPathActive('/audits/') && audit && { _id: audit._id }}
        selectModalCategory="finding"
        setAddSelectedData={setAddSelectedData}
      />
      {isNoteDeleteModalOpen && (
        <NoteConfirmDeleteModal
          handleOnDelete={() => deleteNoteIndex !== null && removeNote(deleteNoteIndex)}
          isDeleteModalOpen={isNoteDeleteModalOpen}
          onDeleteModalClose={() => {
            onNoteDeleteModalClose();
            setDeleteNoteIndex(null);
          }}
        />
      )}
      <ModalContent bg="actionModal.bg.modalContent" h={['auto', '100vh']} m="0" overflow="hidden" p={4} rounded="0">
        <ModalHeader alignItems="center" fontSize="xxl" fontWeight="bold" p="0">
          <Flex alignItems="center" justifyContent="space-between">
            <VStack align="flex-start" spacing={1}>
              <Text alignItems="center" color="actionModal.textColor.header" fontSize={['18px', '24px']}>
                {modalType === 'add' && 'Add an action'}
                {modalType === 'edit' && action?.reference}
              </Text>
              {action?.extensions?.filter((extension) => extension.status !== 'in progress').length! > 1 && (
                <Text color="actionModal.textColor.extension" fontSize="xs" fontWeight="semi_medium">
                  This action has been extended {action?.extensions?.filter((extension) => extension.status !== 'in progress').length!}{' '}
                  times
                </Text>
              )}
            </VStack>
            <Close
              cursor="pointer"
              h="15px"
              onClick={async () => {
                if (modalType === 'edit' && canUpdate) {
                  if (isDirty) handleSaveChangesModalOpen();
                  else onCloseModal();
                } else onCloseModal();
              }}
              stroke="actionModal.icon.close"
              w="15px"
            />
          </Flex>
        </ModalHeader>
        <ModalBody h={`calc(100% - ${canUpdate ? 7 : 1}rem)`} px="0" py="20px">
          <Flex direction="column" h="full" overflow="hidden">
            <Can
              action="actions.approveOrReject"
              data={{
                auditType: action?.answer?.audit?.auditType,
              }}
              yes={() => (
                <>
                  {action?.status === 'under review' && (
                    <ActionAccordion accordionOpen={setAccordionIndex} action={action} onCloseModal={onCloseModal} type="submit" />
                  )}
                  {action?.extensions?.some((extension) => extension.isActive) && (
                    <ActionAccordion accordionOpen={setAccordionIndex} action={action} onCloseModal={onCloseModal} type="extend" />
                  )}
                </>
              )}
            />
            <Flex
              bg="actionModal.bg.modalBody"
              borderRadius="11px"
              direction="column"
              height={
                action?.status !== 'under review' ||
                  action?.extensions?.some((extension) => !extension.isActive) ||
                  !isPermitted({ user, action: 'actions.approveOrReject', data: { auditType: action?.answer?.audit?.auditType } })
                  ? '100%'
                  : isEmpty(accordionIndex)
                    ? 'calc(100vh - 217px)'
                    : 'calc(100vh - 309px)'
              }
              overflowX="hidden"
              overflowY="auto"
              p={15}
            >
              <Stack pb={1} pr={2} spacing={2} width="calc(100% + 10px)">
                {!isEmpty(action) && (
                  <HStack justifyContent="space-between" w="80%">
                    <Box lineHeight="22px">
                      <Text color="actionModal.textColor.field" fontSize="ssm" fontWeight="bold">
                        Date created
                      </Text>
                      <Text color="actionModal.textColor.secondaryColor" fontSize="smm" fontWeight="semi_medium">
                        {format(new Date(action?.metatags?.addedAt || 0), 'd MMMM yyyy')}
                      </Text>
                    </Box>
                    <Box lineHeight="22px">
                      <Text color="actionModal.textColor.field" fontSize="ssm" fontWeight="bold">
                        Created by
                      </Text>
                      <HStack>
                        <Avatar name={action?.creator?.displayName} size="xs" src={action?.creator?.imgUrl} />
                        <Text color="actionModal.textColor.secondaryColor" fontSize="smm" fontWeight="semi_medium">
                          {action?.creator?.displayName}
                        </Text>
                      </HStack>
                    </Box>
                  </HStack>
                )}
                {!isOpenInFindingModal && (
                  <>
                    <Text color="actionModal.textColor.header" fontSize="smm" fontWeight="bold">
                      Applicable Finding
                    </Text>
                    {isEmpty(addSelectedData) ? (
                      <HStack>
                        <Button
                          _hover={{ bg: 'actionModal.hover.addButton' }}
                          bg="actionModal.bg.actionButton"
                          color="actionModal.textColor.actionButton"
                          fontSize="ssm"
                          fontWeight="bold"
                          h="28px"
                          onClick={handleSelectionModalOpen}
                          px={4}
                          rounded="10px"
                        >
                          Select a finding
                        </Button>
                      </HStack>
                    ) : (
                      <HStack
                        alignItems="center"
                        bg="actionModal.bg.audit"
                        boxShadow="simple"
                        justify="space-between"
                        ml="1px"
                        px={6}
                        py={4}
                        rounded="10px"
                        spacing={2}
                      >
                        <Stack justifyContent="space-between" overflow="hidden" spacing={0}>
                          <Stack
                            _hover={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            alignItems="center"
                            direction="row"
                            onClick={() => openInNewTab(`/audits/${action?.answer?.scope?._id}?findingId=${action?.answer?._id}`)}
                            spacing={2}
                          >
                            <Text color="actionModal.textColor.audit" fontSize="smm">
                              {(addSelectedData as IAnswer)?.finding}
                            </Text>
                            <OpenExternalIcon fill="transparent" h="13px" stroke="actionModal.icon.openExternal" w="12px" />
                          </Stack>
                          <Text color="actionModal.textColor.audit" fontSize="ssm" opacity="0.4">
                            {(addSelectedData as IAnswer)?.severity && answerSeveritiesLabels[(addSelectedData as IAnswer)?.severity]}
                          </Text>
                        </Stack>
                        <Can
                          action="actions.fullEdit"
                          data={{ auditType }}
                          yes={() => (
                            <IconButton
                              _active={{ bg: 'none' }}
                              _hover={{ bg: 'none' }}
                              aria-label="Change finding"
                              icon={<RefreshIcon stroke="actionModal.icon.refresh" />}
                              onClick={handleSelectionModalReset}
                              variant="ghost"
                            />
                          )}
                        />
                      </HStack>
                    )}
                  </>
                )}
                <Dropdown
                  color="actionModal.textColor.field"
                  control={control}
                  disabled={!hasFullEditPermission || classificationOptions.length === 0}
                  label="Action classification"
                  name="classification"
                  options={classificationOptions.map((value) => ({ value, label: capitalize(value) }))}
                  placeholder="Select action classification"
                  stroke="dropdown.icon"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
                <Box pt="10px">
                  <Text color="findingModal.textColor.field" fontSize="ssm" fontWeight="bold">
                    Action description
                  </Text>
                  <RichTextEditor control={control} name="title" readMode={!hasFullEditPermission || action?.status === 'closed'} />
                </Box>
                <Dropdown
                  color="actionModal.textColor.field"
                  control={control}
                  disabled={!hasFullEditPermission}
                  label="Business area"
                  name="locationId"
                  options={(locations ?? []).map(({ _id, name }) => ({ value: _id, label: name }))}
                  placeholder="Select business area"
                  stroke="dropdown.icon"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
                <Dropdown
                  color="actionModal.textColor.field"
                  control={control}
                  disabled={!values.locationId || !hasFullEditPermission}
                  label="Business group"
                  name="businessUnitId"
                  options={availableBusinessUnits.map(({ _id, name }) => ({ value: _id, label: name }))}
                  placeholder="Select business group"
                  stroke="dropdown.icon"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
                <Toggle
                  control={control}
                  disabled={!hasFullEditPermission}
                  label="Group wide"
                  name="groupWide"
                  placeholder="Yes"
                  variant="secondaryVariant"
                />
                {modalType === 'edit' && (
                  <Datepicker
                    color="actionModal.textColor.field"
                    control={control}
                    disabled
                    label="Original Due Date"
                    name="originalDueDate"
                    placeholder="Original due date"
                    validations={{
                      notEmpty: true,
                    }}
                    variant="secondaryVariant"
                  />
                )}
                <Datepicker
                  color="actionModal.textColor.field"
                  control={control}
                  disabled={!hasFullEditPermission}
                  label="Due date"
                  name="dueDate"
                  placeholder="Due date"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
                {modalType === 'edit' && (
                  <Dropdown
                    color="actionModal.textColor.field"
                    control={control}
                    defaultvalue="open"
                    disabled
                    label="Action status"
                    name="status"
                    options={[
                      { value: 'under review', label: 'Under review' },
                      { value: 'open', label: 'Open' },
                      { value: 'closed', label: 'Closed' },
                      { value: 'overdue', label: 'Overdue' },
                    ]}
                    placeholder="Select action status"
                    stroke="dropdown.icon"
                    validations={{
                      notEmpty: true,
                    }}
                    variant="secondaryVariant"
                  />
                )}
                <PeoplePicker
                  control={control}
                  disabled={!hasFullEditPermission}
                  label="Assign to"
                  name="assigneeId"
                  placeholder="Select assignee"
                  validations={{
                    notEmpty: true,
                  }}
                />
                {modalType === 'edit' && action?.status === 'closed' && (
                  <>
                    <PeoplePicker control={control} disabled label="Risk and IA Team verification" name="approverId" />
                    <Datepicker
                      color="actionModal.textColor.field"
                      control={control}
                      disabled
                      label="Verification date"
                      name="completedDate"
                      placeholder="Verification date"
                      variant="secondaryVariant"
                    />
                    <Datepicker
                      color="actionModal.textColor.field"
                      control={control}
                      disabled={!hasFullEditPermission}
                      label="Audit closure date"
                      name="auditClosureDate"
                      placeholder="Audit closure date"
                      variant="secondaryVariant"
                    />
                  </>
                )}
                <Dropdown
                  color="actionModal.textColor.field"
                  control={control}
                  disabled={!hasFullEditPermission}
                  label="Standard"
                  name="standard"
                  options={actionStandards.map((value) => ({ value, label: value }))}
                  placeholder="Select standard"
                  stroke="dropdown.icon"
                  tooltip="The standard against which the action has occurred."
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
                <TextInput
                  control={control}
                  disabled={!hasFullEditPermission}
                  label="Clause(s)"
                  name="clauses"
                  placeholder="Clause(s)"
                  tooltip="The specific clause or clauses that the action has occurred."
                />
                <TextInput
                  color="actionModal.textColor.field"
                  control={control}
                  disabled={!hasFullEditPermission}
                  label="Document"
                  name="document"
                  placeholder="Document"
                  tooltip="The specific document or documents that the action relates to the standard."
                  variant="secondaryVariant"
                />

                <Flex direction="column" pt={2} w="full">
                  <Flex alignItems="center" justifyContent="space-between" mb={2}>
                    <Text color="auditModal.textColor.field" fontSize="ssm" fontWeight="bold">
                      Evidence
                    </Text>
                    <Text color="auditModal.textColor.optional" fontSize="xxs" fontWeight="semi_medium">
                      Optional
                    </Text>
                  </Flex>
                  {(values.evidence ?? []).length > 0
                    ? values.evidence?.map((attachment, i) => (
                      <Flex flexDir="column" key={i} mb={2}>
                        <DocumentUploaded callback={async () => removeAttachment(i)} document={attachment} downloadable />
                      </Flex>
                    ))
                    : action?.status === 'closed' && (
                      <Text fontSize="sm" fontStyle="italic">
                        No evidence uploaded
                      </Text>
                    )}
                  {(isEmpty(action) ||
                    action?.status === 'open' ||
                    action?.status === 'overdue' ||
                    (action?.status === 'under review' && hasFullEditPermission)) && (
                      <DocumentUpload
                        background="auditModal.bg.documentUpload"
                        callback={async (uploaded) => appendAttachment(uploaded)}
                        elementId={`temp-${uuidv4()}`}
                      />
                    )}
                </Flex>
                <MultiSelect
                  control={control}
                  disabled={action?.status === 'closed' || (action?.status === 'under review' && !hasFullEditPermission)}
                  label="Root cause"
                  name="rootCause"
                  options={actionRootCauses.map((value) => ({ value, label: capitalize(value) }))}
                  placeholder="Root cause"
                  tooltip="Optional when creating an action but required before submitting an action"
                  variant="secondaryVariant"
                />
                <TextInputMultiline
                  control={control}
                  disabled={action?.status === 'closed' || (action?.status === 'under review' && !hasFullEditPermission)}
                  label="Root cause analysis"
                  name="rootCauseAnalysis"
                  placeholder="Root cause analysis"
                  tooltip="Optional when creating an action but required with at least 20 characters before submitting an action"
                />
                {hasFullEditPermission && <TextInputMultiline control={control} label="Comment" name="comments" placeholder="Comment" />}
                <Flex direction="column" gap={3} pt={2} w="full">
                  <Flex alignItems="center" justifyContent="space-between" mb={2}>
                    <Text color="auditModal.textColor.field" fontSize="ssm" fontWeight="bold">
                      Notes
                    </Text>
                    <Text color="auditModal.textColor.optional" fontSize="xxs" fontWeight="semi_medium">
                      Optional
                    </Text>
                  </Flex>
                  {(values?.notes ?? []).length > 0 &&
                    values?.notes?.map((note, i) => (
                      <>
                        {editNote?.isEditNote && editNote.index === i ? (
                          <>
                            <TextInputMultiline control={control} name="noteInput" placeholder="Note" />
                            <Flex justifyContent="flex-end" my="2">
                              <HStack spacing={2}>
                                <Button
                                  _active={{ bg: 'none' }}
                                  _hover={{ bg: 'transparent' }}
                                  bg="transparent"
                                  color="actionModal.textColor.delete"
                                  cursor="pointer"
                                  fontSize="ssm"
                                  fontWeight="bold"
                                  onClick={() => {
                                    setEditNote(null);
                                    setValue('noteInput', '');
                                  }}
                                >
                                  Cancel edit
                                </Button>
                                <Button
                                  _hover={{ bg: 'actionModal.hover.addButton' }}
                                  bg="actionModal.bg.addButton"
                                  color="actionModal.textColor.addButton"
                                  disabled={!values?.noteInput}
                                  fontSize="xs"
                                  fontWeight="medium"
                                  height="7"
                                  onClick={() => {
                                    const isEditByAdmin = user && user?.role === 'admin' && user?._id !== note?.submittedById;
                                    updateNote(editNote.index, {
                                      submittedById: isEditByAdmin ? note.submittedById : (user?._id as string),
                                      submittedBy: isEditByAdmin ? note.submittedBy : (user?.displayName as string),
                                      note: values.noteInput ?? '',
                                      addedAt: isEditByAdmin ? note.addedAt : new Date(),
                                      isEditedByAdmin: isEditByAdmin as boolean,
                                    });
                                    setEditNote(null);
                                    setValue('noteInput', '');
                                  }}
                                  px="15px"
                                  rounded="5px"
                                >
                                  Save note
                                  <NoteIcon color="actionModal.textColor.addButton" ml="5px" />
                                </Button>
                              </HStack>
                            </Flex>
                          </>
                        ) : (
                          <>
                            <HStack alignItems="flex-start" key={`notes-${i}`} spacing={1}>
                              <Flex flexDir="column" mb={2} width="90%">
                                <Text color="actionModal.textColor.notesHeader" fontSize="ssm" fontWeight="medium">
                                  <Text as="span" fontWeight="bold">
                                    {note?.submittedBy},
                                  </Text>{' '}
                                  {format(new Date(note?.addedAt), 'dd/MM/yyyy HH:mm')}
                                  {note.isEditedByAdmin && ' (edited by Administrator)'}
                                </Text>
                                <Text color="actionModal.textColor.secondaryColor" fontSize="smm" fontWeight="semi_medium">
                                  {note?.note}
                                </Text>
                              </Flex>
                              <Can
                                action="actions.editOrDeleteNote"
                                data={{ note }}
                                yes={() => canUpdate ? (
                                  <HStack spacing={2} width="10%">
                                    <EditIcon cursor="pointer" onClick={() => handleEditNote(i)} stroke="actionModal.icon.edit" />
                                    <Trashcan
                                      cursor="pointer"
                                      onClick={() => {
                                        setDeleteNoteIndex(i);
                                        onNoteDeleteModalOpen();
                                      }}
                                      stroke="actionModal.icon.trash"
                                    />
                                  </HStack>
                                ) : true}
                              />
                            </HStack>
                            {!((values?.notes ?? []).length === i + 1) && <Divider color="actionModal.textColor.notesDivider" my="10px" />}
                          </>
                        )}
                      </>
                    ))}
                  {!editNote?.isEditNote && canUpdate && (
                    <>
                      {(values?.notes?.length ?? 0) > 0 && <Divider color="actionModal.textColor.notesDivider" my="10px" />}

                      <TextInputMultiline control={control} name="noteInput" placeholder="Note" />
                      {user?.role !== 'reader' && (
                        <Flex justifyContent="flex-end" mt="2">
                          <Button
                            _hover={{ bg: 'actionModal.hover.addButton' }}
                            bg="actionModal.bg.addButton"
                            color="actionModal.textColor.addButton"
                            disabled={!values?.noteInput}
                            fontSize="xs"
                            fontWeight="medium"
                            height="7"
                            onClick={() => {
                              appendNote({
                                submittedById: user?._id as string,
                                submittedBy: user?.displayName,
                                note: values.noteInput ?? '',
                                addedAt: new Date(),
                                isEditedByAdmin: false,
                              });
                              setValue('noteInput', '');
                              setTimeout(() => {
                                if (scrollDivRef) scrollDivRef?.current?.scrollIntoView({ behavior: 'smooth' });
                              }, 50);
                            }}
                            px="15px"
                            rounded="5px"
                          >
                            Add note
                            <NoteIcon color="actionModal.textColor.addButton" ml="5px" />
                          </Button>
                        </Flex>
                      )}
                      <Box ref={scrollDivRef} />
                    </>
                  )}
                </Flex>
              </Stack>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter h="50px" p="0">
          <Flex alignItems="center" justifyContent="space-between" w="full">
            {modalType === 'edit' && (
              <Can
                action="actions.delete"
                data={{ auditType }}
                no={() => <Box />}
                yes={() => (
                  <Button
                    _active={{ bg: 'none' }}
                    _hover={{ bg: 'transparent' }}
                    bg="transparent"
                    color="actionModal.textColor.delete"
                    cursor="pointer"
                    fontSize="ssm"
                    fontWeight="bold"
                    onClick={() => handleModal('deleteModal')}
                  >
                    Delete action
                  </Button>
                )}
              />
            )}
            {modalType !== 'edit' && <Box />}
            <Flex alignItems="center">
              {['open', 'overdue'].includes(action?.status || '') &&
                !action?.extensions?.some((extension) => extension.isActive) &&
                !hasFullEditPermission && (
                  <Button
                    _active={{ bg: 'none' }}
                    _hover={{ bg: 'none' }}
                    bg="none"
                    color="actionModal.textColor.field"
                    cursor="pointer"
                    fontSize="ssm"
                    fontWeight="bold"
                    onClick={() => handleModal('extensionModal')}
                    pl={0}
                  >
                    Request extension
                  </Button>
                )}
              {['open', 'overdue'].includes(action?.status || '') && (
                <Button
                  _active={{ bg: 'none' }}
                  _hover={{ bg: 'none' }}
                  bg="none"
                  color="actionModal.textColor.field"
                  cursor="pointer"
                  fontSize="ssm"
                  fontWeight="bold"
                  onClick={async () => {
                    if (!values.rootCause) {
                      toast({ ...toastFailed, title: 'Root cause is required' });
                      return;
                    }
                    if (!values.rootCauseAnalysis || !(values.rootCauseAnalysis.length >= 20)) {
                      toast({ ...toastFailed, title: 'Root cause analysis must have minimum 20 characters' });
                      return;
                    }
                    if (isDirty) {
                      // Save action before submission
                      const updated = await handleActionOperations();
                      if (updated) {
                        reset(values);
                        refetchAction();
                        handleModal('submitModal');
                      }
                    } else handleModal('submitModal');
                  }}
                  pl={0}
                >
                  Submit action
                </Button>
              )}
              {canUpdate && (
                <Button
                  _hover={{ bg: 'actionModal.hover.addButton' }}
                  bg="actionModal.bg.addButton"
                  color="actionModal.textColor.addButton"
                  disabled={(isOpenInFindingModal ? !isOpenInFindingModal : isEmpty(addSelectedData)) || !isValid || savingAction}
                  fontSize="smm"
                  fontWeight="bold"
                  isLoading={savingAction}
                  onClick={async () => {
                    setSavingAction(true);
                    const updated = await handleActionOperations();
                    setSavingAction(false);
                    if (updated) onCloseModal();
                  }}
                  p="10px 20px"
                  rounded="10px"
                >
                  {`${modalType === 'edit' ? 'Update' : 'Add'} action`}
                  <ChevronRight ml="5px" />
                </Button>
              )}
            </Flex>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </>
  );
};

export default ActionModal;

export const actionModalStyles = {
  actionModal: {
    bg: {
      modalBody: '#F0F2F5',
      modalContent: '#ffffff',
      documentUpload: '#ffffff',
      addButton: '#DC0043',
      actionButton: '#DC0043',
      audit: '#ffffff',
      rejectButton: '#E93C44',
      approveButton: '#41B916',
    },
    textColor: {
      header: '#1E1836',
      field: '#1E1836',
      optional: '#818197',
      addButton: '#FFFFFF',
      actionButton: '#FFFFFF',
      audit: '#1E1836',
      secondaryColor: '#282F36',
      delete: '#787486',
      extension: '#787486',
      white: '#FFFFFF',
      notesHeader: '#787486',
      notesDivider: '#D2D1D7',
    },
    hover: {
      addButton: '#DC0043',
      actionButton: '#DC0043',
    },
    icon: {
      close: '#1E1836',
      edit: '#282F36',
      trash: '#282F36',
      openExternal: '#1E1836',
      refresh: '#282F36',
    },
    status: {
      open: '#3C6CE9',
      extend: '#FF9A00',
    },
  },
};
