import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Flex, HStack, Stack, Text, Tooltip, useToast } from '@chakra-ui/react';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import AdminModal from '../../components/Admin/AdminModal';
import AdminTableHeader from '../../components/Admin/AdminTableHeader';
import AdminTableHeaderElement from '../../components/Admin/AdminTableHeaderElement';
import { Dropdown } from '../../components/Forms';
import TextInput from '../../components/Forms/TextInput';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import MultipleParticipantsSelector from '../../components/Participants/MultipleParticipantsSelector';
import UserAvatar from '../../components/UserAvatar';
import { useAdminContext } from '../../contexts/AdminProvider';
import { useFiltersContext } from '../../contexts/FiltersProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useDevice from '../../hooks/useDevice';
import useNavigate from '../../hooks/useNavigate';
import { ArrowCount, DeleteIcon, EditIcon } from '../../icons';
import { IBusinessUnit } from '../../interfaces/IBusinessUnit';

const GET_LOCATIONS = gql`
  query {
    locations {
      _id
      name
    }
  }
`;
const GET_BUSINESS_UNITS = gql`
  query {
    businessUnits {
      _id
      name
      locationId
      location {
        name
      }
      ownersIds
      owners {
        _id
        displayName
        imgUrl
      }
      totalAuditsCount
    }
  }
`;
const CREATE_BUSINESS_UNIT = gql`
  mutation ($values: BusinessUnitInput!) {
    createBusinessUnit(businessUnitInput: $values) {
      _id
    }
  }
`;
const UPDATE_BUSINESS_UNIT = gql`
  mutation ($values: BusinessUnitModifyInput!) {
    updateBusinessUnit(businessUnitModifyInput: $values) {
      _id
    }
  }
`;
const DELETE_BUSINESS_UNIT = gql`
  mutation ($_id: String!) {
    deleteBusinessUnit(_id: $_id)
  }
`;

const defaultValues: Partial<IBusinessUnit> = {
  _id: undefined,
  name: '',
  locationId: '',
  owners: [],
};

const BusinessUnits = () => {
  const toast = useToast();
  const { closeModal } = useViewModalContext();
  const { adminModalState, setAdminModalState } = useAdminContext();
  const { setAuditFiltersValue } = useFiltersContext();
  const { data: locationsData } = useQuery(GET_LOCATIONS);
  const { data, loading, refetch } = useQuery(GET_BUSINESS_UNITS);
  const [createFunction] = useMutation(CREATE_BUSINESS_UNIT);
  const [updateFunction] = useMutation(UPDATE_BUSINESS_UNIT);
  const [deleteFunction] = useMutation(DELETE_BUSINESS_UNIT);
  const device = useDevice();
  const { navigateTo } = useNavigate();
  const [sortType, setSortType] = useState('name');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [currentBusinessUnitName, setCurrentBusinessUnitName] = useState<string>('');

  const getBusinessUnits = (businessUnitsArray: IBusinessUnit[]) => {
    if (!businessUnitsArray) return [];

    return [...businessUnitsArray].sort((a, b) => a.name.localeCompare(b.name));
  };
  const [businessUnits, setBusinessUnits] = useState<IBusinessUnit[]>(getBusinessUnits(data?.businessUnits));

  useEffect(() => {
    setBusinessUnits(getBusinessUnits(data?.businessUnits));
  }, [data]);

  useEffect(() => {
    const sort = (a, b) => {
      if (sortType === 'location') return (a?.location?.name || '').localeCompare(b?.location?.name || '');
      if (sortType === 'totalAuditsCount') return (a.totalAuditsCount || 0) > (b.totalAuditsCount || 0) ? 1 : -1;
      return (a[sortType] || 0).toString().localeCompare((b[sortType] || 0).toString());
    };
    if (sortOrder === 'asc') setBusinessUnits([...businessUnits].sort((a, b) => sort(a, b)));
    else setBusinessUnits([...businessUnits].sort((a, b) => sort(b, a)));
  }, [sortType, sortOrder]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
    trigger,
    reset,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: { ...defaultValues, locationId: locationsData?.locations?.[0]?._id },
  });
  const formValues = watch();

  // Reset the form after closing
  useEffect(() => {
    if (adminModalState === 'closed') {
      reset(defaultValues);
      setCurrentBusinessUnitName('');
      closeModal();
    }
  }, [reset, adminModalState]);

  // If modal opened in edit or delete mode, reset the form and set values of edited element
  const openBusinessUnitModal = (action: 'edit' | 'delete', businessUnit: IBusinessUnit) => {
    setAdminModalState(action);
    setCurrentBusinessUnitName(businessUnit?.name);
    reset({
      _id: businessUnit?._id,
      name: businessUnit?.name,
      locationId: businessUnit?.locationId || locationsData?.locations?.[0]?._id,
      owners: businessUnit?.owners,
    });
  };

  const handleAddBusinessUnit = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        await createFunction({
          variables: {
            values: {
              _id: values._id,
              name: values.name,
              locationId: values.locationId,
              ownersIds: (values.owners || []).map(({ _id }) => _id),
            },
          },
        });
        refetch();
        toast({ ...toastSuccess, description: `${capitalize(t('business unit'))} added` });
      } else {
        toast({
          ...toastFailed,
          description: 'Please complete all the required fields',
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setAdminModalState('closed');
    }
  };

  const handleUpdateBusinessUnit = async () => {
    try {
      if (Object.keys(errors).length === 0) {
        const values = getValues();
        await updateFunction({
          variables: {
            values: {
              _id: values._id,
              name: values.name,
              locationId: values.locationId,
              ownersIds: (values.owners || []).map(({ _id }) => _id),
            },
          },
        });
        refetch();
        toast({ ...toastSuccess, description: `${capitalize(t('business unit'))} updated` });
      } else {
        toast({
          ...toastFailed,
          description: 'Please complete all the required fields',
        });
      }
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setAdminModalState('closed');
    }
  };

  const handleDeleteBusinessUnit = async () => {
    try {
      const { _id } = getValues();
      await deleteFunction({ variables: { _id } });
      refetch();
      toast({ ...toastSuccess, description: `${capitalize(t('business unit'))} deleted` });
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    } finally {
      setAdminModalState('closed');
    }
  };

  const handleAction = async (action) => {
    const isFormValid = await trigger();
    const owners = getValues('owners') || [];
    if (['add', 'edit'].includes(action) && (!isFormValid || owners.length === 0)) {
      return toast({
        ...toastFailed,
        description: 'Please complete all the required fields',
      });
    }
    switch (action) {
      case 'add':
        handleAddBusinessUnit();
        break;
      case 'edit':
        handleUpdateBusinessUnit();
        break;
      case 'delete':
        handleDeleteBusinessUnit();
        break;
      default:
        setAdminModalState('closed');
    }
  };

  const renderBusinessUnitRow = (businessUnit: IBusinessUnit, i: number) => (
    <Flex
      alignItems="center"
      bg="#FFFFFF"
      borderBottomRadius={i === businessUnits.length - 1 ? 'lg' : ''}
      boxShadow="sm"
      flexShrink={0}
      h="73px"
      key={businessUnit._id}
      mb="1px"
      p={4}
      w="full"
    >
      <Flex cursor="pointer" flexDir="column" mr={4} onClick={() => openBusinessUnitModal('edit', businessUnit)} pl={1} w={['80%', '40%']}>
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {businessUnit.name}
        </Text>
      </Flex>
      {device !== 'mobile' && (
        <>
          <Flex w="20%">
            <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {businessUnit.location?.name}
            </Text>
          </Flex>
          <HStack w="20%">
            {businessUnit?.owners ? (
              businessUnit.owners.length === 1 ? (
                <UserAvatar h={8} user={businessUnit.owners[0]} w={8} withJobTitle />
              ) : (
                businessUnit.owners.map((owner) => <UserAvatar h={8} key={owner._id} user={owner} w={8} withTooltip />)
              )
            ) : (
              <Text>-</Text>
            )}
          </HStack>
        </>
      )}
      <Flex align="center" w="10%">
        <Text>{businessUnit.totalAuditsCount}</Text>
        <Tooltip fontSize="sm" label="Show audits">
          <ArrowCount
            cursor="pointer"
            h="10px"
            ml={1}
            onClick={() => {
              setAuditFiltersValue({ businessUnitsIds: { value: [businessUnit._id] } });
              navigateTo('/audits');
            }}
            stroke="businessUnit.iconColor"
            w="10px"
          />
        </Tooltip>
      </Flex>
      <HStack align="center" justify="flex-end" pl={1} w="10%">
        <Tooltip fontSize="sm" label="Edit business group">
          <EditIcon
            cursor="pointer"
            h="16px"
            ml={1}
            onClick={() => openBusinessUnitModal('edit', businessUnit)}
            stroke="businessUnit.iconColor"
            w="16px"
          />
        </Tooltip>
        <Tooltip fontSize="sm" label="Delete business group">
          <DeleteIcon
            cursor="pointer"
            h="16px"
            onClick={() => openBusinessUnitModal('delete', businessUnit)}
            stroke="businessUnit.iconColor"
            w="16px"
          />
        </Tooltip>
      </HStack>
    </Flex>
  );
  return (
    <>
      <AdminModal
        collection={t('business unit')}
        isOpenModal={adminModalState !== 'closed'}
        modalType={adminModalState}
        onAction={handleAction}
      >
        <Stack spacing={2}>
          <TextInput
            control={control}
            initialValue={currentBusinessUnitName.toLowerCase()}
            label="Business group title"
            name="name"
            placeholder="Business group title"
            validations={{
              notEmpty: true,
              uniqueValue: businessUnits.map(({ name }) => name.toLowerCase()),
            }}
          />
          <Dropdown
            control={control}
            label="Business area"
            name="locationId"
            options={((locationsData || {}).locations || []).map((location) => ({ label: location.name, value: location._id }))}
            variant="secondaryVariant"
          />
          <Box pt={3}>
            <MultipleParticipantsSelector
              isMandatory={false}
              isUserAllowedToChange
              label="Owners"
              maxParticipants={20}
              onChange={(owners) => setValue('owners', owners)}
              selectedParticipants={formValues.owners || []}
            />
          </Box>
        </Stack>
      </AdminModal>
      <Header
        breadcrumbs={['Admin', pluralize(capitalize(t('business unit')))]}
        category={t('business unit')}
        mobileBreadcrumbs={[pluralize(capitalize(t('business unit')))]}
      />
      <Flex h="calc(100vh - 160px)" overflow="auto" px={['25px', 0]}>
        <Box h={['calc(100% - 160px)', 'calc(100% - 35px)']} p={[0, '0 25px 30px 30px']} w="full">
          <AdminTableHeader>
            <AdminTableHeaderElement
              label={`${capitalize(t('business unit'))} title`}
              onClick={() => {
                setSortType('name');
                setSortOrder(sortOrder === 'asc' && sortType === 'name' ? 'desc' : 'asc');
              }}
              showSortingIcon={sortType === 'name'}
              sortOrder={sortType === 'name' ? sortOrder : undefined}
              w={['80%', '40%']}
            />
            {device !== 'mobile' && (
              <>
                <AdminTableHeaderElement
                  label="Business area"
                  onClick={() => {
                    setSortType('location');
                    setSortOrder(sortOrder === 'asc' && sortType === 'location' ? 'desc' : 'asc');
                  }}
                  showSortingIcon={sortType === 'location'}
                  sortOrder={sortType === 'location' ? sortOrder : undefined}
                  w="20%"
                />
                <AdminTableHeaderElement label="Owner" w="20%" />
              </>
            )}
            <AdminTableHeaderElement
              label={`${capitalize(pluralize(t('audit')))} number`}
              onClick={() => {
                setSortType('totalAuditsCount');
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
              }}
              showSortingIcon={sortType === 'totalAuditsCount'}
              sortOrder={sortType === 'totalAuditsCount' && sortType === 'totalAuditsCount' ? sortOrder : undefined}
              w="10%"
            />
            <AdminTableHeaderElement w="10%" />
          </AdminTableHeader>
          <Flex bg="white" borderBottomRadius="20px" flexDir="column" fontSize="smm" h="full" overflow="auto" w="full">
            {loading ? (
              <Loader center />
            ) : businessUnits?.length > 0 ? (
              businessUnits?.map(renderBusinessUnitRow)
            ) : (
              <Flex fontSize="18px" fontStyle="italic" h="full" justify="center" mt={4} w="full">
                No {pluralize(t('business unit'))} found
              </Flex>
            )}
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default BusinessUnits;

export const businessUnitsStyles = {
  businessUnit: {
    binIconColor: '#FC5960',
    fontColor: '#818197',
    iconColor: '#282F36',
  },
};
