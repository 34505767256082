import { createIcon } from '@chakra-ui/icons';

const AttachmentIcon = createIcon({
  viewBox: '0 0 11 18',
  displayName: 'Attachment Icon',
  path: (
    <g>
      <path
        d="M10.1 11.666V5.8C10.1 4.52696 9.59429 3.30606 8.69411 2.40589C7.79394 1.50571 6.57304 1 5.3 1C4.02696 1 2.80606 1.50571 1.90589 2.40589C1.00571 3.30606 0.5 4.52696 0.5 5.8V13.8C0.5 14.6487 0.837142 15.4626 1.43726 16.0627C2.03737 16.6629 2.85131 17 3.7 17C4.54869 17 5.36262 16.6629 5.96274 16.0627C6.56286 15.4626 6.9 14.6487 6.9 13.8V5.8C6.9 5.37565 6.73143 4.96869 6.43137 4.66863C6.13131 4.36857 5.72435 4.2 5.3 4.2C4.87565 4.2 4.46869 4.36857 4.16863 4.66863C3.86857 4.96869 3.7 5.37565 3.7 5.8V12.7333"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  ),
});

export default AttachmentIcon;
