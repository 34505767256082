import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';

import { toastSuccess } from '../../bootstrap/config';

const FindingDeleteModal = ({
  message,
  isOpen,
  handleClose,
  handleDelete,
}: {
  message: string;
  isOpen: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}) => {
  const toast = useToast();
  return (
    <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete confirmation</ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign="center">{message}</ModalBody>
        <ModalFooter>
          <Flex justify="end" w="full">
            <Button _hover={{ opacity: 0.7 }} bg="#F0F2F5" color="#787486" mr={3} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button
              _hover={{ opacity: 0.7 }}
              bg="#DC0043"
              color="#FFFFFF"
              onClick={() => {
                handleDelete();
                toast({ ...toastSuccess, description: 'Finding deleted' });
              }}
            >
              Delete
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FindingDeleteModal;
