import { createIcon } from '@chakra-ui/icons';

const ListIcon = createIcon({
  path: (
    <g>
      <path d="M6 1H20" fill="transparent" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 1H3" fill="transparent" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 5.87158H20" fill="transparent" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 5.87158H3" fill="transparent" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 10.7432H20" fill="transparent" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 10.7432H3" fill="transparent" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
  viewBox: '0 0 21 10',
});

export default ListIcon;
