import { useMemo } from 'react';

import { CheckboxGroup, Stack } from '@chakra-ui/react';

import { useFiltersContext } from '../../contexts/FiltersProvider';
import FilterCheckBox from './FilterCheckBox';

const AuditTypeFilter = () => {
  const { filtersValues, setFilters, auditTypes } = useFiltersContext();
  const value = useMemo(() => filtersValues.auditTypesIds?.value, [filtersValues]) as string[];

  return (
    <CheckboxGroup onChange={(newValue) => setFilters({ auditTypesIds: newValue })} value={value}>
      <Stack direction="column" overflow="auto">
        {auditTypes?.map(({ name, _id }) => (
          <FilterCheckBox key={_id} label={name} value={_id} />
        ))}
      </Stack>
    </CheckboxGroup>
  );
};

export default AuditTypeFilter;
