import { useEffect } from 'react';

import { Flex } from '@chakra-ui/react';

import { useFiltersContext } from '../../contexts/FiltersProvider';
import ActionsInsights from './actions';

const Insights = () => {
  const { setUsedFilters, setShowFiltersPanel } = useFiltersContext();

  useEffect(() => {
    setUsedFilters(['walkType', 'status', 'locationsIds', 'businessUnitsIds', 'usersIds']);
    return () => {
      setShowFiltersPanel(false);
      setUsedFilters([]);
    };
  }, []);

  return (
    <>
      <Flex direction="column" pt="3" px={[4, 8]}>
        <ActionsInsights />
      </Flex>
    </>
  );
};

export default Insights;

export const insightsStyles = {
  insights: {
    header: {
      menuButtonBg: 'white',
      rightIcon: '#9A9EA1',
      menuItemFocus: '#462AC4',
      menuItemFontSelected: '#462AC4',
      menuItemFont: '#9A9EA1',
      exportButtonColor: '#1E1836',
    },
    tabBg: '#1E1836',
    tabColor: '#FFFFFF',
    secondaryText: '#787486',
  },
};
