import { RepeatClockIcon } from '@chakra-ui/icons';
import { t } from 'i18next';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';

import {
  Admin,
  AuditIcon,
  CheckIcon,
  EditIcon,
  Eye,
  Home,
  LocationIcon,
  MenuIcon,
  SiteIcon,
  TeamsIcon,
  TickIcon,
} from '../icons';
import IFilters from '../interfaces/IFilters';
import { INavItem } from '../interfaces/INavItem';
import { ISearchCategory } from '../interfaces/ISearchCategory';

const useConfig = () => {
  const auditSearchItems: ISearchCategory[] = [
    { type: 'all', label: 'All categories', icon: MenuIcon },
    {
      type: 'audits',
      label: 'Audits / Assessments',
      icon: Home,
      url: '/audits',
    },
    {
      type: 'actions',
      label: 'Actions',
      icon: CheckIcon,
      url: '/actions',
    },
    {
      type: 'answers',
      label: 'Findings',
      icon: Eye,
      url: '/answers',
    },
  ];

  const trackerSearchItems: ISearchCategory[] = [
    {
      type: 'responses',
      label: pluralize(t('tracker item')),
      icon: Home,
      url: '/tracker/tracker-item',
    },
  ];

  const auditsMenuItems: INavItem[] = [
    {
      type: 'menuItem',
      label: 'Dashboard',
      url: '/dashboard',
      icon: Home,
      permission: 'dashboard.view',
    },
    {
      type: 'menuItem',
      label: 'Actions',
      url: '/actions',
      icon: CheckIcon,
      permission: 'actions.view',
    },
    {
      type: 'menuItem',
      label: 'Audits and Assessments',
      url: '/audits',
      icon: AuditIcon,
      permission: 'home.view',
    },
    // {
    //   type: 'menuItem',
    //   label: capitalize(pluralize(t('question'))),
    //   url: '/walk-items',
    //   icon: ListIcon,
    //   permission: 'questions.view',
    // },
    {
      type: 'menuItem',
      label: 'Admin',
      url: '/admin',
      icon: Admin,
      permission: 'adminPanel.view',
      subSections: [
        { label: 'Audits types', url: '/admin/audit-types', permission: 'adminPanel.auditTypes' },
        // { label: 'Findings categories', url: '/admin/questions-categories', permission: 'adminPanel.questionsCategories' },
        { label: capitalize(pluralize(t('location'))), url: '/admin/business-areas' },
        { label: capitalize(pluralize(t('business unit'))), url: '/admin/business-units' },
        // { label: 'Questions', url: '/admin/questions', permission: 'adminPanel.questions' },
        { label: 'Audit log', url: '/admin/audit-log' },
        { label: 'Users', url: '/admin/users' },
        { label: 'Other settings', url: '/admin/settings' },
      ],
    },
  ];

  const auditNavigationTabs = [
    {
      label: capitalize(pluralize(t('question'))),
      url: '/',
      icon: EditIcon,
    },
    {
      label: 'Participants',
      url: '/participants',
      icon: TeamsIcon,
    },
    {
      label: 'History',
      url: '/history',
      icon: RepeatClockIcon,
    },
  ];

  const trackerMenuItems: INavItem[] = [
    {
      type: 'menuItem',
      label: pluralize(t('tracker item')),
      url: '/dashboard',
      icon: Home,
      permission: 'home.view',
    },
    {
      //   type: "menuItem",
      //   label: "Insights",
      //   url: "/insights",
      //   icon: Insights,
      //   permission: "insights.view"
      // }, {
      type: 'menuItem',
      label: 'Admin',
      url: '/admin',
      icon: Admin,
      permission: 'adminPanel.view',
      subSections: [
        { label: capitalize(pluralize(t('tracker item'))), url: '/admin/tracker-items' },
        { label: 'Regulatory bodies', url: '/admin/regulatory-bodies' },
        { label: 'Categories', url: '/admin/categories' },
        { label: 'Locations', url: '/admin/locations' },
        { label: pluralize(capitalize(t('business unit'))), url: '/admin/business-units' },
        { label: 'Users', url: '/admin/users' },
        { label: 'Audit log', url: '/admin/audit-log' },
        { label: 'Other settings', url: '/admin/settings' },
      ],
    },
  ];

  const initialFilters: IFilters = {
    trackerItemsIds: {
      name: capitalize(t('tracker item')),
      value: [],
    },
    categoriesIds: {
      name: 'Category',
      value: [],
    },
    businessUnitsIds: {
      name: capitalize(t('business unit')),
      value: [],
    },
    itemStatus: {
      name: 'Item status',
      value: [],
    },
    regulatoryBodiesIds: {
      name: 'Regulatory body',
      value: [],
    },
    dueDate: {
      name: 'Expires on',
      value: null,
    },
    isVerified: {
      name: 'Verified',
      value: null,
    },
    collections: {
      name: 'Data type',
      value: [],
    },
    action: {
      name: 'Action',
      value: [],
    },
    usersIds: {
      name: 'User',
      value: {
        responsibleIds: [],
        accountableIds: [],
        contributorIds: [],
        followerIds: [],
      },
    },
    locationsIds: {
      name: capitalize(t('location')),
      value: [],
    },
  };

  const auditAddItems = [
    { label: 'Audits / Assessment', key: 'audit', permission: 'audits.add', icon: AuditIcon },
    { label: 'Finding', key: 'finding', permission: 'answers.add', icon: Eye },
    { label: 'Action', key: 'action', permission: 'actions.add', icon: TickIcon },
    { label: capitalize(t('location')), url: '/admin/business-areas', permission: 'adminPanel.view', icon: SiteIcon },
    { label: capitalize(t('business unit')), url: '/admin/business-units', permission: 'adminPanel.view', icon: LocationIcon },
  ];

  return {
    auditsMenuItems,
    auditSearchItems,
    trackerSearchItems,
    auditNavigationTabs,
    initialFilters,
    trackerMenuItems,
    auditAddItems,
  };
};

export default useConfig;
