import { createContext, useContext, useMemo } from 'react';

import { gql, useMutation, useQuery } from '@apollo/client';
import { useToast } from '@chakra-ui/react';

import { toastFailed, toastSuccess } from '../bootstrap/config';
import { IAuditModalContext } from '../interfaces/IAuditModalContext';
import { useDataContext } from './DataProvider';

export const AuditModalContext = createContext({} as IAuditModalContext);
const GET_FORM_DATA = gql`
  query {
    auditTypes {
      _id
      name
      type
      reviewersIds
    }
    businessUnits {
      _id
      name
      locationId
    }
    locations {
      _id
      name
    }
  }
`;

const CREATE_AUDIT = gql`
  mutation ($audit: AuditCreateInput!) {
    createAudit(audit: $audit) {
      _id
    }
  }
`;

const UPDATE_AUDIT = gql`
  mutation UpdateAudit($audit: AuditModifyInput!) {
    updateAudit(auditInput: $audit) {
      _id
    }
  }
`;

const DELETE_AUDIT = gql`
  mutation DeleteAudit($_id: String!) {
    deleteAudit(_id: $_id)
  }
`;

export const useAuditModalContext = () => {
  const context = useContext(AuditModalContext);
  if (!context) throw new Error('useAuditModalContext must be used within the AuditModalProvider');

  return context;
};

const AuditModalProvider = ({ children }) => {
  const toast = useToast();
  const { refetchAudit } = useDataContext();
  const { data: formData } = useQuery(GET_FORM_DATA);

  // CRUD Operations
  const [createAudit] = useMutation(CREATE_AUDIT);
  const [updateAudit] = useMutation(UPDATE_AUDIT);
  const [deleteAudit] = useMutation(DELETE_AUDIT);

  const auditTypes = formData?.auditTypes || [];
  const locations = formData?.locations || [];
  const businessUnits = formData?.businessUnits || [];

  const handleDeleteAudit = async (id: string) => {
    try {
      await deleteAudit({
        variables: {
          _id: id,
        },
      });
      toast({
        ...toastSuccess,
        description: `Audit successfully deleted`,
      });
      refetchAudit();
      return true;
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    }
  };

  const value = useMemo(
    () => ({
      auditTypes,
      locations,
      businessUnits,
      createAudit,
      updateAudit,
      handleDeleteAudit,
    }),
    [auditTypes, locations, businessUnits],
  );

  return <AuditModalContext.Provider value={value}>{children}</AuditModalContext.Provider>;
};

export default AuditModalProvider;
