import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { gql, useMutation } from '@apollo/client';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ExpandedIndex,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import { useAppContext } from '../../contexts/AppProvider';
import { useAuditContext } from '../../contexts/AuditProvider';
import { useDataContext } from '../../contexts/DataProvider';
import useNavigate from '../../hooks/useNavigate';
import { CrossIcon, TickIcon } from '../../icons';
import { IAction } from '../../interfaces/IAction';
import { serializeDate } from '../../utils/helpers';
import { TextInputMultiline } from '../Forms';

const UPDATE_ACTION = gql`
  mutation SaveAction($action: ActionModifyInput!) {
    updateAction(actionInput: $action) {
      _id
    }
  }
`;

const ActionAccordion = ({
  action,
  type,
  onCloseModal,
  accordionOpen,
}: {
  action: IAction;
  onCloseModal: () => void;
  type: 'submit' | 'extend';
  accordionOpen: Dispatch<SetStateAction<ExpandedIndex>>;
}) => {
  const { user } = useAppContext();
  const toast = useToast();
  const { isPathActive } = useNavigate();
  const { refetchAction } = useDataContext();
  const { refetchActions } = useAuditContext();
  const [updateAction] = useMutation(UPDATE_ACTION);
  const extendedAction = action.extensions?.find((extension) => extension.isActive!);
  const [submissionType, setSubmissionType] = useState<'approve' | 'reject' | ''>();

  const { control, formState, watch, reset } = useForm({
    mode: 'all',
    defaultValues: {
      rejectionComment: '',
    },
  });

  const values = watch() as Partial<IAction>;
  const { isValid } = formState;

  const handleSubmit = async () => {
    try {
      if (type === 'extend' && !extendedAction) throw new Error('Extended action not found');

      await updateAction({
        variables: {
          action: {
            _id: action._id,
            ...(type === 'submit' && {
              status: submissionType === 'reject' ? 'open' : 'closed',
              submissionRejectComment: values.rejectionComment,
            }),
            ...(type === 'submit' && submissionType === 'reject' && { submissionRejectComment: values.rejectionComment }),
            ...(type === 'extend' && {
              extension: {
                dueDate: extendedAction?.dueDate,
                isActive: extendedAction?.isActive,
                reason: extendedAction?.reason,
                status: submissionType === 'reject' ? 'reject' : 'approved',
                requestedById: extendedAction?.requestedById,
                extendedById: user?._id,
                ...(submissionType === 'reject' && { extensionRejectComment: values.rejectionComment }),
              },
              ...(submissionType === 'approve' && { dueDate: extendedAction?.dueDate }),
            }),
            actionTimestamp: action?.metatags?.updatedAt ? serializeDate(action?.metatags?.updatedAt) : serializeDate(action?.metatags?.addedAt),
          },
        },
      });
      toast({
        ...toastSuccess,
        description: `
        ${type === 'submit' ? 'Action' : ''}
        ${type === 'extend' ? 'Extension of an action has been' : ''}
        ${submissionType === 'reject' ? 'rejected' : 'approved'}`,
      });
      if (isPathActive('/actions')) refetchAction();
      else if (isPathActive('/audits/')) refetchActions();
      onCloseModal();
    } catch (e: any) {
      toast({
        ...toastFailed,
        description: e.message,
      });
      onCloseModal();
    } finally {
      setSubmissionType('');
    }
  };

  const getDate = () => {
    let date = action.dueDate;
    if (type === 'extend' && extendedAction?.dueDate) date = extendedAction?.dueDate;
    else if (type === 'submit' && action?.submittedDate) date = action?.submittedDate;
    return date;
  };

  useEffect(() => {
    if (submissionType) reset({ rejectionComment: '' });
  }, [submissionType]);

  return (
    <>
      <Modal closeOnOverlayClick={false} isCentered isOpen={!!submissionType} onClose={() => setSubmissionType('')}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Are you sure you want to {submissionType} this {type === 'submit' ? 'action' : 'extension request'}?
            </Text>
            {submissionType === 'reject' && (
              <TextInputMultiline
                control={control}
                disabled={action?.status === 'closed'}
                label="Comment"
                name="rejectionComment"
                placeholder="Add a comment"
                validations={{
                  notEmpty: true,
                }}
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justify="end" w="full">
              <Button _hover={{ opacity: 0.7 }} bg="#F0F2F5" color="#787486" mr={3} onClick={() => setSubmissionType('')}>
                No
              </Button>
              <Button
                _hover={{ opacity: 0.7 }}
                bg="#DC0043"
                color="#FFFFFF"
                disabled={submissionType === 'reject' ? !isValid : false}
                onClick={handleSubmit}
              >
                Yes
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Accordion
        allowMultiple
        bg="actionModal.bg.modalBody"
        borderRadius="11px"
        mb="10px"
        onChange={(expandedIndex) => accordionOpen(expandedIndex)}
      >
        <AccordionItem border="none">
          <AccordionButton _hover={{ bg: 'actionModal.bg.modalBody' }} borderRadius="11px">
            <Box flex="1" textAlign="left">
              <Flex alignItems="center">
                {type === 'submit' && <Box bg="actionModal.status.open" h="12px" mr="5px" rounded="50%" w="12px" />}
                {type === 'extend' && <Box bg="actionModal.status.extend" h="12px" mr="5px" rounded="50%" w="12px" />}
                <Text color="actionModal.textColor.field" fontSize="smm" fontWeight="semi_medium">
                  {type === 'submit' && 'Action has been submitted for review'}
                  {type === 'extend' && `Extension #${action.extensions?.length} has been requested for this action`}
                </Text>
              </Flex>
              <Text color="actionModal.textColor.field" fontSize="ssm" fontWeight="semi_medium" opacity="0.5">
                {type === 'extend' && 'To '}
                {format(getDate(), 'd MMMM yyyy')}
                {' - '}
                <Box as="span" fontWeight="bold">
                  {action.assignee?.displayName}
                </Box>
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {type === 'submit' && (
              <Text color="actionModal.textColor.field" fontSize="ssm" fontWeight="semi_medium">
                {action.submittedComment ? action?.submittedComment : '- No comments'}
              </Text>
            )}
            {type === 'extend' && (
              <Box color="actionModal.textColor.field" fontSize="ssm" lineHeight="16px">
                <Text color="actionModal.textColor.field" fontSize="ssm" fontWeight="semi_medium">
                  Reason:
                </Text>
                <Text color="actionModal.textColor.field" fontSize="ssm" fontWeight="semi_medium">
                  {extendedAction?.reason ? extendedAction.reason : '- No reason'}
                </Text>
              </Box>
            )}
            <br />
            <Flex justifyContent="flex-end">
              <Button
                _hover={{ bg: 'actionModal.bg.rejectButton' }}
                bg="actionModal.bg.rejectButton"
                borderRadius="10px"
                color="actionModal.textColor.white"
                fontSize="ssm"
                fontWeight="semi_medium"
                h="28px"
                onClick={() => {
                  setSubmissionType('reject');
                }}
                rightIcon={<CrossIcon stroke="#FFFFFF" />}
              >
                Reject
              </Button>
              &nbsp;&nbsp;
              <Button
                _hover={{ bg: 'actionModal.bg.approveButton' }}
                bg="actionModal.bg.approveButton"
                borderRadius="10px"
                color="actionModal.textColor.white"
                fontSize="ssm"
                fontWeight="semi_medium"
                h="28px"
                onClick={() => {
                  setSubmissionType('approve');
                }}
                rightIcon={<TickIcon stroke="#FFFFFF" />}
              >
                Approve {type === 'submit' ? 'action' : 'extension'}
              </Button>
            </Flex>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default ActionAccordion;
