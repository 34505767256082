import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { Box, Button, Flex, ModalBody, ModalContent, ModalHeader, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';

import { toastFailed, toastSuccess } from '../../bootstrap/config';
import { useAppContext } from '../../contexts/AppProvider';
import { useAuditModalContext } from '../../contexts/AuditModalProvider';
import { useDataContext } from '../../contexts/DataProvider';
import { useFiltersContext } from '../../contexts/FiltersProvider';
import useNavigate from '../../hooks/useNavigate';
import { ChevronRight, Close } from '../../icons';
import { IAudit } from '../../interfaces/IAudit';
import Can, { isPermitted } from '../can';
import DocumentUpload from '../Documents/DocumentUpload';
import DocumentUploaded from '../Documents/DocumentUploaded';
import { Datepicker, Dropdown, TextInput, TextInputMultiline, Toggle } from '../Forms';
import PeoplePicker from '../Forms/PeoplePicker';
import DeleteModal from '../Modals/DeleteModal';

interface IAuditModal {
  audit?: IAudit;
  refetch?: () => void;
  modalType: 'edit' | 'add';
  onAction?: (x: 'edit' | 'add' | 'delete') => void;
  onCloseModal: () => void;
  handleCreatedAudit?: (data: Partial<IAudit>) => void;
}

const AuditModal = ({ audit, modalType, onCloseModal, handleCreatedAudit }: IAuditModal) => {
  const toast = useToast();
  const { user } = useAppContext();
  const { isOpen: isDeleteModalOpen, onClose: onDeleteModalClose, onOpen: onDeleteModalOpen } = useDisclosure();
  const { isPathActive } = useNavigate();
  const { refetchAudit } = useDataContext();
  const { auditTypes, locations, businessUnits, createAudit, updateAudit } = useAuditModalContext();
  const { filtersValues } = useFiltersContext();

  const manageableAuditTypes = auditTypes.filter(auditType => isPermitted({
    user,
    action: 'audits.add',
    data: { auditType },
  }));

  const defaultValues: Partial<IAudit> = {
    auditTypeId: filtersValues?.auditTypesIds?.value?.[0] || '',
    impartialityReviewed: false,
  };
  const { watch, control, getValues, formState, reset, trigger } = useForm<IAudit>({
    mode: 'all',
    defaultValues,
  });
  const value = watch() as Partial<IAudit>;
  const { isValid } = formState;
  const { append: appendAttachment, remove: removeAttachment } = useFieldArray({
    control,
    name: 'attachments',
  });

  const availableBusinessUnits = useMemo(
    () => (businessUnits || []).filter(({ locationId }) => locationId === value.locationId),
    [JSON.stringify(businessUnits), value.locationId],
  );

  useEffect(() => {
    // Revalidate "report date" field when changed "audit date"
    if (value.reportDate) trigger('reportDate');
  }, [value.auditDate]);

  const selectedAuditType = useMemo(() => auditTypes.find(({ _id }) => _id === value.auditTypeId), [JSON.stringify(auditTypes), value.auditTypeId]);

  // set editable values to modal
  useEffect(() => {
    reset({
      _id: audit?._id,
      auditTypeId: audit?.auditType?._id,
      title: audit?.title,
      reference: audit?.reference,
      impartialityReviewed: audit?.impartialityReviewed,
      typeOfAssessment: audit?.typeOfAssessment,
      auditDate: audit?.auditDate,
      reportDate: audit?.reportDate,
      description: audit?.description,
      locationId: audit?.locationId,
      businessUnitId: audit?.businessUnitId,
      auditeeId: audit?.auditeeId,
      leadAuditorId: audit?.leadAuditorId,
      leadAuditorName: audit?.leadAuditorName,
      attachments: [
        ...(audit?.attachments?.map((attachment) => ({
          id: attachment.id,
          name: attachment.name,
          addedAt: attachment.addedAt,
        })) || []),
      ],
    });
  }, [JSON.stringify(audit)]);

  const handleAuditOperation = async () => {
    try {
      const values = getValues();
      const auditType = auditTypes.find(({ _id }) => _id === values.auditTypeId);

      // removing unnecessary values if audit type is not matched
      if (auditType?.type === 'UKAS' || auditType?.type === 'LRQA') {
        delete values.impartialityReviewed;
        delete values.businessUnitId;
        delete values.leadAuditorId;
      }
      if (auditType?.type !== 'UKAS') delete values.typeOfAssessment;

      if (auditType?.type !== 'UKAS' && auditType?.type !== 'LRQA') delete values.leadAuditorName;

      if (modalType === 'add') {
        const { data } = await createAudit({
          variables: {
            audit: { ...values, auditorId: user?._id },
          },
        });
        if (handleCreatedAudit) handleCreatedAudit({ ...values, ...data.createAudit, auditType });
      }

      if (modalType === 'edit') {
        await updateAudit({
          variables: {
            audit: { ...values, auditorId: user?._id },
          },
        });
      }
      toast({
        ...toastSuccess,
        description: `Audit and assessment ${modalType === 'edit' ? 'updated' : 'added'}`,
      });
      if (isPathActive('/audits', { exact: true })) refetchAudit();
      onCloseModal();
    } catch (e: any) {
      toast({ ...toastFailed, description: e.message });
    }
  };

  return (
    <>
      {isDeleteModalOpen && (
        <DeleteModal
          id={value._id as string}
          isDeleteModalOpen={isDeleteModalOpen}
          onDeleteModalClose={onDeleteModalClose}
          onOuterModalClose={onCloseModal}
          reference={value.reference as string}
          title={value.title as string}
          type="audit"
        />
      )}
      <ModalContent bg="auditModal.bg.modalContent" h={['auto', '100vh']} m="0" overflow="hidden" p={4} rounded="0">
        <ModalHeader alignItems="center" fontSize="xxl" fontWeight="bold" p="0">
          <Flex justifyContent="space-between">
            <Text
              alignItems="center"
              color="auditModal.textColor.header"
              fontSize={['18px', '24px']}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {modalType === 'add' && 'Add an Audit and Assessment'}
              {modalType === 'edit' && `${audit?.reference} ${audit?.title}`}
            </Text>
            <Flex alignItems="center">
              <Close cursor="pointer" h="15px" onClick={onCloseModal} stroke="auditModal.icon.close" w="15px" />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody h="calc(100% - 2rem)" p="26px 0 0 0">
          <Flex bg="auditModal.bg.modalBody" borderRadius="11px" direction="column" h="calc(100% - 70px)" p={15}>
            <Stack h="100%" overflow="auto" pb={1} pr={2} scrollBehavior="smooth" spacing={2} width="calc(100% + 10px)">
              <Dropdown
                color="auditModal.textColor.field"
                control={control}
                disabled={modalType === 'edit' && !isPermitted({ user, action: 'audits.editType' })}
                label="Type"
                name="auditTypeId"
                options={(modalType === 'edit' ? auditTypes : manageableAuditTypes).map(({ name, _id }) => ({ label: name, value: _id }))}
                placeholder="Select audit type"
                stroke="dropdown.icon"
                validations={{
                  notEmpty: true,
                }}
                variant="secondaryVariant"
              />
              <TextInput
                color="auditModal.textColor.field"
                control={control}
                label="Title"
                name="title"
                placeholder="Enter the item's title"
                validations={{
                  notEmpty: true,
                  isAlphaNumeric: true,
                }}
                variant="secondaryVariant"
              />
              <TextInput
                color="auditModal.textColor.field"
                control={control}
                label="Reference"
                name="reference"
                placeholder="Enter audit's reference"
                validations={{
                  notEmpty: true,
                }}
                variant="secondaryVariant"
              />
              {selectedAuditType?.type !== 'UKAS' && selectedAuditType?.type !== 'LRQA' && (
                <Toggle
                  control={control}
                  label="Impartiality reviewed"
                  name="impartialityReviewed"
                  placeholder="Yes"
                  variant="secondaryVariant"
                />
              )}
              {selectedAuditType?.type === 'UKAS' && (
                <Dropdown
                  color="auditModal.textColor.field"
                  control={control}
                  label="Type of assessment"
                  name="typeOfAssessment"
                  options={[
                    { value: 'testing', label: 'Testing' },
                    { value: 'certification', label: 'Certification' },
                  ]}
                  placeholder="Select assessment type"
                  stroke="dropdown.icon"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
              )}
              <Datepicker
                color="auditModal.textColor.field"
                control={control}
                label="Audit date"
                name="auditDate"
                placeholder="Select audit date"
                tooltip="This is the closing meeting date"
                validations={{
                  notEmpty: true,
                }}
                variant="secondaryVariant"
              />
              <Datepicker
                color="auditModal.textColor.field"
                control={control}
                label="Report date"
                name="reportDate"
                placeholder="Select report date"
                validations={{
                  notEmpty: true,
                  laterThan: value?.auditDate,
                }}
                variant="secondaryVariant"
              />
              <TextInputMultiline
                control={control}
                label="Audit description"
                name="description"
                placeholder="Enter some description here"
                validations={{
                  notEmpty: true,
                }}
              />
              <Dropdown
                color="auditModal.textColor.field"
                control={control}
                label="Business area"
                name="locationId"
                options={(locations ?? []).map(({ _id, name }) => ({ value: _id, label: name }))}
                placeholder="Select business area"
                stroke="dropdown.icon"
                validations={{
                  notEmpty: true,
                }}
                variant="secondaryVariant"
              />
              {!['UKAS', 'LRQA'].includes(auditTypes?.find(({ _id }) => _id === getValues()?.auditTypeId)?.type || '') && (
                <Dropdown
                  color="auditModal.textColor.field"
                  control={control}
                  disabled={!value.locationId}
                  label="Business group"
                  name="businessUnitId"
                  options={availableBusinessUnits.map(({ _id, name }) => ({ value: _id, label: name }))}
                  placeholder="Select business group"
                  stroke="dropdown.icon"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
              )}
              <PeoplePicker
                control={control}
                label="Auditee"
                name="auditeeId"
                placeholder="Select auditee"
                validations={{
                  notEmpty: true,
                }}
              />
              {!['UKAS', 'LRQA'].includes(auditTypes?.find(({ _id }) => _id === getValues()?.auditTypeId)?.type || '') ? (
                <PeoplePicker
                  control={control}
                  label="BRE Key contact"
                  name="leadAuditorId"
                  placeholder="Select key contact"
                  validations={{
                    notEmpty: true,
                  }}
                />
              ) : (
                <TextInput
                  color="auditModal.textColor.field"
                  control={control}
                  label="BRE Key contact"
                  name="leadAuditorName"
                  placeholder="Select key contact"
                  validations={{
                    notEmpty: true,
                  }}
                  variant="secondaryVariant"
                />
              )}
              <Flex direction="column" pt={2} w="full">
                <Flex alignItems="center" justifyContent="space-between" mb={2}>
                  <Text color="auditModal.textColor.field" fontSize="ssm" fontWeight="bold">
                    Attach audit reports
                  </Text>
                  <Text color="auditModal.textColor.optional" fontSize="xxs" fontWeight="semi_medium">
                    Optional
                  </Text>
                </Flex>
                {value.attachments?.map((attachment, i) => (
                  <Flex flexDir="column" key={i} mb={2}>
                    <DocumentUploaded callback={async () => removeAttachment(i)} document={attachment} downloadable />
                  </Flex>
                ))}
                <DocumentUpload
                  background="auditModal.bg.documentUpload"
                  callback={async (uploaded) => appendAttachment(uploaded)}
                  elementId={`temp-${uuidv4()}`}
                />
              </Flex>
            </Stack>
          </Flex>
          <Can
            action="audits.edit"
            data={{ audit, auditType: selectedAuditType }}
            yes={() => (
              <Flex justifyContent="space-between" mt="25px">
                {modalType === 'edit' && (
                  <Flex
                    alignItems="center"
                    color="auditModal.textColor.delete"
                    cursor="pointer"
                    fontSize="ssm"
                    fontWeight="bold"
                    onClick={onDeleteModalOpen}
                  >
                    Delete audit
                  </Flex>
                )}
                {modalType !== 'edit' && <Box />}
                <Button
                  _hover={{ bg: 'auditModal.hover.addButton' }}
                  bg="auditModal.bg.addButton"
                  color="auditModal.textColor.addButton"
                  disabled={!isValid}
                  fontSize="smm"
                  fontWeight="bold"
                  onClick={handleAuditOperation}
                  p="10px 20px"
                  rounded="10px"
                >
                  {modalType === 'edit' ? 'Update' : 'Add audit'}
                  <ChevronRight ml="5px" />
                </Button>
              </Flex>
            )}
          />
        </ModalBody>
      </ModalContent>
    </>
  );
};

export default AuditModal;

export const auditModalStyles = {
  auditModal: {
    bg: {
      modalBody: '#F0F2F5',
      modalContent: '#ffffff',
      documentUpload: '#ffffff',
      addButton: '#DC0043',
    },
    textColor: {
      header: '#1E1836',
      field: '#1E1836',
      optional: '#818197',
      addButton: '#FFFFFF',
      delete: '#787486',
    },
    hover: {
      addButton: '#DC0043',
    },
    icon: {
      close: '#1E1836',
    },
  },
};
