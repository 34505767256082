import { createIcon } from '@chakra-ui/icons';

const QuestionIcon = createIcon({
  viewBox: '0 0 19 18',
  path: (
    <g>
      <path
        d="M16.4333 13.8H8.96667L4.7 17V13.8H2.56667C2.28377 13.8 2.01246 13.6876 1.81242 13.4876C1.61238 13.2875 1.5 13.0162 1.5 12.7333V2.06667C1.5 1.78377 1.61238 1.51246 1.81242 1.31242C2.01246 1.11238 2.28377 1 2.56667 1H16.4333C16.7162 1 16.9875 1.11238 17.1876 1.31242C17.3876 1.51246 17.5 1.78377 17.5 2.06667V12.7333C17.5 13.0162 17.3876 13.2875 17.1876 13.4876C16.9875 13.6876 16.7162 13.8 16.4333 13.8Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.3667 5.26672C7.36677 4.87562 7.47435 4.49208 7.67768 4.158C7.88101 3.82392 8.17227 3.55216 8.51961 3.37243C8.86695 3.19269 9.25702 3.1119 9.64718 3.13887C10.0373 3.16585 10.4126 3.29955 10.7319 3.52538C11.0512 3.75121 11.3023 4.06046 11.4577 4.41935C11.6131 4.77823 11.6669 5.17293 11.6131 5.56031C11.5594 5.94769 11.4002 6.31284 11.1529 6.61586C10.9057 6.91887 10.5799 7.14809 10.2111 7.27845C10.0031 7.35201 9.82297 7.48829 9.69561 7.6685C9.56825 7.84872 9.49992 8.064 9.50003 8.28467V8.46672"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.51501 9.80005C9.44085 9.80005 9.36834 9.82204 9.30668 9.86325C9.24501 9.90445 9.19694 9.96302 9.16856 10.0315C9.14018 10.1001 9.13275 10.1755 9.14722 10.2482C9.16169 10.321 9.1974 10.3878 9.24985 10.4402C9.30229 10.4927 9.36911 10.5284 9.44186 10.5428C9.5146 10.5573 9.59 10.5499 9.65852 10.5215C9.72704 10.4931 9.78561 10.4451 9.82682 10.3834C9.86802 10.3217 9.89001 10.2492 9.89001 10.175C9.89001 10.0756 9.85051 9.98021 9.78018 9.90988C9.70985 9.83956 9.61447 9.80005 9.51501 9.80005Z"
        fill="currentColor"
      />
    </g>
  ),
});

export default QuestionIcon;
