import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Avatar, Flex, Grid, GridItem, HStack, ModalBody, ModalContent, ModalHeader, Spacer, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { t } from 'i18next';

import { useShareContext } from '../../contexts/ShareProvider';
import useNavigate from '../../hooks/useNavigate';
import { Close, OpenExternalIcon } from '../../icons';
import { IAnswer } from '../../interfaces/IAnswer';
import ActionListItem from '../Actions/ActionListItem';
import TextInputMultiline from '../Forms/TextInputMultiline';
import ShareButton from '../ShareButton';

// const SAVE_ANSWER = gql`
//   mutation SaveAnswer($answer: AnswerModifyInput!) {
//     updateAnswer(answerInput: $answer) {
//       _id
//     }
//   }
// `;

const WalkItemModal = ({
  walkItem,
  closeModal,
}: {
  walkItem?: IAnswer;
  refetch: () => void;
  handleDeleteQuestionModalOpen: () => void;
  closeModal: () => void;
}) => {
  const { openInNewTab } = useNavigate();
  const { handleShareOpen, setShareItemUrl, setShareItemName } = useShareContext();
  // const isUserPermittedToModify = isPermitted({
  //   user,
  //   action: 'answers.edit',
  //   data: { answer: walkItem, audit: walkItem?.audit },
  // });
  // const isUserPermittedToModifyStatus = isPermitted({
  //   user,
  //   action: 'answers.editStatus',
  // });
  const isDisabled = false;
  // !!(walkItem?.audit?.status === 'completed') || !isUserPermittedToModify;

  // const [saveAnswer] = useMutation(SAVE_ANSWER);

  const { control, reset } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    reset({
      finding: walkItem?.finding,
      severity: walkItem?.severity,
      date: walkItem?.date,
      details: walkItem?.details,
    });
  }, [JSON.stringify(walkItem)]);

  // const handlePrimaryButtonClick = async () => {
  //   if (!walkItem) return;

  //   const answerData = {
  //     _id: walkItem._id,
  //     finding: values?.finding,
  //     severity: values?.severity,
  //     date: values?.date,
  //     details: values?.details,
  //   };

  //   try {
  //     await saveAnswer({ variables: { answer: answerData } });
  //     refetch();
  //     toast({ ...toastSuccess, description: 'Finding saved' });
  //   } catch (e: any) {
  //     toast({
  //       ...toastFailed,
  //       description: e.message,
  //     });
  //   } finally {
  //     closeModal();
  //   }
  // };

  return (
    <>
      <ModalContent bg="actionModal.bg" h={['auto', '100vh']} m="0" mb={[4, 0, 0]} overflow="hidden" p={[4, 6]} rounded="0">
        <ModalHeader alignItems="center" fontSize="xxl" fontWeight="bold" justifyContent="space-between" p="0">
          <Flex justifyContent="space-between">
            <Flex alignItems="center" fontSize={['14px', '24px']}>
              <Text noOfLines={1}>{walkItem?.finding}</Text>
            </Flex>
            <Flex alignItems="center">
              <ShareButton
                ariaLabel="walk-item-share-button"
                onClick={() => {
                  setShareItemUrl(`walk-items?id=${walkItem?._id}`);
                  setShareItemName(walkItem?.finding);
                  handleShareOpen();
                }}
              />
              <Close cursor="pointer" h="15px" onClick={closeModal} stroke="walkItemModal.closeIcon" w="15px" />
            </Flex>
          </Flex>
        </ModalHeader>
        <ModalBody h="calc(100% - 1rem)" p="1rem 0 0 0">
          <Stack h="100%" justify="space-between" spacing={2}>
            <Stack flexGrow={1} overflowY="auto" px={2} py={0} spacing={6}>
              <Stack spacing={4}>
                <Text fontSize="smm" fontWeight="semibold">
                  Related {t('audit')}
                </Text>
                <HStack
                  bg="walkItemModal.question.bg"
                  boxShadow="simple"
                  flexGrow={1}
                  justify="space-between"
                  px={6}
                  py={4}
                  rounded="10px"
                  spacing={2}
                >
                  <Stack overflow="hidden" spacing={1}>
                    <Stack
                      _hover={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      align="center"
                      direction="row"
                      onClick={() => openInNewTab(`/audits/${walkItem?.audit?._id}`)}
                      spacing={2}
                    >
                      <Text color="walkItemModal.question.color" fontSize="smm" isTruncated>
                        {walkItem?.audit?.businessUnit?.name}
                      </Text>
                      <OpenExternalIcon fill="transparent" stroke="black" />
                    </Stack>
                    <Text color="walkItemModal.auditType" fontSize="ssm">
                      {walkItem?.audit?.auditType?.name}
                    </Text>
                    {/* <Text color={`walkItemModal.status.${walkItem?.audit?.status}`} fontSize="smm" fontWeight="semibold">
                      {`${capitalize(walkItem?.audit?.status)}`}
                    </Text> */}
                  </Stack>
                </HStack>
              </Stack>
              <Stack spacing={4}>
                <Text fontSize="smm" fontWeight="semibold">
                  Walk item details
                </Text>
                <Grid columnGap={4} rowGap={4} templateColumns="repeat(2, 1fr)">
                  <GridItem>
                    <Text color="auditActionForm.labelFont.normal" fontSize="11px" fontWeight="bold">
                      Date added
                    </Text>
                    <Text fontSize="13px">{format(new Date(walkItem?.metatags?.addedAt!), 'd MMM yyyy')}</Text>
                  </GridItem>
                  {walkItem?.addedBy && (
                    <GridItem>
                      <Text color="auditActionForm.labelFont.normal" fontSize="11px" fontWeight="bold">
                        Created by
                      </Text>
                      <Flex align="center" direction="row" mt={1}>
                        <Avatar name={walkItem?.addedBy?.displayName} size="xs" src={walkItem?.addedBy?.imgUrl} />
                        <Text
                          fontSize="13px"
                          lineHeight="17px"
                          opacity="1"
                          overflow="hidden"
                          pl={3}
                          textOverflow="ellipsis"
                          w="full"
                          whiteSpace="nowrap"
                        >
                          {walkItem?.addedBy?.displayName}
                        </Text>
                      </Flex>
                    </GridItem>
                  )}
                  <GridItem>
                    <Text color="auditActionForm.labelFont.normal" fontSize="11px" fontWeight="bold">
                      Unique ID
                    </Text>
                    <Text fontSize="13px">{walkItem?._id}</Text>
                  </GridItem>
                </Grid>
                <Grid columnGap={4} rowGap={2} templateColumns="repeat(1, 1fr)">
                  <GridItem>
                    <TextInputMultiline
                      control={control}
                      disabled={isDisabled}
                      label="Finding"
                      name="finding"
                      required
                      validations={{
                        notEmpty: true,
                      }}
                    />
                  </GridItem>
                </Grid>

                {Array.isArray(walkItem?.actions) && walkItem!.actions!.length > 0 && (
                  <Stack spacing={4}>
                    <Text fontSize="smm" fontWeight="semibold">
                      Actions
                    </Text>
                    {walkItem?.actions?.map((action, index) => (
                      <ActionListItem action={action} disabled index={index} key={action._id} />
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Flex flexBasis="calc(40px + 1rem)" flexShrink={0} justify="space-between" pt={4} w="full">
              {/* {walkItem?.audit?.status !== 'completed' && (
                <Button
                  bg="walkItemModal.buttons.secondary.bg"
                  color="walkItemModal.buttons.secondary.color"
                  fontSize="smm"
                  fontWeight="700"
                  h="40px"
                  ml={3}
                  onClick={handleDeleteQuestionModalOpen}
                  rounded="10px"
                  w="fit-content"
                >
                  Delete
                </Button>
              )} */}
              <Spacer />
              {/* {isUserPermittedToModifyStatus && walkItem?.audit?.status.toString() === 'upcoming' && (
                <Button
                  bg="walkItemModal.buttons.primary.bg"
                  color="walkItemModal.buttons.primary.color"
                  disabled={!isValid}
                  fontSize="smm"
                  fontWeight="700"
                  h="40px"
                  ml={3}
                  onClick={handlePrimaryButtonClick}
                  rightIcon={<Icon as={TickIcon} size={24} stroke="walkItemModal.buttons.primary.icon" />}
                  rounded="10px"
                  w="fit-content"
                >
                  Update
                </Button>
              )} */}
            </Flex>
          </Stack>
        </ModalBody>
      </ModalContent>
    </>
  );
};

export default WalkItemModal;

export const walkItemModalStyles = {
  walkItemModal: {
    shareButton: {
      bg: '#FFFFFF',
      hoverBg: '#818197',
      icon: {
        stroke: '#818197',
        hoverStroke: '#FFFFFF',
      },
    },
    status: {
      completed: '#62c240',
      missed: '#FC5960',
      upcoming: '#FFA012',
    },
    bg: '#ffffff',
    closeIcon: '#282F36',
    question: {
      color: '#1E1836',
      bg: '#FFFFFF',
    },
    auditType: '#1E183670',
    buttons: {
      primary: {
        icon: '#ffffff',
        bg: '#DC0043',
        color: '#ffffff',
      },
      secondary: {
        bg: '#1E1836',
        color: '#FFFFFF',
      },
    },
  },
};
