import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';
import { Avatar, AvatarProps, Box, Flex, Stack, Text, Tooltip } from '@chakra-ui/react';

import { IUser } from '../interfaces/IUser';

const GET_USER = gql`
  query ($userQueryInput: UserQueryInput) {
    usersById(userQueryInput: $userQueryInput) {
      _id
      displayName
      imgUrl
      jobTitle
    }
  }
`;

const UserAvatar = ({
  user,
  userId,
  callback, // function that can be called to return details of user
  withTooltip = false,
  withJobTitle = false,
  ...props
}: AvatarProps & {
  user?: IUser;
  userId?: string;
  callback?: (userDetails: IUser) => void;
  withTooltip?: boolean;
  withJobTitle?: boolean;
}) => {
  const { data } = useQuery(GET_USER, {
    variables: {
      userQueryInput: { usersIds: [userId] },
    },
    skip: Boolean(user) || !userId,
  });
  const userDetails = useMemo(() => {
    if (user) return user;

    const userData = (data?.usersById || [])[0];
    if (callback) callback(userData);
    return userData;
  }, [JSON.stringify(user), JSON.stringify(data)]);

  return !withJobTitle ? (
    <Tooltip
      bg="#1E1836"
      borderRadius="8px"
      hasArrow
      isDisabled={!withTooltip}
      label={
        <Stack color="white" lineHeight="10px">
          <Text fontWeight="700">{userDetails?.displayName}</Text>
          <Text fontWeight="400">{userDetails?.jobTitle || 'No job title'}</Text>
        </Stack>
      }
      p="6px 12px"
    >
      <Avatar {...props} name={userDetails?.displayName} src={userDetails?.imgUrl} />
    </Tooltip>
  ) : (
    <Flex align="center" direction="row" pr={1} w="full">
      <Avatar {...props} name={userDetails?.displayName} src={userDetails?.imgUrl} />
      <Box lineHeight="13px" ml="5px" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        <Text color="auditsList.fontColor" fontSize="smm" isTruncated>
          {userDetails?.displayName ?? 'No display name'}
        </Text>
        <Text color="auditsList.secondary.fontColor" fontSize="ssm" isTruncated mt={1}>
          {userDetails?.jobTitle ? userDetails?.jobTitle : 'No job title'}
        </Text>
      </Box>
    </Flex>
  );
};

export default UserAvatar;
