import { useCallback } from 'react';

import { Flex } from '@chakra-ui/react';
import { differenceInDays } from 'date-fns';

import { IAuditLog } from '../../interfaces/IAuditLog';
import AuditLogRecord from './AuditLogRecord';

const AuditLogDay = ({ auditLog }: { auditLog: IAuditLog }) => {
  const daysAgo = useCallback(
    (day) => {
      const today = new Date();
      const dayAgo = new Date(day);
      let value = '';

      switch (differenceInDays(today, dayAgo)) {
        case -1:
        case 0:
          value = 'Today';
          break;
        case 1:
          value = 'Yesterday';
          break;
        default:
          value = `${differenceInDays(today, dayAgo)} days ago`;
      }
      return value;
    },
    [auditLog._id],
  );

  if (auditLog.records.length === 0) return null;

  return (
    <Flex direction={['column', 'row']} mb="20px">
      <Flex
        bg="auditLogDayStyles.dateBg"
        borderRadius="8px"
        color="auditLogDayStyles.dateColor"
        fontSize="14px"
        fontWeight="bold"
        h="fit-content"
        p="6px 13px"
        w="108px"
      >
        {daysAgo(auditLog._id)}
      </Flex>
      <Flex flexDir="column" mt="2" w="full">
        {auditLog.records.map((audit) => (
          <AuditLogRecord audit={audit} key={audit.metatags?.addedAt?.toString()} />
        ))}
      </Flex>
    </Flex>
  );
};

export const auditLogDayStyles = {
  auditLogDayStyles: {
    dateBg: '#F0F2F5',
    dateColor: '#282F36',
  },
};

export default AuditLogDay;
