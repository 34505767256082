import { Box, Flex } from '@chakra-ui/react';

import AdminTableHeader from '../Admin/AdminTableHeader';
import AdminTableHeaderElement from '../Admin/AdminTableHeaderElement';

interface IHeaderElement {
  name: string;
  label: string;
  w: number | string | {} | [];
}

const AuditDetailsTable = ({
  sortOrder,
  sortType,
  setSortType,
  setSortOrder,
  children,
  headerElements,
  childrenPadding = '25px',
}: {
  sortOrder: 'asc' | 'desc';
  sortType: string;
  setSortType: (key: string) => void;
  setSortOrder: (order: 'asc' | 'desc') => void;
  children: React.ReactNode;
  headerElements: IHeaderElement[];
  childrenPadding?;
}) => (
  <Box h="full" overflow="none" w="full">
    <Box bg="white" border="1px solid #F0F0F0" borderRadius="20px" h="fit-content" minH="full" w="full">
      <AdminTableHeader bg="transparent">
        {headerElements.map(({ name, label, w }) => (
          <AdminTableHeaderElement
            key={name}
            label={label}
            onClick={() => {
              setSortType(name);
              setSortOrder(sortOrder === 'asc' && sortType === name ? 'desc' : 'asc');
            }}
            showSortingIcon={sortType === name}
            sortOrder={sortType === name ? sortOrder : undefined}
            w={w}
          />
        ))}
      </AdminTableHeader>
      <Flex bg="white" px={childrenPadding}>
        {children}
      </Flex>
    </Box>
  </Box>
);

export default AuditDetailsTable;
