import { createIcon } from '@chakra-ui/icons';

const CloneIcon = createIcon({
  path: (
    <>
      <path
        d="M12.4836 3.72656H4.9096C3.98005 3.72656 3.2265 4.48011 3.2265 5.40967V12.9836C3.2265 13.9132 3.98005 14.6667 4.9096 14.6667H12.4836C13.4131 14.6667 14.1667 13.9132 14.1667 12.9836V5.40967C14.1667 4.48011 13.4131 3.72656 12.4836 3.72656Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.51645 12.2737C2.07006 12.2737 1.64196 12.0963 1.32631 11.7807C1.01067 11.4651 0.833344 11.037 0.833344 10.5906V3.0166C0.833344 2.57021 1.01067 2.14211 1.32631 1.82647C1.64196 1.51082 2.07006 1.3335 2.51645 1.3335H10.0904C10.5368 1.3335 10.9649 1.51082 11.2805 1.82647C11.5962 2.14211 11.7735 2.57021 11.7735 3.0166"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
  viewBox: '0 0 18 18',
});

export default CloneIcon;
