import { createIcon } from '@chakra-ui/icons';

const ArrowCount = createIcon({
  displayName: 'Admin',
  viewBox: '0 0 10 10',
  path: (
    <g>
      <path d="M0.916668 8.7727L8.90185 0.916992" fill="none" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.90185 5.40638V0.916992H4.33889" fill="none" strokeLinecap="round" strokeLinejoin="round" />
    </g>
  ),
});

export default ArrowCount;
