import { Flex } from '@chakra-ui/react';

import AuditHeader from '../components/Audit/AuditHeader';
import FiltersPanel from '../components/Filters/FiltersPanel';
import Loader from '../components/Loader';
import ViewModal from '../components/Modals/ViewModal';
import ModuleSwitcher from '../components/ModuleSwitcher';
import NavigationBottomMobile from '../components/NavigationBottomMobile';
import NavigationLeft from '../components/NavigationLeft/NavigationLeft';
import NavigationTop from '../components/NavigationTop';
import ShareModal from '../components/ShareModal';
import AuditProvider, { useAuditContext } from '../contexts/AuditProvider';
import DataProvider from '../contexts/DataProvider';
import { useFiltersContext } from '../contexts/FiltersProvider';
import ShareProvider from '../contexts/ShareProvider';
import { useViewModalContext } from '../contexts/ViewModalProvider';
import useDevice from '../hooks/useDevice';

const AuditLayout = ({ component: Component }: { component: any }) => {
  const { loading, audit } = useAuditContext();
  const { usedFilters } = useFiltersContext();
  const { isViewModalOpen } = useViewModalContext();
  const device = useDevice();
  if (loading && !audit) {
    return (
      <Flex h="100vh">
        <Loader center />
      </Flex>
    );
  }

  return (
    <DataProvider>
      <Flex h="full" minH="100vh" w="full">
        {isViewModalOpen && <ViewModal />}
        <ModuleSwitcher />
        <NavigationLeft />
        <Flex direction="column" grow={1} w={['100%', 'calc(100% - 80px)', 'calc(100% - 290px)']}>
          <NavigationTop />
          <Flex
            bg="layout.bg"
            flexDirection="column"
            h={['calc(100vh - 126px)', 'calc(100vh - 80px)']}
            mt={['65px', 0]}
            overflow="auto"
            pt={['25px', 0]}
            top={[0, '80px']}
            w="full"
          >
            <ShareModal />
            <AuditHeader />
            <Flex flexGrow={1} px="25px" w="full">
              <Flex flexDirection="column" h="full" maxH={['none', 'calc(100vh - 190px)']} pb="25px" w="full">
                <Component />
              </Flex>
            </Flex>
          </Flex>
          {device === 'mobile' && <NavigationBottomMobile />}
        </Flex>
        {usedFilters?.length > 0 && <FiltersPanel />}
      </Flex>
    </DataProvider>
  );
};

const AuditWithContext = (props) => (
  <AuditProvider {...props}>
    <ShareProvider>
      <AuditLayout {...props} />
    </ShareProvider>
  </AuditProvider>
);

export default AuditWithContext;
