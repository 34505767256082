import { createIcon } from '@chakra-ui/icons';

const HealthKitIcon = createIcon({
  viewBox: '0 0 15 18',
  path: (
    <g>
      <path d="M7.3335 8.33325L7.3335 12.9999" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M5 10.6667H9.66667" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path
        d="M10.0667 3.66667H12.7333C13.0162 3.66667 13.2875 3.77905 13.4876 3.97909C13.6876 4.17912 13.8 4.45044 13.8 4.73333V15.9333C13.8 16.2162 13.6876 16.4875 13.4876 16.6876C13.2875 16.8876 13.0162 17 12.7333 17H2.06667C1.78377 17 1.51246 16.8876 1.31242 16.6876C1.11238 16.4875 1 16.2162 1 15.9333V4.73333C1 4.45044 1.11238 4.17912 1.31242 3.97909C1.51246 3.77905 1.78377 3.66667 2.06667 3.66667H4.73333C4.73333 2.95942 5.01428 2.28115 5.51438 1.78105C6.01448 1.28095 6.69276 1 7.4 1C8.10724 1 8.78552 1.28095 9.28562 1.78105C9.78572 2.28115 10.0667 2.95942 10.0667 3.66667V3.66667Z"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M7.39997 3.1333C7.45271 3.1333 7.50427 3.14894 7.54812 3.17824C7.59197 3.20754 7.62615 3.24919 7.64634 3.29792C7.66652 3.34665 7.6718 3.40026 7.66151 3.45199C7.65122 3.50372 7.62582 3.55124 7.58853 3.58853C7.55124 3.62582 7.50372 3.65122 7.45199 3.66151C7.40026 3.6718 7.34665 3.66652 7.29792 3.64634C7.24919 3.62615 7.20754 3.59197 7.17824 3.54812C7.14894 3.50427 7.1333 3.45271 7.1333 3.39997C7.1333 3.32924 7.1614 3.26142 7.21141 3.21141C7.26142 3.1614 7.32924 3.1333 7.39997 3.1333"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
});

export default HealthKitIcon;
