import { createIcon } from '@chakra-ui/icons';

const Mentions = createIcon({
  displayName: 'Mentions',
  viewBox: '0 0 22 22',

  path: (
    <g>
      <path
        d="M15.6662 10.9996C15.6662 11.9227 15.3925 12.825 14.8796 13.5925C14.3668 14.36 13.6379 14.9582 12.7851 15.3114C11.9323 15.6647 10.9939 15.7571 10.0886 15.577C9.18329 15.3969 8.35169 14.9524 7.69899 14.2997C7.04629 13.647 6.60179 12.8154 6.42171 11.9101C6.24163 11.0048 6.33405 10.0664 6.68729 9.21359C7.04053 8.36079 7.63873 7.63189 8.40622 7.11906C9.17372 6.60624 10.0761 6.33252 10.9991 6.33252C12.2369 6.33252 13.424 6.82423 14.2992 7.69948C15.1745 8.57472 15.6662 9.76181 15.6662 10.9996Z"
        fill="none"
        stroke="#9A9EA1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M15.6662 10.9996V12.9997C15.6662 13.707 15.9472 14.3854 16.4473 14.8855C16.9475 15.3857 17.6258 15.6666 18.3331 15.6666C19.0404 15.6666 19.7187 15.3857 20.2189 14.8855C20.719 14.3854 21 13.707 21 12.9997V10.9996C20.9999 8.7469 20.2392 6.56026 18.8412 4.79391C17.4432 3.02756 15.4897 1.78497 13.2973 1.26747C11.1049 0.749962 8.80199 0.987856 6.76167 1.94261C4.72135 2.89736 3.06316 4.51303 2.05574 6.52787C1.04832 8.54271 0.750698 10.8387 1.21109 13.0438C1.67148 15.2489 2.86292 17.234 4.59237 18.6774C6.32181 20.1208 8.48796 20.938 10.7399 20.9966C12.9917 21.0552 15.1975 20.3518 16.9996 19.0003"
        fill="none"
        stroke="#9A9EA1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
  ),
});

export default Mentions;
