import { Flex, Heading, Stack, Tooltip } from '@chakra-ui/react';

import { useAuditContext } from '../../contexts/AuditProvider';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useNavigate from '../../hooks/useNavigate';
import { ChevronRight, EditIcon } from '../../icons';
import Can from '../can';
import ActionsSeverityTable from '../Insights/ActionsSeverityTable';
import AuditMetadata from './AuditMetadata';

const AuditHeader = () => {
  const { audit, applicableActions, loadingApplicableActions, refetchAudit } = useAuditContext();
  const { editAudit } = useViewModalContext();
  const { navigateTo } = useNavigate();

  if (!audit) return null;
  return (
    <>
      <Stack
        bg="auditHeader.bg"
        direction="row"
        flexWrap={['wrap', 'wrap', 'nowrap']}
        justify="space-between"
        mb="15px"
        pl={6}
        spacing={[0, 0, 8]}
        w="full"
        zIndex={1}
      >
        <Flex direction="column" overflow="hidden">
          <Stack align={['flex-start', 'center']} direction="row" h={['auto', '40px']} mb="15px" spacing={[2, 4]}>
            <Flex
              align="center"
              bg="white"
              color="auditHeader.goBackColor"
              cursor="pointer"
              fontSize="14px"
              h="35px"
              justify="center"
              onClick={() => navigateTo('/audits')}
              rounded="10px"
              w="35px"
            >
              <ChevronRight sx={{ display: 'flex', align: 'center', justify: 'center' }} transform="Rotate(180deg)" />
            </Flex>
            <Flex maxW="clamp(25rem,50%,80%)">
              <Tooltip fontSize="sm" label={`${audit.reference} ${audit.title}`}>
                <Heading
                  alignItems={['flex-start', 'center']}
                  color="auditHeader.heading"
                  fontSize="xxl"
                  fontWeight="bold"
                  isTruncated
                  maxW="350px"
                  mr="10px"
                  w="full"
                >
                  {audit.reference} {audit.title}
                </Heading>
              </Tooltip>
              <Can
                action="audits.edit"
                data={{ auditType: audit.auditType }}
                yes={() => (
                  <Flex
                    align="center"
                    bg="#FFFFFF"
                    cursor="pointer"
                    height="28px"
                    justify="center"
                    onClick={() => editAudit(audit, refetchAudit)}
                    rounded="6px"
                    width="28px"
                  >
                    <EditIcon color="#1E1836" />
                  </Flex>
                )}
              />
            </Flex>
          </Stack>
          <AuditMetadata audit={audit} />
        </Flex>
        <Flex direction={['column', 'row']} maxW="calc(100% - 500px)" pr="25px">
          <ActionsSeverityTable
            auditType={audit.auditType?.type}
            data={applicableActions}
            loading={loadingApplicableActions}
            p="0"
            sx={{ td: { bg: '#FFFFFF' } }}
            title="Applicable Actions"
            w='full'
          />
        </Flex>
      </Stack>
    </>
  );
};

export default AuditHeader;

export const auditHeaderStyles = {
  auditHeader: {
    goBackColor: '#818197',
    bg: '#E5E5E5',
    heading: '#282F36',
    badge: '#FF9A00',
    badgeBg: 'rgba(255, 154, 0, 0.1)',
    badgeColorScheme: 'orange',
    buttonDarkBg: '#818197',
    buttonDarkColor: '#FFFFFF',
    buttonDarkBgHover: '#FFFFFF',
    buttonDarkColorHover: '#818197',
    buttonLightBg: '#FFFFFF',
    buttonLightColor: '#818197',
    buttonLightBgHover: '#818197',
    buttonLightColorHover: '#FFFFFF',
    snapshot: {
      color: '#ff7000',
    },
  },
};
