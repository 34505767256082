import { Box, Flex, IconButton, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { differenceInDays, format, formatDistanceToNow, startOfDay } from 'date-fns';
import h2p from 'html2plaintext';
import { capitalize } from 'lodash';

import { answerSeveritiesLabels } from '../../bootstrap/config';
import { useViewModalContext } from '../../contexts/ViewModalProvider';
import useNavigate from '../../hooks/useNavigate';
import { EditIcon, OpenExternalIcon, QuestionMark, SpreadIcon } from '../../icons';
import DocumentIcon from '../../icons/DocumentIcon';
import { IAction } from '../../interfaces/IAction';
import UserAvatar from '../UserAvatar';

const ActionTrackerListItem = ({ action, px = '0' }: { action: IAction; px?}) => {
  const { editAction } = useViewModalContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { openInNewTab, isPathActive, navigateTo } = useNavigate();
  const isAuditDetailsPage = isPathActive(`/audits/`);

  // const defaultValues: Partial<{ checked: boolean }> = {
  //   checked: false,
  // };

  // const { control } = useForm({
  //   mode: 'all',
  //   defaultValues,
  // });

  return (
    <>
      <Box
        bg="white"
        borderBottomColor="actionsList.headerBorderColor"
        borderBottomWidth="1px"
        cursor="pointer"
        onClick={() => editAction(action)}
        px={px}
        py={[1, 0]}
        w="full"
      >
        <Flex align="center" h={['full', '73px']} position="relative" w="full">
          <Flex align="center" w="5%">
            {/* <Checkbox control={control} name="checked" /> */}
            <UserAvatar size="xs" user={action.assignee} withTooltip />
          </Flex>
          <Flex flexDir="column" w="15%">
            <Flex
              align="flex-start"
              color="actionsList.fontColor"
              fontSize="smm"
              fontWeight="400"
              h="50%"
              lineHeight="18px"
              noOfLines={1}
              opacity="1"
              pt="3px"
              textOverflow="ellipsis"
              w="85%"
            >
              <Text isTruncated>{h2p(action?.title || '')}</Text>
            </Flex>
          </Flex>
          <Flex flexDir="column" w="10%">
            <Flex
              align="flex-start"
              color={`severities.${action?.answer?.severity}`}
              fontSize="smm"
              fontWeight="700"
              h="50%"
              lineHeight="18px"
              opacity="1"
              pt="3px"
              textOverflow="ellipsis"
            >
              <Tooltip hasArrow label={action?.answer?.severity && answerSeveritiesLabels[action?.answer?.severity]}>
                <Text isTruncated w="85%">
                  {isPathActive('/audits', { exact: true }) && `${capitalize(action?.answer?.audit?.auditType?.name)} - `}
                  {action?.answer?.severity && answerSeveritiesLabels[action?.answer?.severity]}
                </Text>
              </Tooltip>
            </Flex>
          </Flex>
          <Flex w="9%">
            <Flex color="actionsList.fontColor" fontSize="smm" fontWeight="400" isTruncated opacity="1" w="85%">
              <Text isTruncated>{capitalize(action?.classification)}</Text>
            </Flex>
          </Flex>
          <Flex
            _hover={{ textDecoration: 'underline', cursor: 'pointer' }}
            align="center"
            direction="row"
            onClick={(e) => {
              e.stopPropagation();
              openInNewTab(`/audits/${action?.answer?.scope?._id}?findingId=${action?.answer?._id}`);
            }}
            pr={2}
            w="15%"
          >
            <Text color="actionSquare.section.text" fontSize="smm" fontWeight="semi_medium" isTruncated>
              {action?.answer?.finding}
            </Text>
            <OpenExternalIcon fill="transparent" stroke="actionSquare.section.text" w="12px" />
          </Flex>
          <Flex w="8%">
            <Flex align="center">
              <Flex color="#1E1836" fontSize="smm">
                <Text>{action?.dueDate ? format(new Date(action.dueDate), 'dd MMM yyyy') : 'No due date'}</Text>
                {action?.extensions?.some((extension) => extension.isActive) && (
                  <Tooltip hasArrow label="Extension has been requested">
                    <Flex align="center" borderRadius="5px" fontSize="12px" h="19px" justify="center" p={0.5} w="20px">
                      <QuestionMark fill="#FF9A00" />
                    </Flex>
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex w="7.5%">
            <Flex align="center">
              <Flex color="#1E1836" fontSize="smm">
                {action.status !== 'overdue'
                  ? 'N/A'
                  : `${action.daysOverdue} days`}
              </Flex>
            </Flex>
          </Flex>
          <Flex w="7.5%">
            <Flex align="center">
              <Flex color="#1E1836" fontSize="smm">
                {`${action.daysOpen} days`}
              </Flex>
            </Flex>
          </Flex>
          <Flex w="10%">
            <Flex align="center">
              <Flex color="#1E1836" fontSize="smm" fontWeight="700">
                <Flex align="center">
                  <Text
                    color={
                      action?.status !== 'closed' && differenceInDays(startOfDay(new Date(action.dueDate)), startOfDay(new Date())) < 0
                        ? '#E93C44'
                        : '#1E1836'
                    }
                    fontSize="14px"
                    fontWeight="bold"
                  >
                    {capitalize(action?.status)}
                  </Text>
                  {action?.status === 'under review' && (
                    <Flex align="center" borderRadius="2px" fontSize="12px" h="fit-content" justify="center" ml="0.25" p="0.5">
                      <Tooltip label="Action submitted for review">
                        <QuestionMark fill="#3C6CE9" />
                      </Tooltip>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex w="10%">
            <Flex align="center">
              <Flex color="#1E1836" fontSize="smm" fontWeight="400">
                {action?.metatags?.addedAt ? formatDistanceToNow(new Date(action?.metatags?.addedAt)) : 'age not provided'}
              </Flex>
            </Flex>
          </Flex>
          <Flex justify="flex-end" w="3%">
            <Menu autoSelect={false} closeOnBlur closeOnSelect={false} isOpen={isOpen} onClose={onClose}>
              <MenuButton
                _active={{ bg: '#ffffff' }}
                _hover={{ bg: '#ffffff' }}
                aria-label="menu"
                as={IconButton}
                bg="#ffffff"
                color="#1E1836"
                h="24px"
                icon={<SpreadIcon h="14px" w="14px" />}
                minW="24px"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpen();
                }}
                w="24px"
              />
              <MenuList border="none" boxShadow="simple" p={2} rounded="lg" w="100px" zIndex={2}>
                <MenuItem
                  color="actionSquare.header.menuItemColor"
                  fontSize="14px"
                  onClick={(e) => {
                    e.stopPropagation();
                    editAction(action);
                  }}
                >
                  <EditIcon mr={3} stroke="currentColor" />
                  Edit Action
                </MenuItem>

                {!isAuditDetailsPage && (
                  <>
                    <MenuDivider opacity="0.1" w="calc(full - 4px)" />
                    <MenuItem
                      color="actionSquare.header.menuItemColor"
                      fontSize="14px"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigateTo(`/audits/${action.answer?.audit?._id}`);
                      }}
                    >
                      <DocumentIcon mr={3} stroke="currentColor" />
                      Related Audit
                    </MenuItem>
                  </>
                )}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default ActionTrackerListItem;

export const actionListItemStyles = {
  severities: {
    severe: 'purple',
    major: '#E93C44',
    minor: '#FF9A00',
    ofi: '#41B916',
    mandatory: '#E93C44',
    recommendation: '#41B916',
  },
};
